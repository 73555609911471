import { BrandclubFooter } from "@brandclub/common-ui";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import axios from "../../../utils/axios";

import { useSiteContext } from "../../SiteProvider";
import { withFadeTransition } from "../../StoreComponents/StoreTransition";
import { buildFooterConfig } from "./BrandStore/config";
import { useEffect, useMemo, useState } from "react";
import { sumCampaignRewardCounts } from "../../../utils/misc";
import ConfigUtils from "../../../utils/ConfigUtils";
import reduxApolloClient from "../../../redux/reduxApolloClient";
import { getRewardAmountsByStatus } from "../../../api";
import AppLink from "../../AppNavigation/AppLink";
import { useUserLogin } from "../Auth/UserLoginProvider";
import { styled } from "@mui/material";

const MainContentContainer = styled("div")({
  flex: 1,
});

const DtcFooterOutlet = () => {
  const { signedIn } = useUserLogin();
  const appConfig = useAppSelector(({ appConfig }) => appConfig);
  const { siteBrandingType, site, persistentQueryParams, routePrefix } =
    useSiteContext();
  const userProfile = useAppSelector(({ userProfile }) => userProfile);
  const [rewardSummaryForBrand, setRewardSummaryForBrand] = useState<{
    lifetimeEarnings: number;
    lifetimePayouts: number;
    lifetimeAdjustments: number;
    lockedBalance: number;
    pendingBalance: number;
    unlockedBalance: number;
    cancelledBalance: number;
    hasLoaded: boolean;
  }>({
    lifetimeEarnings: 0,
    lifetimePayouts: 0,
    lifetimeAdjustments: 0,
    lockedBalance: 0,
    pendingBalance: 0,
    unlockedBalance: 0,
    cancelledBalance: 0,
    hasLoaded: false,
  });

  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);
  const totalRewardCounts = useMemo(() => {
    return brandEntity?.brandclub
      ? sumCampaignRewardCounts(brandEntity.brandclub)
      : 0;
  }, [brandEntity?.brandclub]);

  useEffect(() => {
    (async () => {
      if (brandEntity?.brandclub && userProfile?.userId) {
        const variables = {
          brandIds: brandEntity?.brandclub?.brandIds,
          forceRefresh: true,
        };
        const { data } = await reduxApolloClient.query({
          query: getRewardAmountsByStatus,
          variables,
          context: {
            uri: `${ConfigUtils.getGraphQlUri()}?id=getRewardAmountsByStatus`,
          },
        });
        if (data && data.RewardSummary) {
          setRewardSummaryForBrand({ ...data.RewardSummary, hasLoaded: true });
        }
      }
    })();
  }, [brandEntity?.brandclub, userProfile]);

  const footerConfigs = useMemo(() => {
    const config: any = buildFooterConfig(
      siteBrandingType,
      rewardSummaryForBrand.lifetimeEarnings -
        rewardSummaryForBrand.lifetimePayouts -
        rewardSummaryForBrand.lifetimeAdjustments,
      totalRewardCounts,
      site,
      routePrefix,
      persistentQueryParams,
      signedIn
    );

    config.customText = appConfig?.domainConfig?.storeDescription;

    return [config];
  }, [
    signedIn,
    appConfig?.domainConfig?.storeDescription,
    siteBrandingType,
    rewardSummaryForBrand,
    totalRewardCounts,
    site,
    routePrefix,
    persistentQueryParams,
  ]);

  return (
    <div
      id={"dtc_footer"}
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <MainContentContainer>
        <Outlet />
      </MainContentContainer>

      <BrandclubFooter
        LinkComponent={AppLink}
        axios={axios}
        brandEntity={brandEntity as any}
        footerConfigs={footerConfigs}
        userProfile={userProfile}
        socialLinks={appConfig?.socialLinks}
      />
    </div>
  );
};

export default withFadeTransition(DtcFooterOutlet);
