import { styled } from "@mui/material";

import { useAppSelector } from "../../../../redux/hooks";
import { Retailer } from "../../../../redux/types";

import Loading from "../../../Loading";
import { SolidButton } from "../../../StoreComponents/StoreButton";
import { useSyncContext } from "../../../SyncProvider";
import { Link, useNavigate } from "react-router-dom";

import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import {
  RetailerIconGroup,
  SuccessCheckCircleIcon,
  CancelIcon,
} from "@brandclub/common-ui";
import { useEffect } from "react";
import {
  ParentRetailerSyncSession,
  StoreBrandingType,
} from "../../../../types/misc";
import useStoreType from "../../../../utils/hooks/useStoreType";

const RetailerListContainer = styled("div")({
  marginTop: 10,
  width: "100%",
  maxWidth: 380,
  ".row": {
    display: "flex",
    padding: "7.5px 10px",
    height: 70,
    boxSizing: "border-box",
    alignItems: "center",

    ".name": {
      flex: 1,
      fontSize: 18,
      fontWeight: 600,
      marginLeft: 14,
      marginRight: 14,
    },
    ".status": {
      display: "flex",
      alignItems: "center",
      ".indicator": {
        width: 30,
        height: 30,
        objectFit: "contain",
      },
    },
  },
});

const Container = styled("div")({
  maxWidth: 486,
  width: "100%",
  margin: "0 auto",
  padding: "34px 54px",
  borderRadius: 11,
  border: "1.5px solid #dedede",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflow: "hidden",
  backgroundColor: "#fff",
  position: "relative",
  zIndex: 1,
  marginTop: 80,
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    marginTop: 60,
  },
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    maxWidth: "100%",
    width: "unset",
    padding: "34px 23px",
    marginTop: 20,
  },
  ".title": {
    fontSize: 24,
    fontWeight: 700,
    textAlign: "center",
    marginBottom: 10,
  },
  ".subtitle": {
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
  },
});

const SyncContainer = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <Container className="sync-container">
      <div>
        <div>{children}</div>
      </div>
    </Container>
  );
};

const StatusWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  ".subtitle": {
    maxWidth: 424,
  },
});

const SyncButton = styled(SolidButton)({
  fontSize: 14,
  width: "100%",
  maxWidth: 345,
  margin: "20px 0 0 0",
  height: 50,
});

const RetailerRow = ({
  parentRetailer,
}: {
  parentRetailer: ParentRetailerSyncSession;
}) => {
  const retailers = useAppSelector((state) => state.retailers);

  const retailerGroups = retailers?.filter(
    (r) => r.parentRetailerId === parentRetailer.parentRetailerId
  );

  const status =
    parentRetailer.syncStatus === "completed"
      ? "success"
      : ["timed_out", "error"].includes(parentRetailer.syncStatus)
      ? "failed"
      : "in_progress";

  const retailerIdGroup = retailerGroups?.map((r) => r.retailerId);

  if (retailerIdGroup && retailerGroups?.length) {
    return (
      <div className="row">
        <RetailerIconGroup retailerIds={retailerIdGroup} iconSize={35} />
        <div className="name">
          {getDisplayName(retailerGroups[0], retailerGroups.length)}
        </div>
        <div className="status">
          {status === "in_progress" ? (
            <div style={{}}>
              <Loading storeSpin size={30} />
            </div>
          ) : null}
          {status === "failed" ? (
            <CancelIcon
              backgroundColor="primary"
              htmlColor="#fff"
              className="indicator"
            />
          ) : null}
          {status === "success" ? (
            <SuccessCheckCircleIcon className="indicator" />
          ) : null}
        </div>
      </div>
    );
  }
  return null;
};

export const RetailerList = ({
  syncStatusByParentRetailer,
  ...props //used to allow styled(RetailerList) to work properly
}: {
  syncStatusByParentRetailer: ParentRetailerSyncSession[];
}) => {
  if (syncStatusByParentRetailer.length <= 0) {
    return <Loading dot></Loading>;
  }

  return (
    <RetailerListContainer {...props}>
      {[...syncStatusByParentRetailer]
        .sort((a, b) => a.parentRetailerId - b.parentRetailerId)
        .map((r) => {
          return (
            <RetailerRow
              key={r.parentRetailerId}
              parentRetailer={r}
            ></RetailerRow>
          );
        })}
    </RetailerListContainer>
  );
};

const getDisplayName = (retailer: Retailer, length: number) => {
  if (length === 1) {
    return retailer.displayName;
  }
  return "" + (retailer.parentDisplayName ?? retailer.displayName) + " group";
};

const renderActionButton = (
  syncStatus:
    | "connecting"
    | "syncing"
    | "checking_rewards"
    | "error"
    | "completed",
  latestSyncBatchId: string | undefined = ""
) => {
  switch (syncStatus) {
    case "connecting":
    case "syncing":
      return <SyncButton disabled>View rewards summary</SyncButton>;
    case "checking_rewards":
      return (
        <Link
          to={`/sync/summary/${latestSyncBatchId}`}
          style={{ textDecoration: "none", width: "100%", maxWidth: 345 }}
        >
          <SyncButton trackedAdditionalData={{ latestSyncBatchId, syncStatus }}>
            View rewards summary
          </SyncButton>
        </Link>
      );
    case "error":
      return (
        <Link
          to={`/dashboard/accounts`}
          style={{ textDecoration: "none", width: "100%", maxWidth: 345 }}
        >
          <SyncButton trackedAdditionalData={{ latestSyncBatchId, syncStatus }}>
            View account details
          </SyncButton>
        </Link>
      );
    default:
      return null;
  }
};

const Sync = () => {
  const navigate = useNavigate();
  const {
    syncStatusByRetailer,
    syncStatusByParentRetailer,
    latestSyncBatchId,
    slowestSyncSessionWithStatusMessage,
    loading,
    syncStatus,
  } = useSyncContext();
  const storeType = useStoreType();
  const { title, message, status } =
    slowestSyncSessionWithStatusMessage?.displayMessage || {};

  useEffect(() => {
    if (storeType === StoreBrandingType.CustomDTCStore) {
      // dtc handles sync differently
      if (syncStatus === "normal" && status === "completed") {
        navigate(`/accounts`, { replace: true });
      } else if (
        syncStatus === "normal" &&
        status === "error" &&
        syncStatusByRetailer[0].retailerId
      ) {
        navigate(`/connect`, {
          replace: true,
          state: {
            error: message,
            retailerId: syncStatusByRetailer[0].retailerId,
          },
        });
      }
    } else {
      // universal sync handling
      if (syncStatus === "normal" && status === "completed") {
        navigate(`/sync/summary/${latestSyncBatchId}`);
      }
    }
  }, [
    status,
    latestSyncBatchId,
    syncStatus,
    navigate,
    storeType,
    message,
    syncStatusByRetailer,
  ]);

  return (
    <SyncContainer>
      {loading ? (
        <Loading dot></Loading>
      ) : (
        <StatusWrapper>
          <div className="title">{title}</div>
          <div className="subtitle">{message}</div>
          <RetailerList
            syncStatusByParentRetailer={syncStatusByParentRetailer}
          />
          {status ? renderActionButton(status, latestSyncBatchId) : null}
        </StatusWrapper>
      )}
    </SyncContainer>
  );
};

export default Sync;
