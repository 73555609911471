import React, { useContext } from "react";

export enum CustomThemeKeys {
  ADD_TO_BAG_BUTTON = "addToBagButton",
  OUTLINED_BUTTON = "outlinedButton",
  SHARE_DIALOG_BUTTON = "shareDialogButton",
  CART_BUTTON = "cartButton",
}
type CustomThemeType = {
  primary: string;
  secondary: string;
  tertiary: string;
  enabled: boolean;
  capitalize?: boolean;
  [key: string]: any;
};

export type StyleDataType = {
  primary: string;
  secondary: string;
  tertiary: string;
  componentStyleOverrides: {
    [CustomThemeKeys.ADD_TO_BAG_BUTTON]: CustomThemeType;
    [CustomThemeKeys.OUTLINED_BUTTON]: CustomThemeType;
    [CustomThemeKeys.SHARE_DIALOG_BUTTON]: CustomThemeType;
    [CustomThemeKeys.CART_BUTTON]: CustomThemeType;
  };
  font: {
    fontFamily: string;
    baseFontSize: number;
  };
  [key: string]: any;
};

export const StoreThemeContext = React.createContext({});

const StoreThemeProvider = ({
  children,
  theme,
}: {
  children: any;
  theme: any;
}) => {
  return (
    <StoreThemeContext.Provider value={theme}>
      {children}
    </StoreThemeContext.Provider>
  );
};

export const useStoreTheme = () => useContext(StoreThemeContext);

export default StoreThemeProvider;
