import React from "react";
import { SubmitRes } from "../ContactForm";
import DownloadApp from "../DownloadApp";
import InitializeUserData from "../pages/Auth/InitializeUserData";
import { AppRoutesWithChildrenProps } from "./routeBuilder";

const AboutPage = React.lazy(() => import("../pages/AppPages/About"));
const AppShare = React.lazy(() => import("../AppShare"));
const California = React.lazy(
  () => import("../pages/AppPages/CaliforniaPolicy")
);
const CareerPage = React.lazy(() => import("../CareerPage"));
const ContactPage = React.lazy(() => import("../pages/AppPages/Contact"));
const ContactForm = React.lazy(
  () => import("../pages/AppPages/Contact/ContactForm")
);

const Endorsement = React.lazy(() => import("../pages/AppPages/Endorsement"));
const FAQ = React.lazy(() => import("../pages/AppPages/FAQ"));
const GetApp = React.lazy(() => import("../pages/AppPages/GetApp/GetApp"));
const Influencer = React.lazy(() => import("../pages/AppPages/Influencer"));
const Invite = React.lazy(() => import("../Invite"));
const PartnerPage = React.lazy(() => import("../pages/AppPages/Partner"));
const Privacy = React.lazy(() => import("../pages/AppPages/Privacy"));
const RewardTerms = React.lazy(() => import("../pages/AppPages/RewardTerms"));
const Terms = React.lazy(() => import("../pages/AppPages/Terms"));

/**
 * General routes for standalone pages
 */
export const generalRoutes: AppRoutesWithChildrenProps[] = [
  { path: "invite", element: <Invite /> },
  { path: "appshare", element: <AppShare /> },
  { path: "career", element: <CareerPage /> },
  { path: "downloadApp", element: <DownloadApp /> },
  { path: "support", element: <ContactForm formType="support" /> },
  { path: "initialize/", element: <InitializeUserData /> },
];

const contactusRoutes: AppRoutesWithChildrenProps[] = [
  { path: "contact", element: <ContactPage /> },
  { path: "contact/res", element: <SubmitRes /> },
  { path: "contact/business", element: <ContactForm formType="business" /> },
  { path: "contact/demo", element: <ContactForm formType="demo" /> },
  { path: "contact/media", element: <ContactForm formType="media" /> },
  { path: "contact/waitlist", element: <ContactForm formType="waitlist" /> },
  { path: "contact/support", element: <ContactForm formType="support" /> },
];

export const termsOfUseRoutes: AppRoutesWithChildrenProps[] = [
  { path: "privacy", element: <Privacy /> },
  { path: "californiaprivacy", element: <California /> },
  { path: "terms", element: <Terms /> },
  { path: "endorsement", element: <Endorsement /> },
  { path: "rewardterms", element: <RewardTerms /> },
];

/**
 * General routes to be nested under AppMainPageOutlet
 */
export const appGeneralRoutes: AppRoutesWithChildrenProps[] = [
  { path: "about", element: <AboutPage /> },
  { path: "getapp", element: <GetApp /> },
  { path: "faq", element: <FAQ /> },
  { path: "influencer", element: <Influencer /> },
  { path: "partners", element: <PartnerPage /> },
  ...contactusRoutes,
  ...termsOfUseRoutes,
];
