import { styled } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import {
  DESKTOP_HORIZONTAL_PADDING,
  DESKTOP_SCREEN_SIZE,
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";

import { useLocation } from "react-router";
import { useSignInPage } from "../../../StoreComponents/StoreLogin/useStoreLogin";
import { getUserSignedInState } from "../../../../Auth";

export const MainContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: "5%",
  paddingRight: "5%",
  boxSizing: "border-box",
  color: theme.palette.primary.main,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    backgroundColor: "#fff",
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    backgroundColor: "#fff",
  },
}));

export const MainWrapper = styled("div")({
  maxWidth: DESKTOP_SCREEN_SIZE - DESKTOP_HORIZONTAL_PADDING * 2,
  width: "100%",
  display: "flex",
  // flexDirection: "column",
  // alignItems: "center",
  justifyContent: "center",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
});

export const MainOutlet = ({ children }: { children: React.ReactNode }) => {
  return (
    <MainContainer>
      <MainWrapper>{children}</MainWrapper>
    </MainContainer>
  );
};

type UserLoginStatus = "notChecked" | "loggedIn" | "notLoggedIn";

// const canNavigateBack = () => {
//   // if the user's history length is greater than 1 and the user is navigating back/forward, return true.
//   return window.history.length > 1;
// };

export const LoggedInMainOutlet = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userLoginStatus, setUserLoginStatus] =
    useState<UserLoginStatus>("notChecked");
  const userProfile = useAppSelector(({ userProfile }) => userProfile);
  const signIn = useSignInPage();
  const location = useLocation();
  // const navigate = useNavigate();
  // const navigationType = useNavigationType();
  /**
   * TODO: Fix signed-out user navigation after BrandStore rewards redirect
   *
   * This code in `redirectUser` was commented out:
   * // canNavigateBack() ? navigate(-1) : navigate("/", { replace: true });
   *
   * It broke the ability to return to previous page from the sign in page after a redirect.
   *
   * To reproduce:
   *  1. sign out
   *  2. navigate to a brandstore's reward page
   *  3. you should be redirected to sign in page. Try to click x or back button
   *  4. if it works, you should go back to where you were redirected from, if it doesn't you'll be stuck in the sign in page
   *
   * Restore this functionality while addressing the reason for commenting out.
   */
  const redirectUser = useCallback(
    (loginStatus: UserLoginStatus) => {
      /**
       * Navigation actions:
       *   - Clicking a link: "PUSH"
       *   - Refreshing the page or navigating with replace: "REPLACE"
       *   - Using the browser's back or forward buttons: "POP"
       * If the action is not a "PUSH", redirect to the previous page to prevent the user from getting stuck on a redirect loop to and from the login page.
       **/
      // if (navigationType !== "PUSH") {
      //   canNavigateBack() ? navigate(-1) : navigate("/", { replace: true });
      //   return;
      // }
      // Redirect to the login page if the user is not signed in.
      if (loginStatus === "notLoggedIn") {
        signIn(location);
        return;
      }
    },
    [signIn, location]
  );

  useEffect(() => {
    const checkUserInfo = async () => {
      try {
        const { userInfo, signedIn } = await getUserSignedInState();

        if (userInfo && signedIn) {
          setUserLoginStatus("loggedIn");
        } else {
          redirectUser("notLoggedIn");
          setUserLoginStatus("notLoggedIn");
        }
      } catch (error) {
        redirectUser("notLoggedIn");
        setUserLoginStatus("notLoggedIn");
      }
    };
    if (!userProfile) {
      checkUserInfo();
    } else {
      setUserLoginStatus("loggedIn");
    }
  }, [userProfile, redirectUser]);

  if (userLoginStatus === "notChecked") {
    return null;
  }

  if (userLoginStatus === "notLoggedIn") {
    return (
      <MainOutlet>
        <div></div>
      </MainOutlet>
    );
  }

  return (
    <MainContainer className="main_container" sx={{ paddingTop: "30px" }}>
      <MainWrapper>{children}</MainWrapper>
    </MainContainer>
  );
};
