import {
  CampaignType,
  RewardPillWithText,
  getAppConsistentParams,
  getProductUrl,
} from "@brandclub/common-ui";
import { styled, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { decodeHTMLCharacters } from "@stackline/ui";
import _startCase from "lodash/startCase";
import { UserPurchaseHistory } from "../../../../../types/misc";
import { formatMoney, getEntityImage } from "../../../../../utils/StringUtils";
import {
  OutlineButton,
  SolidButton,
} from "../../../../StoreComponents/StoreButton";
import { CardBrand, getLogoUrlForCardIssuer } from "../Wallet/CardBrandIcon";

import KeyboardArrowRightRounded from "@mui/icons-material/KeyboardArrowRightRounded";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";
import { Address as AddressType } from "../../../../../redux/types";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import { StyledLink } from "../../../../AppNavigation/StyledLink";
import { useCheckout } from "../../../../Checkout/CheckoutProvider";
import { useCheckoutDrawer } from "../../../../Checkout/useCheckoutDrawer";
import {
  isPurchaseCheckoutSuccess,
  sumItemQuantities,
} from "../../../../Checkout/utils";
import ProductImageWithQuantity from "./ProductImageWithQuantity";
import moment from "moment";
import { getShipmentStatus } from "./purchaseUtil";
import { QuickLinksContainer, SingleExternalLink } from "..";
import { ComponentProps } from "react";
import { margin } from "@mui/system";

interface PurchaseDetailBreakdownProps
  extends ComponentProps<typeof Container> {
  purchase: UserPurchaseHistory;
}

type OrderProduct = ReturnType<typeof getAllProductOnOrder>[0];
interface ProductRowProps {
  product: OrderProduct;
  checkoutSuccess?: boolean;
}
const getAllProductOnOrder = (order: UserPurchaseHistory) => {
  // get item's first key
  const products = Object.entries(order.items).map(([key, value]) => {
    const product = order.products[value.stacklineSku];
    return {
      ...product,
      ...value,
    };
  });
  return products;
};

interface AddressProps {
  address?: AddressType;
}
const Address = (props: AddressProps) => {
  const { address } = props;
  const name = [address?.firstName, address?.lastName]
    .filter(Boolean)
    .join(" ");
  const stateAndZip = [address?.state, address?.zipCode]
    .filter(Boolean)
    .join(" ");
  if (!address) {
    return <span>--</span>;
  }
  return (
    <Box display="flex" flexDirection="column">
      {!!name && <span>{name}</span>}
      {!!address.address1 && <span>{address.address1}</span>}
      {!!address.address2 && <span>{address.address2}</span>}
      {!!address.city && <span>{address.city}</span>}
      {!!stateAndZip && <span>{stateAndZip}</span>}
    </Box>
  );
};

const HelpSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  ".help_title": {
    fontSize: "20px",
    fontWeight: 600,
    marginBottom: "30px",
  },
}));

const quickLinks = [
  {
    label: "Help center",
    subtitle: "Answers to the most common questions",
    path: "",
    linkType: "internal",
  },
  {
    label: "Returns or order issues",
    subtitle: "Let us connect you to the retailer",
    path: "",
    linkType: "external",
  },
];

const ProductRowContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 30,
  padding: "15px 0",
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    gap: 20,
  },
  width: "100%",
  ".p_logo": {
    width: 120,
    aspectRatio: "1/1",
    objectFit: "contain",
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      width: 92,
    },
  },
  ".product": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 6,
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gap: 4,
    },
    color: theme.palette.primary.main,
    ".product_price": {
      fontSize: 16,
      fontWeight: 600,
    },
    ".product_title": {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.primary.main,
      WebkitLineClamp: 2,
      lineHeight: 1.5,
      // height: `3em`, // line height * 2
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      wordBreak: "break-word",
      overflow: "hidden",

      maxWidth: 450,
    },
    ".reward": {},
  },
  ".track_package": {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 35,
  padding: "40px 0",
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "20px 0",
    gap: 15,
  },
  ".label": {
    fontSize: 16,
    fontWeight: 600,
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 14,
    },
  },
  ".value": {
    fontSize: 16,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 14,
    },
  },
  ".info": {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    justifyContent: "space-between",
    width: "100%",
    ".products": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 15,
      ".title": {
        fontSize: 20,
        fontWeight: 700,
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 16,
          fontWeight: 600,
        },
        color: theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        gap: 15,
        ".r_logo": {
          width: 35,
          aspectRatio: "1/1",
          objectFit: "contain",
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            width: 25,
          },
        },
      },
      ".list": {
        display: "flex",
        flexDirection: "column",
        gap: 10,
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          gap: 0,
        },
      },
    },
    ".action": {
      ".buttons": {
        display: "flex",
        flexDirection: "column",
        gap: 7,
      },
      ".link": {},
    },
  },
  ".breakdown": {
    display: "flex",
    flexDirection: "row",
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      flexDirection: "column",
    },
    gap: 20,
    color: theme.palette.primary.main,
    ".largeLabel": {
      fontSize: 20,
      fontWeight: 600,
    },
    ".summaryValue": {
      fontSize: 16,
      fontWeight: 600,
    },
    ".card_row": {
      display: "flex",
      flexDirection: "row",
      gap: 10,
      alignItems: "center",
      justifyContent: "space-between",
    },
    ".address_section": {
      flex: 1,
      display: "flex",
      flexDirection: "column",

      ".retailer_order": {
        display: "flex",
        flexDirection: "column",
        gap: 10,
        marginBottom: 40,
      },
      ".payment_details": {
        display: "flex",
        flexDirection: "column",
        gap: 30,
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          gap: 20,
        },
        ".label": {
          paddingBottom: 10,
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            paddingBottom: 4,
          },
        },
        ".payment_details__billing_shipping": {
          gap: 22,
          display: "flex",
          flexDirection: "column",
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 50,
          },
        },
      },
    },
    ".card_section": {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      width: "100%",
      maxWidth: 321,
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        gap: 20,
        maxWidth: "unset",
      },
      ".card_info": {
        display: "flex",
        flexDirection: "column",
        gap: 10,
      },
      ".checkout_summary": {
        display: "flex",
        flexDirection: "column",
        gap: 6,
      },
    },
  },
  ".order_info_sum": {
    marginTop: 20,
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    ".info_sum_title": {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 10,

      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 14,
      },
    },
    ".info_sum_value": {
      fontSize: 16,
      fontWeight: 500,
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 14,
      },
    },
  },
}));

const useBuyAgain = () => {
  const { addItemsToCart } = useCheckout();
  const { openDrawer } = useCheckoutDrawer();

  const buyAgain = async (products: Array<ProductRowProps["product"]>) => {
    await addItemsToCart(
      products.map((product) => ({
        ...product,
        quantity: 1,
      }))
    );
    openDrawer();
  };
  return buyAgain;
};

const getProductReward = (product: ProductRowProps["product"]) => {
  if (!product?.reward) {
    return undefined;
  }
  const rewardAmount = product.reward.rewardAmount;
  const rewardPercent =
    !!product.retailPrice && !!rewardAmount
      ? (rewardAmount * 100) / product.retailPrice
      : 0;
  if (!rewardAmount) {
    return undefined;
  }
  return {
    hasActiveInstantReward:
      product.reward.rewardType === "limitedTimePurchaseCashBackReward",
    rewardAmount,
    rewardPercent,
  };
};

const ProductRow = ({ product, checkoutSuccess }: ProductRowProps) => {
  const buyAgain = useBuyAgain();
  const productReward = getProductReward(product);
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const appConfig = useAppSelector((state) => state.appConfig);
  const urlSearchParams = getAppConsistentParams(window.location.search);
  const targetUrl = getProductUrl({
    title: product.title,
    stacklineSku: product.stacklineSku,
    urlSearchParams,
    dtcProductPageUrl: product.dtcProductPageUrl,
    storeType: appConfig?.domainConfig?.storeBrandingType,
  });
  return (
    <ProductRowContainer>
      <Link to={targetUrl} style={{ textDecoration: "none" }}>
        <ProductImageWithQuantity
          imageProps={{ className: "p_logo" }}
          quantity={product.quantity}
          url={product.imageUrls[0]}
          height="unset"
        />
      </Link>
      <div className="product">
        <Box display="flex" gap="10px">
          <span className="product_price">
            {formatMoney(product.retailPrice)}
          </span>
          {product.canWriteReview && (
            <span className="product_price">
              <StyledLink
                to={`/review/${product.stacklineSku}`}
                state={{ product }}
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                Write review
              </StyledLink>
            </span>
          )}
        </Box>
        <Link to={targetUrl} style={{ textDecoration: "none" }}>
          <div className="product_title">
            {decodeHTMLCharacters(product.title)}
          </div>
        </Link>
        {isMobileView && (
          <span className="value">
            {getShipmentStatus(product.orderTracking?.shipmentStatus)}
          </span>
        )}
        {!!productReward && (
          <RewardPillWithText
            rewardAmount={productReward.rewardAmount}
            containerStyle={{
              marginTop: 6,
            }}
            pillContainerStyle={{
              boxSizing: "border-box",
              padding: "3.2px 11.4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            rewardPercent={productReward.rewardPercent}
            rewardCampaignType={
              productReward.hasActiveInstantReward
                ? CampaignType.DailyDealsRewardCampaign
                : CampaignType.PurchaseRewardCampaign
            }
          />
        )}
      </div>
      {!isMobileView && (
        <div
          className="action"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            gap: 3,
          }}
        >
          <div className="buttons">
            <SolidButton
              trackedAdditionalData={{
                product,
              }}
              style={{ minWidth: 124, width: 124, padding: 0, height: 35 }}
              onClick={() => buyAgain([product])}
            >
              Buy again
            </SolidButton>
            {checkoutSuccess && product.canWriteReview && (
              <StyledLink
                to={`/review/${product.stacklineSku}`}
                state={{ product }}
                sx={{ textDecoration: "none" }}
              >
                <OutlineButton
                  trackedAdditionalData={{
                    product,
                  }}
                  style={{
                    minWidth: 124,
                    width: 124,
                    padding: 0,
                    height: 35,
                  }}
                >
                  Write review
                </OutlineButton>
              </StyledLink>
            )}
          </div>
          <div style={{}}>
            <a
              href={(product as any).retailerOrderPageUrl}
              rel="noreferrer"
              target="_blank"
              style={{
                textDecoration: "none",

                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "inherit",
              }}
            >
              <div className="track_package">Track package</div>
              <KeyboardArrowRightRounded
                style={{ color: "inherit", fontSize: 21, marginRight: -7 }}
              />
            </a>
          </div>
        </div>
      )}
    </ProductRowContainer>
  );
};

const PurchaseDetailBreakdown = ({
  purchase,
  className,
  ...rest
}: PurchaseDetailBreakdownProps) => {
  const products = getAllProductOnOrder(purchase);
  const userProfile = useAppSelector((state) => state.userProfile);
  const { isInternalUser }: { isInternalUser?: boolean } =
    userProfile?.extendedAttributes ?? {};
  const retailerId = products[0].retailerId;
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const totalProductQuantity = sumItemQuantities(purchase.items);
  const paymentIcon = purchase?.paymentMethod?.cardIssuer
    ? getLogoUrlForCardIssuer(
        purchase?.paymentMethod?.cardIssuer.toLocaleLowerCase() as CardBrand
      )
    : null;
  const buyAgain = useBuyAgain();

  return (
    <Container
      className={["order_breakdown", className].filter(Boolean).join(" ")}
      {...rest}
    >
      <div className="info">
        <div className="products">
          <div className="title">
            <img
              className="r_logo"
              alt=""
              src={getEntityImage(retailerId, "retailer")}
            ></img>
            Order #{purchase.retailerOrderId || "--"}
          </div>
          <div className={"order_info_sum"}>
            <div className="order-detail__section">
              <div className="info_sum_title">Date ordered</div>
              <div className="info_sum_value">
                {moment(purchase.updatedTime).format("MMM DD, YYYY")}
              </div>
            </div>
            <div className="order-detail__section">
              <div className="info_sum_title">Items ordered</div>
              <div className="info_sum_value">{products.length}</div>
            </div>
            <div className="order-detail__section">
              <div className="info_sum_title">Quantity ordered</div>
              <div className="info_sum_value">{totalProductQuantity}</div>
            </div>
          </div>
          <div className="list">
            {products.map((product, index) => (
              <ProductRow
                key={index}
                product={product}
                checkoutSuccess={isPurchaseCheckoutSuccess(purchase)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="breakdown">
        <div className="address_section">
          {isInternalUser && (
            <div className="retailer_order">
              <div className="label">Brandclub Order</div>
              <div className="value">{purchase.orderId || "--"}</div>
            </div>
          )}
          <div className="payment_details">
            <div className="largeLabel">Payment details</div>
            <div className="payment_details__billing_shipping">
              <div>
                <div className="label">Billed to</div>
                <div className="value">
                  <Address address={purchase.billingAddress} />
                </div>
              </div>
              <div>
                <div className="label">Shipped to</div>
                <div className="value">
                  <Address address={purchase.shippingAddress} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card_section">
          {purchase.paymentMethod ? (
            <div className="card_info">
              <div className="label">Paid with</div>
              <div className="value">
                {paymentIcon ? (
                  <img
                    src={paymentIcon}
                    alt=""
                    style={{ width: 30, height: 30, marginRight: 8 }}
                  />
                ) : null}
                {`${_startCase(purchase.paymentMethod.cardIssuer ?? "Card")} ${
                  purchase.paymentMethod?.cardNumberEnding
                }`}
              </div>
            </div>
          ) : null}
          {purchase?.orderSummary ? (
            <div className="checkout_summary">
              <div className="card_row">
                <div className="value">Subtotal</div>
                <div className="value">
                  {formatMoney(purchase?.orderSummary.subtotal)}
                </div>
              </div>
              <div className="card_row">
                <div className="value">Tax</div>
                <div className="value">
                  {formatMoney(purchase?.orderSummary.tax)}
                </div>
              </div>
              <div className="card_row">
                <div className="value">Shipping</div>
                <div className="value">
                  {formatMoney(purchase?.orderSummary.shippingFees)}
                </div>
              </div>
              <div className="card_row">
                <div className="label">Total</div>
                <div className="value">
                  {formatMoney(purchase?.orderSummary.total)}
                </div>
              </div>

              {isMobileView && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    gap: "10px",
                    paddingTop: "24px", // 24px here + 6px from checkout_summary gap = 30px
                    paddingBottom: "20px",
                  }}
                >
                  <SolidButton
                    trackedAdditionalData={{
                      products,
                      purchase,
                    }}
                    sx={{
                      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                        maxWidth: "100%",
                      },
                    }}
                    style={{
                      width: "100%",
                      padding: 0,
                      height: 44,
                    }}
                    onClick={() => buyAgain(products)}
                  >
                    Buy again
                  </SolidButton>
                  <a
                    href={(products[0] as any)?.retailerOrderPageUrl}
                    rel="noreferrer"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      color: "inherit",
                    }}
                  >
                    <OutlineButton
                      trackedAdditionalData={{
                        products,
                      }}
                      sx={{
                        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                          maxWidth: "100%",
                        },
                      }}
                      style={{
                        width: "100%",
                        padding: 0,
                        height: 44,
                      }}
                    >
                      Track package
                    </OutlineButton>
                  </a>
                </Box>
              )}
            </div>
          ) : null}
        </div>
      </div>
      {!isMobileView && (
        <HelpSection>
          <div className="help_title">Need help?</div>
          <QuickLinksContainer>
            {quickLinks.map((link) => {
              link.path = (products[0] as any)?.retailerOrderPageUrl;
              return <SingleExternalLink quickLink={link} key={link.label} />;
            })}
          </QuickLinksContainer>
        </HelpSection>
      )}
    </Container>
  );
};

export default PurchaseDetailBreakdown;
