import { brandclub_colors, brandclubStyles } from "@brandclub/common-ui";
import { Dialog, styled } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { create } from "zustand";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setOrder } from "../../../redux/reducers/checkout";
import { SyncStatus } from "../../../types/misc";
import { TABLET_SCREEN_SIZE } from "../../AppNavigation/constants";
import Loading from "../../Loading";
import { OutlineButton } from "../../StoreComponents/StoreButton";
import { getRetailerCheckoutOrderStatus } from "../api";
import {
  UserRetailerCheckoutOrderStatus,
  UserRetailerCheckoutSessionStatus,
} from "../types";

import { useCheckout } from "../CheckoutProvider";

interface BearState {
  orderId: string;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  ".container": {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    color: theme.palette.primary.main,
    padding: "117px 63px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    ".title": {
      fontSize: 32,
      fontWeight: 700,
      textAlign: "center",
      marginBottom: 10,
    },
    ".subtitle": {
      fontSize: 24,
      fontWeight: 500,
      textAlign: "center",
      marginBottom: 10,
    },
  },

  "& div.MuiDialog-paper": {
    margin: 0,
    borderRadius: 10,
    minWidth: 789,
    maxWidth: 789,
    minHeight: 211,
    color: theme.palette.primary.main,
  },
  "& h2.MuiDialogTitle-root": {
    padding: 16,
  },

  "& .dialog-content": {
    padding: 0,
    overflowY: "auto",
    color: theme.palette.primary.main,
    fontFamily: "Poppins",
    ...brandclubStyles.customScrollbar,
  },
  // media queries
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    "& div.MuiDialog-paper": {
      width: "100vw",
      minWidth: "100vw",
      maxWidth: "100vw",
      maxHeight: "75vh",
      position: "absolute",
      bottom: 0,
    },
  },
}));

const useConfirmDialogStore = create<BearState>(() => ({
  orderId: "",
}));

const CloseButton = styled(OutlineButton)({
  fontSize: 16,
  height: 48,
  fontWeight: 600,
});
const CheckoutTransition = () => {
  const batch = useAppSelector((state) => state.checkout.batch);
  const order = useAppSelector((state) => state.checkout.order);
  const { checkoutNextOrder } = useCheckout();
  const { orderId } = useConfirmDialogStore();

  const [orderStatus, setOrderStatus] =
    useState<UserRetailerCheckoutOrderStatus | undefined>(undefined);
  const [sessionStatus, setSessionStatus] =
    useState<UserRetailerCheckoutSessionStatus | undefined>(undefined);
  const [syncStatus, setSyncStatus] =
    useState<SyncStatus | undefined>(undefined);
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [statusMessageDescription, setStatusMessageDescription] =
    useState<string>("");
  const batchOrderStatusRef =
    useRef<{
      [orderId: string]: UserRetailerCheckoutOrderStatus;
    }>();

  const resetStatus = () => {
    setOrderStatus(undefined);
    setSessionStatus(undefined);
    setSyncStatus(undefined);
  };

  const { pathname, search } = useLocation();

  const isAtCheckoutPage = pathname === "/checkout" && search.includes(orderId);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const shouldMoveToCheckout =
    orderStatus === "checkout_in_progress" &&
    sessionStatus === "checkout_waiting_for_user_input";

  const shouldSuccess = orderStatus === "checkout_success";

  const shouldShowError =
    orderStatus === "checkout_timed_out" ||
    orderStatus === "checkout_error" ||
    orderStatus === "checkout_cancelled";

  const pollingIntervalRef = useRef<NodeJS.Timeout>();

  const fetchOrderForPoll = useCallback(async () => {
    try {
      const res = await getRetailerCheckoutOrderStatus({
        orderId,
      });
      if (res?.userRetailerCheckoutOrder?.status) {
        batchOrderStatusRef.current = {
          ...batchOrderStatusRef.current,
          [orderId]: res.userRetailerCheckoutOrder.status,
        };
      }
      setOrderStatus(res?.userRetailerCheckoutOrder?.status);
      setSessionStatus(res?.userRetailerCheckoutSessionStatus?.status);
      setSyncStatus(res?.userRetailerSyncSessionStatus?.syncStatus);
      setStatusMessage(res?.displayStatusInfo?.title ?? "");
      setStatusMessageDescription(res?.displayStatusInfo?.description ?? "");

      dispatch(setOrder(res));
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, orderStatus, syncStatus, sessionStatus]);

  useEffect(() => {
    if (orderId) {
      // Start polling the order
      if (shouldMoveToCheckout) {
        closeDialog();
        if (!isAtCheckoutPage) {
          navigate({
            pathname: "checkout",
            search: createSearchParams({
              orderId,
            }).toString(),
          });
        }
      } else if (shouldShowError) {
        clearInterval(pollingIntervalRef.current);
      } else {
        pollingIntervalRef.current = setInterval(fetchOrderForPoll, 5000);
      }
    }

    return () => {
      // Stop polling when the component unmounts
      clearInterval(pollingIntervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAtCheckoutPage, orderId, fetchOrderForPoll]);

  useEffect(() => {
    if (orderId) {
      fetchOrderForPoll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const closeDialog = () => {
    useConfirmDialogStore.setState({
      orderId: "",
    });
    resetStatus();
  };
  const hasNextRetailerToCheckout =
    batch?.retailerIds &&
    batch?.retailerIds?.length > 1 &&
    batch?.retailerIds[batch.retailerIds.length - 1] !==
      order?.userRetailerCheckoutOrder?.retailerId;

  const handleSuccess = () => {
    useConfirmDialogStore.setState({
      orderId: "",
    });
    resetStatus();

    if (hasNextRetailerToCheckout) {
      checkoutNextOrder();
    } else {
      navigate(
        {
          pathname: "checkoutsuccess",
          search: createSearchParams({
            batchId: batch?.checkoutBatchId ?? "",
          }).toString(),
        },
        { replace: true }
      );
    }
  };

  const handleError = () => {
    useConfirmDialogStore.setState({
      orderId: "",
    });
    resetStatus();
    const hasAtLeastOneSuccessfulCheckout = Object.values(
      batchOrderStatusRef.current ?? {}
    ).some((status) => status === "checkout_success");
    if (hasAtLeastOneSuccessfulCheckout) {
      navigate(
        {
          pathname: "checkoutsuccess",
          search: createSearchParams({
            batchId: batch?.checkoutBatchId ?? "",
          }).toString(),
        },
        { replace: true }
      );
    } else {
      navigate("/mybag", { replace: true });
    }
  };
  const theme = useTheme();

  return (
    <StyledDialog
      open={orderId !== ""}
      //   open={true}
      BackdropProps={{
        style: {
          backgroundColor: theme.dtc
            ? `${theme.palette.primary.main}0D`
            : brandclub_colors.darkBlue3,
        },
      }}
    >
      {orderStatus ? (
        <div className="container">
          <div className="title">{statusMessage}</div>
          <div className="subtitle">{statusMessageDescription}</div>
          {shouldShowError ? (
            <CloseButton
              trackedAdditionalData={{ ...order }}
              onClick={handleError}
            >
              Close
            </CloseButton>
          ) : null}
          {shouldSuccess ? (
            <CloseButton onClick={handleSuccess}>
              {hasNextRetailerToCheckout ? "Checkout Next" : "View Summary"}
            </CloseButton>
          ) : null}
        </div>
      ) : (
        <div className="container">
          <Loading dot />
        </div>
      )}
    </StyledDialog>
  );
};

CheckoutTransition.toOrder = (orderId: string) => {
  useConfirmDialogStore.setState({
    orderId: orderId,
  });
};

export default CheckoutTransition;
