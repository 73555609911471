import { BrandclubPageType, Page, Site } from "@brandclub/types";
import { SiteBrandingType } from "../../../../types/misc";
import { escapeUriPath, formatMoney } from "../../../../utils/StringUtils";
import { buildRouteLink } from "../../../AppNavigation";
import { UNIVERSAL_STORE_SITE } from "@brandclub/common-ui";

export type FooterLink = {
  label: string;
  link: string;
  offsetText?: string;
};
export type FooterLinkSection = {
  title?: string;
  titleLink?: string;
  links: FooterLink[];
};

const getDtcMemberAccountColumn = (signedIn?: boolean): FooterLinkSection => {
  const authLinks = [
    { label: "Dashboard", link: "/me" },
    { label: "Purchases", link: "/purchase" },
    { label: "Account", link: "/accounts" },
  ];
  const unauthLinks = [
    { label: "Membership program", link: "/membership" },
    { label: "Sign up", link: "/signInWithOtp" },
    { label: "Sign in", link: "/signInWithOtp" },
  ];
  if (!signedIn) {
    return {
      title: "Member Account",
      links: unauthLinks,
    };
  }

  return {
    title: "Member Account",
    links: authLinks,
  };
};

const getDtcConnectColumn = (hasApp?: boolean): FooterLinkSection => {
  const links = [
    { label: "Profile", link: "/profile" },
    { label: "FAQs", link: `${UNIVERSAL_STORE_SITE}/faq` },
  ];
  if (hasApp) {
    links.unshift({
      label: "Get the app",
      link: `${UNIVERSAL_STORE_SITE}/getApp`,
    });
  }
  return {
    title: "Connect",
    links: links,
  };
};

const getDtcRewardsColumn = (userRewardAmount?: number): FooterLinkSection => ({
  title: "Rewards",
  links: [
    // { label: "Reward offers", link: "/offers" },
    {
      label: "Reward balance",
      link: "/rewards/balance",
      offsetText: userRewardAmount ? formatMoney(userRewardAmount) : undefined,
    },
    { label: "Reward activity", link: "/rewards/activity" },
  ],
});

const RewardsColumn: FooterLinkSection = {
  title: "Rewards",
  links: [
    { label: "Daily deals", link: "/dailydeals" },
    { label: "Instant rewards", link: "/instantrewards" },
    { label: "Membership rewards", link: "/clubinvites" },
    { label: "Survey rewards", link: "/surveys" },
    { label: "Content rewards", link: "/contentrewards" },
    { label: "Affiliate rewards", link: "/affiliate" },
    { label: "Connect rewards", link: "/dashboard/accounts" },
  ],
};

const BrandclubColumn: FooterLinkSection = {
  title: "Brandclub",
  links: [
    { label: "About us", link: "/about" },
    { label: "Partners", link: "/partners" },
    { label: "Influencers", link: "/influencer" },
    { label: "Contact us", link: "/contact" },
    { label: "Media inquiries", link: "/contact/media" },
    { label: "Request demo", link: "/contact/demo" },
  ],
};

const getTopthreeLinks = (site?: Site) => {
  const isCustomGroup = (site?.pages || []).some(
    (p: Page) => p.pageType === BrandclubPageType.CustomCategoryGroup
  );
  const categoriesPage = site?.pages?.find(
    (x) => x.displayName === "Categories" || x.displayName === "Shop"
  );

  if (!isCustomGroup) {
    return categoriesPage?.childPages?.slice(0, 3) || [];
  } else {
    const res: Page[] = [];
    const secendLevelCategories = categoriesPage?.childPages || [];
    secendLevelCategories.forEach((category) => {
      if (category.childPages) {
        category.childPages.forEach((subCategory) => {
          res.push(subCategory);
        });
      }
    });

    return res.slice(0, 3);
  }
};

const formatRewardOffers = (rewardCounts?: number) => {
  if (!rewardCounts || rewardCounts < 0) {
    return undefined;
  }
  if (rewardCounts > 25) {
    return "25+";
  }
  return rewardCounts.toString();
};

const getAccountColumn = (userRewardAmount?: number): FooterLinkSection => ({
  title: "Account",
  links: [
    { label: "Dashboard", link: "/dashboard" },
    {
      label: "Reward balance",
      link: "/dashboard/rewards/balance",
      offsetText: userRewardAmount ? formatMoney(userRewardAmount) : undefined,
    },
    { label: "Get the app", link: "/getApp" },
    { label: "Shop", link: "/shop" },
    { label: "Accounts", link: "/dashboard/accounts" },
    { label: "FAQs", link: "/faq" },
  ],
});

const getMemberAccountColumn = (
  userRewardAmount?: number,
  userRewardCounts?: number,
  routePrefix?: string
): FooterLinkSection => ({
  title: "Member Account",
  links: [
    { label: "Dashboard", link: `${routePrefix}/branddashboard` },
    { label: "Purchases", link: `${routePrefix}/branddashboard/purchase` },
    {
      label: "Reward offers",
      link: `${routePrefix}/offers`,
      offsetText: formatRewardOffers(userRewardCounts),
    },
    {
      label: "Reward balance",
      link: `${routePrefix}/branddashboard/rewards/balance`,
      offsetText: userRewardAmount ? formatMoney(userRewardAmount) : undefined,
    },
    {
      label: "Reward activity",
      link: `${routePrefix}/branddashboard/rewards/activity`,
    },
  ],
});

const getCustomStoreMemberAccountColumn = (
  userRewardAmount?: number,
  userRewardCounts?: number
): FooterLinkSection => ({
  title: "Member Account",
  links: [
    { label: "Dashboard", link: "/dashboard" },
    { label: "Purchases", link: "/dashboard/rewards/purchase" },
    {
      label: "Reward balance",
      link: "/dashboard/rewards/balance",
      offsetText: userRewardAmount ? formatMoney(userRewardAmount) : undefined,
    },
    {
      label: "Reward activity",
      link: "/dashboard/rewards/activity",
    },
    { label: "Accounts", link: "/dashboard/accounts" },
  ],
});

const getShopColumn = (
  site?: Site,
  routePrefix?: string,
  persistentQueryParams?: string
): FooterLinkSection => {
  const topThreeCategories = getTopthreeLinks(site);
  const collectionCount =
    site?.pages?.find((page) => page.pageType === "Collection")?.childPages
      ?.length || 0;
  const editorialCount =
    site?.pages?.find((page) => page.pageType === "Editorial")?.childPages
      ?.length || 0;

  const ShopColumn: FooterLinkSection = {
    title: "Shop",
    links: [{ label: "Shop all", link: "/shop" }],
  };

  if (collectionCount > 0) {
    ShopColumn.links.push({
      label: "Collections",
      link: buildRouteLink(
        [{}],
        routePrefix || "",
        "/collections",
        persistentQueryParams
      ),
    });
  }
  if (editorialCount > 0) {
    ShopColumn.links.push({
      label: "Editorials",
      link: buildRouteLink(
        [{}],
        routePrefix || "",
        "/editorials",
        persistentQueryParams
      ),
    });
  }

  ShopColumn.links.push({ label: "Shop by category", link: "#" });
  if (topThreeCategories && topThreeCategories.length > 0) {
    topThreeCategories?.forEach((category) => {
      ShopColumn.links.push({
        label: category.displayName || "",
        link: buildRouteLink(
          [{}],
          routePrefix || "",
          category.route,
          persistentQueryParams
        ),
      });
    });
  }

  return ShopColumn;
};

export const buildFooterConfig = (
  siteBrandingType: SiteBrandingType,
  userRewardAmount?: number,
  userRewardCounts?: number,
  site?: Site,
  routePrefix?: string,
  persistentQueryParams?: string,
  signedIn?: boolean,
  brandEntity?: any
): {
  siteBrandingType: SiteBrandingType;
  linkSections: FooterLinkSection[];
} => {
  const linkSections: FooterLinkSection[] = [];

  switch (siteBrandingType) {
    case SiteBrandingType.CustomDTCStore:
      linkSections.push(
        getDtcMemberAccountColumn(signedIn),
        getDtcConnectColumn(true),
        getDtcRewardsColumn(userRewardAmount)
      );
      break;
    case SiteBrandingType.UniversalStore:
      linkSections.push(
        getAccountColumn(userRewardAmount),
        RewardsColumn,
        BrandclubColumn
      );
      break;
    case SiteBrandingType.BrandStore:
      linkSections.push(
        getMemberAccountColumn(userRewardAmount, userRewardCounts, routePrefix),
        getShopColumn(site, routePrefix, persistentQueryParams),
        { links: [] }
      );
      break;
    case SiteBrandingType.CustomStore:
      linkSections.push(
        getCustomStoreMemberAccountColumn(userRewardAmount, userRewardCounts),
        getShopColumn(
          site,
          `/${escapeUriPath(brandEntity?.entity?.name)}/b/${
            brandEntity?.entity?.brandId
          }`,
          persistentQueryParams
        ),
        { links: [] }
      );
      break;
  }

  const footerConfig = {
    siteBrandingType,
    linkSections,
  };

  return footerConfig;
};
