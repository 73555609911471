import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useCheckout } from "../../CheckoutProvider";
import { useAppSelector } from "../../../../redux/hooks";
import { UserLoginContext } from "../../../pages/Auth/UserLoginProvider";

const EnterCheckoutFlow = () => {
  const { initiateCartCheckout, allCarts, loadingAllCarts } = useCheckout();
  const userProfile = useAppSelector((state) => state.userProfile);
  const { send, setInitialOpenUrl } = useContext(UserLoginContext);
  const navigate = useNavigate();

  useEffect(() => {
    const search = window.location.search;

    if (search.includes("action=checkout")) {
      setInitialOpenUrl({ pathname: "/entercheckout", search });
    }

    if (loadingAllCarts) {
      return;
    }
    if (allCarts.length > 0 && !!userProfile) {
      initiateCartCheckout(
        allCarts[0].retailerId,
        allCarts.map((cart) => cart.retailerId) as any
      );
    } else if (!userProfile) {
      send({
        type: "SIGN_IN",
      });
    } else {
      navigate("/mybag");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCarts.length, loadingAllCarts, userProfile]);

  return <div className="checkout-flow"></div>;
};

export default EnterCheckoutFlow;
