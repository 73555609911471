import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "../../initialState";

// Use the initialState as a default value
const userSlice = createSlice({
  name: "userProfile",
  initialState: initialState.userProfile,
  reducers: {
    setUserProfile(_state, action) {
      return {
        ...action.payload,
      };
    },

    // update brandEntity
    clearUserProfile(_state) {
      return null;
    },
  },
});

export const { setUserProfile, clearUserProfile } = userSlice.actions;

export default userSlice.reducer;
