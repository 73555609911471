import { Box, Typography, styled } from "@mui/material";
import { FallbackImage } from "@brandclub/common-ui";
import { Link } from "react-router-dom";
import { formatMoney } from "../../../utils/StringUtils";
import {
  getDefaultProductImage,
  getFallbackProductImage,
} from "../../../utils/media";
import { ShoppingCartItem, UserApprovalStatus } from "../types";
import { useCheckout } from "../CheckoutProvider";
import {
  CampaignType,
  getAppConsistentParams,
  getProductUrl,
  getPurchaseRewardAmountForProductV2,
  RewardPillWithText,
} from "@brandclub/common-ui";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../AppNavigation/constants";
import { useAppSelector } from "../../../redux/hooks";
import { OutlineButton, SolidButton } from "../../StoreComponents/StoreButton";

const CartProductContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  textDecoration: "none",
  color: theme.palette.primary.main,
  minHeight: 134,
  alignItems: "center",
  gap: 15,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    minHeight: 80,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    minHeight: 80,
  },
  ".product_image": {
    width: 121,
    height: 121,
    objectFit: "contain",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      width: 61,
      height: 61,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      width: 61,
      height: 61,
    },
  },
  ".product_detail": {
    display: "flex",
    flexDirection: "column",

    gap: 4,
    width: "100%",

    ".info": {
      display: "flex",
      flexDirection: "column",
      gap: 12,
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        gap: 4,
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        gap: 4,
      },
      ".price": {
        fontSize: 16,
        fontWeight: 600,

        display: "flex",
        flexDirection: "row",
        gap: 20,
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          fontSize: 14,
          marginBottom: 4,
        },
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 14,
          marginBottom: 4,
        },
      },
      ".name": {
        fontSize: 14,
        fontWeight: 500,
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 3,
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          WebkitLineClamp: 2,
        },
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          WebkitLineClamp: 2,
        },
      },
      ".actions": {
        display: "flex",
        flexDirection: "row",
        gap: 5,
        ".confirm": {
          fontSize: 12,
          height: 32,
          [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
            height: 27,
          },
          [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            height: 27,
          },
        },
        ".remove": {
          fontSize: 12,
          height: 32,

          [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
            height: 27,
          },
          [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            height: 27,
          },
        },
      },
    },
    ".actions": {
      display: "flex",
      gap: 50,

      ".quantity": {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  },
  "&.large": {
    gap: 40,
    height: 196,
    ".product_image": { width: 146, height: 146 },
    ".product_detail": {
      gap: 14,

      ".info": {
        ".price": {
          fontSize: 24,
          fontWeight: 600,
          marginBottom: 7.4,
        },
        ".name": {
          fontSize: 20,
          fontWeight: 500,
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 3,
          [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
            WebkitLineClamp: 2,
          },
          [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            WebkitLineClamp: 2,
          },
        },
      },
      ".actions": {
        justifyContent: "space-between",

        ".quantity": {
          fontSize: 18,
          fontWeight: 500,
        },
      },
    },
  },
}));

export const SummaryProduct = ({
  product,
  large = false,
  disabled,
}: {
  product: ShoppingCartItem;
  large?: boolean;
  disabled?: boolean;
}) => {
  const {
    products,
    confirmPartiallyOutOfStock,
    removePartiallyOutOfStock,
    removeErrorProduct,
  } = useCheckout();

  const appConfig = useAppSelector(({ appConfig }) => appConfig);
  const urlSearchParams = getAppConsistentParams(window.location.search);

  const order = useAppSelector(({ checkout }) => checkout.order);
  const currentProduct = products?.[product.stacklineSku];

  const { amount, percent, type } = getPurchaseRewardAmountForProductV2(
    currentProduct as any
  );

  const orderItems = order?.userRetailerCheckoutOrder?.items;

  const orderItem = orderItems?.find(
    (e) => e.stacklineSku === product.stacklineSku
  );

  const isItemPartiallyOutOfStock =
    orderItem?.status !== "error" &&
    orderItem?.userApprovalStatus === UserApprovalStatus.PENDING &&
    orderItem.quantity !== orderItem.requestedQuantity;

  const isItemAllOutOfStock =
    orderItem?.status === "error" &&
    orderItem?.userApprovalStatus !== "deleted";

  if (!currentProduct) {
    return null;
  }

  return (
    <Link
      to={getProductUrl({
        title: currentProduct.title,
        stacklineSku: currentProduct.stacklineSku,
        urlSearchParams,
        dtcProductPageUrl: currentProduct.dtcProductPageUrl,
        storeType: appConfig?.domainConfig?.storeBrandingType,
      })}
      style={{ textDecoration: "none" }}
      target="_blank"
    >
      <CartProductContainer className={large ? "large" : ""}>
        <Box position="relative">
          {disabled && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor: "#ffffff",
                opacity: 0.3,
              }}
            />
          )}
          <FallbackImage
            title={currentProduct?.title ?? product.stacklineSku}
            className="product_image"
            src={getDefaultProductImage(product?.stacklineSku || "")}
            fallbackImage={getFallbackProductImage(product?.stacklineSku || "")}
          />
          {product.quantity > 0 ? (
            <Box
              sx={{
                position: "absolute",
                right: "5px",
                bottom: "12px",
                fontSize: 12,
                fontWeight: 600,
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f6f8fa",
                border: "solid 1px #dcdddc",
                borderRadius: "50%",
                height: "25px",
                transform: "translate(0%, 50%)",
                aspectRatio: "1/1",
                [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
                  fontSize: 10,
                  height: "20px",
                },
                [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                  fontSize: 10,
                  height: "20px",
                },
              }}
            >
              {product.quantity}
            </Box>
          ) : null}
        </Box>
        {isItemAllOutOfStock ? (
          <Box
            className={"product_detail"}
            sx={{
              opacity: disabled ? 0.3 : 1,
            }}
          >
            <Box className={"info"}>
              <Box className={"price"}>
                <div>
                  {formatMoney(
                    product.retailPrice ?? currentProduct?.retailPrice
                  )}
                </div>
                <div>
                  <RewardPillWithText
                    rewardAmount={amount ?? 0}
                    rewardCampaignType={
                      type === "instant"
                        ? CampaignType.DailyDealsRewardCampaign
                        : CampaignType.PurchaseRewardCampaign
                    }
                    rewardPercent={percent ?? 0}
                    rewardPercentText="reward"
                    containerStyle={undefined}
                    baseFontSize={12}
                  />
                </div>
              </Box>
              <Typography className={"name"}>
                This item is now out of stock. Please remove to continue.
              </Typography>
              <div className="actions">
                <div>
                  <SolidButton
                    className="confirm"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeErrorProduct(product.stacklineSku);
                    }}
                  >
                    Remove
                  </SolidButton>
                </div>
              </div>
            </Box>
          </Box>
        ) : isItemPartiallyOutOfStock ? (
          <Box
            className={"product_detail"}
            sx={{
              opacity: disabled ? 0.3 : 1,
            }}
          >
            <Box className={"info"}>
              <Box className={"price"}>
                <div>
                  {formatMoney(
                    product.retailPrice ?? currentProduct?.retailPrice
                  )}
                </div>
                <div>
                  <RewardPillWithText
                    rewardAmount={amount ?? 0}
                    rewardCampaignType={
                      type === "instant"
                        ? CampaignType.DailyDealsRewardCampaign
                        : CampaignType.PurchaseRewardCampaign
                    }
                    rewardPercent={percent ?? 0}
                    rewardPercentText="reward"
                    containerStyle={undefined}
                    baseFontSize={12}
                  />
                </div>
              </Box>
              <Typography className={"name"}>
                We’ve updated the quantity based on the inventory available.
              </Typography>
              <div className="actions">
                <div>
                  <SolidButton
                    className="confirm"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      confirmPartiallyOutOfStock(product.stacklineSku);
                    }}
                  >
                    Confirm
                  </SolidButton>
                </div>
                <div>
                  <OutlineButton
                    className="remove"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removePartiallyOutOfStock(product.stacklineSku);
                    }}
                  >
                    Remove
                  </OutlineButton>
                </div>
              </div>
            </Box>
          </Box>
        ) : (
          <Box
            className={"product_detail"}
            sx={{
              opacity: disabled ? 0.3 : 1,
            }}
          >
            <Box className={"info"}>
              <Box className={"price"}>
                <div>
                  {formatMoney(
                    product.retailPrice ?? currentProduct?.retailPrice
                  )}
                </div>
                <div>
                  <RewardPillWithText
                    rewardAmount={amount ?? 0}
                    rewardCampaignType={
                      type === "instant"
                        ? CampaignType.DailyDealsRewardCampaign
                        : CampaignType.PurchaseRewardCampaign
                    }
                    rewardPercent={percent ?? 0}
                    rewardPercentText="reward"
                    containerStyle={undefined}
                    baseFontSize={12}
                  />
                </div>
              </Box>
              <Typography className={"name"} title={currentProduct?.title}>
                {currentProduct?.title ?? "Unknown Product"}
              </Typography>
            </Box>
          </Box>
        )}
      </CartProductContainer>
    </Link>
  );
};
