import { useCallback, useEffect, useState } from "react";
import {
  cancelCheckout,
  confirmCheckout,
  getRetailerCheckoutOrderStatus,
  getUserRetailerCheckoutBatch,
  updatePaymentMethodToUserRetailerCheckoutOrder,
  updateShippingOption,
  updateShippingToUserRetailerCheckoutOrder,
} from "./api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setOrder, setBatch } from "../../redux/reducers/checkout";
import { Address } from "../../redux/types";
import _sortBy from "lodash/sortBy";
import CheckoutTransition from "./components/CheckoutTransition";

import { useCheckout } from "./CheckoutProvider";

/**
 * Fetches the user's checkout batch
 * @param batchId the batch id to fetch
 * @throws if the fetch fails
 * @returns the user's checkout batch
 */
export const getUserCheckoutBatch = async (batchId: string) => {
  const res = await getUserRetailerCheckoutBatch({
    checkoutBatchId: batchId,
  });
  return res?.userRetailerCheckoutBatch;
};

export const useOrderStatus = (orderId?: string, autoFetch = true) => {
  const { initiateCartCheckout } = useCheckout();
  const [loading, setLoading] = useState(false);
  const batch = useAppSelector((state) => state.checkout.batch);
  const allCarts = useAppSelector((state) => state.checkout.allCarts);
  // const pollingIntervalRef = useRef<NodeJS.Timeout>();

  const order = useAppSelector((state) => state.checkout.order);
  const dispatch = useAppDispatch();

  const modifyingOrder =
    order?.userRetailerCheckoutSessionStatus?.status !==
    "checkout_waiting_for_user_input";

  const updateOrderDeliveryAddress = async (address: Address) => {
    if (
      order?.userRetailerCheckoutOrder?.retailerId &&
      order?.userRetailerCheckoutOrder?.orderId
    ) {
      const res = await updateShippingToUserRetailerCheckoutOrder({
        retailerId: order?.userRetailerCheckoutOrder?.retailerId,
        orderId: order?.userRetailerCheckoutOrder?.orderId,
        shippingAddress: {
          ...(order.userRetailerCheckoutOrder.shippingAddress ?? {}),
          ...address,
        } as any,
      });
      dispatch(setOrder(res));
    }
  };

  const updateOrderShippingOption = async (
    deliveryOption?: {
      name: string;
      cssSelector: string;
    },
    deliveryTimeWindow?: {
      name: string;
      cssSelector: string;
    }
  ) => {
    if (
      order?.userRetailerCheckoutOrder?.retailerId &&
      order?.userRetailerCheckoutOrder?.orderId
    ) {
      const res = await updateShippingOption({
        retailerId: order?.userRetailerCheckoutOrder?.retailerId,
        orderId: order?.userRetailerCheckoutOrder?.orderId,
        deliveryOption,
        deliveryTimeWindow,
      });
      dispatch(setOrder(res));
    }
  };

  const cancelOrder = async (retailerId: number) => {
    const existingCheckoutId = allCarts?.find(
      (c) => c.retailerId === retailerId
    )?.checkoutOrderId;

    if (retailerId && existingCheckoutId) {
      return await cancelCheckout({ retailerId, orderId: existingCheckoutId });
    }
    return;
  };

  const confirmOrder = async () => {
    if (!order?.userRetailerCheckoutOrder?.orderId) {
      throw new Error("No order found to confirm");
    }
    // Make the API call to initiate here
    try {
      await confirmCheckout({
        retailerId: order?.userRetailerCheckoutOrder?.retailerId,
        orderId: order?.userRetailerCheckoutOrder?.orderId,
      });

      CheckoutTransition.toOrder(order?.userRetailerCheckoutOrder?.orderId);
    } catch (e) {
      console.error(e);
      alert(e);
    }
  };

  const updatePaymentConfirmation = async (paymentConfirmation: {
    type: "cvv" | "cardNumber" | "expirationDate" | "cardHolderName";
    value: string;
  }) => {};

  const updateOrderPaymentMethod = async (
    cardInfo: {
      cardNumber?: string;
      cardIssuer: string;
      expirationDate: string;
      cvv?: string;
      cardHolderName: string;
      cardNumberEnding?: string;
    },
    billingAddress?: Address
  ) => {
    if (
      order?.userRetailerCheckoutOrder?.retailerId &&
      order?.userRetailerCheckoutOrder?.orderId
    ) {
      const res = await updatePaymentMethodToUserRetailerCheckoutOrder({
        retailerId: order?.userRetailerCheckoutOrder?.retailerId,
        orderId: order?.userRetailerCheckoutOrder?.orderId,
        cardInfo: cardInfo,
        billingAddress: billingAddress
          ? ({
              ...(order.userRetailerCheckoutOrder.billingAddress ?? {}),
              ...billingAddress,
            } as any)
          : undefined,
      });
      dispatch(setOrder(res));
    }
  };

  // fetch order status from server
  const fetchOrder = useCallback(async () => {
    if (!orderId) return;

    try {
      setLoading(true);
      const order = await getRetailerCheckoutOrderStatus({ orderId });
      dispatch(setOrder(order));
    } finally {
      setLoading(false);
    }
  }, [dispatch, orderId]);

  // useEffect(() => {
  //   if (orderId && modifyingOrder) {
  //     // Start polling the order
  //     pollingIntervalRef.current = setInterval(fetchOrder, 5000);
  //   }

  //   return () => {
  //     // Stop polling when the component unmounts
  //     clearInterval(pollingIntervalRef.current);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [orderId, fetchOrder, modifyingOrder]);

  const updateBatch = async (batchId?: string) => {
    try {
      const currentBatchId =
        batchId ?? order?.userRetailerCheckoutOrder?.checkoutBatchId;
      if (currentBatchId) {
        const userRetailerCheckoutBatch = await getUserCheckoutBatch(
          currentBatchId
        );

        const formatBatch = userRetailerCheckoutBatch && {
          ...userRetailerCheckoutBatch,
          retailerIds: _sortBy(userRetailerCheckoutBatch.retailerIds),
        };

        dispatch(setBatch(formatBatch));
      }
    } catch (e) {
      console.error("Error updating batch:", e);
    }
  };

  useEffect(() => {
    if (autoFetch) fetchOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkoutNextOrder = async () => {
    if (!batch?.retailerIds) {
      return;
    }
    const currentRetailerId = order?.userRetailerCheckoutOrder?.retailerId ?? 0;
    const index = batch?.retailerIds.indexOf(currentRetailerId);
    if (index === -1 || index === batch?.retailerIds?.length - 1) {
      return;
    }
    // Return the next element in the array
    const nextRetailerId = batch?.retailerIds?.[index + 1];
    cancelOrder(currentRetailerId);
    const r = await initiateCartCheckout(
      nextRetailerId,
      batch.retailerIds,
      batch.checkoutBatchId
    );
    const orderId = r?.userRetailerCheckoutOrder?.orderId ?? "";
    CheckoutTransition.toOrder(orderId);
  };

  const skipOrder = async () => {
    const currentRetailerId = order?.userRetailerCheckoutOrder?.retailerId ?? 0;
    cancelOrder(currentRetailerId);
    checkoutNextOrder();
  };

  return {
    modifyingOrder,
    updateOrderPaymentMethod,
    updatePaymentConfirmation,
    updateOrderDeliveryAddress,
    updateOrderShippingOption,
    checkoutNextOrder,
    cancelOrder,
    confirmOrder,
    updateBatch,
    skipOrder,
    order,
    orderId,
    loading,
    batch,
  };
};
