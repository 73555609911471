/**
 * Functions for initializing `axios` and the API request environment
 */
import axios from "axios";
import ConfigUtils from "./ConfigUtils";

export function getCommonRequestHeaders() {
  return {
  };
}

const axiosInstance = axios.create({
  baseURL: ConfigUtils.getApiHostName(),
  headers: {
    common: getCommonRequestHeaders(),
  },
});

export const axiosRetailerSync = axios.create({
  baseURL: ConfigUtils.getServerSideSyncHostName(),
  headers: {
    common: getCommonRequestHeaders(),
  },
});

export const axiosEdgeCached = axios.create({
  baseURL: ConfigUtils.getEdgeCachedApiHostName(),
  headers: {
    common: getCommonRequestHeaders(),
  },
});

export const axiosBuilder = () => {
  return axios.create({
    baseURL: ConfigUtils.getApiHostName(),
  });
};

export const axiosEdgeCachedBuilder = () => {
  return axios.create({
    baseURL: ConfigUtils.getEdgeCachedApiHostName(),
  });
};

export default axiosInstance;
