import { ContentRewardCampaignForCarousel } from "../../../../../types/misc";

import { LongCardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import ContentCard from "./components/ContentCard";
import { useFetchContent } from "./data";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import colors from "../../../../styles/colors";
import { useEffect, useRef } from "react";
import { useInView } from "framer-motion";
import PreviewContainer from "../components/PreviewContainer";
import { defaultPreviewLazyLoadConfig } from "../../../../../utils/lazyLoadConfig";
import { useLinkPrefix } from "../../../../../utils/hooks/useLinkPrefix";
import { isWhite } from "../../../../../utils/StringUtils";

const ContentPreview = ({
  brandclubIds,
  backgroundColor = colors.blueWhite,
  arrowColor = "blue",
}: {
  brandclubIds?: string[];
  backgroundColor?: string;
  arrowColor?: "blue" | "grey";
}) => {
  const ref = useRef(null);
  const widgetInView = useInView(ref, defaultPreviewLazyLoadConfig);

  const { linkPrefix } = useLinkPrefix();

  const { data, loading, fetchInit } = useFetchContent({
    pageSize: 4,
    autoInitFetch: false,
    brandclubIds,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  if (!loading && data.length === 0) {
    return null;
  }

  return (
    <PreviewWidgetWrapper ref={ref} backgroundColor={backgroundColor}>
      <PreviewContainer>
        {loading ? (
          <StoreLoadingSkeleton
            cardStyle="longCard"
            header
            row={2}
            bgColor={isWhite(backgroundColor) ? "white" : "grey"}
          />
        ) : (
          <>
            <PreviewHeader
              title={"Content rewards"}
              linkTo={linkPrefix + "/contentrewards"}
              subtitle="Earn rewards for viewing content"
              indicator={arrowColor}
            />

            <LongCardGrid>
              {data.map((d: ContentRewardCampaignForCarousel) => (
                <ContentCard
                  key={d.campaignId}
                  content={d}
                  border={isWhite(backgroundColor)}
                ></ContentCard>
              ))}
            </LongCardGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default ContentPreview;
