import ReduxStore from "./types";

export const initialState: ReduxStore = {
  appConfig: null,
  visitorTracking: { uniqueVisitorId: null, referrer: null },
  mainEntity: null,
  brandEntity: null,
  brandAppSlice: {
    topProduct: null,
  },
  retailers: [],
  userProfile: null,
  checkout: {
    batch: undefined,
    order: undefined,
    allCarts: [],
    open: false,
  },
  rewards: {
    rewards: {},
    loading: true,
  },
  topBrands: {
    topBrands: undefined,
    loading: true,
  },
  categories: {
    categories: undefined,
    loading: false,
  },
  departments: {
    departments: undefined,
    loading: false,
  },
  referralReward: {
    amountForReferringUser: null,
    amountForInstallingUser: null,
  },
  customerSpendByBrand: {
    brandIds: [],
    brandclubIds: [],
    spendByBrandId: {},
    loading: false,
  },
};
