import {
  BookmarkStarIcon,
  PersonIcon,
  SmartphoneIcon,
} from "@brandclub/common-ui";
import { Link } from "react-router-dom";
import NavCartButton from "../Buttons/NavCartButton";
import { HeaderLink, NAV_ATTACHMENT_SIZE } from ".";

import pluralize from "pluralize";
import { CreateAccountLink } from "../../StoreComponents/StoreLogin/LoginButtons";
import { useCheckout } from "../../Checkout/CheckoutProvider";

interface UnauthAttachmentProps {
  onClose?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleLoginOpen?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
const UnauthAttachment = ({
  onClose,
  handleLoginOpen,
}: UnauthAttachmentProps) => {
  const { cartCount } = useCheckout();
  const shoppingCartLabel =
    cartCount > 0 ? pluralize("item", cartCount, true) : "My Bag";
  return (
    <>
      <Link to={"/mybag"} className="link" onClick={onClose}>
        <HeaderLink>
          <NavCartButton id="nav_cart_button" />
          <div className="label">{shoppingCartLabel}</div>
        </HeaderLink>
      </Link>
      <Link to={"/getApp"} className="link" onClick={onClose}>
        <HeaderLink>
          <SmartphoneIcon
            sx={{
              fontSize: NAV_ATTACHMENT_SIZE,
            }}
          />

          <div className="label">Get the app</div>
        </HeaderLink>
      </Link>
      <CreateAccountLink to={"/signin"} className="link" onClick={onClose}>
        <HeaderLink>
          <PersonIcon sx={{ fontSize: NAV_ATTACHMENT_SIZE }} />
          <span className="label">Sign In</span>
        </HeaderLink>
      </CreateAccountLink>
      <CreateAccountLink to={"/signin"} className="link" onClick={onClose}>
        <HeaderLink>
          <BookmarkStarIcon
            sx={{
              fontSize: NAV_ATTACHMENT_SIZE,
            }}
          />
          <div className="label">Sign up</div>
        </HeaderLink>
      </CreateAccountLink>
    </>
  );
};

export default UnauthAttachment;
