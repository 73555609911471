import { useEffect, useState } from "react";

import { useAppSelector } from "../../../../../redux/hooks";
import {
  EligibilityStatus,
  RetailerWithSyncStatus,
} from "../../../../../redux/types";
import { useSyncContext } from "../../../../SyncProvider";

import { useUserLogin } from "../../../Auth/UserLoginProvider";

const sliceRetailer = (retailers: RetailerWithSyncStatus[], size?: number) => {
  const sortedRetailers = retailers?.sort((a, b) => {
    return (
      (b?.retailerConnectRewardAmount ?? 0) -
      (a?.retailerConnectRewardAmount ?? 0)
    );
  });
  if (!size) return sortedRetailers;
  return sortedRetailers.slice(0, size);
};

export const useFetchAccount = ({ pageSize }: { pageSize?: number }) => {
  const [loading, setLoading] = useState(true);
  const [connectedRetailers, setConnectedRetailers] = useState<
    RetailerWithSyncStatus[]
  >([]);
  const [disconnectedRetailers, setDisconnectedRetailers] = useState<
    RetailerWithSyncStatus[]
  >([]);
  const [newRetailers, setNewRetailers] = useState<RetailerWithSyncStatus[]>(
    []
  );

  const { signedIn } = useUserLogin();

  const retailers = useAppSelector(({ retailers }) => retailers ?? []);
  const { syncStatus, syncStatusByParentRetailer, retailerConnections } =
    useSyncContext();

  const filterRetailersByConnectionStatus = (
    serverConnectionStatus: string[],
    displayConnectionStatus: "valid" | "invalid" | "new"
  ): RetailerWithSyncStatus[] => {
    return (
      retailers
        .filter((retailer) => {
          return retailer.syncConfig.serverSide === EligibilityStatus.ENABLED;
        })
        .filter((retailer) => {
          return signedIn
            ? serverConnectionStatus.includes(
                retailerConnections[
                  `${retailer.parentRetailerId || retailer.retailerId}`
                ]?.connectionStatus
              )
            : displayConnectionStatus === "new";
        })
        .map((r) => {
          return {
            ...r,
            connectionStatus: displayConnectionStatus,
            currentSyncStatus:
              syncStatus === "normal"
                ? "none"
                : syncStatusByParentRetailer.find(
                    (x) => x.parentRetailerId === r.parentRetailerId
                  )
                ? "syncing"
                : "anotherSyncInProgress",
          };
        }) || []
    );
  };

  const init = () => {
    const connectedRetailersUpdated: RetailerWithSyncStatus[] =
      filterRetailersByConnectionStatus(["valid"], "valid");
    const disconnectedRetailersUpdated: RetailerWithSyncStatus[] =
      filterRetailersByConnectionStatus(["invalid", "retry"], "invalid");
    const newRetailersUpdated: RetailerWithSyncStatus[] =
      filterRetailersByConnectionStatus(["notConnected"], "new");

    setConnectedRetailers(
      sliceRetailer(connectedRetailersUpdated || [], pageSize)
    );
    setDisconnectedRetailers(
      sliceRetailer(disconnectedRetailersUpdated || [], pageSize)
    );
    setNewRetailers(sliceRetailer(newRetailersUpdated || [], pageSize));

    if (
      connectedRetailersUpdated.length > 0 ||
      disconnectedRetailersUpdated.length > 0 ||
      newRetailersUpdated.length > 0
    ) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (retailers.length > 0 && retailerConnections) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailers, retailerConnections, syncStatus]);

  return {
    connectedRetailers,
    disconnectedRetailers,
    newRetailers,
    loading,
  };
};
