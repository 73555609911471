import { useContext, useEffect, useState } from "react";
import StoreInput from "../../StoreComponents/StoreInput";
import AuthSolidButton from "./components/AuthSolidButton";
import { ReactComponent as AddUser } from "../../../assets/Add_User.svg";
import {
  formatPhone,
  getPhoneNumberMasking,
  validatePhone,
} from "../../../utils/StringUtils";
import { ButtonLoadingEllipsis } from "../../StoreComponents/StoreButton";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { UserLoginContext } from "./UserLoginProvider";
import { clearBrandEntity } from "../../../redux/reducers/brandEntity";
import { TermsAndConditions } from "./TermsAndConditions";
import { useTheme } from "@mui/material";
import { BRANDCLUB_BRAND_ID } from "@brandclub/common-ui";
import { AuthHelper } from "./AuthHelper";
import { NumberFormatCustom } from "../AppPages/Dashboard/Wallet/EditCard";
import OTPWrapper from "./OTPWrapper";

const ROW_SPACING = 20;
const SignIn = () => {
  const { send } = useContext(UserLoginContext);
  const appConfig = useAppSelector(({ appConfig }) => appConfig);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (appConfig?.domainConfig.brandId === BRANDCLUB_BRAND_ID) {
      dispatch(clearBrandEntity());
    }
  }, [dispatch, appConfig?.domainConfig.brandId]);

  const [phone, setPhone] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const theme = useTheme();

  const signInWithPhone = async (isNewUser: boolean) => {
    try {
      const phoneNumber = formatPhone(phone);
      const session = await AuthHelper.sendAuthOtpWithSms(
        phoneNumber,
        isNewUser
      );
      return send({
        type: "MOVE_TO_VERIFY_OTP",
        cognitoSession: session,
        otpPhoneNumber: phoneNumber,
        isNewUser,
      });
    } catch (e: any) {
      if ((e.name || e.code) === "UserNotFoundException") {
        // signUpWithPhone();
      } else {
        setErrorMsg(`An error occurred: Please try again after some time`);
        console.error(e);
      }
    }
  };

  const signUpWithPhone = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();
    const phoneNumber = formatPhone(phone);
    setSubmitting(true);
    try {
      await AuthHelper.signUp(phoneNumber);
      return await signInWithPhone(true);
    } catch (e: any) {
      if (e.code === "UsernameExistsException") {
        return await signInWithPhone(false);
      } else {
        setErrorMsg(`An error occurred: Please try again after some time`);
        console.error(e);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const clearErrors = () => {
    setErrorMsg("");
  };

  const isNumberValid = validatePhone(phone);

  const phoneMasking = getPhoneNumberMasking(phone || "");

  return (
    <OTPWrapper>
      <div className="auth_container">
        <AddUser
          style={{
            width: 75,
            height: 75,
            fill: theme.palette.primary.main,
          }}
        />
        <div className="page_title">Enter your phone number</div>
        <div className="page_subtitle">
          We will text you a verification code.
        </div>
        <StoreInput
          errorMsg={errorMsg || ""}
          value={phone}
          onChange={(e) => {
            const value = e.target.value;
            setPhone(value?.trim());
            clearErrors();
          }}
          inputProps={{
            format: phoneMasking,
          }}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
          placeholder="Phone number"
          inputStyle={{ width: "100%" }}
          style={{ marginTop: ROW_SPACING }}
        ></StoreInput>
        <AuthSolidButton
          onClick={signUpWithPhone}
          disabled={submitting || !isNumberValid}
          style={{ marginTop: ROW_SPACING }}
        >
          {submitting ? (
            <>
              Submitting
              <ButtonLoadingEllipsis baseFontSize={16} />
            </>
          ) : (
            "Continue"
          )}
        </AuthSolidButton>
        <div className="termsAgreement" style={{ maxWidth: 300 }}>
          <TermsAndConditions />
        </div>
      </div>
    </OTPWrapper>
  );
};

export default SignIn;
