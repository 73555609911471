import Box, { BoxProps } from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import { styled, useTheme } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { CSSProperties, useEffect } from "react";
import { useLocation } from "react-router";
import { create } from "zustand";
import { TABLET_SCREEN_SIZE } from "../../AppNavigation/constants";
import { OutlineButton, SolidButton } from "../StoreButton";
import { hexadecimal } from "@brandclub/common-ui";

interface BearState {
  open: boolean;
  title: string;
  text: string;
  actions: DialogButtonAction[];
  close: () => void;
  titleStyle?: CSSProperties;
  textProps?: BoxProps;
  actionsProps?: BoxProps;
  listener?: (newState: Readonly<BearState>) => void;
}

export type DialogButtonAction = {
  text: string;
  onClick?: () => void;
  variant: "solid" | "outline";
};

const useConfirmDialogStore = create<BearState>((set) => ({
  open: false,
  title: "",
  text: "",
  actions: [],
  close: () => set({ open: false }),
}));

const ContentContainer = styled("div")(({ theme }) => ({
  padding: "30px 35px",
  ".title": {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginBottom: 7,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      textAlign: "center",
    },
  },
  ".text": {
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      textAlign: "center",
    },
  },
  ".actions": {
    display: "flex",
    gap: 20,
    justifyContent: "flex-end",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      flexDirection: "column",
      gap: 10,
      alignItems: "center",
    },
  },
}));

const SlideUpTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const joinClassNames = (...classNames: (string | undefined)[]) =>
  classNames.filter(Boolean).join(" ");

const GenericDialog = () => {
  const state = useConfirmDialogStore();
  const {
    open,
    close,
    title,
    text,
    actions,
    textProps,
    actionsProps,
    titleStyle,
  } = state;

  const { className: actionsClassName, ...restActionsProps } =
    actionsProps || {};
  const {
    className: textClassName,
    sx: textSx,
    ...restTextProps
  } = textProps || {};

  useEffect(() => {
    state.listener?.(state);
  }, [state]);

  const location = useLocation();
  React.useEffect(() => {
    close();
    // close the dialog whenever the location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const desktopView = useMediaQuery(`(min-width:${TABLET_SCREEN_SIZE}px)`);
  const theme = useTheme();

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        fullScreen={false}
        sx={{
          zIndex: 1400,
          "& div.MuiDialog-paper": {
            margin: 0,

            minWidth: 569,
            maxWidth: 569,

            color: theme.palette.primary.main,
          },

          // media queries
          [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
            "& div.MuiDialog-paper": {
              width: "100vw",
              minWidth: "100vw",
              maxWidth: "100vw",
              maxHeight: "75vh",
              position: "absolute",
              bottom: 0,
            },
          },
        }}
        PaperProps={{
          sx: { zIndex: 1502, width: "100%", maxWidth: "580px" },
        }}
        TransitionComponent={desktopView ? Fade : SlideUpTransition}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: hexadecimal(theme.palette.primary.main)(21),
              zIndex: 1501,
            },
          },
        }}
      >
        <ContentContainer>
          <div className="title" style={titleStyle}>
            {title}
          </div>
          <Box
            className={joinClassNames("text", textClassName)}
            sx={{
              fontSize: 16,
              color: theme.palette.primary.main,
              marginBottom: "16px",
              ...textSx,
            }}
            {...restTextProps}
          >
            {text}
          </Box>
          <Box
            className={joinClassNames("actions", actionsClassName)}
            {...restActionsProps}
          >
            {actions.map((action, index) => {
              return action.variant === "solid" ? (
                <SolidButton
                  sx={{
                    fontSize: 16,
                    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
                      width: "100%",
                      maxWidth: 333,
                      fontSize: 14,
                    },
                  }}
                  key={index}
                  onClick={() => {
                    action.onClick?.();
                    close();
                  }}
                >
                  {action.text}
                </SolidButton>
              ) : (
                <OutlineButton
                  sx={{
                    fontSize: 16,
                    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
                      width: "100%",
                      maxWidth: 333,
                      fontSize: 14,
                    },
                  }}
                  key={index}
                  onClick={() => {
                    action.onClick?.();
                    close();
                  }}
                >
                  {action.text}
                </OutlineButton>
              );
            })}
          </Box>
        </ContentContainer>
      </Dialog>
    </>
  );
};

GenericDialog.show = (props: Omit<BearState, "open" | "close">) => {
  useConfirmDialogStore.setState({
    ...props,
    open: true,
  });
};

GenericDialog.hide = () => {
  useConfirmDialogStore.setState({
    open: false,
  });
};

export default GenericDialog;
