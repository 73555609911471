import { styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCustomerPaymentTransactionById } from "../../../../../api/graphQl/authenticated";
import reduxApolloClient from "../../../../../redux/reduxApolloClient";
import { PayoutWithRewards } from "../../../../../types/misc";
import { formatDate, formatMoney } from "../../../../../utils/StringUtils";
import { withFadeTransition } from "../../../../StoreComponents/StoreTransition";

import useRetailerIdToNameMap from "../../../../../utils/hooks/useRetailerIdToNameMap";
import RewardCard from "./components/RewardCard";
import { getActivityExpiringCardRenderDetail } from "./components/helper";

const PayoutDetailContainer = styled("div")({
  ".header": {
    fontSize: 18,
    fontWeight: 700,
  },
  ".row": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    margin: "10px 0",
    ".title": {},
    ".value": {},
  },
  ".breakdowns": {
    ".sec_title": {
      fontSize: 18,
      fontWeight: 700,
      margin: "30px 0",
    },
    ".sec_data": {
      display: "flex",
      flexDirection: "column",
      gap: 20,
    },
  },
});

const PayoutDetail = () => {
  const [payout, setPayout] = useState<PayoutWithRewards | null>(null);
  const { transactionId } = useParams();
  const retailerIdToNameMap = useRetailerIdToNameMap();

  const fetchPayout = async () => {
    try {
      const {
        data: { GetCustomerPaymentTransaction: item },
      } = await reduxApolloClient.query<{
        GetCustomerPaymentTransaction: PayoutWithRewards;
      }>({
        query: getCustomerPaymentTransactionById,
        variables: {
          transactionId,
        },
      });
      setPayout(item);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    fetchPayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!payout) return <></>;
  return (
    <PayoutDetailContainer>
      <div className="header">{formatDate(payout.updatedTime)}</div>
      <div style={{ marginTop: 25 }}>
        <div className="row">
          <div className="title">Cash out eligible</div>
          <div className="value">
            {formatMoney(payout.payoutBreakdown?.payoutEligibleAmount)}
          </div>
        </div>
        <div className="row">
          <div className="title">Transaction fee</div>
          <div className="value">
            {formatMoney(Math.abs(payout.payoutBreakdown?.cashOutFeeAmount))}
          </div>
        </div>

        {/* Divider */}
        <div
          style={{
            width: "100%",
            backgroundColor: "#dcdddc",
            height: 1,
            margin: "8.5px 0",
          }}
        />
        <div className="row">
          <div className="title">Total payout</div>
          <div className="value">
            {formatMoney(Math.abs(payout.transactionAmount))}
          </div>
        </div>
        {payout?.rewards?.length ? (
          <div className="breakdowns">
            <div className="sec_title">Rewards included in payout</div>
            <div className="sec_data">
              {payout.rewards.map((row) => {
                const formatItem = getActivityExpiringCardRenderDetail(
                  row as any,
                  retailerIdToNameMap
                );
                return (
                  <RewardCard
                    key={row.rewardId}
                    item={formatItem}
                    type={"activity"}
                  />
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </PayoutDetailContainer>
  );
};

export default withFadeTransition(PayoutDetail);
