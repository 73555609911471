import _ from "lodash";
import _get from "lodash/get";
import React, {
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// import { getTOtp } from '../../api/rest';
import {
  FailedAttemptType,
  IProducts,
  RetailerCheckoutOrderStatus,
  addPaymentMethodToUserRetailerCheckoutOrder,
  addShippingToUserRetailerCheckoutOrder,
  cancelCheckout,
  confirmCheckout,
  deleteItemsInCheckoutPage,
  deleteOutOfStockItemsFromCheckoutOrder,
  getActiveCarts,
  getRetailerCheckoutOrderStatus,
  initiateRetailerCheckout,
  removeItemFromShoppingCart,
  retryRetailerLoginDuringCheckout,
  updatePaymentMethodToUserRetailerCheckoutOrder,
  updateShippingOption,
  updateShippingToUserRetailerCheckoutOrder,
  updateUserConfirmationToCheckoutItems,
} from "./api";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  addOptimisticShoppingCartItem,
  closeCartDrawer,
  decrementOptimisticShoppingCartItemQuantity,
  incrementOptimisticShoppingCartItemQuantity,
  removeOptimisticShoppingCartItem,
  setAllCarts,
  setBatch,
  setOrder,
  setProducts,
} from "../../redux/reducers/checkout";
import ReduxStore, { Address } from "../../redux/types";

import { encryptData } from "../../utils/encryptionUtils";

import { sleep, sumAllItemQuantities } from "../../utils/misc";

import { isPurchaseCheckoutSuccess } from "./utils";

import { getTOtp } from "../../api";

import { useNavigate } from "react-router-dom";
import { getEntityImage } from "../../utils/StringUtils";
import {
  ShoppingCartItem,
  UserRetailerCheckoutBatch,
  UserRetailerCheckoutOrder,
  UserRetailerShoppingCart,
} from "./types";
import { getUserRetailerCheckoutOrderDetailBreakup } from "../../api/graphQl/authenticated/Purchase";

import {
  ProductCardProps,
  boxingCard,
  deliveryCard,
  getConnectPlugCard,
} from "./components/CheckoutFlow/EnterCheckoutAnimation";
import { serverSideSyncApolloClient } from "../../redux/reduxApolloClient";
import { RetailerSyncSession, UserPurchaseHistory } from "../../types/misc";
import { SearchResult } from "../../types/search";

import CheckoutLoadingOverlay from "./components/CheckoutFlow/CheckoutLoadingOverlay";
import CheckoutErrorDialog from "./components/CheckoutFlow/CheckoutErrorDialog";

import GenericDialog, {
  DialogButtonAction,
} from "../StoreComponents/StoreDialogs/GenericDialog";
import { useMatch } from "react-router-dom";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../AppNavigation/constants";

import { getUserSignedInState } from "../../Auth";
import { ERROR_MESSAGES } from "../../utils/errors/errorUtils";
import { useSignInPage } from "../StoreComponents/StoreLogin/useStoreLogin";

interface RetailerLimits {
  maximumQuantityPerProduct: number;
  maxProductCount: number;
}
const retailerLimits: RetailerLimits = {
  maximumQuantityPerProduct: 10,
  maxProductCount: 20,
};

type SetSectionAction =
  | {
      section: "payment" | "shipping";
      state: SetStateAction<boolean>;
    }
  | {
      section: "delivery";
      state: SetStateAction<boolean[]>;
    };

const getStepFromCartProducts = (
  cartProducts: ShoppingCartItem[] | undefined
) => {
  if (!cartProducts) {
    return [];
  }
  return cartProducts.map((product, index) => {
    return {
      title:
        cartProducts.length > 1
          ? `Checking item ${index + 1} of ${cartProducts.length}`
          : "Checking item",
      subtitle: `Quantity of ${product.quantity}`,
      imageUrl: getEntityImage(product.stacklineSku, "product"),
      visible: true,
      success: true,
    };
  });
};
interface RetriableErrorFeedback {
  updateAddressStatus?: CheckoutActionStatus;
  updatePaymentStatus?: CheckoutActionStatus;
  updatingDelivery?: boolean;
}

type EnterAnimationConfig = {
  title: string;
  stepCards: ProductCardProps[];
  retailerId: number;
};

export interface CheckoutContextProps {
  MFAChooseRetailerSyncSession: RetailerSyncSession | undefined;
  MFAEnterRetailerSyncSession: RetailerSyncSession | undefined;
  successBatchId: string | undefined;
  removingOutOfStockProduct: boolean;
  confirmPartiallyOutOfStock: (stacklineSku: string) => Promise<void>;
  removePartiallyOutOfStock: (stacklineSku: string) => Promise<void>;
  removeErrorProduct: (stacklineSku: string) => Promise<void>;
  enterAnimationConfig?: EnterAnimationConfig;
  initiatingCheckout: boolean;
  setDoneEnteringAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  updateAddressStatus: CheckoutActionStatus;
  updatePaymentStatus: CheckoutActionStatus;
  updatingDelivery: boolean;
  submittingCheckout: boolean;
  retailerToConnect: ReduxStore["retailers"][0] | undefined;
  setRetailerToConnect: React.Dispatch<
    React.SetStateAction<ReduxStore["retailers"][0] | undefined>
  >;
  paymentOpen: boolean;
  shippingOpen: boolean;
  deliveryOpen: boolean[];
  handleSetSectionOpen: ({ section, state }: SetSectionAction) => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  endCheckout: () => void;
  getAllCarts: () => Promise<void>;
  allCarts: Partial<UserRetailerShoppingCart>[];
  isBagFullDialogOpen?: React.MutableRefObject<boolean>;
  products?: IProducts;
  cartCount: number;
  checkoutNextOrder: () => Promise<void>;
  updateCartItem: (
    item: ShoppingCartItem,
    type: "INCREMENT_QUANTITY" | "DECREMENT_QUANTITY"
  ) => void;
  addItemsToCart: (items: ShoppingCartItem[]) => boolean;
  removeItemFromCart: (retailerId: number, items: ShoppingCartItem[]) => void;
  initiateCartCheckout: (
    retailerId?: number,
    retailerIds?: number[],
    batchId?: string,
    loginInfo?: {
      username: string;
      password: string;
    }
  ) => Promise<any>;
  cancelOrder: (retailerId: number) => Promise<any> | void;
  loadingAllCarts: boolean;
  attemptOrder: () => Promise<void>;
  confirmOrder: (value?: string | number) => Promise<void>;
  updateOrderShippingOption: (
    deliveryOption?: {
      name: string;
      cssSelector: string;
    },
    deliveryTimeWindow?: {
      name: string;
      cssSelector: string;
    }
  ) => Promise<void>;

  updateOrderDeliveryAddress: (
    actionType: "add" | "update",
    address: Address
  ) => Promise<void>;
  updateOrderPaymentMethod: (
    actionType: "add" | "update",
    cardInfo: {
      cardNumber?: string;
      cardIssuer: string;
      expirationDate: string;
      cvv?: string;
      cardHolderName: string;
      cardNumberEnding?: string;
    },
    billingAddress?: Address
  ) => Promise<void>;
  hasNextRetailerToCheckout: boolean;
  canAddItemToCart: (retailerId: number, retailerSku: string) => boolean;
  failedAttemptError: FailedAttemptType | undefined;
}

type CheckoutActionStatus = "loading" | "error" | "success" | "idle";

const CheckoutContext = createContext<CheckoutContextProps>({
  MFAChooseRetailerSyncSession: undefined,
  successBatchId: undefined,
  removingOutOfStockProduct: false,
  MFAEnterRetailerSyncSession: undefined,
  confirmPartiallyOutOfStock: async () => {},
  removeErrorProduct: async () => {},
  removePartiallyOutOfStock: async () => {},
  enterAnimationConfig: undefined,
  initiatingCheckout: false,
  setDoneEnteringAnimation: () => {},
  updateAddressStatus: "idle",
  updatePaymentStatus: "idle",
  updatingDelivery: false,
  submittingCheckout: false,
  retailerToConnect: undefined,
  setRetailerToConnect: () => {},
  handleSetSectionOpen: () => {},
  paymentOpen: false,
  shippingOpen: false,
  deliveryOpen: [],
  loading: false,
  setLoading: () => {},
  checkoutNextOrder: () => new Promise((resolve) => resolve()),
  endCheckout: () => {},
  cancelOrder: async () => {},
  getAllCarts: () =>
    new Promise((resolve) => {
      resolve();
    }),
  allCarts: [],
  products: {},
  cartCount: 0,
  updateCartItem: () => {},
  addItemsToCart: () => false,
  removeItemFromCart: () => {},
  initiateCartCheckout: async () => {},
  loadingAllCarts: false,
  updateOrderShippingOption: async () => {},
  confirmOrder: async () => {},
  attemptOrder: async () => {},
  updateOrderDeliveryAddress: async () => {},
  updateOrderPaymentMethod: async () => {},
  hasNextRetailerToCheckout: false,
  canAddItemToCart: () => false,
  failedAttemptError: undefined,
});

const canIncrementItemQuantity = (
  item: Pick<ShoppingCartItem, "quantity"> | undefined,
  maxQuantity: number
) => {
  const quantity = item?.quantity ?? 0;
  return quantity < maxQuantity;
};

const canAddNewItemToCart = (
  cart: Partial<UserRetailerShoppingCart> | undefined,
  maxItems: number
) => {
  if (!cart?.items) {
    return true;
  }
  return cart.items.length < maxItems;
};

interface CanAddItemToCartParams {
  cart: Partial<UserRetailerShoppingCart> | undefined;
  retailerSku: string;
  limits: RetailerLimits;
}
export const canAddItem = ({
  cart,
  retailerSku,
  limits,
}: CanAddItemToCartParams) => {
  const existingItem = cart?.items?.find(
    (item) => item.retailerSku === retailerSku
  );
  if (existingItem) {
    return canIncrementItemQuantity(
      existingItem,
      limits.maximumQuantityPerProduct
    );
  }
  return canAddNewItemToCart(cart, limits.maxProductCount);
};

// const getNextRetailerId = (
//   currentRetailerId: number | undefined,
//   batch: UserRetailerCheckoutBatch | undefined
// ) => {
//   if (currentRetailerId == null || !batch) {
//     return;
//   }
//   const index = batch.retailerIds.indexOf(currentRetailerId);
//   if (index === -1 || index === batch.retailerIds.length - 1) {
//     return;
//   }
//   // Return the next element in the array
//   return batch?.retailerIds?.[index + 1];
// };

const CheckoutProvider = ({ children }: { children: ReactElement }) => {
  const aborterRef = useRef(new AbortController());
  const signIn = useSignInPage();
  const userProfile = useAppSelector((state) => state.userProfile);
  const order = useAppSelector((state) => state.checkout.order);
  const batch = useAppSelector((state) => state.checkout.batch);
  const retailers = useAppSelector((state) => state.retailers);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingAllCarts, setLoadingAllCarts] = useState(false);
  const navigate = useNavigate();
  const [retailerSyncSessions, setRetailerSyncSessions] =
    useState<undefined | RetailerSyncSession[]>(undefined);
  const [updatePaymentStatus, setUpdatePaymentStatus] =
    useState<CheckoutActionStatus>("idle");
  const [updatingDelivery, setUpdatingDelivery] = useState(false);
  const [
    removingPartiallyOutOfStockProduct,
    setRemovingPartiallyOutOfStockProduct,
  ] = useState(false);
  const [submittingCheckout, setSubmittingCheckout] = useState(false);
  const pollIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [polling, setPolling] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [shippingOpen, setShippingOpen] = useState(false);
  const [deliveryOpen, setDeliveryOpen] = useState<boolean[]>([]);
  const [successBatchId, setSuccessBatchId] = useState<string | undefined>();
  const [retailerToConnect, setRetailerToConnect] =
    useState<ReduxStore["retailers"][0] | undefined>(undefined);

  const [enterAnimationConfig, setEnterAnimationConfig] =
    React.useState<EnterAnimationConfig>();
  const [failedAttemptError, setFailedAttemptError] =
    useState<FailedAttemptType | undefined>();
  const [initiatingCheckout, setInitiatingCheckout] = useState(false);
  const [doneEnteringAnimation, setDoneEnteringAnimation] = useState(false);
  const [updateAddressStatus, setUpdateAddressStatus] =
    useState<CheckoutActionStatus>("idle");
  const [retryingLogin, setRetryingLogin] = useState(false);

  const [MFAChooseRetailerSyncSession, setMFAChooseRetailerSyncSession] =
    useState<RetailerSyncSession | undefined>();
  const [MFAEnterRetailerSyncSession, setMFAEnterRetailerSyncSession] =
    useState<RetailerSyncSession | undefined>();
  const isBagFullDialogOpen = useRef(false);

  const myBagRouteMatch = useMatch({
    path: "/mybag",
    end: true,
  });
  const isMyBagRoute = myBagRouteMatch != null;

  const showBagFullDialog = () => {
    const actions: DialogButtonAction[] = [
      {
        text: "Close",
        variant: "outline",
      },
    ];
    if (!isMyBagRoute) {
      actions.push({
        text: "View my bag",
        onClick: () => {
          navigate("/mybag");
        },
        variant: "solid",
      });
    }
    GenericDialog.show({
      title: "Your bag is full",
      text: "You’ve reached the maximum number of items you can have in your bag for this retailer.",
      textProps: {
        sx: {
          marginBottom: "30px",
          [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
            marginTop: "10px",
          },
          [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            fontSize: 12,
          },
        },
      },
      actionsProps: {
        sx: {
          "&.actions": {
            [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
              flexDirection: "column-reverse",
              button: {
                height: 44,
                fontSize: 14,
                maxWidth: 355,
              },
            },
          },
        },
      },
      listener(state) {
        isBagFullDialogOpen.current = state.open;
      },
      actions,
    });
  };
  const doneLoading = useCallback(() => {
    setEnterAnimationConfig(undefined);
    setUpdatingDelivery(false);
    setRemovingPartiallyOutOfStockProduct(false);
    setSubmittingCheckout(false);
  }, []);

  const resetUpdateStatuses = useCallback(() => {
    setUpdatePaymentStatus("idle");
    setUpdateAddressStatus("idle");
    setUpdatingDelivery(false);
  }, []);

  useEffect(() => {
    // reset update status when entering checkout
    if (initiatingCheckout) {
      resetUpdateStatuses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiatingCheckout]);

  const handleOpenPaymentVerification = useCallback(() => {
    //     bottomModalRef.current?.present();
  }, []);
  const handleClosePaymentVerification = useCallback(() => {
    //bottomModalRef.current?.close();
  }, []);

  const endCheckout = useCallback(() => {
    aborterRef.current.abort();
    setSuccessBatchId(undefined);
    setPolling(false);
    doneLoading();
    resetUpdateStatuses();
    setLoading(false);
    setRetailerSyncSessions(undefined);
    setOrderId("");
    setPaymentOpen(false);
    setShippingOpen(false);
    setDeliveryOpen([]);
    setDoneEnteringAnimation(false);
    dispatch(setOrder(undefined));
    dispatch(setBatch(undefined));

    handleClosePaymentVerification();
  }, [
    dispatch,
    doneLoading,
    handleClosePaymentVerification,
    resetUpdateStatuses,
  ]);

  // const retailerLoginInfo = useAppSelector(({ retailerLoginInfo }) => retailerLoginInfo);

  const allCartsUnfiltered = useAppSelector(
    (state) => state.checkout.optimisticAllCarts ?? state.checkout.allCarts
  );
  const products = useAppSelector((state) => state.checkout.products);

  // const connectedRetailerIds = [1];
  const connectedRetailers = retailers.filter((retailer) => {
    return (
      retailer.parentRetailerId &&
      _get(
        userProfile,
        [
          "extendedAttributes",
          `connectedRetailer_${retailer.parentRetailerId}_state`,
        ],
        "invalid"
      ) === "valid"
    );
  });

  const connectedRetailerIds = connectedRetailers.map(
    (retailer) => retailer.parentRetailerId
  );

  const updateCartItem = (
    item: ShoppingCartItem,
    type: "INCREMENT_QUANTITY" | "DECREMENT_QUANTITY"
  ) => {
    switch (type) {
      case "INCREMENT_QUANTITY":
        if (!canAddItemToCart(item.retailerId, item.retailerSku)) {
          showBagFullDialog();
          return;
        }
        dispatch(
          incrementOptimisticShoppingCartItemQuantity({
            retailerId: item.retailerId,
            retailerSku: item.retailerSku,
          })
        );

        break;
      case "DECREMENT_QUANTITY":
        dispatch(
          decrementOptimisticShoppingCartItemQuantity({
            retailerId: item.retailerId,
            retailerSku: item.retailerSku,
          })
        );
        break;
    }
  };

  const removeItemFromCart = (
    retailerId: number,
    items: ShoppingCartItem[]
  ) => {
    dispatch(removeOptimisticShoppingCartItem({ items, retailerId }));
  };

  const allCarts = useMemo(
    () => allCartsUnfiltered?.filter((c) => c.items && c.items.length),
    [allCartsUnfiltered]
  );
  const cartCount = useMemo(() => sumAllItemQuantities(allCarts), [allCarts]);

  /**
   * Adds items to the cart.
   *
   * @param items - The items to be added to the cart.
   * @returns A boolean indicating whether the items were successfully added to the cart.
   */
  const addItemsToCart = (items: ShoppingCartItem[]) => {
    const itemRetailerId = items?.[0].retailerId;
    const allowedItems = items.filter((item) =>
      canAddItemToCart(itemRetailerId, item.retailerSku)
    );
    if (!allowedItems.length) {
      showBagFullDialog();
      // failed to add items to cart
      return false;
    }
    dispatch(
      addOptimisticShoppingCartItem({
        retailerId: items[0].retailerId,
        items,
      })
    );
    return true;
  };
  const resetState = () => {
    dispatch(setAllCarts([]));
    dispatch(setProducts(undefined));
  };

  const getAllCarts = async () => {
    try {
      setLoadingAllCarts(true);
      const { userRetailerShoppingCarts, products } = await getActiveCarts();
      const sortedAllCarts = _.sortBy(userRetailerShoppingCarts, "retailerId");
      dispatch(setAllCarts(sortedAllCarts));
      dispatch(setProducts(products));
    } catch (e) {
      console.log("error", JSON.stringify(e));
    } finally {
      setLoadingAllCarts(false);
    }
  };

  const initiateCartCheckout = async (
    retailerId?: number,
    retailerIds?: number[],
    batchId?: string,
    loginInfo?: {
      username: string;
      password: string;
    }
  ) => {
    const { signedIn } = await getUserSignedInState();
    if (!signedIn) {
      closeCartDrawer();
      signIn({ pathname: "/mybag" });
      return;
    }

    if (!retailerId || !retailerIds) {
      return;
    }
    const disconnected = !connectedRetailerIds.includes(retailerId);

    if (disconnected && !loginInfo) {
      // prompt user to connect all retailers
      const disconnectedRetailer = retailers?.find((retailer) => {
        return retailer.retailerId === retailerId;
      });
      // TODO: show retailer sign in page instead of alert
      setRetailerToConnect(disconnectedRetailer);
      navigate("/checkout", { state: { from: "init_checkout" } });
      return;
    }

    try {
      // Make the API call to initiate here
      let res: {
        userRetailerCheckoutOrder?: UserRetailerCheckoutOrder;
        userRetailerCheckoutBatch?: UserRetailerCheckoutBatch;
      } = {};
      setInitiatingCheckout(true);
      setLoading(true);
      const currentCart = allCarts.find(
        (cart) => cart.retailerId === retailerId
      );
      const currentSteps = getStepFromCartProducts(currentCart?.items);
      const newStepCard = [...currentSteps, deliveryCard, boxingCard];

      if (loginInfo) {
        newStepCard.unshift(getConnectPlugCard(retailerId));
      }

      setEnterAnimationConfig({
        title:
          retailerIds.length > 1
            ? `Preparing order ${retailerIds.indexOf(retailerId) + 1} of ${
                retailerIds.length
              }`
            : `Preparing order`,
        stepCards: newStepCard,
        retailerId: retailerId,
      });
      setDoneEnteringAnimation(false);

      navigate("/checkout", { state: { from: "init_checkout" } });

      if (loginInfo) {
        const { token, currentStamp } = (await getTOtp()) ?? {};

        if (token && currentStamp) {
          const connectionInfo = encryptData(
            [
              {
                retailerId: retailerId,
                credential: {
                  username: loginInfo.username,
                  password: loginInfo.password,
                },
              },
            ],
            token
          );
          if (retryingLogin) {
            if (!order?.userRetailerCheckoutOrder?.orderId) {
              throw new Error("No order found to retry login");
            }
            await retryRetailerLoginDuringCheckout({
              orderId: order.userRetailerCheckoutOrder.orderId,
              currentStamp,
              connectionInfo,
            });
          } else {
            res = await initiateRetailerCheckout({
              retailerId,
              retailerIds,
              checkoutBatchId: batchId ?? "",
              checkoutMode: "serverSide",
              currentStamp,
              connectionInfo,
            });
          }
        }
      } else {
        res = await initiateRetailerCheckout({
          retailerId,
          retailerIds,
          checkoutBatchId: batchId ?? "",
          checkoutMode: "serverSide",
        });
      }

      const { userRetailerCheckoutOrder, userRetailerCheckoutBatch } = res;

      console.log(
        "init checkout res ==>",
        userRetailerCheckoutOrder?.orderId ?? orderId
      );
      const formatBatch = userRetailerCheckoutBatch
        ? {
            ...userRetailerCheckoutBatch,
            retailerIds: _.sortBy(userRetailerCheckoutBatch.retailerIds),
          }
        : undefined;

      formatBatch && dispatch(setBatch(formatBatch));
      userRetailerCheckoutOrder &&
        dispatch(setOrder(userRetailerCheckoutOrder));
      const newOrderId = userRetailerCheckoutOrder?.orderId ?? orderId;
      // CheckoutTransition.toOrder(newOrderId);

      setOrderId(newOrderId);
      setPolling(true);
      fetchOrderStatus(newOrderId);
      return res;
    } catch (e) {
      console.error("init checkout error", e);
    } finally {
      setRetryingLogin(false);
    }
  };

  const handleRetriableError = useCallback(
    (
      failedAttemptError: FailedAttemptType | undefined
    ): RetriableErrorFeedback | undefined => {
      const type = failedAttemptError?.type;
      const errorMessage = failedAttemptError?.errorMessage;
      if (!type || !errorMessage) {
        setFailedAttemptError(undefined);
        return;
      }
      setFailedAttemptError(failedAttemptError);
      switch (type) {
        case "addShippingAddress":
        case "updateShippingAddress":
          setUpdateAddressStatus("error");
          return { updateAddressStatus: "error" };
        case "addPaymentMethod":
        case "updatePaymentMethod":
          setUpdatePaymentStatus("error");
          return { updatePaymentStatus: "error" };
        case "updateShippingOption":
          setUpdatingDelivery(false);
          return { updatingDelivery: false };
        case "login": {
          const currentRetailerId =
            order?.userRetailerCheckoutOrder?.retailerId;
          const retailer =
            currentRetailerId != null
              ? retailers?.find(
                  (retailer) => retailer.retailerId === currentRetailerId
                )
              : undefined;

          if (retailer) {
            setRetailerToConnect(retailer);
            setRetryingLogin(true);
            return;
          }
          break;
        }
        default:
          console.error("Unhandled failed attempt error", failedAttemptError);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      order?.userRetailerCheckoutOrder?.retailerId,
      connectedRetailerIds,
      retailers,
    ]
  );

  const moveToSuccessSummary = useCallback((batchId: string) => {
    setLoading(false);
    doneLoading();
    getAllCarts();
    setSuccessBatchId(batchId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSuccess = useCallback(async () => {
    const interval = setInterval(async () => {
      const res = await serverSideSyncApolloClient.query<{
        UserRetailerCheckoutOrders: SearchResult<UserPurchaseHistory>;
      }>({
        query: getUserRetailerCheckoutOrderDetailBreakup,
        variables: {
          checkoutBatchIds: [order?.userRetailerCheckoutOrder?.checkoutBatchId],
          page: {
            start: 0,
            size: 10,
          },
        },
        fetchPolicy: "network-only",
      });
      const summaryRetailers = _get(
        res,
        ["data", "UserRetailerCheckoutOrders", "items"],
        []
      );

      const items = summaryRetailers.filter(
        (d) =>
          d.checkoutBatchId ===
            order?.userRetailerCheckoutOrder?.checkoutBatchId &&
          isPurchaseCheckoutSuccess(d)
      );

      if (items.length > 0) {
        clearInterval(interval);
        moveToSuccessSummary(items?.[0].checkoutBatchId);
      }
    }, 2500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.userRetailerCheckoutOrder?.retailerId]);

  const handleFetchResult = useCallback(
    async (pollRes: RetailerCheckoutOrderStatus) => {
      const orderStatus = pollRes?.userRetailerCheckoutOrder?.status;
      const sessionStatus = pollRes?.userRetailerCheckoutSessionStatus?.status;
      const lastFailedAttempt =
        pollRes?.userRetailerCheckoutSessionStatus?.lastFailedAttempt;
      const errorMessage = pollRes?.userRetailerCheckoutOrder?.errorMessage;
      const retryErrorFeedback = handleRetriableError(lastFailedAttempt);
      // ensures we have the latest status
      const newUpdatePaymentStatus =
        retryErrorFeedback?.updatePaymentStatus ?? updatePaymentStatus;
      const newUpdateAddressStatus =
        retryErrorFeedback?.updateAddressStatus ?? updateAddressStatus;
      const newUpdatingDelivery =
        retryErrorFeedback?.updatingDelivery ?? updatingDelivery;

      const shouldMoveToCheckout =
        orderStatus === "checkout_in_progress" &&
        sessionStatus === "checkout_waiting_for_user_input";

      const shouldSuccess = orderStatus === "checkout_success";

      // const isAllOutOfStock =
      //   orderStatus === "checkout_error" &&
      //   errorMessage === "No items in the cart";

      const shouldShowError =
        orderStatus === "checkout_timed_out" ||
        orderStatus === "checkout_error" ||
        orderStatus === "checkout_cancelled";

      const retailerSyncSessions = pollRes?.retailerSyncSessions;
      errorMessage ??
        console.error(
          "checkout poll error ==>",
          errorMessage,
          `orderStatus(${orderStatus}), sessionStatus(${sessionStatus}), doneEnteringAnimation(${doneEnteringAnimation})`,
          Date.now()
        );

      console.log(
        "Checkout test ==> ",
        newUpdatePaymentStatus,
        newUpdateAddressStatus,
        newUpdatingDelivery,
        shouldMoveToCheckout,
        shouldSuccess,
        shouldShowError,
        doneEnteringAnimation,
        newUpdatePaymentStatus,
        newUpdateAddressStatus,
        newUpdatingDelivery
      );
      setRetailerSyncSessions(retailerSyncSessions);
      setInitiatingCheckout(false);
      if (shouldMoveToCheckout) {
        setDeliveryOpen((prev) => {
          if (prev.length === 0) {
            return (
              pollRes?.userRetailerCheckoutOrder?.shippingOptions?.shippingGroups.map(
                () => false
              ) || []
            );
          } else {
            return prev;
          }
        });

        if (doneEnteringAnimation) {
          setLoading(false);
          setEnterAnimationConfig(undefined);
        }
        // finish updating payment
        if (newUpdatePaymentStatus === "loading") {
          setLoading(false);
          setUpdatePaymentStatus("success");
        }
        // finish updating address
        if (newUpdateAddressStatus === "loading") {
          setLoading(false);
          setUpdateAddressStatus("success");
        }
        // finish updating delivery
        if (newUpdatingDelivery) {
          setLoading(false);
          setUpdatingDelivery(false);
        }

        // navigation.navigate("checkoutFlow");
      } else if (shouldSuccess) {
        setPolling(false);

        if (hasNextRetailerToCheckout) {
          checkoutNextOrder();
          setSubmittingCheckout(false);
        } else {
          await handleSuccess();

          await getAllCarts();
        }
      } else if (shouldShowError) {
        if (updateAddressStatus === "loading") {
          // set address update status to error if we are still updating address
          setUpdateAddressStatus("error");
        }
        if (updatePaymentStatus === "loading") {
          // set payment update status to error if we are still updating payment
          setUpdatePaymentStatus("error");
        }
        doneLoading();
        setPolling(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      doneEnteringAnimation,
      updateAddressStatus,
      updatePaymentStatus,
      updatingDelivery,
      handleRetriableError,
    ]
  );

  const fetchOrderStatus = useCallback(
    async (orderId: string) => {
      const pollRes = await fetchOrderForPoll(orderId);
      await handleFetchResult(pollRes as RetailerCheckoutOrderStatus);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleFetchResult]
  );

  const handleSetSectionOpen = ({ section, state }: SetSectionAction) => {
    switch (section) {
      case "payment":
        setShippingOpen(false);
        setDeliveryOpen((prev) => {
          return prev.map(() => false);
        });
        setPaymentOpen(state);
        break;
      case "shipping":
        setPaymentOpen(false);
        setDeliveryOpen((prev) => {
          return prev.map(() => false);
        });
        setShippingOpen(state);
        break;
      case "delivery":
        setShippingOpen(false);
        setPaymentOpen(false);
        setDeliveryOpen(state);
        break;
    }
  };

  useEffect(() => {
    if (retailerSyncSessions && retailerSyncSessions.length > 0) {
      const retailerSyncSessionInMFAChoose = retailerSyncSessions?.find(
        (r) =>
          r.syncStatus === "userintervention" &&
          r.eventSequence &&
          r.eventSequence.type === "mfa-choose" &&
          r.eventSequence.initiator === "server"
      );
      const retailerSyncSessionInMFAEnter = retailerSyncSessions?.find(
        (r) =>
          r.syncStatus === "userintervention" &&
          r.eventSequence &&
          r.eventSequence.type === "mfa-enter" &&
          r.eventSequence.initiator === "server"
      );
      if (retailerSyncSessionInMFAChoose) {
        if (retailerSyncSessionInMFAChoose) {
          setMFAChooseRetailerSyncSession(retailerSyncSessionInMFAChoose);
        }
      } else if (retailerSyncSessionInMFAEnter) {
        setMFAEnterRetailerSyncSession(retailerSyncSessionInMFAEnter);
      } else {
        setMFAChooseRetailerSyncSession(undefined);
        setMFAEnterRetailerSyncSession(undefined);
      }
    }
  }, [retailerSyncSessions]);

  useEffect(() => {
    const updatingAddress =
      updateAddressStatus === "loading" || updateAddressStatus === "error";
    const updatingPayment =
      updatePaymentStatus === "loading" || updatePaymentStatus === "error";
    if (
      !loading &&
      orderId &&
      !updatingAddress &&
      !updatingPayment &&
      !updatingDelivery
    ) {
      setPaymentOpen(false);
      setShippingOpen(false);
      setDeliveryOpen((prev) => {
        return prev.map(() => false);
      });
      // if we are done updating address, payment, and delivery, we can move back to the checkout screen
    }
  }, [
    loading,
    orderId,
    updateAddressStatus,
    updatePaymentStatus,
    updatingDelivery,
  ]);

  useEffect(() => {
    if (polling && orderId) {
      pollIntervalRef.current = setInterval(async () => {
        fetchOrderStatus(orderId);
      }, 5000);
    }

    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }
    }; // clear interval when unmounting the component
  }, [polling, orderId, fetchOrderStatus]);

  const updateOrderDeliveryAddress = async (
    actionType: "add" | "update",
    address: Address
  ) => {
    if (
      order?.userRetailerCheckoutOrder?.retailerId &&
      order?.userRetailerCheckoutOrder?.orderId
    ) {
      try {
        setLoading(true);
        // For the useEffect. This ensures navigation back to checkout after address update, even if other updates are in error state.
        resetUpdateStatuses();
        setUpdateAddressStatus("loading");
        const request = {
          retailerId: order?.userRetailerCheckoutOrder?.retailerId,
          orderId: order?.userRetailerCheckoutOrder?.orderId,
          shippingAddress: {
            ...(order.userRetailerCheckoutOrder.shippingAddress ?? {}),
            ...address,
          } as any,
        };
        const res = await (actionType === "update"
          ? updateShippingToUserRetailerCheckoutOrder(request)
          : addShippingToUserRetailerCheckoutOrder(request));
        if (res.status === "error" || res.errorMessage) {
          throw new Error(res.errorMessage ?? ERROR_MESSAGES.default[0]);
        }
        fetchOrderStatus(order?.userRetailerCheckoutOrder?.orderId);
      } catch (e) {
        console.error(e);
        setUpdateAddressStatus("error");
        setLoading(false);
        throw e;
      }
    }
  };

  const updateOrderPaymentMethod = async (
    actionType: "add" | "update",
    cardInfo: {
      cardNumber?: string;
      cardIssuer: string;
      expirationDate: string;
      cvv?: string;
      cardHolderName: string;
      cardNumberEnding?: string;
    },
    billingAddress?: Address
  ) => {
    if (
      order?.userRetailerCheckoutOrder?.retailerId &&
      order?.userRetailerCheckoutOrder?.orderId
    ) {
      try {
        setLoading(true);
        // For the useEffect. This ensures navigation back to checkout after payment method update, even if other updates are in error state.
        resetUpdateStatuses();
        setUpdatePaymentStatus("loading");
        const req = {
          retailerId: order?.userRetailerCheckoutOrder?.retailerId,
          orderId: order?.userRetailerCheckoutOrder?.orderId,
          cardInfo: cardInfo,
          billingAddress: billingAddress
            ? ({
                ...(order.userRetailerCheckoutOrder.billingAddress ?? {}),
                ...billingAddress,
              } as any)
            : undefined,
        };
        const res = await (actionType === "update"
          ? updatePaymentMethodToUserRetailerCheckoutOrder(req)
          : addPaymentMethodToUserRetailerCheckoutOrder(req));
        if (res.status === "error" || res.errorMessage) {
          throw new Error(res.errorMessage ?? ERROR_MESSAGES.default[0]);
        }
        fetchOrderStatus(order?.userRetailerCheckoutOrder?.orderId);
      } catch (e) {
        console.error(e);
        setUpdatePaymentStatus("error");
        setLoading(false);
        throw e;
      }
    }
  };

  const updateOrderShippingOption = async (
    deliveryOption?: {
      name: string;
      cssSelector: string;
    },
    deliveryTimeWindow?: {
      name: string;
      cssSelector: string;
    }
  ) => {
    if (
      order?.userRetailerCheckoutOrder?.retailerId &&
      order?.userRetailerCheckoutOrder?.orderId
    ) {
      setLoading(true);
      setUpdatingDelivery(true);
      try {
        const res = await updateShippingOption({
          retailerId: order?.userRetailerCheckoutOrder?.retailerId,
          orderId: order?.userRetailerCheckoutOrder?.orderId,
          deliveryOption,
          deliveryTimeWindow,
        });
        if (res.status === "error" || res.errorMessage) {
          throw new Error(res.errorMessage ?? ERROR_MESSAGES.default[0]);
        }
        fetchOrderStatus(order?.userRetailerCheckoutOrder?.orderId);
      } catch (e) {
        console.error(e);
        setUpdatingDelivery(false);
        setLoading(false);
        throw e;
      }
    }
  };

  const fetchOrderForPoll = useCallback(
    async (orderId: string) => {
      aborterRef.current.abort();
      const controller = new AbortController();
      aborterRef.current = controller;
      try {
        await sleep(500);
        const res = await getRetailerCheckoutOrderStatus(
          {
            orderId,
          },
          controller.signal
        );
        dispatch(setOrder(res));
        return res;
      } catch (e) {
        console.log("polling error", e);
      }
    },
    [dispatch]
  );

  const confirmPartiallyOutOfStock = async (stacklineSku: string) => {
    const removeItem = order?.userRetailerCheckoutOrder?.items?.find(
      (item) => item.stacklineSku === stacklineSku
    );
    if (!removeItem) {
      return;
    }
    const request = {
      retailerId: order?.userRetailerCheckoutOrder?.retailerId,
      orderId: order?.userRetailerCheckoutOrder?.orderId,
      brandId: order?.userRetailerCheckoutOrder?.brandId,
      items: [
        {
          quantity: removeItem?.quantity,
          retailPrice: removeItem?.retailPrice,
          retailerSku: removeItem?.retailerSku,
          retailerId: removeItem?.retailerId,
        },
      ],
    };
    try {
      const res = await updateUserConfirmationToCheckoutItems(request);

      await handleFetchResult(res as RetailerCheckoutOrderStatus);
      fetchOrderStatus(order?.userRetailerCheckoutOrder?.orderId as string);
    } catch (e) {
      console.log("error", JSON.stringify(e));
    }
  };

  const removePartiallyOutOfStock = async (stacklineSku: string) => {
    if (!order?.userRetailerCheckoutOrder?.items) {
      // no items in the cart
      return;
    }
    const removeItem = order.userRetailerCheckoutOrder.items.find(
      (item) => item.stacklineSku === stacklineSku
    );
    if (!removeItem) {
      // no item to remove
      return;
    }
    const remainingItemsAfterRemoval =
      order.userRetailerCheckoutOrder.items.filter(
        (item) => item.stacklineSku !== removeItem.stacklineSku
      );

    const request = {
      retailerId: order?.userRetailerCheckoutOrder?.retailerId,
      orderId: order?.userRetailerCheckoutOrder?.orderId,
      brandId: order?.userRetailerCheckoutOrder?.brandId,
      items: [
        {
          brandId: removeItem?.brandId,
          quantity: removeItem?.quantity,
          retailPrice: removeItem?.retailPrice,
          retailerSku: removeItem?.retailerSku,
          retailerId: removeItem?.retailerId,
          stacklineSku: removeItem?.stacklineSku,
        },
      ],
    };

    // if removing item results in an empty cart
    if (remainingItemsAfterRemoval.length === 0) {
      try {
        // stop listening to the poll to avoid errors when removing the last item from the order
        aborterRef.current.abort();
        setPolling(false);

        // remove the item from the bag
        await removeItemFromShoppingCart({
          retailerId: order.userRetailerCheckoutOrder.retailerId,
          items: [removeItem],
        });
        // update the cart to reflect the removal in the bag
        await getAllCarts();
      } catch (e) {
        console.error(
          `failed to remove item ${removeItem.stacklineSku} from the cart`,
          e
        );
      }
      return;
    }

    try {
      setLoading(true);
      setRemovingPartiallyOutOfStockProduct(true);
      await deleteItemsInCheckoutPage(request);

      fetchOrderStatus(order?.userRetailerCheckoutOrder?.orderId as string);
    } catch (e) {
      console.log("error", JSON.stringify(e));
    }
  };

  const removeErrorProduct = async (stacklineSku: string) => {
    const removeItem = order?.userRetailerCheckoutOrder?.items?.find(
      (item) => item.stacklineSku === stacklineSku
    );
    if (!removeItem) {
      return;
    }

    const request = {
      retailerId: order?.userRetailerCheckoutOrder?.retailerId,
      orderId: order?.userRetailerCheckoutOrder?.orderId,
      brandId: order?.userRetailerCheckoutOrder?.brandId,
      items: [
        {
          quantity: removeItem?.quantity,
          retailPrice: removeItem?.retailPrice,
          retailerSku: removeItem?.retailerSku,
          retailerId: removeItem?.retailerId,
        },
      ],
    };
    try {
      const res = await deleteOutOfStockItemsFromCheckoutOrder(request);
      await handleFetchResult(res as RetailerCheckoutOrderStatus);
      fetchOrderStatus(order?.userRetailerCheckoutOrder?.orderId as string);
    } catch (e) {
      console.log("error", JSON.stringify(e));
    }
  };

  const hasNextRetailerToCheckout =
    batch?.retailerIds &&
    batch?.retailerIds?.length > 1 &&
    batch?.retailerIds[batch.retailerIds.length - 1] !==
      order?.userRetailerCheckoutOrder?.retailerId;

  const attemptOrder = async () => {
    if (!order?.userRetailerCheckoutOrder?.orderId) {
      throw new Error("No order found to confirm");
    }

    const paymentConfirmationTypes =
      order?.userRetailerCheckoutOrder?.paymentMethod?.paymentConfirmationTypes;

    if (
      paymentConfirmationTypes?.[0]?.type === "cvv" ||
      paymentConfirmationTypes?.[0]?.type === "cardNumber"
    ) {
      //   if (true) {
      handleOpenPaymentVerification();
    } else {
      confirmOrder();
    }
  };

  const confirmOrder = async (verificationValue?: number | string) => {
    if (order?.userRetailerCheckoutOrder?.retailerId) {
      const paymentConfirmationTypes =
        order?.userRetailerCheckoutOrder?.paymentMethod
          ?.paymentConfirmationTypes;
      try {
        await confirmCheckout({
          retailerId: order?.userRetailerCheckoutOrder?.retailerId,
          orderId: order?.userRetailerCheckoutOrder?.orderId,
          paymentConfirmation: verificationValue
            ? {
                type: paymentConfirmationTypes?.[0]?.type ?? "cvv",
                value: verificationValue?.toString() ?? "",
              }
            : undefined,
        });
        setSubmittingCheckout(true);
        setLoading(true);
        fetchOrderStatus(order?.userRetailerCheckoutOrder?.orderId);
      } catch (e) {
        console.error(e);
        alert(e);
      }
    }
  };

  useEffect(() => {
    if (userProfile?.userId) {
      getAllCarts();
    } else {
      resetState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile?.userId]);

  const cancelOrder = async (retailerId: number) => {
    const existingCheckoutId = allCarts?.find(
      (c) => c.retailerId === retailerId
    )?.checkoutOrderId;

    if (retailerId && existingCheckoutId) {
      return await cancelCheckout({ retailerId, orderId: existingCheckoutId });
    }
    return;
  };

  const checkoutNextOrder = async () => {
    if (!batch?.retailerIds) {
      return;
    }

    const currentRetailerId = order?.userRetailerCheckoutOrder?.retailerId ?? 0;
    const index = batch?.retailerIds.indexOf(currentRetailerId);
    if (index === -1 || index === batch?.retailerIds?.length - 1) {
      return;
    }
    // Return the next element in the array
    const nextRetailerId = batch?.retailerIds?.[index + 1];
    await cancelOrder(currentRetailerId);
    await initiateCartCheckout(
      nextRetailerId,
      batch.retailerIds,
      batch.checkoutBatchId
    );
  };

  const cartsByRetailerId = useMemo(
    () => new Map(allCarts.map((cart) => [cart.retailerId, cart])),
    [allCarts]
  );

  const canAddItemToCart = useCallback(
    (retailerId: number, retailerSku: string | undefined) => {
      if (!retailerSku) {
        return false;
      }
      const retailerCart = cartsByRetailerId.get(retailerId);
      return canAddItem({
        cart: retailerCart,
        retailerSku,
        limits: retailerLimits,
      });
    },
    [cartsByRetailerId]
  );

  return (
    <CheckoutContext.Provider
      value={{
        MFAChooseRetailerSyncSession,
        MFAEnterRetailerSyncSession,
        successBatchId,
        enterAnimationConfig,
        initiatingCheckout,
        setDoneEnteringAnimation,
        confirmPartiallyOutOfStock,
        removePartiallyOutOfStock,
        removeErrorProduct,
        loading,
        updateAddressStatus,
        updatePaymentStatus,
        updatingDelivery,
        submittingCheckout,
        removingOutOfStockProduct: removingPartiallyOutOfStockProduct,
        allCarts,
        isBagFullDialogOpen,
        products,
        loadingAllCarts,
        cartCount,
        hasNextRetailerToCheckout: !!hasNextRetailerToCheckout,
        checkoutNextOrder,
        setLoading,
        getAllCarts,
        cancelOrder,
        updateCartItem,
        addItemsToCart,
        removeItemFromCart,
        initiateCartCheckout,
        updateOrderShippingOption,
        updateOrderDeliveryAddress,
        updateOrderPaymentMethod,
        endCheckout,
        confirmOrder,
        attemptOrder,
        handleSetSectionOpen,
        paymentOpen,
        shippingOpen,
        deliveryOpen,
        retailerToConnect,
        setRetailerToConnect,
        canAddItemToCart,
        failedAttemptError,
      }}
    >
      {children}

      <CheckoutLoadingOverlay />
      <CheckoutErrorDialog />
    </CheckoutContext.Provider>
  );
};

export default CheckoutProvider;

export const useCartProduct = (stacklineSku: string) => {
  const cartProducts = useAppSelector((state) => state.checkout.products);
  const orderProducts = useAppSelector(
    (state) => state.checkout.order?.products
  );
  return cartProducts?.[stacklineSku] ?? orderProducts?.[stacklineSku] ?? null;
};

export const useCheckout = () => useContext(CheckoutContext);
