import { AxiosInstance, AxiosRequestConfig } from "axios";
import axios from "../../utils/axios";
import { useCallback, useRef, useState } from "react";
import {
  AnalyticsTrackingEvent,
  getRecentlySearchedTerms,
  RecentlySearchedTerm,
} from "@brandclub/common-ui";
import _uniqBy from "lodash/uniqBy";
import moment from "moment";
import { getAuthorizationHeader } from "../../Auth";
import { useTrackActions } from "../../utils/hooks/useTracking";

// 30 days
const MAX_SEARCH_AGE = 30 * 24 * 60 * 60 * 1000;
const MAX_RECENT_SEARCHES = 9;

type AxiosHeader = AxiosRequestConfig["headers"];
interface SearchScoreByCategoryId {
  [key: number]: number;
}
interface SearchTerm {
  searchScore: number;
  searchTerm: string;
}

type SearchTermOrRecentSearch = SearchTerm | RecentlySearchedTerm;
interface SearchTermAutoCompleteResponse {
  searchTerms?: SearchTerm[];
}

const isRecentSearch = (searchTerm: RecentlySearchedTerm) => {
  return (
    moment().diff(moment(searchTerm.updatedTime), "milliseconds") <
    MAX_SEARCH_AGE
  );
};

const limitSearchTerms = <T extends SearchTermOrRecentSearch>(
  searchTerms: T[],
  limit: number
) => {
  return searchTerms.slice(0, limit);
};

const filterRecentSearches = <T extends RecentlySearchedTerm>(
  searchTerms: T[],
  limit?: number
) => {
  if (limit == null) return searchTerms.filter(isRecentSearch);
  return limitSearchTerms(searchTerms.filter(isRecentSearch), limit);
};

const filterSearchTermsByText = <T extends SearchTermOrRecentSearch>(
  searchTerms: T[],
  searchText: string
) => {
  return searchTerms.filter((v) =>
    v.searchTerm.toLowerCase().startsWith(searchText.toLowerCase())
  );
};

interface CombinedSearchTermProps {
  searchHistory: RecentlySearchedTerm[];
  suggestedKeywords: SearchTerm[];
  limit?: number;
  searchText: string;
}
interface FetchSearchTermProps {
  searchScoreByCategoryId?: SearchScoreByCategoryId;
  limit?: number;
  searchText: string;
  brandId?: number;
  preventAbort?: boolean;
}

export const getCombinedSearchTerms = ({
  searchHistory,
  suggestedKeywords,
  limit = MAX_RECENT_SEARCHES,
  searchText,
}: CombinedSearchTermProps) => {
  const filteredSearchHistory = filterRecentSearches(searchHistory);
  const relevantRecentSearchTerms = filterSearchTermsByText(
    filteredSearchHistory,
    searchText
  );
  return _uniqBy([...relevantRecentSearchTerms, ...suggestedKeywords], (v) =>
    v.searchTerm.trim().toLowerCase()
  ).slice(0, limit);
};

const fetchRecentRelevantSearches = async (
  axios: AxiosInstance,
  headers: AxiosHeader
): Promise<RecentlySearchedTerm[] | undefined> => {
  try {
    const recentSearches = await getRecentlySearchedTerms(axios, headers);
    const searchTerms = recentSearches.data.payload;
    return searchTerms;
  } catch (e) {
    console.error("Error fetching recent searches", e);
    return undefined;
  }
};

export const useSearchAutoComplete = () => {
  const [trackAction] = useTrackActions();
  const autocompleteSearchAbortControllerRef =
    useRef<AbortController | null>(null);
  const [searchHistory, setSearchHistory] = useState<RecentlySearchedTerm[]>(
    []
  );

  const fetchSearchAutoComplete = useCallback(
    async ({
      searchText,
      searchScoreByCategoryId,
      limit = MAX_RECENT_SEARCHES,
      brandId,
      preventAbort,
    }: FetchSearchTermProps) => {
      const controller = new AbortController();
      if (!preventAbort) {
        autocompleteSearchAbortControllerRef.current?.abort(
          "New search initiated"
        );
        autocompleteSearchAbortControllerRef.current = controller;
      }
      trackAction(AnalyticsTrackingEvent.SEARCH, {
        searchText,
        brandId,
      });
      const headers = await getAuthorizationHeader();
      const result = await axios.post<SearchTermAutoCompleteResponse>(
        "/product/searchTermAutoCompleteSuggestions",
        {
          searchText,
          searchScoreByCategoryId: searchScoreByCategoryId ?? {},
          brandId,
        },
        {
          headers,
          signal: controller.signal,
        }
      );
      const suggestions = result?.data?.searchTerms ?? [];
      if (suggestions.length === 0) {
        trackAction(AnalyticsTrackingEvent.NO_SEARCH_RESULTS, {
          searchText,
          brandId,
        });
      }
      return limitSearchTerms(suggestions, limit);
    },
    [trackAction]
  );

  const fetchSearchHistory = useCallback(
    async (limit = MAX_RECENT_SEARCHES) => {
      const headers = await getAuthorizationHeader();
      const searchHistory = await fetchRecentRelevantSearches(axios, headers);
      if (searchHistory) {
        const filteredSearchHistory = filterRecentSearches(
          searchHistory,
          limit
        );
        setSearchHistory(filteredSearchHistory);
        return filteredSearchHistory;
      }
    },
    []
  );

  const fetchSearchTermsWithSearchHistory = useCallback(
    async (args: FetchSearchTermProps) => {
      const limit = args.limit ?? MAX_RECENT_SEARCHES;
      const [searchHistory, suggestedKeywords] = await Promise.all([
        fetchSearchHistory(limit),
        fetchSearchAutoComplete(args),
      ]);
      return getCombinedSearchTerms({
        searchHistory: searchHistory ?? [],
        suggestedKeywords,
        searchText: args.searchText,
        limit,
      });
    },
    [fetchSearchAutoComplete, fetchSearchHistory]
  );

  return {
    fetchSearchTermsWithSearchHistory,
    fetchSearchAutoComplete,
    fetchSearchHistory,
    searchHistory,
  };
};
