import {
  CloseIcon,
  MenuIcon,
  PersonIcon,
  SearchIcon,
  brandclubStyles,
  hexadecimal,
} from "@brandclub/common-ui";
import { Drawer, styled } from "@mui/material";
import React, { forwardRef } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import {
  DRAWER_Z_INDEX,
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
  SingleNavType,
  MOBILE_TOP_BAR_HEIGHT,
} from "./constants";

import { SiteBrandingType } from "../../types/misc";
import { useStateWithLocationReset } from "../../utils/hooks/useStateWithLocationReset";
import CheckoutDrawer from "../Checkout/CheckoutDrawer";
import { useCheckoutDrawer } from "../Checkout/useCheckoutDrawer";
import { MobileNavCartButton } from "./Buttons/NavCartButton";
import DrawerMenu from "./MobileDrawerMenu";
import SearchDropdown from "./SearchDropdown";
import { getHeaderTextColor } from "../../utils/StringUtils";

export const MobileNavButton = styled("div")(({ theme }) => ({
  height: 44,
  width: 44,
  minWidth: 44,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  img: {
    maxHeight: 23,
    maxWidth: 23,
  },
  color: getHeaderTextColor(theme),
}));

const Container = styled("div")(({ theme }) => ({
  height: MOBILE_TOP_BAR_HEIGHT,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  padding: "0 40px",
  boxSizing: "border-box",
  zIndex: 1202,
  position: "relative",
  backgroundColor: theme.palette.headerColor?.main ?? "#f6f8fa",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    padding: "0 40px",
  },
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "0 20px",
  },
  ".logo": {
    img: {
      objectFit: "contain",
      maxHeight: 35,
      maxWidth: 125,
    },
  },
  ".buttons": {
    display: "flex",
  },
}));

const MobileNav = (
  {
    siteBrandingType,
    persistentQueryParams,
    handleSearchClose,
    onSubmitSearch,
    searchInput,
    setSearchInput,
    handleSearchOpen,
    searchOpen,
    pageContext,
    filterDialogOpen,
    setFilterDialogOpen,
    filterCount,
    setFilterCount,
    cartCount,
    navMenu,
    brandHeaderLogo,
  }: {
    siteBrandingType: SiteBrandingType;
    persistentQueryParams: string;
    handleSearchClose: () => void;
    onSubmitSearch: () => void;
    searchInput: string;
    setSearchInput: (searchInput: string) => void;
    handleSearchOpen: (event: React.MouseEvent<HTMLDivElement>) => void;
    searchOpen: boolean;
    pageContext: any;
    filterDialogOpen: boolean;
    setFilterDialogOpen: (filterDialogOpen: boolean) => void;
    filterCount: number;
    setFilterCount: (filterCount: number) => void;
    navMenu: SingleNavType[];
    cartCount: number;
    brandHeaderLogo: string;
  },
  ref: React.Ref<any>
) => {
  const [drawerMenuOpen, setDrawerMenuOpen] = useStateWithLocationReset(false);
  const navigate = useNavigate();
  const homePagePathMatch = useMatch("/");
  const onHomePage = homePagePathMatch?.pattern?.end;
  const topBarOffset = Math.max(0, MOBILE_TOP_BAR_HEIGHT - window.scrollY);

  const { isOpen: isCheckoutDrawerOpen, closeDrawer: closeCheckoutDrawer } =
    useCheckoutDrawer();

  const closeDrawer = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    handleSearchClose();
    setDrawerMenuOpen(false);
    closeCheckoutDrawer();
  };

  return (
    <>
      <Container>
        <div className="logo">
          <Link style={{ display: "flex" }} to={"/"}>
            <img loading="lazy" src={brandHeaderLogo} alt="bd_logo"></img>
          </Link>
        </div>
        <div className="buttons">
          {searchOpen || drawerMenuOpen ? (
            <MobileNavButton onClick={closeDrawer}>
              <CloseIcon sx={{ fontSize: 16 }} />
            </MobileNavButton>
          ) : (
            <>
              {onHomePage && (
                <MobileNavButton
                  onClick={(e: any) => {
                    navigate("/dashboard/me");
                  }}
                >
                  <PersonIcon />
                </MobileNavButton>
              )}
              {!onHomePage && (
                <MobileNavButton onClick={handleSearchOpen}>
                  <SearchIcon />
                </MobileNavButton>
              )}
              <MobileNavCartButton
                onClick={() => {
                  navigate("/mybag");
                }}
                count={cartCount}
              />
              <MobileNavButton
                onClick={() => {
                  setDrawerMenuOpen(true);
                }}
              >
                <MenuIcon />
              </MobileNavButton>
            </>
          )}
        </div>
      </Container>
      <Drawer
        id="login-popover"
        open={searchOpen}
        sx={{
          zIndex: DRAWER_Z_INDEX,
          width: 462,
        }}
        PaperProps={{
          sx: {
            width: "100%",
            height: "100%",
            boxShadow: "none",
            borderRadius: "0",
            ...brandclubStyles.customScrollbar,
          },
        }}
        slotProps={{
          backdrop: {
            sx: (theme) => ({
              backgroundColor: hexadecimal(theme.palette.primary.main)(21),
            }),
          },
        }}
        anchor="top"
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        onClose={handleSearchClose}
      >
        <div
          style={{
            width: "100%",
            padding: "6px 20px",
            boxSizing: "border-box",
          }}
        >
          <div style={{ paddingTop: topBarOffset + 8 }}></div>
          <div>
            <SearchDropdown
              onSubmitSearch={onSubmitSearch}
              onClose={handleSearchClose}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              persistentQueryParams={persistentQueryParams}
              pageContext={pageContext}
              filterDialogOpen={filterDialogOpen}
              setFilterDialogOpen={setFilterDialogOpen}
              filterCount={filterCount}
              setFilterCount={setFilterCount}
            />
          </div>
        </div>
      </Drawer>
      <Drawer
        id="cart-popover"
        open={isCheckoutDrawerOpen}
        sx={{
          zIndex: DRAWER_Z_INDEX,
          width: 462,
          maxWidth: "100%",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            borderRadius: "0",
            maxWidth: "100%",
            ...brandclubStyles.customScrollbar,
          },
        }}
        slotProps={{
          backdrop: {
            sx: (theme) => ({
              backgroundColor: hexadecimal(theme.palette.primary.main)(21),
            }),
          },
        }}
        anchor="right"
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        onClose={() => {
          closeCheckoutDrawer();
        }}
      >
        <div style={{ paddingTop: topBarOffset }}></div>
        <CheckoutDrawer />
      </Drawer>
      <DrawerMenu
        open={drawerMenuOpen}
        setOpen={setDrawerMenuOpen}
        navMenu={navMenu}
        handleLoginOpen={() => {}}
      />
    </>
  );
};

export default forwardRef(MobileNav);
