import {
  ContentRewardCampaignForCarousel,
  RewardUnlockStrategy,
} from "../../../../../../types/misc";
import { styled } from "@mui/material";
import FlowContainer from "../../components/FlowContainer";
import { formatMoney } from "../../../../../../utils/StringUtils";
import {
  CampaignType,
  RewardPillWithText,
  brandclub_colors,
} from "@brandclub/common-ui";
import { FlowSolidButton } from "../../components/FlowButtons";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../../AppNavigation/constants";

const CoverContainer = styled("div")(({ theme }) => ({
  maxWidth: 500,
  padding: 20,
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  flex: 1,

  ".logo": {
    maxWidth: 225,
    maxHeight: 225,
    objectFit: "contain",
    borderRadius: 999,

    aspectRatio: 1,
    marginBottom: 36,
  },
  ".title": {
    marginBottom: 11,
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.primary.main,
    textAlign: "center",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 24,
    },
  },
  ".subtitle": {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 30,
    color: theme.palette.primary.main,
    textAlign: "center",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 18,
    },
  },
  ".est": {
    marginTop: 22,
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.primary.main,
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 16,
    },
  },
  ".reward_pill": {
    marginTop: 20,
  },
}));

const Cover = ({
  content,
  moveNext,
}: {
  content: ContentRewardCampaignForCarousel;
  moveNext: () => void;
}) => {
  const rewardAmount = content?.extendedAttributes?.reward?.reward?.amount ?? 0;
  const hasInstantReward =
    content?.extendedAttributes?.rewardUnlockStrategy ===
    RewardUnlockStrategy.Instant;
  return (
    <FlowContainer style={{ background: brandclub_colors.white }}>
      <CoverContainer>
        <img className="logo" alt="logo" src={content.brandclub?.imageUrl} />
        <div className="title">Content reward</div>
        <div className="subtitle">
          {`You will earn ${formatMoney(
            content.extendedAttributes.reward.reward.amount
          )} for viewing this content from ${
            content?.brandclub?.name || "this brand"
          }.`}
        </div>
        <FlowSolidButton
          style={{ width: "80%" }}
          onClick={() => {
            moveNext();
          }}
        >
          Continue
        </FlowSolidButton>
        <div className="reward_pill">
          <RewardPillWithText
            rewardAmount={rewardAmount ?? 0}
            rewardCampaignType={
              hasInstantReward
                ? CampaignType.DailyDealsRewardCampaign
                : CampaignType.ContentRewardCampaign
            }
            containerStyle={{}}
            baseFontSize={14}
            rewardPercentTextOverride={
              hasInstantReward ? "instant reward" : "content reward"
            }
          />
        </div>
      </CoverContainer>
    </FlowContainer>
  );
};

export default Cover;
