import { useEffect } from "react";
import LandingPageWrapper from "../../../pages/AppPages/Pages/components/LandingPageWrapper";
import { CheckoutContextProps, useCheckout } from "../../CheckoutProvider";
import AllOutOfStockScreen, { useIsAllOutOfStock } from "./AllOutOfStockScreen";
import CheckoutConnect from "./CheckoutConnect";
import { CheckoutMainFlow } from "./CheckoutMainFlow";
import CheckoutMFA from "./CheckoutMFA";
import CheckoutMFAChoose from "./CheckoutMFAChoose";
import CheckoutSuccess from "./CheckoutSuccess";
import EnterCheckoutAnimation from "./EnterCheckoutAnimation";
import { useLocation, useNavigate } from "react-router-dom";

type checkoutStatus =
  | "otp"
  | "otp_choose"
  | "connect"
  | "entering"
  | "flow"
  | "success";

const getStatus = ({
  enterAnimationConfig,
  successBatchId,
  retailerToConnect,
  MFAEnterRetailerSyncSession,
  MFAChooseRetailerSyncSession,
}: Partial<CheckoutContextProps>): checkoutStatus => {
  if (retailerToConnect) {
    return "connect";
  }
  if (MFAChooseRetailerSyncSession) {
    return "otp_choose";
  }
  if (MFAEnterRetailerSyncSession) {
    return "otp";
  }
  if (enterAnimationConfig) {
    return "entering";
  }
  if (successBatchId) {
    return "success";
  }
  return "flow";
};

const CheckoutFlow = () => {
  const { state } = useLocation();
  const navigateFrom = state?.from;

  const navigate = useNavigate();
  const {
    enterAnimationConfig,
    successBatchId,
    retailerToConnect,
    MFAEnterRetailerSyncSession,
    MFAChooseRetailerSyncSession,
  } = useCheckout();

  const status = getStatus({
    enterAnimationConfig,
    successBatchId,
    retailerToConnect,
    MFAEnterRetailerSyncSession,
    MFAChooseRetailerSyncSession,
  });

  const { allOutOfStock, showFullSrceen } = useIsAllOutOfStock();

  useEffect(() => {
    if (navigateFrom !== "init_checkout") {
      navigate("/mybag");
    }
  }, [navigate, navigateFrom]);

  return (
    <LandingPageWrapper>
      {allOutOfStock && showFullSrceen ? (
        <AllOutOfStockScreen />
      ) : status === "otp_choose" ? (
        <CheckoutMFAChoose />
      ) : status === "otp" ? (
        <CheckoutMFA />
      ) : status === "connect" ? (
        <CheckoutConnect />
      ) : status === "entering" ? (
        <EnterCheckoutAnimation />
      ) : status === "flow" ? (
        <CheckoutMainFlow />
      ) : (
        <CheckoutSuccess />
      )}
    </LandingPageWrapper>
  );
};

export default CheckoutFlow;
