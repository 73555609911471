import { useEffect, useState } from "react";
import { getCustomerSyncSummaries } from "../../../../../api";
import SyncSummaryList from "./SyncSummaryList";

const SyncDebugging = () => {
  const [loading, setLoading] = useState(true);
  const [syncSummaries, setSyncSummaries] = useState<any[]>([]);

  useEffect(() => {
    const fetchSyncSummaries = async () => {
      try {
        setLoading(true);
        const data = await getCustomerSyncSummaries({
          pageSize: 20,
        });
        setSyncSummaries(data.syncSummaries);
      } catch (e) {
        console.error("error: ", e);
      } finally {
        setLoading(false);
      }
    };
    fetchSyncSummaries();
  }, []);

  return (
    <SyncSummaryList data={syncSummaries} loading={loading}></SyncSummaryList>
  );
};

export default SyncDebugging;
