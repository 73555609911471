import { brandclub_colors } from "@brandclub/common-ui";
import { motion } from "framer-motion";
import _cloneDeep from "lodash/cloneDeep";
import { useEffect } from "react";
import { MINIMUM_ANSWERING_TIME } from ".";
import { convertS3UrlToCDN } from "../../../../../../utils/media";
import { ButtonLoadingEllipsis } from "../../../../../StoreComponents/StoreButton";
import { FlowSolidButtonGrey } from "../../components/FlowButtons";
import FlowContainer from "../../components/FlowContainer";

import SurveyTimingNextButton from "../components/SurveyTimingButton";
import { useSurvey } from "./SurveyProvider";

import SurveyPill from "../components/SurveyPill";
import { SurveyQuestionContainer } from "../components/SurveyQuestionWarpper";
import { updateArray, toggleArrayValue } from "../../../../../../utils/misc";

const PillQuestion = () => {
  const {
    currentStep,
    moveToNextStep,
    moveToPreviousStep,
    question,
    answers,
    setAnswers,
    timer,
    setTimer,
    submitting,
  } = useSurvey();
  const ans = answers[currentStep]?.selectedOptions ?? [];

  const saveAnswer = (updatedAns: string[]) => {
    setAnswers((state) => {
      return updateArray(state, currentStep, {
        type: "multipleChoice",
        selectedOptions: updatedAns,
      });
    });
  };

  const handleItemClick = (str: string) => {
    const updatedAns = question?.canSelectMultipleResponses
      ? toggleArrayValue(ans, str)
      : [str];
    saveAnswer(updatedAns);
  };

  const handleNextClick = () => {
    // save and move to next
    moveToNextStep();
  };
  const handleBackClick = () => {
    moveToPreviousStep();
  };

  useEffect(() => {
    let interval: any = null;

    interval = setInterval(() => {
      const timerClone = _cloneDeep(timer);
      if (timerClone[currentStep] < MINIMUM_ANSWERING_TIME) {
        timerClone[currentStep] = timerClone[currentStep] + 1;
        setTimer(timerClone);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, timer[currentStep]]);
  const answerTime = timer[currentStep];
  const disableNext =
    answerTime < MINIMUM_ANSWERING_TIME ? true : ans.length === 0;

  return (
    <FlowContainer
      style={{ background: brandclub_colors.white }}
      key={currentStep}
    >
      <SurveyQuestionContainer>
        <motion.div
          key={question?.description}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`question pill`}
        >
          <div className="title">{question?.description}</div>

          <div className={`pills ${question?.imageUrl ? "with_image" : ""}`}>
            {question?.imageUrl ? null : (
              <div
                className="subtitle"
                style={{ marginTop: 0, marginBottom: 15 }}
              >
                {question?.canSelectMultipleResponses
                  ? "Select all that apply"
                  : "Select one"}
              </div>
            )}

            {question?.imageUrl ? (
              <div>
                <img
                  className="question_image"
                  alt="question_image"
                  src={convertS3UrlToCDN(question.imageUrl)}
                />
              </div>
            ) : null}
            <>
              <div className="pill_options">
                {question?.responseOptions?.map((option) => {
                  const selected = ans.includes(option);
                  const disabled =
                    ans.length > 0 &&
                    !selected &&
                    !question.canSelectMultipleResponses;
                  return (
                    <SurveyPill
                      key={option}
                      text={option}
                      onClick={() => {
                        handleItemClick(option);
                      }}
                      disabled={disabled}
                      selected={selected}
                    />
                  );
                })}
              </div>
            </>
          </div>
        </motion.div>
        <div className="actions">
          <FlowSolidButtonGrey disabled={submitting} onClick={handleBackClick}>
            Back
          </FlowSolidButtonGrey>
          <SurveyTimingNextButton
            answerTime={answerTime}
            onClick={handleNextClick}
            disabled={disableNext}
            text={
              submitting ? (
                <>
                  Submitting
                  <ButtonLoadingEllipsis baseFontSize={16} />
                </>
              ) : (
                "Next"
              )
            }
          />
        </div>
      </SurveyQuestionContainer>
    </FlowContainer>
  );
};

export default PillQuestion;
