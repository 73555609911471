import FlowContainer from "../../../pages/AppPages/Pages/components/FlowContainer";
import { useCheckout } from "../../CheckoutProvider";
import EnterMFACode from "../../../pages/AppPages/Sync/MFA/EnterMFACode";
import { useEffect, useRef } from "react";

const CheckoutMFA = () => {
  const { MFAEnterRetailerSyncSession } = useCheckout();
  // used to poll the status of MFAEnterRetailerSyncSession after updating OTP
  const CheckMFAEnterSyncSessionTimeoutRef =
    useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const timeout = CheckMFAEnterSyncSessionTimeoutRef.current;
    return () => {
      if (timeout) {
        // cleanup timeout when component unmounts
        clearTimeout(timeout);
      }
    };
  }, []);

  const onCompleteCallback = async () => {
    return new Promise<void>((resolve) => {
      function checkMFAStatus() {
        if (!MFAEnterRetailerSyncSession) {
          resolve();
        } else {
          CheckMFAEnterSyncSessionTimeoutRef.current = setTimeout(
            checkMFAStatus,
            100
          ); // Poll every 100ms
        }
      }
      checkMFAStatus();
    });
  };
  if (!MFAEnterRetailerSyncSession) {
    return null;
  }
  return (
    <FlowContainer>
      <EnterMFACode
        retailerId={MFAEnterRetailerSyncSession.retailerId}
        retailerSyncSession={MFAEnterRetailerSyncSession}
        onCompleteCallback={onCompleteCallback}
      />
    </FlowContainer>
  );
};

export default CheckoutMFA;
