import ConfigUtils from "../../utils/ConfigUtils";
import { AppDispatch } from "../store";
import reduxApolloClient from "../reduxApolloClient";
import { Retailer } from "@brandclub/types";
import { setRetailers } from "../reducers/retailers";
import { getRetailersAuth } from "../../api/graphQl/authenticated";
import { getRetailers } from "../../api";

/* --------------------- retailers ---------------------*/

export const loadRetailersAuth = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await reduxApolloClient.query({
      query: getRetailersAuth,
      variables: { enableExperimentalRetailers: true },
      fetchPolicy: "no-cache",
      context: {
        uri: `${ConfigUtils.getGraphQlUri()}?id=getRetailers`,
      },
    });
    if (data?.Retailers?.length > 0) {
      const retailers = data.Retailers.map(
        (retailer: Retailer & { parentRetailerId: number }) => ({
          ...retailer,
          parentRetailerId: retailer?.parentRetailerId ?? retailer.retailerId,
        })
      );
      dispatch(setRetailers(retailers));
    }
  } catch (e) {
    console.error("loadRetailersAuth error: ", e);
  }
};

export const loadRetailersUnauth = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await reduxApolloClient.query({
      query: getRetailers,
      variables: { enableExperimentalRetailers: true },
      fetchPolicy: "cache-first",
      context: {
        uri: `${ConfigUtils.getGraphQlUri()}?id=getRetailers`,
      },
    });
    dispatch(setRetailers(data?.RetailersForLandingPage));
  } catch (e) {}
};
