export const SMALL_MOBILE_SCREEN_SIZE = 360;
export const MOBILE_SCREEN_SIZE = 600;
export const TABLET_SCREEN_SIZE = 1050;
export const SMALL_TABLET_SCREEN_SIZE = 768;
export const DESKTOP_SCREEN_SIZE = 1680;
export const DESKTOP_HORIZONTAL_PADDING = 140;

export const MOBILE_HORIZONTAL_PADDING = 20;
export const TABLET_HORIZONTAL_PADDING = 40;

export const WIDGET_SPACE = 50;
export const WIDGET_SPACE_HALF = WIDGET_SPACE / 2;

export const WIDGET_MEDIA_BORDER_RADIUS = 5; // image / video

export const DEFAULT_LINK_ID = "#";

export const DRAWER_Z_INDEX = 1200;
export const MOBILE_TOP_BAR_HEIGHT = 70;
export const DESKTOP_TOP_BAR_HEIGHT = 80;
export const NAV_BAR_HEIGHT = 135;

export type SingleNavType = {
  name: string;
  displayName: string;
  route?: string;
  subNav?: SingleNavType[];
  previewImageUrl?: string;
  metaDescription?: string;
};
