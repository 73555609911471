import { CloseIcon } from "@brandclub/common-ui";
import { RetailerSyncFlowTypes } from "@brandclub/types";
import Box from "@mui/material/Box";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import _get from "lodash/get";
import moment from "moment-timezone";
import pluralize from "pluralize";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  connectRetailerServerSide,
  getLatestSyncSession,
  getSyncStatusBySyncBatchId,
  getTOtp,
  syncRetailerServerSide,
} from "../api";
import { useAppSelector } from "../redux/hooks";
import {
  ParentRetailerSyncSession,
  RetailerConnections,
  RetailerSyncSession,
  SyncSession,
  SyncStatus,
} from "../types/misc";
import { encryptData } from "../utils/encryptionUtils";
import { MOBILE_SCREEN_SIZE } from "./AppNavigation/constants";
import ChooseMFAOption from "./pages/AppPages/Sync/MFA/ChooseMFAOption";
import EnterMFACode from "./pages/AppPages/Sync/MFA/EnterMFACode";
import { UserLoginContext } from "./pages/Auth/UserLoginProvider";
import { isOnboardingRoute } from "./routes/isOnboardingRoute";
import { EligibilityStatus } from "../redux/types";

const SYNC_SESSION_POLL_INTERVAL = 5e3; // 5 seconds

interface MFADialogProps {
  dialogProps: DialogProps;
  retailerId: number;
  retailerSyncSession: RetailerSyncSession;
  onCompleteCallback?: () => Promise<void>;
  handleDialogClose: () => void;
}

const StyledEnterMFACode = styled(EnterMFACode)(() => ({
  flex: 1,
  minHeight: "unset",
  "& .auth_container": {
    justifyContent: "center",
  },
}));
const StyledChooseMFAOption = styled(ChooseMFAOption)(() => ({
  flex: 1,
  minHeight: "unset",
  "& .auth_container": {
    justifyContent: "center",
  },
}));

export const SyncContext = React.createContext<SyncContextType>({
  loading: true,
  syncStatus: "normal",
  syncStatusByRetailer: [],
  syncStatusByParentRetailer: [],
  latestSyncBatchId: undefined,
  retailerConnections: {},
  retailerConnectionsFromSyncSession: undefined,
  mfaRetailerSyncSession: undefined,
  connectRetailer: (
    retailerId: number,
    userName: string,
    password: string,
    mode: RetailerSyncFlowTypes.FIX | RetailerSyncFlowTypes.CONNECT,
    redirectToSync?: boolean
  ) => {},
  syncAllRetailer: () => {},
  refreshSyncProvider: async () => {},
  sessionType: undefined,
});

export const useSyncContext = () => React.useContext(SyncContext);

// syncing = any retailer syncing in progress
export type GlobalSyncStatus = "syncing" | "normal";

const isRetailerSyncing = (retailerStatus: RetailerSyncSession) => {
  if (
    retailerStatus.syncStatus &&
    !["completed", "error", "timed_out", "cancelled"].includes(
      retailerStatus.syncStatus
    )
  ) {
    return true;
  }
  return false;
};

const getSyncStatus = (
  syncStatusRes: RetailerSyncSession[],
  sessionType?: SyncSession["sessionType"]
): GlobalSyncStatus => {
  let res: GlobalSyncStatus = "normal";
  if (sessionType === "liveShopping") {
    return res;
  }
  syncStatusRes.forEach((s) => {
    if (isRetailerSyncing(s)) {
      res = "syncing";
    }
  });

  return res;
};

export const getSyncStatusMessage = (
  syncStatus: RetailerSyncSession,
  syncingRetailersCount = 1
): {
  title: string;
  message: string;
  status: "connecting" | "syncing" | "checking_rewards" | "error" | "completed";
} => {
  const connecting = {
    status: "connecting",
    title: `Connecting ${pluralize("account", syncingRetailersCount)}`,
    message: `We’re connecting your ${pluralize(
      "account",
      syncingRetailersCount
    )}. This may take a moment.`,
  } as const;
  const syncing = {
    status: "syncing",
    title: `Syncing ${pluralize("account", syncingRetailersCount)}`,
    message:
      "We’re checking purchases that qualify for rewards. This could take a minute.",
  } as const;
  const checkingRewards = {
    status: "checking_rewards",
    title: "Checking for rewards",
    message:
      "We’re calculating ways to maximize your rewards. We’re almost done.",
  } as const;
  const error = {
    status: "error",
    title: "Let’s try again",
    message: "We weren’t able to sync any of your accounts.",
  } as const;
  // user should be redirected wherever it's appropriate, there is no success messaging. We show the previous status message to avoid flickering
  const completed = {
    status: "completed",
    title: checkingRewards.title,
    message: checkingRewards.message,
  } as const;
  switch (syncStatus.syncStatus) {
    case "queued":
    case "pending":
    case "connecting":
    case "userintervention":
      return connecting;
    case "connected":
    case "scanning":
    case "scanning_completed":
      return syncing;
    case "processing_rewards":
      return checkingRewards;
    case "completed":
      return completed;
    case "timed_out":
    case "error":
    case "skipped":
      return error;
  }
};

export const sortBySyncStatusPriority = (
  sessions: RetailerSyncSession[]
): RetailerSyncSession[] => {
  return [...sessions].sort(
    (a, b) =>
      getSyncStatusPriority(a.syncStatus) - getSyncStatusPriority(b.syncStatus)
  );
};

const getSyncStatusPriority = (syncStatus: SyncStatus): number => {
  switch (syncStatus) {
    case "queued":
    case "pending":
    case "connecting":
    case "userintervention":
      return 0; // prioritize connecting
    case "connected":
    case "scanning":
    case "scanning_completed":
      return 1; // next priority is syncing
    case "processing_rewards":
      return 2; // then checking rewards
    case "completed":
      return 3; // then completed
    case "timed_out":
    case "skipped":
    case "error":
      return 4; // next lowest is errors
    default:
      return 5; // lowest priority for anything else we haven't accounted for
  }
};

const ChooseMFAOptionDialog = (props: MFADialogProps) => {
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  return (
    <Dialog {...props.dialogProps} fullScreen={isMobileView}>
      <Box
        sx={{
          marginBottom: "20px",
          width: "600px",
          maxWidth: "100%",
          maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "center",
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            marginBottom: 0,
          },
        }}
      >
        <Box
          component="button"
          sx={{
            background: "none",
            border: "none",
            outline: "none",
            alignSelf: "flex-end",
            padding: 0,
            marginTop: "28px",
            marginRight: "28px",
            [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              marginTop: "48px",
              marginRight: "30px",
            },
          }}
          onClick={props.handleDialogClose}
        >
          <CloseIcon color="primary" sx={{ fontSize: 12.5 }} />
        </Box>
        <StyledChooseMFAOption
          retailerId={props.retailerId}
          retailerSyncSession={props.retailerSyncSession}
          onCompleteCallback={props.onCompleteCallback}
        />
      </Box>
    </Dialog>
  );
};

const EnterMFACodeDialog = (props: MFADialogProps) => {
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  return (
    <Dialog {...props.dialogProps} fullScreen={isMobileView}>
      <Box
        sx={{
          marginBottom: "20px",
          width: "600px",
          maxWidth: "100%",
          maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "center",
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            marginBottom: 0,
          },
        }}
      >
        <Box
          component="button"
          sx={{
            background: "none",
            border: "none",
            outline: "none",
            alignSelf: "flex-end",
            padding: 0,
            marginTop: "28px",
            marginRight: "28px",
            [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              marginTop: "48px",
              marginRight: "30px",
            },
          }}
          onClick={props.handleDialogClose}
        >
          <CloseIcon color="primary" sx={{ fontSize: 12.5 }} />
        </Box>
        <StyledEnterMFACode
          retailerId={props.retailerId}
          retailerSyncSession={props.retailerSyncSession}
          onCompleteCallback={props.onCompleteCallback}
        />
      </Box>
    </Dialog>
  );
};

export const SyncProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const navigate = useNavigate();
  const aborterRef = useRef(new AbortController());
  const { send } = useContext(UserLoginContext);

  const location = useLocation();
  const isOnboarding = isOnboardingRoute(location.pathname);

  const handleFullPageMFA = useCallback(() => {
    send({
      type: "CONNECT_RETAILER_REQUEST_OTP",
    });
  }, [send]);
  const ignoredSyncBatchId = useRef<string | undefined>();
  const isSyncPage = useLocation().pathname === "/sync";
  const [mfaRetailerSyncSession, setMFARetailerSyncSession] =
    useState<RetailerSyncSession | undefined>();
  const [isChooseMFAOptionDialogOpen, setIsChooseMFAOptionDialogOpen] =
    useState(false);
  const [isEnterMFACodeDialogOpen, setIsEnterMFACodeDialogOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [retailerConnections, setRetailerConnections] =
    useState<RetailerConnections>({});
  const [
    retailerConnectionsFromSyncSession,
    setRetailerConnectionsFromSyncSession,
  ] = useState<RetailerSyncSession[] | undefined>();
  const [latestSyncBatchId, setLatestSyncBatchId] =
    useState<string | undefined>();
  const [sessionType, setSessionType] =
    useState<SyncSession["sessionType"] | undefined>(undefined);

  const [syncStatus, setSyncStatus] = useState<GlobalSyncStatus>("normal");
  const [
    sessionRewardSummaryDisplayStatus,
    setSessionRewardSummaryDisplayStatus,
  ] = useState<"pending" | "viewed" | undefined>();

  const [syncStatusByRetailer, setSyncStatusByRetailer] = useState<
    RetailerSyncSession[]
  >([]);
  const [syncStatusByParentRetailer, setSyncStatusByParentRetailer] = useState<
    ParentRetailerSyncSession[]
  >([]);

  useEffect(() => {
    if (
      isOnboarding &&
      (isChooseMFAOptionDialogOpen || isEnterMFACodeDialogOpen)
    ) {
      handleFullPageMFA();
    }
  }, [
    handleFullPageMFA,
    isOnboarding,
    isChooseMFAOptionDialogOpen,
    isEnterMFACodeDialogOpen,
  ]);

  const slowestSyncSessionWithStatusMessage = useMemo(() => {
    // the sync session status that is lagging the most is of highest priority and should be shown first to reflect the aggregate status of the entire sync session
    const priorityOrderedSyncSessions =
      sortBySyncStatusPriority(syncStatusByRetailer);
    // sync session with highest priority is the slowest one in the sync session
    const slowestSyncSession = priorityOrderedSyncSessions[0] as
      | RetailerSyncSession
      | undefined;
    return (
      slowestSyncSession && {
        ...slowestSyncSession,
        displayMessage: getSyncStatusMessage(
          slowestSyncSession,
          priorityOrderedSyncSessions.length
        ),
      }
    );
  }, [syncStatusByRetailer]);

  const retailers = useAppSelector((state) => state.retailers);
  const userProfile = useAppSelector(({ userProfile }) => userProfile);

  const localStorageKey = userProfile?.userId
    ? `UID_${userProfile?.userId}_latestSyncSession`
    : "";

  const updateSyncBatchId = useCallback(
    (syncBatchId: string | undefined) => {
      setLatestSyncBatchId(syncBatchId);

      // only let visible browser tab update local storage,
      // incase localStorage won't update like crazy if user open many tabs,
      if (document.visibilityState === "visible" && localStorageKey) {
        localStorage.setItem(localStorageKey, syncBatchId || "");
      }
    },
    [localStorageKey]
  );

  const getLatestSyncInfo = async () => {
    try {
      const getLatestSyncSessionResponse = await getLatestSyncSession(
        aborterRef.current.signal
      );
      const retailerConnectionsFromApi = _get(
        getLatestSyncSessionResponse,
        ["data", "retailerConnections"],
        undefined
      );
      setRetailerConnectionsFromSyncSession(
        getLatestSyncSessionResponse?.data?.retailerSyncSessions
      );
      setRetailerConnections(retailerConnectionsFromApi);
      const latestSyncBatchIdFromApi: string | undefined = _get(
        getLatestSyncSessionResponse,
        ["data", "syncBatchId"],
        undefined
      );
      const latestSyncSessionTypeFromApi = _get(
        getLatestSyncSessionResponse,
        ["data", "sessionType"],
        undefined
      );
      const lastSyncCreatedAt = _get(
        getLatestSyncSessionResponse,
        ["data", "createdAt"],
        undefined
      );
      const rewardSummaryDisplayStatus = _get(
        getLatestSyncSessionResponse,
        ["data", "rewardSummaryDisplayStatus"],
        undefined
      );
      setSessionRewardSummaryDisplayStatus(rewardSummaryDisplayStatus);
      let updatedSyncStatus: GlobalSyncStatus = "normal";
      const syncStatusResByParentRetailerId: RetailerSyncSession[] = _get(
        getLatestSyncSessionResponse,
        ["data", "retailerSyncSessions"],
        []
      );
      const lastSyncCreatedLessThan24HoursAgo =
        moment().diff(moment(lastSyncCreatedAt), "hours") < 24;

      const syncStatusResByRetailerId: RetailerSyncSession[] = [];
      syncStatusResByParentRetailerId.forEach((syncStatusOfParent) => {
        const retailersWithSameParent = retailers?.filter((retailer) => {
          return retailer.parentRetailerId === syncStatusOfParent.retailerId;
        });
        if (retailersWithSameParent && retailersWithSameParent.length > 0) {
          retailersWithSameParent.forEach((retailer) => {
            syncStatusResByRetailerId.push({
              ...syncStatusOfParent,
              retailerId: retailer.retailerId,
            });
          });
        }
      });

      const newSyncStatusByParentRetailer = syncStatusResByParentRetailerId.map(
        (r) => {
          return {
            parentRetailerId: r.retailerId,
            ...r,
          };
        }
      );

      updatedSyncStatus = getSyncStatus(
        syncStatusResByRetailerId || [],
        latestSyncSessionTypeFromApi
      );

      setSyncStatusByRetailer(syncStatusResByRetailerId);
      setSyncStatusByParentRetailer(newSyncStatusByParentRetailer);
      updateSyncBatchId(latestSyncBatchIdFromApi);
      setSessionType(latestSyncSessionTypeFromApi);
      if (lastSyncCreatedLessThan24HoursAgo) {
        setSyncStatus(updatedSyncStatus);
      }
      return latestSyncBatchIdFromApi;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getSyncStatusByLastBatchId = async (syncBatchId: string) => {
    try {
      const getLatestSyncSessionResponse = await getSyncStatusBySyncBatchId(
        {
          syncBatchId,
        },
        aborterRef.current.signal
      );

      let updatedSyncStatus: GlobalSyncStatus = "normal";
      const syncStatusResByParentRetailerId: RetailerSyncSession[] = _get(
        getLatestSyncSessionResponse,
        ["data", "retailerSyncSessions"],
        []
      );

      const sessionType: SyncSession["sessionType"] = _get(
        getLatestSyncSessionResponse,
        ["data", "sessionType"],
        []
      );

      const syncStatusResByRetailerId: RetailerSyncSession[] = [];
      syncStatusResByParentRetailerId.forEach((syncStatusOfParent) => {
        const retailersWithSameParent = retailers?.filter((retailer) => {
          return retailer.parentRetailerId === syncStatusOfParent.retailerId;
        });
        if (retailersWithSameParent && retailersWithSameParent.length > 0) {
          retailersWithSameParent.forEach((retailer) => {
            syncStatusResByRetailerId.push({
              ...syncStatusOfParent,
              retailerId: retailer.retailerId,
            });
          });
        }
      });

      const newSyncStatusByParentRetailer = syncStatusResByParentRetailerId.map(
        (r) => {
          return {
            parentRetailerId: r.retailerId,
            ...r,
          };
        }
      );
      updatedSyncStatus = getSyncStatus(
        syncStatusResByRetailerId || [],
        sessionType
      );
      setSyncStatusByRetailer(syncStatusResByRetailerId);
      setSyncStatusByParentRetailer(newSyncStatusByParentRetailer);

      setSyncStatus(updatedSyncStatus);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSyncPage) {
      //reset ignoredSyncBatchId when user navigates to sync page
      ignoredSyncBatchId.current = undefined;
    }
  }, [isSyncPage]);

  useEffect(() => {
    // imidiately get latest sync info

    aborterRef.current = new AbortController();
    if (userProfile?.userId) {
      if (syncStatus === "syncing") {
        getLatestSyncInfo().then((syncBatchId) => {
          if (syncBatchId && syncBatchId !== ignoredSyncBatchId.current) {
            getSyncStatusByLastBatchId(syncBatchId);
          }
        });
      } else {
        getLatestSyncInfo();
      }
    }
    // poll for latest sync info ever SYNC_SESSION_POLL_INTERVAL

    const timer = setInterval(async () => {
      if (userProfile?.userId) {
        if (
          syncStatus === "syncing" &&
          latestSyncBatchId &&
          latestSyncBatchId !== ignoredSyncBatchId.current
        ) {
          await getSyncStatusByLastBatchId(latestSyncBatchId);
        } else {
          await getLatestSyncInfo();
        }
      }
    }, SYNC_SESSION_POLL_INTERVAL);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile?.userId, syncStatus, latestSyncBatchId, latestSyncBatchId]);

  const onStorageUpdate = (e: any) => {
    const value = _get(e, ["target", "localStorage", localStorageKey]);
    if (value && value !== "") {
      setLatestSyncBatchId(value);
    }
  };

  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorageKey]);

  const connectRetailer = async (
    retailerId: number,
    username: string,
    password: string,
    mode: RetailerSyncFlowTypes.FIX | RetailerSyncFlowTypes.CONNECT,
    redirectToSync = true
  ) => {
    try {
      // aborterRef.abort();
      // setAbortRef(new AbortController());
      setSyncStatusByRetailer([]);
      setSyncStatusByParentRetailer([]);
      const { token, currentStamp } = (await getTOtp()) ?? {};
      let connectionInfo = undefined;
      const retailerConnectionInfoList = [
        { retailerId, credential: { username, password } },
      ];
      if (token && currentStamp && retailerConnectionInfoList) {
        connectionInfo = encryptData(retailerConnectionInfoList, token);
      }

      const parentRetailerId =
        retailers?.find((r) => r.retailerId === retailerId)?.parentRetailerId ??
        retailerId;
      const { data } = await connectRetailerServerSide({
        req: {
          parentRetailerIds: [parentRetailerId],
          currentStamp,
          connectionInfo,
          retailerSyncFlow: mode,
        },
      });
      if (redirectToSync) {
        navigate("/sync");
      }
      setSyncStatus("syncing");
      updateSyncBatchId(data.syncBatchId);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const syncAll = useCallback(
    async (parentRetailerIds: number[]) => {
      // aborterRef.abort();
      // setAbortRef(new AbortController());
      setSyncStatusByRetailer([]);
      setSyncStatusByParentRetailer([]);
      try {
        setLoading(true);
        const res = await syncRetailerServerSide({
          req: { parentRetailerIds: parentRetailerIds },
        });
        setSyncStatus("syncing");
        updateSyncBatchId(res.syncBatchId);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [updateSyncBatchId]
  );

  useEffect(() => {
    if (
      !latestSyncBatchId ||
      latestSyncBatchId === ignoredSyncBatchId.current
    ) {
      setMFARetailerSyncSession(undefined);
      setIsChooseMFAOptionDialogOpen(false);
      setIsEnterMFACodeDialogOpen(false);
      return;
    }
    if (syncStatusByRetailer && syncStatusByRetailer.length > 0) {
      const retailerSyncSessionInMFAChoose = syncStatusByRetailer?.find(
        (r) =>
          r.syncStatus === "userintervention" &&
          r.eventSequence &&
          r.eventSequence.type === "mfa-choose" &&
          r.eventSequence.initiator === "server"
      );
      const retailerSyncSessionInMFAEnter = syncStatusByRetailer?.find(
        (r) =>
          r.syncStatus === "userintervention" &&
          r.eventSequence &&
          r.eventSequence.type === "mfa-enter" &&
          r.eventSequence.initiator === "server"
      );
      if (retailerSyncSessionInMFAChoose) {
        setMFARetailerSyncSession(retailerSyncSessionInMFAChoose);
        setIsChooseMFAOptionDialogOpen(true);
      } else if (retailerSyncSessionInMFAEnter) {
        setMFARetailerSyncSession(retailerSyncSessionInMFAEnter);
        setIsEnterMFACodeDialogOpen(true);
      } else {
        setMFARetailerSyncSession(undefined);
        setIsChooseMFAOptionDialogOpen(false);
        setIsEnterMFACodeDialogOpen(false);
      }
    }
  }, [
    syncStatusByRetailer,
    latestSyncBatchId,
    setIsEnterMFACodeDialogOpen,
    setIsChooseMFAOptionDialogOpen,
  ]);

  const syncAllRetailer = useCallback(() => {
    const connectedParentRetailerIds = Object.keys(retailerConnections)
      .filter((parentRetailerId) => {
        const currentRetailer = retailers.find(
          (r) => r.parentRetailerId === +parentRetailerId
        );
        const canSyncServerSide =
          currentRetailer?.syncConfig.serverSide === EligibilityStatus.ENABLED;
        return canSyncServerSide;
      })
      .filter((parentRetailerId) => {
        return (
          retailerConnections[parentRetailerId].connectionStatus === "valid"
        );
      })
      .map((parentRetailerId) => parseInt(parentRetailerId));

    // sync if there is connected retailers, otherwise take them to accounts page
    if (syncStatus === "syncing") {
      navigate("/sync");
    } else if (
      connectedParentRetailerIds &&
      connectedParentRetailerIds.length >= 1
    ) {
      syncAll(connectedParentRetailerIds);
      navigate("/sync");
    } else {
      navigate("/dashboard/accounts");
    }
  }, [syncStatus, retailerConnections, navigate, syncAll, retailers]);

  return (
    <SyncContext.Provider
      value={{
        loading,
        latestSyncBatchId,
        retailerConnections,
        retailerConnectionsFromSyncSession,
        sessionRewardSummaryDisplayStatus,
        syncStatus,
        mfaRetailerSyncSession,
        syncStatusByRetailer,
        syncStatusByParentRetailer,
        sessionType,
        connectRetailer,
        syncAllRetailer,
        slowestSyncSessionWithStatusMessage,
        refreshSyncProvider: async () => {
          if (userProfile?.userId) {
            await getLatestSyncInfo();
          }
        },
      }}
    >
      {!isOnboarding && mfaRetailerSyncSession && (
        <ChooseMFAOptionDialog
          handleDialogClose={() => {
            setIsChooseMFAOptionDialogOpen(false);
            setMFARetailerSyncSession(undefined);
            ignoredSyncBatchId.current = latestSyncBatchId;
            isSyncPage && navigate(-1);
          }}
          dialogProps={{
            open: isChooseMFAOptionDialogOpen,
          }}
          retailerId={mfaRetailerSyncSession.retailerId}
          retailerSyncSession={mfaRetailerSyncSession}
          onCompleteCallback={async () => {
            setIsChooseMFAOptionDialogOpen(false);
            setMFARetailerSyncSession(undefined);
          }}
        />
      )}
      {!isOnboarding && mfaRetailerSyncSession && (
        <EnterMFACodeDialog
          handleDialogClose={() => {
            setIsEnterMFACodeDialogOpen(false);
            setMFARetailerSyncSession(undefined);
            ignoredSyncBatchId.current = latestSyncBatchId;
            isSyncPage && navigate(-1);
          }}
          dialogProps={{
            open: isEnterMFACodeDialogOpen,
          }}
          retailerId={mfaRetailerSyncSession.retailerId}
          retailerSyncSession={mfaRetailerSyncSession}
          onCompleteCallback={async () => {
            setIsEnterMFACodeDialogOpen(false);
            setMFARetailerSyncSession(undefined);
          }}
        />
      )}
      {children}
    </SyncContext.Provider>
  );
};

export type SyncContextType = {
  loading: boolean;
  syncStatus: GlobalSyncStatus;
  syncStatusByRetailer: RetailerSyncSession[];
  syncStatusByParentRetailer: ParentRetailerSyncSession[];
  mfaRetailerSyncSession: RetailerSyncSession | undefined;
  retailerConnections: RetailerConnections;
  retailerConnectionsFromSyncSession: RetailerSyncSession[] | undefined;
  latestSyncBatchId?: string;
  sessionRewardSummaryDisplayStatus?: "pending" | "viewed";
  sessionType?: SyncSession["sessionType"];
  slowestSyncSessionWithStatusMessage?: RetailerSyncSession & {
    displayMessage: {
      title: string;
      message: string;
      status:
        | "connecting"
        | "syncing"
        | "checking_rewards"
        | "error"
        | "completed";
    };
  };
  connectRetailer: (
    retailerId: number,
    username: string,
    password: string,
    mode: RetailerSyncFlowTypes.FIX | RetailerSyncFlowTypes.CONNECT,
    redirectToSync?: boolean
  ) => void;
  syncAllRetailer: () => void;
  refreshSyncProvider: () => Promise<void>;
};
