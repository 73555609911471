import FlowContainer from "../../../pages/AppPages/Pages/components/FlowContainer";

import { useAppSelector } from "../../../../redux/hooks";
import { useCheckout } from "../../CheckoutProvider";
import { styled } from "@mui/material";

import ConnectRetailer from "../../../pages/AppPages/Sync/Connect";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import { getErrorMessage } from "../../../../utils/errors/errorUtils";

export interface FormState {
  username: string;
  password: string;
}

const ConnectContainer = styled("div")(({ theme }) => ({
  maxWidth: "580px",
  width: "100%",
  marginTop: "33px",
  marginBottom: "68px",
  ".auth_container": {
    ".reward_summary": {
      textAlign: "center",
      ...(theme.dtc && {
        margin: "unset",
        width: "100%",
        maxWidth: "unset !important",
        marginTop: "20px",
      }),
    },
  },
  ".close": {
    cursor: "pointer",
    position: "absolute",
    top: 20,
    right: 20,
    width: 20,
    height: 20,
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ".close_img": {
      width: 14,
      height: 14,
    },
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      top: 10,
      right: 10,
      width: 20,
      height: 20,
    },
  },
}));

const CheckoutConnect = () => {
  const batch = useAppSelector(({ checkout }) => checkout.batch);
  const {
    allCarts,
    retailerToConnect,
    setRetailerToConnect,
    initiateCartCheckout,
    failedAttemptError,
  } = useCheckout();

  const handleClose = () => {
    setRetailerToConnect(undefined);
  };
  const setLoginInfo = (values: FormState) => {
    initiateCartCheckout(
      retailerToConnect?.retailerId as number,
      allCarts.map((cart) => cart.retailerId as number),
      batch?.checkoutBatchId,
      {
        username: values.username,
        password: values.password,
      }
    );
    handleClose();
  };

  //   const end = () => {
  //     endCheckout();
  //     handleClose();
  //     navigate(-1);
  //   };

  return (
    <FlowContainer>
      <ConnectContainer>
        <ConnectRetailer
          retailerId={retailerToConnect?.retailerId}
          connectOverwrite={(username, password) => {
            setLoginInfo({ username, password });
          }}
          errorMsg={
            failedAttemptError?.type === "login"
              ? getErrorMessage({
                  error: failedAttemptError?.errorMessage,
                })
              : undefined
          }
        />
      </ConnectContainer>
    </FlowContainer>
  );
};

export default CheckoutConnect;
