import { forwardRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { motion, HTMLMotionProps, AnimatePresence } from "framer-motion";
import Loading from "../Loading";
import {
  AnalyticsTrackingEvent,
  ArrowRightIcon,
  hexadecimal,
  MOBILE_SCREEN_SIZE,
} from "@brandclub/common-ui";
import { useTrackActions } from "../../utils/hooks/useTracking";
import { useAppSelector } from "../../redux/hooks";

export interface TrackedMotionButtonProps extends HTMLMotionProps<"button"> {
  trackedEventName?: AnalyticsTrackingEvent;
  trackedAdditionalData?: Record<string, any>;
}

export const TrackedMotionButton = forwardRef<
  HTMLButtonElement,
  TrackedMotionButtonProps
>(({ trackedEventName, trackedAdditionalData, onClick, ...props }, ref) => {
  const [trackAction] = useTrackActions();
  const domainConfig = useAppSelector((state) => state.appConfig?.domainConfig);
  const mainEntity = useAppSelector((state) => state.mainEntity);
  const brandEntity = useAppSelector((state) => state.brandEntity);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (trackedEventName) {
      trackAction(trackedEventName, {
        brandName: mainEntity?.entity.brandName,
        brandId: mainEntity?.entity.brandId,
        brandclubId: brandEntity?.brandclub?.brandclubId,
        appId: domainConfig?.domainName,
        ...trackedAdditionalData,
      });
    }
    if (onClick) {
      onClick(event);
    }
  };

  return <motion.button ref={ref} onClick={handleClick} {...props} />;
});

TrackedMotionButton.displayName = "TrackedMotionButton";

export const ButtonLoadingEllipsis = ({
  baseFontSize = 12,
  blue,
}: {
  baseFontSize?: number;
  blue?: boolean;
}) => {
  const ellipsisContainerSize = baseFontSize * 1.4;
  const ellipsisSize = baseFontSize * 2.6;
  const yOffset = ellipsisSize / 10;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        width: ellipsisContainerSize,
        height: ellipsisContainerSize,
        minWidth: ellipsisContainerSize,
        minHeight: ellipsisContainerSize,
      }}
    >
      <div
        style={{
          position: "absolute",
          width: ellipsisSize,
          height: ellipsisSize,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transform: `translateY(${yOffset}px)`,
        }}
      >
        <Loading ellipsis={!blue} ellipsisBlue={blue} size={ellipsisSize} />
      </div>
    </div>
  );
};

export const SolidButton = styled(TrackedMotionButton)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "999px",
  transition: "transform 0.3s",
  "&:active": {
    "&:not([disabled])": {
      transform: "scale(0.95)",
    },
  },
  "not(:focus-visible)": {
    outline: "none",
  },
  border: `1.5px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  padding: "7px 17px",
  fontSize: 14,
  ":disabled": {
    cursor: "not-allowed",
    opacity: 1,
    borderColor: "#f6f7f8",
    backgroundColor: "#f6f7f8",
    color: "#dcdddc",
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 14,
    height: 44,
    maxWidth: 333,
  },
  fontWeight: 600,

  ".left_icon": {
    marginRight: 6,
  },
  ".button_img": {
    marginRight: 6,
    maxWidth: 18,
    maxHeight: 18,
  },
}));

export const OutlineButton = styled(TrackedMotionButton)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "999px",
  transition: "transform 0.3s",
  "&:active": {
    "&:not([disabled])": {
      transform: "scale(0.95)",
    },
  },
  "not(:focus-visible)": {
    outline: "none",
  },
  border: `1.5px solid ${theme.palette.primary.main}`,
  backgroundColor: "#fff",
  color: theme.palette.primary.main,
  padding: "7px 17px",
  fontSize: 14,

  ":disabled": {
    opacity: 1,
    borderColor: "#dcdddc",
    backgroundColor: "#fff",
    color: hexadecimal("#000000")(30),
    cursor: "not-allowed",
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: 14,
    height: 44,
    maxWidth: 333,
  },
  fontWeight: 600,

  ".button_img": {
    marginRight: 6,
    maxWidth: 18,
    maxHeight: 18,
  },
}));

export const NeutralButton = styled(SolidButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: "#f6f8fa",
  borderColor: "#f6f8fa",
}));

export const SolidButtonWithArrow = forwardRef<
  HTMLButtonElement,
  TrackedMotionButtonProps
>(({ ...props }, ref) => {
  const [hovered, setHovered] = useState(false);

  return (
    <SolidButton
      ref={ref}
      {...props}
      onMouseLeave={() => {
        setHovered(false);
      }}
      onMouseEnter={() => {
        setHovered(true);
      }}
    >
      <AnimatePresence mode="wait">
        <motion.div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          key={hovered ? "arrow" : "content"}
          initial={{ x: -30, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 30, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {hovered ? (
            <ArrowRightIcon
              style={{
                marginLeft: 0,
                width: 20,
                height: 20,
              }}
            />
          ) : (
            props.children
          )}
        </motion.div>
      </AnimatePresence>
    </SolidButton>
  );
});

export const OutlineButtonWithArrow = forwardRef<
  HTMLButtonElement,
  TrackedMotionButtonProps
>(({ ...props }, ref) => {
  const [hovered, setHovered] = useState(false);

  return (
    <OutlineButton
      ref={ref}
      {...props}
      onMouseLeave={() => {
        setHovered(false);
      }}
      onMouseEnter={() => {
        setHovered(true);
      }}
    >
      <AnimatePresence mode="wait">
        <motion.div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          key={hovered ? "arrow" : "content"}
          initial={{ x: -30, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 30, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {hovered ? (
            <ArrowRightIcon
              style={{
                marginLeft: 0,
                width: 20,
                height: 20,
              }}
            />
          ) : (
            props.children
          )}
        </motion.div>
      </AnimatePresence>
    </OutlineButton>
  );
});
