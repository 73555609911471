import { Box } from "@mui/material";
import { useAppSelector } from "../../../../redux/hooks";

import { ShippingGroupPreview } from "./ShippingGroupPreview";

import { useCheckout } from "../../CheckoutProvider";

import { ChevronDownIcon } from "@brandclub/common-ui";
import { ShoppingCartItem } from "../../types";
import { SummaryProduct } from "../SummaryProduct";

export const getErrorProducts = (items: ShoppingCartItem[]) => {
  let res: ShoppingCartItem[] = items;
  res = res.filter(
    (item) => item.status === "error" && item?.userApprovalStatus !== "deleted"
  );
  return res;
};

const DeliverySection = () => {
  const order = useAppSelector((state) => state.checkout.order);
  const {
    updateOrderShippingOption,
    deliveryOpen,
    handleSetSectionOpen,
    // failedAttemptError,
  } = useCheckout();

  const hasAnyOpen = deliveryOpen.some((e) => e);

  const toggleOpen = (open: boolean) => {
    handleSetSectionOpen({
      section: "delivery",
      state(prev) {
        return prev.map(() => open);
      },
    });
  };

  const items = Object.values(order?.userRetailerCheckoutOrder?.items ?? {});

  const errorProducts = getErrorProducts(items);

  const handleUpdateShippingOption = async (
    deliveryOption?: {
      name: string;
      cssSelector: string;
    },
    deliveryTimeWindow?: {
      name: string;
      cssSelector: string;
    }
  ) => {
    await updateOrderShippingOption(deliveryOption, deliveryTimeWindow);
  };

  const shippingOptions = order?.userRetailerCheckoutOrder?.shippingOptions;

  return (
    <Box className="section">
      <div
        className="section_head"
        style={{ cursor: "pointer" }}
        onClick={() => toggleOpen(!hasAnyOpen)}
      >
        <div className="title">Delivery</div>
        <ChevronDownIcon
          style={{
            transition: "transform 0.3s",
            width: "33px",
            height: "33px",
            transform: hasAnyOpen ? "rotate(180deg)" : "rotate(0deg)",
          }}
        ></ChevronDownIcon>
      </div>
      <div style={{}} className="shipping_group_list">
        {shippingOptions?.shippingGroups?.map((e, index) => {
          return (
            <ShippingGroupPreview
              shippingGroup={e}
              items={items}
              index={index}
              handleUpdateShippingOption={handleUpdateShippingOption}
            />
          );
        })}
        {errorProducts.length > 0 ? (
          <>
            {errorProducts.map((e) => {
              return <SummaryProduct product={e as any} />;
            })}
          </>
        ) : null}
      </div>
    </Box>
  );
};

export default DeliverySection;
