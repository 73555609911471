import "./App.css";
import AppRoutes from "./components/AppRoutes";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "./redux/hooks";
import {
  getAppConfig,
  getBrandAppConfigs,
} from "./redux/reducers/appConfig/thunk";

import { initializeAuth } from "./Auth";
import StoreFullScreenLoading from "./components/StoreComponents/StoreFullScreenLoading";
import { FingerprintJSProvider } from "@brandclub/common-ui";

const App = () => {
  const appConfig = useAppSelector(({ appConfig }) => appConfig);
  const [isAuthInitialized, setIsAuthInitialized] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (appConfig?.authConfig) {
        await initializeAuth(appConfig?.authConfig);
        setIsAuthInitialized(true);
      } else {
        dispatch(getAppConfig({}) as any);
      }
    })();
  }, [appConfig]);

  useEffect(() => {
    dispatch(
      getBrandAppConfigs({ appId: appConfig?.domainConfig?.domainName })
    );
  }, [dispatch, appConfig?.domainConfig?.domainName]);

  // we should let the app load until we have the app config and cognito initialized
  if (!appConfig?.authConfig) {
    return <div data-testid="store-loading-skeleton-fullsite-content" />;
  }

  if (!isAuthInitialized) {
    return <StoreFullScreenLoading />;
  }

  return (
    <FingerprintJSProvider
      hostDomainName={`https://${window.location.hostname}`}
    >
      <AppRoutes />
    </FingerprintJSProvider>
  );
};

export default App;
