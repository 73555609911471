import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useInView } from "react-intersection-observer";
import { Link, useNavigate } from "react-router-dom";
import { TextTheApp } from "./pages/AppPages/GetApp/GetApp";
import { connect } from "react-redux";
import TextTransition, { presets } from "react-text-transition";
import Slider from "react-slick";
import { joinWaitList } from "../api";
import BrandclubVideo from "./BrandclubVideo";
import "./home.scss";

export const BrandClubButton = ({
  text,
  width = 251,
  height = 55,
  fontSize = 22,
  onClick = () => {},
  buttonColor = "#16949b",
  fontColor = "#fff",
  shapeColor = "#71c184",
  border = "none",
}: {
  text: string;
  width?: number;
  onClick?: () => void;
  height?: number;
  fontSize?: number;
  buttonColor?: string;
  fontColor?: string;
  shapeColor?: string;
  border?: string;
}) => {
  return (
    <button
      className="brandclub_btn"
      style={{ width, height }}
      onClick={onClick}
    >
      <span style={{ backgroundColor: shapeColor }}></span>
      <span
        style={{
          backgroundColor: buttonColor,
          fontSize,
          color: fontColor,
          border: border,
        }}
      >
        {text}
      </span>
    </button>
  );
};

const TeachTile = ({
  header,
  desc,
  margintop,
  backgroundColor,
  className = "",
}: any) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  const renderDownloadImg = () => {
    return (
      <motion.img
        ref={ref}
        animate={controls}
        initial="hidden"
        transition={{ duration: 1, ease: "easeInOut" }}
        variants={{
          visible: { opacity: 1, rotate: 0, scale: 1 },
          hidden: { opacity: 0, rotate: 20, scale: 0.5 },
        }}
        alt="download"
        src="https://media.brandclub.com/brandclub/image_asset/teach_download.png"
        className="download_img"
      />
    );
  };
  const renderCashImg = () => {
    return (
      <motion.img
        ref={ref}
        animate={controls}
        initial="hidden"
        transition={{ duration: 1, ease: "easeInOut" }}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 100 },
        }}
        alt="cash"
        src="https://media.brandclub.com/brandclub/image_asset/teach_cash.png"
        className="cash_img"
      />
    );
  };
  const renderRetailerImage = () => {
    return (
      <>
        <motion.img
          ref={ref}
          animate={controls}
          initial="hidden"
          transition={{ duration: 1, ease: "easeInOut" }}
          variants={{
            visible: { opacity: 1, scale: 1, rotate: 0 },
            hidden: { opacity: 0, scale: 0.5, rotate: 80 },
          }}
          alt="cash"
          src="https://media.brandclub.com/brandclub/image_asset/teach_amazon.png"
          className="retailer_img1"
        />
        <motion.img
          ref={ref}
          animate={controls}
          initial="hidden"
          transition={{ duration: 1, ease: "easeInOut" }}
          variants={{
            visible: { opacity: 1, scale: 1, rotate: 0 },
            hidden: { opacity: 0, scale: 0.5, rotate: 80 },
          }}
          alt="cash"
          src="https://media.brandclub.com/brandclub/image_asset/teach_walmart.png"
          className="retailer_img2"
        />
        <motion.img
          ref={ref}
          animate={controls}
          initial="hidden"
          transition={{ duration: 1, ease: "easeInOut" }}
          variants={{
            visible: { opacity: 1, scale: 1, rotate: 0 },
            hidden: { opacity: 0, scale: 0.5, rotate: 80 },
          }}
          alt="cash"
          src="https://media.brandclub.com/brandclub/image_asset/teach_target.png"
          className="retailer_img3"
        />
      </>
    );
  };
  const renderSocialMediaImage = () => {
    return (
      <motion.img
        ref={ref}
        animate={controls}
        initial="hidden"
        transition={{ duration: 1, ease: "easeInOut" }}
        variants={{
          visible: { opacity: 1, scale: 1, rotate: 0 },
          hidden: { opacity: 0, scale: 0.5, rotate: 80 },
        }}
        alt="cash"
        src="https://media.brandclub.com/brandclub/image_asset/influencer_medias.png"
        className="retailer_img1"
      />
    );
  };
  const renderInfluencerPhone = () => {
    return (
      <motion.img
        ref={ref}
        animate={controls}
        initial="hidden"
        transition={{ duration: 1, ease: "easeInOut" }}
        variants={{
          visible: { opacity: 1, rotate: 0, scale: 1 },
          hidden: { opacity: 0, rotate: 20, scale: 0.5 },
        }}
        alt="download"
        src="https://media.brandclub.com/brandclub/image_asset/influencer_phoneapp.png"
        className="download_img"
      />
    );
  };
  return (
    <div
      className={`tile ${className}`}
      style={{
        marginTop: margintop,
        // y: disableScrollAnimation ? undefined : y,
      }}
    >
      <div className="img">
        <div className="img_bg" style={{ backgroundColor }} />
        <div className="tophalf"></div>
        {header === "Earn" && renderCashImg()}
        {header === "Get cash back" && renderCashImg()}
        <div className="bothalf"></div>
        {header === "Download Brandclub" && renderDownloadImg()}
        {header === "Select" && renderInfluencerPhone()}
        {header === "Shop and engage" && renderRetailerImage()}
        {header === "Share" && renderSocialMediaImage()}
      </div>
      <div className="header">{header}</div>
      <div className={`desc ${header}`}>{desc}</div>
    </div>
  );
};

const ShopCard = ({ title, imgUrl, desc, y, disableScrollAnimation }: any) => {
  return (
    <motion.div
      // style={disableScrollAnimation ? undefined : { y }}
      className="shop_card"
    >
      <div className="title_container">
        <div className="icon">
          <img src={imgUrl} alt={title} />
        </div>
        <div className="title">{title}</div>
      </div>
      <div className="desc">{desc}</div>
    </motion.div>
  );
};

const EarnCard = ({ title0, title1, title2, title3, color1, color2 }: any) => {
  return (
    <div className="earncard_container" style={{ backgroundColor: color1 }}>
      <div className="earncard" style={{}}>
        <div className="title" style={{ color: color2 }}>
          {title0}
        </div>
        <div className="textcontent">
          <div className="textcontent_left" style={{ color: color1 }}>
            {title1}
          </div>
          <div className="textcontent_right">{title2}</div>
        </div>
        <div className="subtitle">
          <div className="subtitle_left">cash back</div>
          <div className="subtitle_right">{title3}</div>
        </div>
      </div>
    </div>
  );
};

const EnrichCard = ({
  width,
  imgUrl,
  desc,
  marginTop,
  y,
  disableScrollAnimation,
}: any) => {
  return (
    <motion.div
      className="enrich_card"
      // style={{ marginTop, y: disableScrollAnimation ? undefined : y }}
    >
      <div className="tag">
        <img src={imgUrl} alt={imgUrl} />
      </div>
      <div style={{ width }} className="desc">
        {desc}
      </div>
    </motion.div>
  );
};
const mapStateToProps = ({ user }: any) => ({ user });
export const TopBar = connect(mapStateToProps)(
  ({ borderButton = false, user }: any) => {
    const [click, setClick] = useState(false);
    const navigate = useNavigate();

    const buttonText = user ? "Portal" : "Sign up";
    const buttonLink = user ? "/portal" : "/getApp";
    const handleSignUpClick = () => {
      navigate(buttonLink);
    };

    return (
      <div
        className="section topbar"
        style={{
          zIndex: click ? 9999 : 1,
        }}
      >
        <div
          className="content_container"
          style={{ position: click ? "fixed" : "absolute" }}
        >
          <div className={`burger_menu_detail ${click ? "open" : ""}`}>
            <a
              href="#0"
              onClick={(e) => {
                e.preventDefault();
                navigate("/partners");
              }}
              className="link"
            >
              Partners
            </a>
            <a
              href="#0"
              onClick={(e) => {
                e.preventDefault();
                navigate("/about");
              }}
              className="link"
            >
              About
            </a>
            <a
              href="#0"
              onClick={(e) => {
                e.preventDefault();
                navigate("/career");
              }}
              className="link"
            >
              Careers
            </a>
            <a href={buttonLink} className="link">
              {buttonText}
            </a>
          </div>
          <div className="content topbar">
            <div className="head">
              <div className="bar_content">
                <a href="/">
                  <img
                    className="logo"
                    // style={{ height: 38 }}
                    src="https://media.brandclub.com/brandclub/image_asset/brandclub_logo_dark.svg"
                    alt="brandclub logo"
                  />
                </a>
                <div className="bar_menu">
                  <a
                    href="#0"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/partners");
                    }}
                    className="link"
                  >
                    Partners
                  </a>
                  <a
                    href="#0"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/about");
                    }}
                    className="link"
                  >
                    About
                  </a>
                  <a
                    href="#0"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/career");
                    }}
                    className="link"
                  >
                    Careers
                  </a>
                  {borderButton ? (
                    <button
                      className="signup_button"
                      onClick={handleSignUpClick}
                    >
                      {buttonText}
                    </button>
                  ) : (
                    <BrandClubButton
                      text={buttonText}
                      width={153}
                      height={47}
                      fontSize={18}
                      onClick={handleSignUpClick}
                    />
                  )}
                </div>
                <div className="burger_menu">
                  <div
                    className={`menu-btn ${click ? "open" : ""}`}
                    role="button"
                    onClick={() => {
                      setClick(!click);
                    }}
                  >
                    <div className="menu-btn__burger"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

// const openMobileMenu = () => {
//   setClick(true);
// };
// const navigate = useNavigate();

export const HeadSection = ({ videos, title, subtitle }: any) => {
  const [mainVideoIndex, setMainVideoIndex] = useState(0);

  return (
    <div className="section head blue_bg">
      <div className="content_container ">
        <div className="content head">
          <div className="head_bot">
            <motion.div
              animate={{ opacity: [0, 1], y: [100, 0] }}
              // style={disableScrollAnimation ? undefined : { y: y2 }}
              transition={{ ease: "easeInOut", duration: 1 }}
              className="text"
            >
              <div className="header">{title || "Get rewarded to shop"}</div>
              <div className="disc">
                {subtitle ||
                  "Earn cash rewards when you shop and engage with your favorite brands."}
              </div>
              <TextTheApp />
              {/* <BrandClubButton
                text="Join Brandclub"
                onClick={() => {
                  navigate("/getApp");
                }}
              /> */}
            </motion.div>
            <div className="img">
              <img
                style={{ display: "none" }}
                className=""
                src="https://media.brandclub.com/brandclub/image_asset/brandclub_google_search.png"
                alt="brandclub"
              />
              <div className="header_videos">
                {videos.length > 1 && (
                  <div className="sub">
                    <div
                      className="video_container"
                      role="button"
                      onClick={() => {
                        setMainVideoIndex((mainVideoIndex + 2) % videos.length);
                      }}
                    >
                      <BrandclubVideo
                        canPlay={false}
                        posterUrl={
                          videos[(mainVideoIndex + 2) % videos.length].posterUrl
                        }
                        videoUrl={
                          videos[(mainVideoIndex + 2) % videos.length].video ||
                          "https://media.brandclub.com/brandclub/media/sample.mp4"
                        }
                      ></BrandclubVideo>
                    </div>
                    <div
                      className="video_container"
                      role="button"
                      onClick={() => {
                        setMainVideoIndex((mainVideoIndex + 1) % videos.length);
                      }}
                    >
                      <BrandclubVideo
                        canPlay={false}
                        posterUrl={
                          videos[(mainVideoIndex + 1) % videos.length].posterUrl
                        }
                        videoUrl={
                          videos[(mainVideoIndex + 1) % videos.length].video ||
                          "https://media.brandclub.com/brandclub/media/sample.mp4"
                        }
                      ></BrandclubVideo>
                    </div>
                  </div>
                )}
                <div className="main">
                  <div
                    className={`video_container ${
                      videos.length > 1 ? "" : "alone"
                    }`}
                  >
                    <BrandclubVideo
                      posterUrl={
                        videos[mainVideoIndex % videos.length].posterUrl
                      }
                      videoUrl={
                        videos[mainVideoIndex].video ||
                        "https://media.brandclub.com/brandclub/media/sample.mp4"
                      }
                    ></BrandclubVideo>
                  </div>
                </div>
              </div>

              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const HomeTeachSection = ({
  scrollYProgress,
  disableScrollAnimation,
  title1,
  title2,
  title3,
  desc1,
  desc2,
  desc3,
}: any) => {
  return (
    <div className="section teach ">
      <div className="content_container">
        <div className="content teach">
          <div className="teach_top">How it works</div>
          <div className="teach_bot">
            <img
              className="tech_star_top"
              alt="tech_star_top"
              src="https://media.brandclub.com/brandclub/image_asset/tech_star_top.svg"
            />
            <img
              className="tech_star_bot"
              alt="tech_star_bot"
              src="https://media.brandclub.com/brandclub/image_asset/tech_star_bot.svg"
            />
            <TeachTile
              // disableScrollAnimation={disableScrollAnimation}
              // y={tile1y}
              margintop={0}
              imgUrl="https://media.brandclub.com/brandclub/image_asset/teach_download.svg"
              backgroundColor="#FFC5C566"
              header={title1 ? title1 : "Download Brandclub"}
              desc={
                desc1
                  ? desc1
                  : "Get our iPhone or Android app to sign up for a free account."
              }
              // descWidth={280}
              className={"tile1"}
            />
            <TeachTile
              // disableScrollAnimation={disableScrollAnimation}
              // y={tile2y}
              margintop={0}
              backgroundColor="#E5E5E566"
              imgUrl="https://media.brandclub.com/brandclub/image_asset/teach_shop.svg"
              header={title2 ? title2 : `Shop and engage`}
              desc={
                desc2
                  ? desc2
                  : "Engage with your favorite brands while shopping online or in-store."
              }
              // descWidth={310}
              className={"tile2"}
            />
            <TeachTile
              // disableScrollAnimation={disableScrollAnimation}
              // y={tile3y}
              margintop={0}
              backgroundColor="#4E74D066"
              imgUrl="https://media.brandclub.com/brandclub/image_asset/teach_get.svg"
              header={title3 ? title3 : "Get cash back"}
              desc={
                desc3
                  ? desc3
                  : "Earn real cash. No confusing point systems or gift cards."
              }
              className={"tile3"}
              // descWidth={330}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Detail1Section = ({
  buttonText,
  buttonOnClick,
  className,
  imgUrl,
  scrollYProgress,
  disableScrollAnimation,
  title,
  desc,
}: any) => {
  return (
    <div className={`section detail ${className}`}>
      <div className="content_container">
        <div className={`content detail detailsec1 ${className}`}>
          <motion.div
            // style={
            //   disableScrollAnimation ? undefined : { x: leftx, y: lefty, opacity }
            // }
            className="detail_left"
          >
            <div className="header">
              {title || "Unify all rewards into a single app"}
            </div>
            <div className="desc">
              {desc ||
                "Connect the rewards programs from your favorite brands into a single, beautiful app. Get rewarded to shop anywhere, such as Amazon, Walmart, Instacart and your favorite brand websites."}
            </div>
            {buttonText && buttonOnClick && (
              <div className="brandclub_sol_btn" onClick={buttonOnClick}>
                {buttonText}
              </div>
            )}

            {/* <div className="bullet_point">
            <ul>
              <li>Online marketplaces, such as Amazon, Walmart.com and more</li>
              <li>Brick-and-mortar stores, such as Target and Best Buy</li>
              <li>Brand websites, such as Nike.com</li>
            </ul>
          </div> */}
          </motion.div>
          <motion.div className="detail_right">
            <img
              className="phone_img_bot"
              src={
                imgUrl ||
                "https://media.brandclub.com/brandclub/image_asset/detail_phone1_bg.png"
              }
              alt="detail_phone1_bot"
            />
            {!imgUrl && (
              <img
                className="phone_img_top"
                src={
                  "https://media.brandclub.com/brandclub/image_asset/detail_phone1.png"
                }
                alt="detail_phone1_top"
              />
            )}
            {/* </div> */}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export const Detail2Section = ({
  buttonText,
  buttonOnClick,
  imgUrl,
  className,
  scrollYProgress,
  disableScrollAnimation,
  title,
  desc,
}: any) => {
  return (
    <div className={`section detail2 ${className}`}>
      <div className="content_container">
        <div className={`content detail2 detailsec2 ${className}`}>
          <motion.div
            // style={
            //   disableScrollAnimation ? undefined : { x: leftx, y: lefty, opacity }
            // }
            className="detail2_left"
          >
            <img
              className="phone_img_bot"
              src={
                imgUrl ||
                "https://media.brandclub.com/brandclub/image_asset/detail_phone2_bg.png"
              }
              alt="detail_phone2_bot"
            />
            {!imgUrl ? (
              <img
                className="phone_img_top"
                src="https://media.brandclub.com/brandclub/image_asset/detail_phone2.png"
                alt="detail_phone2_top"
              />
            ) : null}
          </motion.div>
          <motion.div
            // style={
            //   disableScrollAnimation
            //     ? undefined
            //     : { x: rightx, y: righty, opacity }
            // }
            className="detail2_right"
          >
            <div>
              <div className="header">
                {title || "Unlimited daily cash back"}
              </div>
              <div className="desc">
                {desc ||
                  "Earn real cash. No points. No gimmicks. You earn real cash back every time you shop and engage with your favorite brands."}
              </div>
              {buttonText && buttonOnClick && (
                <div className="brandclub_sol_btn" onClick={buttonOnClick}>
                  {buttonText}
                </div>
              )}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export const Detail3Section = ({
  buttonOnClick,
  buttonText,
  className,
  imgUrl,
  scrollYProgress,
  disableScrollAnimation,
  title,
  desc,
}: any) => {
  return (
    <div className={`section detail ${className}`}>
      <div className="content_container">
        <div className={`content detail detailsec3 ${className}`}>
          <motion.div
            // style={
            //   disableScrollAnimation ? undefined : { x: leftx, y: lefty, opacity }
            // }
            className="detail_left"
          >
            <div className="header">
              {title || "Earn rewards to advocate for your favorite brands"}
            </div>
            <div className="desc">
              {desc ||
                "Generate commission when you share affiliate links and drive sales from your blog posts, social, email, and more."}
            </div>
            {buttonText && buttonOnClick && (
              <div className="brandclub_sol_btn" onClick={buttonOnClick}>
                {buttonText}
              </div>
            )}
            {/* <div className="bullet_point">
            <ul>
              <li>Online marketplaces, such as Amazon, Walmart.com and more</li>
              <li>Brick-and-mortar stores, such as Target and Best Buy</li>
              <li>Brand websites, such as Nike.com</li>
            </ul>
          </div> */}
          </motion.div>
          <motion.div className="detail_right">
            <img
              className="phone_img_bot"
              src={
                imgUrl ||
                "https://media.brandclub.com/brandclub/image_asset/detail_phone3_bg.png"
              }
              alt="detail_phone3_bot"
            />

            {!imgUrl && (
              <img
                className="phone_img_top"
                src={
                  "https://media.brandclub.com/brandclub/image_asset/detail_phone3.png"
                }
                alt="detail_phone3_top"
              />
            )}
            {/* </div> */}
          </motion.div>
        </div>
      </div>
    </div>
  );
};
const ShopSection = ({ scrollYProgress }: any) => {
  return (
    <div className="section shophome ">
      <div className="content_container">
        <div className="content shophome">
          <div className="shop_left">
            <img
              className="shop_img_bot"
              src="https://media.brandclub.com/brandclub/image_asset/shop_reward_bg.png"
              alt="shop_reward_bg"
            />
            <img
              className="shop_img_top"
              src="https://media.brandclub.com/brandclub/image_asset/shop_reward.png"
              alt="shop_reward"
            />
            <img
              className="shop_img_bot_mid"
              src="https://media.brandclub.com/brandclub/image_asset/shop_reward_bg_mid.png"
              alt="shop_reward_bg"
            />
          </div>
          <div className="shop_right">
            <div className="shop_top">
              <div className="header">
                The first rewards program that goes beyond shopping
              </div>
              <div className="desc">
                Earn cash back by engaging with brands any way you choose.
              </div>
            </div>
            <div className="shop_bot">
              <ShopCard
                y={0}
                title="Purchases"
                imgUrl="https://media.brandclub.com/brandclub/image_asset/tag1.svg"
                desc="Get cash back on everyday purchases."
              />
              <ShopCard
                y={0}
                title="Membership"
                imgUrl="https://media.brandclub.com/brandclub/image_asset/tag2.svg"
                desc="Get rewards and exclusive offers when you join."
              />
              <ShopCard
                y={0}
                title="Social Media"
                imgUrl="https://media.brandclub.com/brandclub/image_asset/tag3.svg"
                desc="Earn rewards when connecting with your favorite brands."
              />
              <ShopCard
                y={0}
                title="Surveys"
                imgUrl="https://media.brandclub.com/brandclub/image_asset/tag4.svg"
                desc="Get paid to take surveys from your favorite brands."
              />
              <ShopCard
                y={0}
                title="Content"
                imgUrl="https://media.brandclub.com/brandclub/image_asset/tag5.svg"
                desc="View the latest content from brands to earn rewards."
              />
              <ShopCard
                y={0}
                title="Reviews"
                imgUrl="https://media.brandclub.com/brandclub/image_asset/tag6.svg"
                desc="Share your perspective with global brands."
              />
              <ShopCard
                y={0}
                title="Affiliate"
                imgUrl="https://media.brandclub.com/brandclub/image_asset/tag7.svg"
                desc="Get paid a commission for every affiliate sale."
              />
              <ShopCard
                y={0}
                title="Advocacy"
                imgUrl="https://media.brandclub.com/brandclub/image_asset/tag8.svg"
                desc="Get paid for each club member you refer."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EarnSection = () => {
  return (
    <div className="section earn">
      <div className="content_container">
        <div className="content earn">
          <div
            // style={
            //   disableScrollAnimation ? undefined : { x: leftx, y: lefty, opacity }
            // }
            className="earn_left"
          >
            <div className="header">Earn smarter rewards</div>
            <div className="desc">
              Whether you’re shopping online or in-store, Brandclub allows you
              to earn cash back from your favorite brands.
            </div>
          </div>
          <div className="earn_right">
            <EarnCard
              color1="#71c184"
              color2="#185450"
              title0="Shopping"
              title1="$25.00"
              title2="$300.00"
              title3="retail purchases"
            />
            <EarnCard
              color1="#ffbd32"
              color2="#ed792f"
              title0="Engaging"
              title1="$16.00"
              title2="4 total"
              title3="surveys"
            />
            <EarnCard
              color1="#a21671"
              color2="#4f0842"
              title0="Sharing"
              title1="$24.00"
              title2="6 total"
              title3="referrals"
            />
            <div className="summary">
              <div className="summary_left">
                Estimated cash back for this month
              </div>
              <div className="summary_right">
                <img
                  className="flash_left"
                  src="https://media.brandclub.com/brandclub/image_asset/flash_left.svg"
                  alt="flash_left"
                />
                $65.00
                <img
                  className="flash_right"
                  src="https://media.brandclub.com/brandclub/image_asset/flash_right.svg"
                  alt="flash_right"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EnrichSection = ({ scrollYProgress, disableScrollAnimation }: any) => {
  return (
    <div className="section enrich">
      <div className="content_container">
        <div className="content enrich">
          <div className="enrich_top">
            <div className="header">Enrich the way you shop</div>
            <div className="desc">
              Brandclub is an all-in-one shopping assistant that automatically
              saves you money and helps you make the right decision.
            </div>
          </div>
          <div className="enrich_bot">
            <div className="enrich_left">
              <img
                className="enrich_phone_bot"
                src="https://media.brandclub.com/brandclub/image_asset/enrich_phone_bg.png"
                alt="enrich_phone_bg"
              />
              <img
                className="enrich_phone_top"
                src="https://media.brandclub.com/brandclub/image_asset/enrich_phone.png"
                alt="enrich_phone"
              />
            </div>
            <div className="enrich_right">
              <EnrichCard
                imgUrl="https://media.brandclub.com/brandclub/image_asset/tag9.svg"
                desc="Easily compare prices across retailers."
              />
              <EnrichCard
                imgUrl="https://media.brandclub.com/brandclub/image_asset/tag10.svg"
                desc="Get special access to deals and new product releases."
              />
              <EnrichCard
                imgUrl="https://media.brandclub.com/brandclub/image_asset/tag11.svg"
                desc="Receive exclusive offers from your favorite brands."
              />
              <EnrichCard
                imgUrl="https://media.brandclub.com/brandclub/image_asset/tag12.svg"
                desc="Chat directly with brand experts when you need assistance."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShareSection = ({
  buttonText,
  buttonOnClick,
  className,
  imgUrl,
  scrollYProgress,
  header,
  desc,
  hideDesc = false,
}: any) => {
  return (
    <div className={`section share ${className}`}>
      <div className="content_container">
        <div className={`content share ${className}`}>
          <div className="share_right">
            <div className="header">{header || "Make your voice heard"}</div>
            {hideDesc ? null : (
              <div className="desc">
                {desc ||
                  "Get paid to share your opinions, such as feedback on new products or packaging—make things you care about better."}
              </div>
            )}
            {buttonText && buttonOnClick && (
              <div className="brandclub_sol_btn" onClick={buttonOnClick}>
                {buttonText}
              </div>
            )}
          </div>
          <div className="share_left">
            <img
              className="share_sec_bot"
              src={
                imgUrl ||
                "https://media.brandclub.com/brandclub/image_asset/share_sec.png"
              }
              alt="share_sec_bg"
            />
            {!imgUrl && (
              <img
                className="share_sec_top"
                src="https://media.brandclub.com/brandclub/image_asset/share_sec_bg.png"
                alt="share_sec"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const LockSection = ({ scrollYProgress }: any) => {
  return (
    <div className="section lock ">
      <div className="content_container">
        <div className="content lock">
          <img
            src="https://media.brandclub.com/brandclub/image_asset/lock_sec.svg"
            alt="lock_sec"
          />

          <div className="header">Your finances, safe and secure</div>
          <div className="desc">
            Our top priority is keeping your data secure. With multiple safety
            measures, like secure 256-bit encryption and multi-factor
            authentication, we protect your info like it’s our own.
          </div>
        </div>
      </div>
    </div>
  );
};

const videos = [
  {
    video: "https://media.brandclub.com/brandclub/media/video_david.mp4",
    posterUrl:
      "https://media.brandclub.com/brandclub/media/cover/video_david.png",
    name: "David R.",
    city: "Minneapolis, MN",
    comment: `“I can now earn cash back for purchases at Amazon, Walmart and Target.”`,
  },
  {
    video: "https://media.brandclub.com/brandclub/media/video_jessica.mp4",
    posterUrl:
      "https://media.brandclub.com/brandclub/media/cover/video_jessica.png",
    name: "Jessica P.",
    city: "Dallas, TX",
    comment: `“I can earn cash back whenever I shop online or in my favorite stores.”`,
  },
  {
    video: "https://media.brandclub.com/brandclub/media/video_emma.mp4",
    posterUrl:
      "https://media.brandclub.com/brandclub/media/cover/video_emma.png",
    name: "Emma S.",
    city: "Phoenix, AZ",
    comment: `“Brandclub has an amazing affiliate program where I can earn up to 20% commission.”`,
  },

  {
    video: "https://media.brandclub.com/brandclub/media/video_angela.mp4",
    posterUrl:
      "https://media.brandclub.com/brandclub/media/cover/video_angela.png",
    name: "Angela W.",
    city: "Chicago, Il.",
    comment: `“Brandclub allows me to earn cash back for the items that are always in my shopping cart.”`,
  },
  {
    video: "https://media.brandclub.com/brandclub/media/video_marcus.mp4",
    posterUrl:
      "https://media.brandclub.com/brandclub/media/cover/video_marcus.png",
    name: "Marcus H.",
    city: "Boston, MA",
    comment: `“I can easily compare prices across Chewy, Amazon and other retailers.”`,
  },
];

export const VideosSection = ({
  useInputHeader = false,
  hideHeader = false,
}: {
  useInputHeader?: boolean;
  hideHeader?: boolean;
}) => {
  const [index, setIndex] = useState<number>(2);
  const [mobileIndex, setMobileIndex] = useState<number>(0);
  const sliderref: any = useRef(null);
  return (
    <div className="section videos grey_bg">
      <div className="content_container">
        <div className="content videos">
          <div className="videos_container">
            {hideHeader ? null : useInputHeader ? (
              <div className="header_container">
                <div className="videos_title">{"Enjoy exclusive perks"}</div>
                <div className="videos_subtitle">
                  Get members-only access to cash rewards, offers, and events.
                  The best of products—tailored just for you.
                </div>
                <TextTheApp hideHeader />
              </div>
            ) : (
              <div className="header_container">
                <div className="videos_title">
                  {"Why people love Brandclub"}
                </div>
                <br></br>
              </div>
            )}

            <div className="people">
              <button
                className={"videos_button maxleft"}
                onClick={() => {
                  sliderref?.current?.slickPrev();
                }}
              >
                <img
                  className="perks_icon"
                  src="https://media.brandclub.com/brandclub/image_asset/arrow_left_white.svg"
                  alt="arrow_l"
                />
              </button>
              <button
                className={"videos_button maxright"}
                onClick={() => {
                  sliderref?.current?.slickNext();
                }}
              >
                <img
                  className="perks_icon"
                  src="https://media.brandclub.com/brandclub/image_asset/arrow_right_white.svg"
                  alt="arrow_l"
                />
              </button>
              <Slider
                ref={sliderref}
                slidesToShow={3}
                slidesToScroll={1}
                initialSlide={2}
                arrows={false}
                swipe={false}
                infinite={true}
                beforeChange={(current, next) => setIndex(next)}
              >
                {[...videos].map((item, idx) => {
                  return (
                    <div key={idx}>
                      <div
                        role="button"
                        onClick={() => {
                          if (index === idx) {
                            sliderref?.current?.slickPrev();
                          }
                          if ((index + 2) % videos.length === idx) {
                            sliderref?.current?.slickNext();
                          }
                        }}
                        className={`card ${
                          idx === (index + 1) % videos.length ? "select" : ""
                        }`}
                      >
                        <BrandclubVideo
                          lowerPlaybutton
                          posterUrl={item.posterUrl}
                          canPlay={idx === (index + 1) % videos.length}
                          videoUrl={item.video}
                        />
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="people_mobile">
              <Slider
                // ref={sliderref}
                slidesToShow={1}
                slidesToScroll={1}
                initialSlide={0}
                className={"mobile_video_slider"}
                arrows={false}
                swipe={true}
                infinite={true}
                centerMode
                centerPadding="0px"
                beforeChange={(current, next) => setMobileIndex(next)}
              >
                {[...videos].map((item, idx) => {
                  return (
                    <div key={idx}>
                      <div
                        role="button"
                        style={{ padding: "0 8px" }}
                        className={`card ${
                          idx === mobileIndex % videos.length ? "select" : ""
                        }`}
                      >
                        <BrandclubVideo
                          lowerPlaybutton
                          canPlay={idx === mobileIndex % videos.length}
                          videoUrl={item.video}
                          posterUrl={item.posterUrl}
                        />
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="videos_desc">
              <div className="videos_text">
                <TextTransition
                  className="comment"
                  text={videos[(index + 1) % videos.length].comment}
                  springConfig={presets.wobbly}
                  noOverflow
                />
              </div>

              <div className="videos_text">
                <TextTransition
                  className="name"
                  text={videos[(index + 1) % videos.length].name}
                  springConfig={presets.wobbly}
                  noOverflow
                />
              </div>
              <div className="videos_text">
                <TextTransition
                  style={{ width: "100%" }}
                  inline
                  className="city"
                  text={videos[(index + 1) % videos.length].city}
                  springConfig={presets.wobbly}
                  noOverflow
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const GetAppSection = ({
  imgUrl,
  textTheAppCallBack,
}: {
  imgUrl?: string;
  textTheAppCallBack?: ({
    phoneNumber,
    email,
    name,
  }: {
    phoneNumber: string;
    email: string;
    name: string;
  }) => void;
}) => {
  return (
    <div className="section getapp blue_bg">
      <div className="content_container ">
        <div className="content getapp">
          <div className="signup">
            <div className="header">Get the Brandclub app</div>
            <TextTheApp textTheAppCallBack={textTheAppCallBack} />
          </div>
          <div className="img">
            <motion.img
              animate={{ y: [100, 0], x: [50, 0], opacity: [0, 1] }}
              transition={{ ease: "easeInOut", duration: 1 }}
              className="phone_img_bot"
              src={
                imgUrl ||
                "https://media.brandclub.com/brandclub/image_asset/getapp_phone_top.png"
              }
              alt="brandclub header phone"
            />
            {/* <motion.img
              animate={{ y: [100, 0], x: [-50, 0], opacity: [0, 1] }}
              transition={{ ease: "easeInOut", duration: 1 }}
              className="phone_img_top"
              src="https://media.brandclub.com/brandclub/image_asset/getapp_phone_top.png"
              alt="brandclub header phone"
            /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export const FooterSection = () => {
  const [mail, setMail] = useState("");
  const [added, setAdded] = useState(false);
  const navigate = useNavigate();
  const addToWaitList = async () => {
    if (mail.length > 0 && !added) {
      const res = await joinWaitList({ email: mail });
      if (res.status === 200) {
        navigate("/contact/res", { state: { status: "success" } });
      }
    }
  };
  return (
    <div className="section footer">
      <div className="content_container">
        <div className="content footer">
          <div className="links">
            <div className="links_sec">
              <div className="header">Consumers</div>
              <Link to="/faq">FAQs</Link>
              <Link to="/privacy">Privacy policy</Link>
              <Link to="/californiaprivacy">California policy</Link>
              <Link to="/terms">Terms of service</Link>
              <Link to="/endorsement">Endorsement</Link>
              <Link to="/rewardterms">Reward terms</Link>
            </div>
            <div className="links_sec">
              <div className="header">Businesses</div>
              <Link to="/partners">Brands</Link>
              <Link to="/partners">Retailers</Link>
              <Link to="/partners">Agencies</Link>
              <Link to="/influencer">Influencers</Link>
              <Link to="/partners">Affiliates</Link>
              {/* <a href="/portal">Login</a> */}
            </div>
            <div className="links_sec">
              <div className="header">Company</div>
              <Link to="/about">About</Link>
              <Link to="/career">Careers</Link>
              <Link to="/contact">Contact us</Link>
              <Link to="/contact/media">Media inquiries</Link>
              <Link to="/contact/demo">Request a demo</Link>
              {/* <a href="/">News</a> */}
            </div>
            <div className="links_sec">
              <div className="header">Stay in touch</div>
              <div className="desc">Receive news and announcements</div>
              <input
                value={mail}
                onChange={(e) => {
                  setAdded(false);
                  setMail(e.target.value);
                }}
                placeholder="Enter your email"
              ></input>
              <button onClick={addToWaitList}>
                {added ? "Submited" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BotBar = () => {
  return (
    <div className="section botbar">
      <div className="content_container">
        <div className="content botbar" style={{}}>
          <div className="logo">
            <a href="/">
              <img
                className="logo"
                style={{ height: 25 }}
                src="https://media.brandclub.com/brandclub/image_asset/brandclub_logo_dark.svg"
                alt="brandclub logo"
              />
            </a>
          </div>
          <div className="medias">
            <a
              href="https://www.youtube.com/channel/UCWBK_j_fCWnYC0HR7YoHD1Q"
              target="blank"
            >
              <img
                src="https://media.brandclub.com/brandclub/image_asset/footer_youtube.svg"
                alt="youtube"
              ></img>
            </a>
            <a href="https://www.instagram.com/brandclub/" target="blank">
              <img
                src="https://media.brandclub.com/brandclub/image_asset/footer_instergram.svg"
                alt="ins"
              ></img>
            </a>
            <a href="https://www.facebook.com/brandclub" target="blank">
              <img
                src="https://media.brandclub.com/brandclub/image_asset/footer_facebook.svg"
                alt="tb"
              ></img>
            </a>
            <a href="https://www.tiktok.com/@brandclubhq" target="blank">
              <img
                src="https://media.brandclub.com/brandclub/image_asset/footer_tiktok.svg"
                alt="tt"
              ></img>
            </a>
            <a href="https://twitter.com/brandclub" target="blank">
              <img
                src="https://media.brandclub.com/brandclub/image_asset/footer_twitter.svg"
                alt="tw"
              ></img>
            </a>
            <a href="https://www.pinterest.com/brandclub/" target="blank">
              <img
                src="https://media.brandclub.com/brandclub/image_asset/footer_pinterest.svg"
                alt="pt"
              ></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
const HomePage = () => {
  const navigate = useNavigate();
  const ref = useRef() as any;
  const disable = false;
  if (disable) {
    return <></>;
  }
  const headerVideos = [
    {
      video: "https://media.brandclub.com/brandclub/media/home_head.mp4",
      posterUrl:
        "https://media.brandclub.com/brandclub/media/cover/home_head.png",
    },
    {
      video: "https://media.brandclub.com/brandclub/media/video_david.mp4",
      posterUrl:
        "https://media.brandclub.com/brandclub/media/cover/video_david.png",
    },
    {
      video: "https://media.brandclub.com/brandclub/media/video_jessica.mp4",
      posterUrl:
        "https://media.brandclub.com/brandclub/media/cover/video_jessica.png",
    },
  ];

  return (
    <div className="site" ref={ref}>
      <Helmet>
        <title>Brandclub - Get rewarded to shop</title>
        <meta
          name="description"
          content="Brandclub earns you cash rewards when you engage with your favorite brands while shopping online or in-store."
        />
        <meta property="og:url" content={`https://brandclub.com/`} />
      </Helmet>
      <TopBar />
      <HeadSection videos={headerVideos} />
      <HomeTeachSection />
      {/* <ReviewSection /> */}
      <Detail1Section
        buttonOnClick={() => {
          navigate("/getApp");
        }}
        buttonText={"Get the app"}
      />
      <Detail2Section
        buttonOnClick={() => {
          navigate("/getApp");
        }}
        buttonText={"Join now"}
      />
      <Detail3Section
        buttonOnClick={() => {
          navigate("/influencer");
        }}
        buttonText={"Learn more"}
      />
      <VideosSection />
      <ShopSection />
      <EarnSection />
      <EnrichSection />
      <ShareSection
        buttonOnClick={() => {
          navigate("/getApp");
        }}
        buttonText={"Join now"}
      />
      <LockSection />
      <GetAppSection />
      <FooterSection />
      <BotBar />
    </div>
  );
};

export default HomePage;
