const onboardingRoutes = [
  "signin",
  "verifyResetPassword",
  "resetPassword",
  "signUp",
  "profileSetup",
  "verifyOAuthEmail",
  "setupPhoneNumber",
  "verifyPhoneNumber",
  "joinBrandclubs",
  "connectRetailers",
  "authenticateWithRetailer",
  "connectRetailerInProgress",
  "connectRetailerOTP",
];

/**
 * Determines if the given pathname corresponds to an onboarding route.
 *
 * @param pathname - The pathname to check.
 * @returns `true` if the pathname corresponds to an onboarding route, `false` otherwise.
 */
export const isOnboardingRoute = (pathname: string): boolean =>
  isValidPathname(onboardingRoutes, pathname);

/**
 * Checks if a given pathname is valid based on a list of valid paths.
 *
 * @param validPaths - An array of valid paths.
 * @param pathname - The pathname to be checked.
 * @returns A boolean indicating whether the pathname is valid or not.
 */
const isValidPathname = (validPaths: string[], pathname: string): boolean => {
  const cleanedPath = pathname.replace(/^\//, "").toLowerCase();
  const pathSegments = cleanedPath.split("/");
  return validPaths.some((path) => path.toLowerCase() === pathSegments[0]);
};
