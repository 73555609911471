import { UserProfile } from "@brandclub/types";
import { Address } from "../../../redux/types";
import axios from "../../../utils/axios";

import Stripe from "stripe";
import { getAuthorizationHeader } from "../../../Auth";

interface UpdateProfileRequest {
  given_name?: string;
  family_name?: string;
  phoneNumber?: string;
  name?: string;
  defaultAddressId?: string;
  email?: string;
}

interface VerifyPhoneNumberRequest {
  phoneNumberVerificationCode: string;
}

export const getProfile = async (
  {
    refreshCache,
    cleanupUserEndpoints,
  }: {
    refreshCache?: boolean;
    cleanupUserEndpoints?: boolean;
  },
  resultCallback: (data: { data?: UserProfile & { status: string } }) => void
) => {
  if (refreshCache === undefined) {
    refreshCache = true;
  }
  const { data } = await axios.get(
    `/profile/me?refreshCache=${refreshCache ? "true" : "false"}`,
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );

  await resultCallback({ data: data });
};

export const updateProfile = async (req: UpdateProfileRequest) => {
  return axios.post("/profile/updateProfile", req, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
};

export const updateDefaultAddress = async ({
  shippingAddress,
}: {
  shippingAddress?: Address;
}) => {
  const res = await axios.post(
    "/profile/updateDefaultAddress",
    {
      shippingAddress,
    },
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );
  return res;
};

export const addPaymentMethod = async ({
  card,
  billing_details,
}: {
  card: Stripe.PaymentMethodCreateParams.Card1;
  billing_details: Stripe.PaymentMethodCreateParams.BillingDetails;
}) => {
  const res = await axios.post(
    "/profile/addPaymentMethod",
    {
      card,
      billing_details,
    },
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );
  return res;
};

export const updatePaymentMethod = async ({
  paymentMethodId,
  card,
  billing_details,
}: {
  paymentMethodId: string;
  card: Stripe.PaymentMethodUpdateParams.Card;
  billing_details: Stripe.PaymentMethodUpdateParams.BillingDetails;
}) => {
  const res = await axios.post(
    "/profile/updatePaymentMethod",
    {
      paymentMethodId,
      card,
      billing_details,
    },
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );
  return res;
};

export const getPaymentMethods = async () => {
  const res = await axios.post(
    "/profile/getPaymentMethods",
    {},
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );
  return res;
};

export const removePaymentMethod = async ({
  paymentMethodId,
}: {
  paymentMethodId: string;
}) => {
  const res = await axios.post(
    "/profile/removePaymentMethod",
    {
      paymentMethodId,
    },
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );
  return res;
};

export const verifyPhoneNumber = async (req: VerifyPhoneNumberRequest) => {
  return axios.post("/profile/verifyPhoneNumber", req, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
};

export const resendPhoneNumberVerificationCode = async () => {
  return axios.post(
    "/profile/resendPhoneNumberVerificationCode",
    {},
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );
};

export const addEditAddress = async (req: Address & { id?: string }) => {
  return axios.post("/profile/address", req, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
};

export const deleteAddress = async (addressId: string) => {
  return axios.delete(`/profile/address/${addressId}`, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
};

export const uploadUserProfilePhoto = async ({ file }: { file: File }) => {
  const response = await (
    await axios.post(
      "/profile/generateProfilePhotoUploadUrl",
      {},
      {
        headers: {
          ...(await getAuthorizationHeader()),
        },
      }
    )
  )?.data;
  if (response && response.status === "success") {
    await fetch(response.signedUploadUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": "image/jpeg", // must match the content type in server
      },
    });
    return {
      /**
       * path to which the object was uploaded.
       */
      path: response.path,
    };
  } else {
    throw new Error("Failed to generate upload url");
  }
};

export const updateProfilePhotoUrl = async ({ path }: { path: string }) => {
  const res = await axios.post(
    "/profile/updateProfilePhotoUrl",
    {
      path,
    },
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );
  return res;
};


export const initiateIdpLinking = async (payload: {
  idpProvider: string;
  redirectUrl: string;
  customState?: Record<string, string>;
}) => {
  const res = await axios.post(
    "/auth/idpLinkInitiate",
    payload,
    {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    }
  );
  return res;
};