import { brandclub_colors } from "@brandclub/common-ui";
import { motion } from "framer-motion";
import _cloneDeep from "lodash/cloneDeep";
import { useEffect } from "react";
import { MINIMUM_ANSWERING_TIME } from ".";
import { ButtonLoadingEllipsis } from "../../../../../StoreComponents/StoreButton";
import { FlowSolidButtonGrey } from "../../components/FlowButtons";
import FlowContainer from "../../components/FlowContainer";

import SurveyTimingNextButton from "../components/SurveyTimingButton";
import { useSurvey } from "./SurveyProvider";

import SurveyImageSelect from "../components/SurveyImageSelect";
import { SurveyQuestionContainer } from "../components/SurveyQuestionWarpper";
import { updateArray, toggleArrayValue } from "../../../../../../utils/misc";

const ImageQuestion = () => {
  const {
    currentStep,
    moveToNextStep,
    moveToPreviousStep,
    question,
    answers,
    setAnswers,
    timer,
    setTimer,
    submitting,
  } = useSurvey();
  const ans = answers[currentStep]?.selectedOptions ?? [];
  const saveAnswer = (updatedAns: string[]) => {
    setAnswers((state) => {
      return updateArray(state, currentStep, {
        type: "multipleChoice",
        selectedOptions: updatedAns,
      });
    });
  };
  const handleItemClick = (str: string) => {
    const updatedAns = question?.canSelectMultipleResponses
      ? toggleArrayValue(ans, str)
      : [str];
    saveAnswer(updatedAns);
  };

  const handleNextClick = () => {
    // save and move to next
    moveToNextStep();
  };
  const handleBackClick = () => {
    moveToPreviousStep();
  };

  useEffect(() => {
    let interval: any = null;

    interval = setInterval(() => {
      const timerClone = _cloneDeep(timer);
      if (timerClone[currentStep] < MINIMUM_ANSWERING_TIME) {
        timerClone[currentStep] = timerClone[currentStep] + 1;
        setTimer(timerClone);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, timer[currentStep]]);
  const answerTime = timer[currentStep];
  const disableNext =
    answerTime < MINIMUM_ANSWERING_TIME ? true : ans.length === 0;

  const getCombinedObject = question?.responseOptions?.map((val, index) => ({
    responseOptions: val,
    responseOptionsMetaData:
      question?.responseOptionsMetaData &&
      question?.responseOptionsMetaData.optionsMetaData[index],
  }));

  const wideContainer =
    getCombinedObject?.length === 2 &&
    question?.responseOptionsMetaData?.layoutMode === "square";

  return (
    <FlowContainer
      style={{ background: brandclub_colors.white }}
      key={currentStep}
    >
      <SurveyQuestionContainer>
        <motion.div
          key={question?.description}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`question pill`}
        >
          <div className="title">{question?.description}</div>

          <div
            className={`image_question ${wideContainer ? "wideContainer" : ""}`}
          >
            <>
              <div
                className={`image_options ${question?.responseOptionsMetaData?.layoutMode}`}
              >
                {getCombinedObject?.map((option) => {
                  const selected = ans.includes(option.responseOptions);

                  return (
                    <SurveyImageSelect
                      mode={
                        question?.responseOptionsMetaData?.layoutMode ??
                        "square"
                      }
                      key={option.responseOptions}
                      imageUrl={
                        option?.responseOptionsMetaData?.sourceUrl ?? ""
                      }
                      onClick={() => {
                        handleItemClick(option.responseOptions);
                      }}
                      disabled={false}
                      selected={selected}
                    />
                  );
                })}
              </div>
            </>
          </div>
        </motion.div>
        <div className="actions">
          <FlowSolidButtonGrey disabled={submitting} onClick={handleBackClick}>
            Back
          </FlowSolidButtonGrey>
          <SurveyTimingNextButton
            answerTime={answerTime}
            onClick={handleNextClick}
            disabled={disableNext}
            text={
              submitting ? (
                <>
                  Submitting
                  <ButtonLoadingEllipsis baseFontSize={16} />
                </>
              ) : (
                "Next"
              )
            }
          />
        </div>
      </SurveyQuestionContainer>
    </FlowContainer>
  );
};

export default ImageQuestion;
