import {
  CloseIcon,
  brandclubStyles,
  getEntityImage,
} from "@brandclub/common-ui";
import { Box, ClickAwayListener, Typography, styled } from "@mui/material";
import { getRetailerFromId } from "@stackline/ui";
import { FC, useEffect } from "react";
import { Link } from "react-router-dom";

import { OutlineButton, SolidButton } from "../StoreComponents/StoreButton";
import { CartProduct } from "./components/CartProduct";

import { useCheckoutDrawer } from "./useCheckoutDrawer";

import Loading from "../Loading";

import pluralize from "pluralize";
import { useAppSelector } from "../../redux/hooks";
import { sumItemQuantities } from "../../utils/misc";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../AppNavigation/constants";
import { EmptyBagMessageCard } from "./EmptyBagMessageCard";
import { UserRetailerShoppingCart } from "./types";
import { calculateTotal, calculateTotalOfAllCarts } from "./utils";
import { useCheckout } from "./CheckoutProvider";

const CheckoutContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflow: "hidden",
  width: 462,
  maxWidth: "100%",
  color: theme.palette.primary.main,
  ".header_section": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "23px 28px 10px 28px",

    ".header": {
      fontSize: 20,
      fontWeight: 700,
    },
    ".sub_header": {
      fontSize: 18,
      fontWeight: 600,
    },
  },

  ".product_section": {
    flex: 1,
    overflowY: "scroll",
    ...brandclubStyles.customScrollbar,
    padding: "23px 27px",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      padding: "23px 5%",
    },
    ".cart_group": {
      marginBottom: 50,
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        marginBottom: 30,
      },
    },
    ".group_info": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 8,
      marginBottom: 30,
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        marginTop: 10,
        marginBottom: 24,
      },
      ".group_count": {
        fontSize: 14,
        fontWeight: 500,
      },
      ".group_subtotal": {
        fontSize: 14,
        fontWeight: 500,
        ".price": {
          fontWeight: 600,
        },
      },
    },
  },

  ".checkout_section": {
    display: "flex",
    flexDirection: "column",
    gap: "23px",
    padding: "23px 27px 37px 27px",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      padding: "23px 5% 37px 5%",
    },
    boxShadow: "0 0 6px 0 rgba(119, 115, 115, 0.16)",
    ".subtotal": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      fontSize: 18,
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
  },
}));

const CheckoutButton = styled(SolidButton)({
  fontSize: 16,
  height: 48,
  fontWeight: 600,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    fontSize: 14,
    height: 44,
  },
});
const ViewBagButton = styled(OutlineButton)({
  fontSize: 16,
  height: 48,
  fontWeight: 600,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    fontSize: 14,
    height: 44,
  },
});

const StyledEmptyBagMessageCard = styled(EmptyBagMessageCard)({
  width: "auto",
  margin: "0 25px",
  marginTop: 50,
  ".store-message-card__content": {
    fontSize: 16,
    width: 282,
  },
  ".store-message-card__title": {
    fontSize: 20,
  },
  ".store-message-card__cta": {
    marginTop: 10,
  },
});

interface ProductSectionProps {
  loading: boolean;
  allCarts: Partial<UserRetailerShoppingCart>[];
}
const ProductSection: FC<ProductSectionProps> = ({ loading, allCarts }) => {
  if (loading) {
    return (
      <Box className="product_section">
        <Loading dot></Loading>
      </Box>
    );
  }
  return (
    <Box className="product_section">
      {/* Render all carts (those with > 0 items - filtered by redux already) */}
      {allCarts.map((item) => (
        <Box
          key={`cartGroup:${item.shoppingCartId}`}
          display="flex"
          flexDirection="column"
          className="cart_group"
          gap={0}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={"12px"}
          >
            <img
              src={getEntityImage(item.retailerId as number, "retailer")}
              alt={`Logo for retailer: ${item.retailerId}`}
              style={{ height: 24, width: 24 }}
            />
            <Typography
              sx={(theme) => ({
                fontWeight: 600,
                fontSize: 20,
                color: theme.palette.primary.main,
              })}
            >
              {getRetailerFromId(item.retailerId as number)}
            </Typography>
          </Box>
          <Box className="group_info">
            <Box className="group_count">
              {pluralize("item", sumItemQuantities(item?.items), true)}
            </Box>
            <Box className="group_subtotal">
              Subtotal:{" "}
              <span className="price">{calculateTotal(item?.items ?? [])}</span>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="30px">
            {(item?.items ?? []).map((product) => (
              <CartProduct
                product={product}
                key={`cart:${item?.shoppingCartId}:item:${product.stacklineSku}`}
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

interface CheckoutSectionProps {
  initiating: boolean;
  allCarts: Partial<UserRetailerShoppingCart>[];
  checkoutAllCarts: () => void;
  closeDrawer: () => void;
}
const CheckoutSection: FC<CheckoutSectionProps> = ({
  initiating,
  checkoutAllCarts,
  closeDrawer,
  allCarts,
}) => {
  return (
    <Box className="checkout_section">
      <Box className="subtotal">
        <div>Subtotal</div>
        <div>{calculateTotalOfAllCarts(allCarts)}</div>
      </Box>
      <Box
        display="flex"
        gap="12px"
        width="100%"
        flexDirection="column"
        alignItems="center"
      >
        <CheckoutButton
          trackedAdditionalData={{ cartCount: allCarts.length }}
          style={{ width: "100%" }}
          disabled={initiating}
          onClick={checkoutAllCarts}
        >
          {initiating ? "Creating cart..." : "Checkout"}
        </CheckoutButton>
        <Link
          to="/mybag"
          style={{
            textDecoration: "none",
            width: "100%",
            display: "inline-flex",
            justifyContent: "center",
          }}
        >
          <ViewBagButton style={{ width: "100%" }} onClick={closeDrawer}>
            View bag
          </ViewBagButton>
        </Link>
      </Box>
    </Box>
  );
};

const CheckoutDrawer = () => {
  // Hooks
  const {
    allCarts,
    initiateCartCheckout,
    loading,
    initiatingCheckout,
    getAllCarts,
    isBagFullDialogOpen,
  } = useCheckout();
  const { closeDrawer } = useCheckoutDrawer();

  // Navigation
  const hasOptimisticItems = useAppSelector(
    (state) => !!state.checkout.optimisticAllCarts
  );

  useEffect(() => {
    !hasOptimisticItems && getAllCarts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (!isBagFullDialogOpen?.current) {
          closeDrawer();
        }
      }}
    >
      <CheckoutContainer>
        <Box className="header_section">
          <Box className="header">Bag</Box>
          <CloseIcon
            className="options"
            onClick={closeDrawer}
            sx={{
              fontSize: 15,
              cursor: "pointer",
            }}
          />
        </Box>
        {allCarts.length === 0 && !loading ? (
          <StyledEmptyBagMessageCard onActionClick={closeDrawer} />
        ) : (
          <>
            <ProductSection loading={loading} allCarts={allCarts} />
            {!loading && (
              <CheckoutSection
                initiating={initiatingCheckout}
                allCarts={allCarts}
                checkoutAllCarts={() => {
                  initiateCartCheckout(
                    allCarts[0].retailerId,
                    allCarts.map((cart) => cart.retailerId) as any
                  );
                }}
                closeDrawer={closeDrawer}
              />
            )}
          </>
        )}
      </CheckoutContainer>
    </ClickAwayListener>
  );
};

export default CheckoutDrawer;
