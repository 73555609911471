import { ChevronDownIcon } from "@brandclub/common-ui";
import { Box } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import _startCase from "lodash/startCase";
import { useAppSelector } from "../../../../redux/hooks";
import { Address } from "../../../../redux/types";
import CardBrandIcon from "../../../pages/AppPages/Dashboard/Wallet/CardBrandIcon";
import { StoreAnimatedHeightDiv } from "../../../StoreComponents/StoreAnimatedHeightDiv";
import { useCheckout } from "../../CheckoutProvider";
import { ShippingAddress, UserRetailerCheckoutOrder } from "../../types";
import CheckoutEditPaymentForm from "./CheckoutEditPaymentForm";
import InfoBox from "./InfoBox";
import { useEffect, useState } from "react";
import { ERROR_MESSAGES } from "../../../../utils/errors/errorUtils";

interface PaymentMethodProps {
  paymentMethod: UserRetailerCheckoutOrder["paymentMethod"];
  onAddPaymentMethod: () => void;
}
const PaymentMethod = (props: PaymentMethodProps) => {
  const { paymentMethod } = props;
  if (!paymentMethod) {
    return (
      <InfoBox
        message="Please add a payment method to complete this purchase."
        cta="Add new payment method"
        onClick={props.onAddPaymentMethod}
      />
    );
  }
  return (
    <div className="info_box">
      <div className="info_row">
        <CardBrandIcon
          className="icon"
          alt="icon"
          cardBrand={paymentMethod?.cardIssuer?.toLocaleLowerCase()}
        />
        {_startCase(paymentMethod?.cardIssuer || "")} ending in{" "}
        {paymentMethod?.cardNumberEnding}
      </div>
    </div>
  );
};
const PaymentSection = () => {
  const order = useAppSelector((state) => state.checkout.order);

  const {
    paymentOpen,
    handleSetSectionOpen,
    updateOrderPaymentMethod,
    failedAttemptError,
  } = useCheckout();
  const [error, setError] = useState("");

  useEffect(() => {
    if (
      failedAttemptError?.type === "addPaymentMethod" ||
      failedAttemptError?.type === "updatePaymentMethod"
    ) {
      setError(ERROR_MESSAGES.paymentMethodInvalid);
    }
  }, [failedAttemptError?.type]);

  const handleUpdatePayment = async (
    method: "add" | "update",
    cardInfo: {
      cardNumber?: string;
      cardIssuer: string;
      expirationDate: string;
      cvv?: string;
      cardHolderName: string;
      cardNumberEnding?: string;
    },
    address?: Partial<ShippingAddress>
  ) => {
    try {
      setError("");
      await updateOrderPaymentMethod(method, cardInfo, address as Address);
    } catch (e) {
      console.error(e);
      setError(ERROR_MESSAGES.paymentMethodInvalid);
    }
    //   CheckoutTransition.toOrder(orderId);
  };

  const availablePayments =
    order?.userRetailerCheckoutOrder?.availablePaymentMethods?.filter(
      (payment) => {
        return !payment.expirationDate.includes("Expired");
      }
    ) ?? [];

  return (
    <Box className="section">
      <div
        className="section_head"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setError("");
          handleSetSectionOpen({
            section: "payment",
            state(prev) {
              return !prev;
            },
          });
        }}
      >
        <div className="title">{"Payment Method"}</div>
        <ChevronDownIcon
          style={{
            transition: "transform 0.3s",
            width: "33px",
            height: "33px",
            transform: paymentOpen ? "rotate(180deg)" : "rotate(0deg)",
          }}
        ></ChevronDownIcon>
      </div>
      <StoreAnimatedHeightDiv>
        <AnimatePresence mode="wait">
          <motion.div
            layout="position"
            key={paymentOpen ? "view" : "edit"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            {paymentOpen ? (
              <CheckoutEditPaymentForm
                error={error}
                billingAddress={
                  order?.userRetailerCheckoutOrder?.billingAddress
                }
                availablePayments={availablePayments}
                handleUpdatePayment={handleUpdatePayment}
                existingAddress={
                  order?.userRetailerCheckoutOrder?.billingAddress
                }
                cancelEditing={() => {
                  handleSetSectionOpen({
                    section: "payment",
                    state: false,
                  });
                }}
              />
            ) : (
              <PaymentMethod
                paymentMethod={order?.userRetailerCheckoutOrder?.paymentMethod}
                onAddPaymentMethod={() => {
                  handleSetSectionOpen({
                    section: "payment",
                    state: true,
                  });
                }}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </StoreAnimatedHeightDiv>
    </Box>
  );
};

export default PaymentSection;
