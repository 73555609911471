import { useMediaQuery } from "@mui/material";
import { Reorder } from "framer-motion";
import _sortBy from "lodash/sortBy";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Stripe from "stripe";
import { ProfileContainer } from "../Profile";
import { getPaymentMethods } from "../../../../../api";
import { useAppSelector } from "../../../../../redux/hooks";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import { DashboardSolidButton } from "../DashboardOutlet";
import { PaymentCard } from "./PaymentCard";
import { DashboardHeaderContainer } from "../DashboardHeaderContainer";

const WalletList = () => {
  const mobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const [paymentMethods, setPaymentMethods] = useState<Stripe.PaymentMethod[]>(
    []
  );

  const fetchUserPayment = async () => {
    const res = await getPaymentMethods();
    if (res.data.status === "success") {
      const newPaymentMethods = res.data.paymentMethods;
      setPaymentMethods(newPaymentMethods);
    }
  };

  useEffect(() => {
    fetchUserPayment();
  }, []);

  const addresses = useAppSelector(
    ({ userProfile }) => userProfile?.addresses || []
  );

  const defaultShippingAddressId = useAppSelector(
    ({ userProfile }) => userProfile?.defaultShippingAddressId || null
  );

  const addressesOrdered = _sortBy(addresses, (a) =>
    a.id === defaultShippingAddressId ? -1 : 1
  );

  return (
    <ProfileContainer style={{ maxWidth: "100%" }}>
      <div className="profile_header_container">
        <div className="profile_header">
          <DashboardHeaderContainer>
            <div className="title">Wallet</div>
          </DashboardHeaderContainer>
        </div>
        <div className="header_action">
          <Link to={"/dashboard/wallet/new"}>
            <DashboardSolidButton>Add card</DashboardSolidButton>
          </Link>
        </div>
      </div>

      <Reorder.Group
        as="div"
        values={addressesOrdered}
        onReorder={() => {}}
        style={{
          display: "grid",
          gridTemplateColumns: mobileView ? "repeat(1, 1fr)" : "repeat(3, 1fr)",
          gap: 18,

          listStyle: "none",
        }}
      >
        {paymentMethods.map((item) => (
          <Reorder.Item key={item.id} value={item.id} drag={false}>
            <PaymentCard payment={item} />
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </ProfileContainer>
  );
};

export default WalletList;
