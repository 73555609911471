import { Box } from "@mui/material";

import { useAppSelector } from "../../../../redux/hooks";
import { Address } from "../../../../redux/types";
import { ShippingAddress } from "../../types";

import CheckoutEditShipingForm from "./CheckoutEditShipingForm";

import { useCheckout } from "../../CheckoutProvider";
import { ChevronDownIcon } from "@brandclub/common-ui";
import { StoreAnimatedHeightDiv } from "../../../StoreComponents/StoreAnimatedHeightDiv";
import { AnimatePresence, motion } from "framer-motion";
import { buildAddressString } from "../..";
import InfoBox from "./InfoBox";
import { useEffect, useState } from "react";
import { ERROR_MESSAGES } from "../../../../utils/errors/errorUtils";

interface SelectedAddressProps {
  shippingAddress?: ShippingAddress;
  onAddShippingAddress: () => void;
}
const SelectedAddress = (props: SelectedAddressProps) => {
  const { shippingAddress } = props;
  if (!shippingAddress) {
    return (
      <InfoBox
        message="Please add a shipping address to complete this purchase."
        cta="Add new shipping address"
        onClick={props.onAddShippingAddress}
      />
    );
  }
  return (
    <div className="info_box">
      <div className="info_row">
        {shippingAddress?.firstName} {shippingAddress?.lastName}
      </div>

      <div className="info_row address">
        {buildAddressString(shippingAddress)}
      </div>
    </div>
  );
};

const ShippingSection = () => {
  const {
    shippingOpen,
    handleSetSectionOpen,
    updateOrderDeliveryAddress,
    failedAttemptError,
  } = useCheckout();

  const order = useAppSelector((state) => state.checkout.order);
  const [error, setError] = useState("");

  useEffect(() => {
    if (
      failedAttemptError?.type === "addShippingAddress" ||
      failedAttemptError?.type === "updateShippingAddress"
    ) {
      setError(ERROR_MESSAGES.default[0]);
    }
  }, [failedAttemptError?.type]);

  const shippingAddress = order?.userRetailerCheckoutOrder?.shippingAddress;
  const availableAddresses = (
    order?.userRetailerCheckoutOrder?.availableAddresses || []
  )
    .slice()
    .sort((a, b) => {
      return a?.extendedAttributes?.isSelected
        ? -1
        : b?.extendedAttributes?.isSelected
        ? 1
        : 0;
    })
    .filter(
      (address) =>
        address &&
        address.firstName &&
        address.address1 &&
        address.city &&
        address.state &&
        address.zipCode
    );

  const filteredAddresses = availableAddresses.filter((address) => {
    const notAmazonFresh = !address?.firstName
      .toLocaleLowerCase()
      ?.includes("amazon fresh");
    const notWholeFoods = !address?.firstName
      .toLocaleLowerCase()
      ?.includes("whole foods");
    const notUPS = !address?.firstName.toLocaleLowerCase()?.includes("ups");
    return notAmazonFresh && notWholeFoods && notUPS;
  });

  const sortedShippingAddresses = filteredAddresses.sort((a, b) => {
    return a?.extendedAttributes?.isSelected
      ? -1
      : b?.extendedAttributes?.isSelected
      ? 1
      : 0;
  });

  const handleUpdateShippingAddress = async (
    method: "add" | "update",
    address: Partial<ShippingAddress>
  ) => {
    try {
      setError("");
      await updateOrderDeliveryAddress(method, {
        ...address,
      } as Address);
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : ERROR_MESSAGES.default[0];
      setError(errorMessage);
    }
    // CheckoutTransition.toOrder(orderId);
  };

  return (
    <Box className="section">
      <div
        className="section_head"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setError("");
          handleSetSectionOpen({
            section: "shipping",
            state(prev) {
              return !prev;
            },
          });
        }}
      >
        <div className="title">{"Shipping Address"}</div>
        <ChevronDownIcon
          style={{
            transition: "transform 0.3s",
            width: "33px",
            height: "33px",
            transform: shippingOpen ? "rotate(180deg)" : "rotate(0deg)",
          }}
        ></ChevronDownIcon>
      </div>

      <StoreAnimatedHeightDiv>
        <AnimatePresence mode="wait">
          <motion.div
            layout="position"
            key={shippingOpen ? "view" : "edit"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            {shippingOpen ? (
              <CheckoutEditShipingForm
                availableAddresses={sortedShippingAddresses}
                handleUpdateShippingAddress={handleUpdateShippingAddress}
                existingAddress={shippingAddress}
                error={error}
                cancelEditing={() => {
                  handleSetSectionOpen({
                    section: "shipping",
                    state: false,
                  });
                }}
              />
            ) : (
              <SelectedAddress
                shippingAddress={shippingAddress}
                onAddShippingAddress={() =>
                  handleSetSectionOpen({
                    section: "shipping",
                    state: true,
                  })
                }
              />
            )}
          </motion.div>
        </AnimatePresence>
      </StoreAnimatedHeightDiv>
    </Box>
  );
};

export default ShippingSection;
