import { useEffect, useMemo, useState } from "react";
import useStoreType from "./useStoreType";
import { getCustomTheme, ThemeResponse } from "../../api";
import { StoreBrandingType } from "../../types/misc";
import { useAppSelector } from "../../redux/hooks";
import { AppConfigTheme } from "../../redux/types";
import { ThemeOptions } from "@mui/material/styles";

const buildDtcTheme = (theme: ThemeResponse): ThemeOptions => ({
  palette: {
    primary: {
      main: theme.primary,
    },
    secondary: {
      main: theme.secondary,
    },
  },
  typography: {
    fontFamily: theme.font.fontFamily,
  },
  dtc: true,
});

const buildCustomStoreTheme = (theme: AppConfigTheme): ThemeOptions => ({
  palette: {
    primary: {
      main: theme.primaryColor,
    },
    secondary: {
      main: theme.secondaryColor,
    },
    tertiary: {
      main: theme.tertiaryColor,
    },
    headerColor: {
      main: theme.headerColor,
    },
  },
});

export const useCustomTheme = () => {
  const [storeTheme, setStoreTheme] = useState<any>();
  const [theme, setTheme] = useState<ThemeOptions | undefined>(undefined);
  const brandAppThemeConfig = useAppSelector(
    ({ appConfig }) => appConfig?.brandAppThemeConfig
  );
  const storeType = useStoreType();
  const isDtc = storeType === StoreBrandingType.CustomDTCStore;

  const brandAppTheme = useMemo(() => {
    return (
      brandAppThemeConfig?.theme &&
      buildCustomStoreTheme(brandAppThemeConfig.theme)
    );
  }, [brandAppThemeConfig]);

  useEffect(() => {
    const fetchDtcTheme = async () => {
      const theme = await getCustomTheme();
      setStoreTheme(theme);
      setTheme(theme && buildDtcTheme(theme));
      if (theme?.font?.fontFamily) {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = theme.font.fontFamily;
        document.head.appendChild(link);
        const fontFamily = theme.font.fontFamily.match(/(?<=family=)[^:&,]+/);
        document.body.style.fontFamily = fontFamily ? fontFamily[0] : "Poppins";
      }
    };
    if (isDtc) {
      fetchDtcTheme();
    }
  }, [isDtc]);

  switch (storeType) {
    case StoreBrandingType.CustomDTCStore:
      return { theme, storeTheme };
    case StoreBrandingType.CustomStore:
      return { theme: brandAppTheme };
    default:
      return undefined;
  }
};
