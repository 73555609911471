import { useResponsiveData } from "../../../../../utils/hooks/useResponsiveData";
import BrandCard from "../../../../StoreComponents/StoreCard.tsx/BrandCard";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { CardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import PreviewContainer from "../components/PreviewContainer";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import { useFetchMyClubs } from "./data";
import _orderBy from "lodash/orderBy";
import _uniqBy from "lodash/uniqBy";
import { useMemo } from "react";

const MyClubsPreview = () => {
  const { data, loading } = useFetchMyClubs();

  const rows = useMemo(() => {
    const orderedData = _orderBy(
      _uniqBy([...(data || [])], "brandclubId"),
      ["rewardAmount"],
      ["desc"]
    );
    return orderedData.slice(0, 10);
  }, [data]);

  const responsiveRows = useResponsiveData(rows);

  if (!loading && rows?.length === 0) {
    return null;
  }
  return (
    <PreviewWidgetWrapper>
      <PreviewContainer>
        {loading ? (
          <StoreLoadingSkeleton header />
        ) : (
          <>
            <PreviewHeader
              title={"My clubs"}
              linkTo="/myclubs"
              subtitle="View your reward balance for each club"
              indicator="grey"
            />
            <CardGrid>
              {responsiveRows.map((d) => (
                <BrandCard joined key={d.brandclubId} brand={d} />
              ))}
            </CardGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default MyClubsPreview;
