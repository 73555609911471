import QueryString from 'qs';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// https://dev.to/brettfishy/the-easiest-way-to-use-query-parameters-in-react-1ioe
const getQuery = () => {
  if (typeof window !== "undefined") {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
};

const getQueryStringVal = (key: string): string | null => {
  return getQuery().get(key);
};

const useQueryParam = (
  key: string,
  defaultVal: string
): [string, (val: string) => void] => {
  const [query, setQuery] = useState(getQueryStringVal(key) || defaultVal);

  const updateUrl = (newVal: string) => {
    setQuery(newVal);

    const query = getQuery();

    if (newVal && newVal.trim() !== "") {
      query.set(key, newVal);
    } else {
      query.delete(key);
    }

    const modifiedQuery = query.toString().replace(/%2C/g, ",");

    if (typeof window !== "undefined") {
      const { protocol, pathname, host } = window.location;
      const newUrl = `${protocol}//${host}${pathname}?${modifiedQuery}`;
      window.history.pushState({}, "", newUrl);
    }
  };

  return [query, updateUrl];
};

export const useQueryTab = (defaultVal = "") => {
  const location = useLocation();
  const params = useParams();

  const [tab, setTab] = useState(defaultVal);

  useEffect(() => {
    const params = QueryString.parse(window.location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100,
    });
    if (params.tab && params.tab !== tab) {
      setTab(params.tab as string);
    }
    // Don't ask...if it works, it works, right?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, params, tab, window.location.search]);

  return tab;
};

export default useQueryParam;
