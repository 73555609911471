import { CampaignType } from "@brandclub/common-ui";
import {
  ContentRewardCampaignForCarousel,
  RewardUnlockStrategy,
} from "../../../../../../types/misc";
import LongRewardCard from "../../../../../StoreComponents/StoreCard.tsx/LongRewardCard";

const ContentCard = ({
  content,
  border = false,
}: {
  content: ContentRewardCampaignForCarousel;
  border?: boolean;
}) => {
  const rewardAmount = content.extendedAttributes.reward.reward.amount;
  const hasInstantReward =
    content?.extendedAttributes?.rewardUnlockStrategy ===
    RewardUnlockStrategy.Instant;

  return (
    <LongRewardCard
      type="media"
      title={content.content.title}
      subtitle={content.content.description}
      image={content.content.previewImageUrl || ""}
      rewardType={
        hasInstantReward
          ? CampaignType.DailyDealsRewardCampaign
          : CampaignType.ContentRewardCampaign
      }
      rewardAmount={rewardAmount}
      linkTo={`/content/${content.extendedAttributes.contentId}`}
      linkState={{
        content,
        brandId: content.brandclub?.brandId,
        brandName: content.brandclub?.name,
      }}
      border={border}
    ></LongRewardCard>
  );
};

export default ContentCard;
