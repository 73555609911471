import { styled } from "@mui/material/styles";
import Card, { CardProps } from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import {
  SolidButton,
  TrackedMotionButtonProps,
} from "../../../StoreComponents/StoreButton";
import { formatMoney, formatNumber } from "../../../../utils/StringUtils";
import { MOBILE_SCREEN_SIZE } from "../../../AppNavigation/constants";
import { RewardAmountType } from "@brandclub/common-ui";

export type RewardCardType =
  | "join"
  | "survey"
  | "purchase"
  | "content"
  | "affiliate"
  | "connectedAccount"
  | "customerReferral";

export interface RewardAmount {
  rewardAmount: number;
  offers: number;
  rewardCardType: RewardCardType;
  rewardAmountType: RewardAmountType;
}

export interface BrandstoreRewardCardProps {
  className?: string;
  sx?: CardProps["sx"];
  reward: RewardAmount;
  onClick?: () => void;
  actionButtonProps?: Omit<TrackedMotionButtonProps, "onClick">;
  title: string;
  description: string;
  cta: string | React.ReactNode;
  icon: React.ReactNode;
}
const BrandstoreRewardsCardContainer = styled(Card)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: 320,
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    width: 265,
  },
  boxShadow: "none",
  borderColor: "#dcdddc",
  boxSizing: "border-box",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: 12,
}));

const formatOffers = (offers: number) => {
  if (!offers) {
    return undefined;
  }
  if (offers === 1) {
    return "1 offer";
  }
  if (offers === Infinity) {
    return "Unlimited";
  }
  return `${formatNumber(offers, false)} offers`;
};

const formatRewardAmount = (reward: RewardAmount) => {
  if (reward.rewardAmountType === RewardAmountType.Fixed) {
    return formatMoney(reward.rewardAmount ?? 0, undefined, true);
  }
  if (reward.rewardAmountType === RewardAmountType.Percent) {
    return `${reward.rewardAmount}%`;
  }
  return undefined;
};

const BrandstoreRewardCard = ({
  className,
  sx,
  actionButtonProps,
  icon,
  reward,
  title,
  description,
  onClick,
  cta,
  ...props
}: BrandstoreRewardCardProps) => {
  return (
    <BrandstoreRewardsCardContainer
      {...props}
      variant="outlined"
      elevation={0}
      className={className}
      sx={sx}
    >
      <CardHeader
        sx={{
          paddingLeft: "30px",
          paddingRight: "30px",
          paddingTop: "24px",
          paddingBottom: "0px",
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "20px",
          },
          ".MuiCardHeader-avatar": {
            marginRight: "20px",
            [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              marginRight: "10px",
            },
          },
        }}
        avatar={
          <Box
            width={66}
            height={66}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginRight={0}
            bgcolor="#f6f8fa"
            aria-label="recipe"
            borderRadius="100%"
            sx={{
              [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                width: 48,
                height: 48,
              },
            }}
          >
            {icon}
          </Box>
        }
        title={
          <Box
            component="span"
            fontSize={24}
            fontWeight={600}
            sx={{
              [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                fontSize: 18,
              },
            }}
          >
            {formatRewardAmount(reward)}
          </Box>
        }
        subheader={
          !!reward.offers && (
            <Box
              component="span"
              fontSize={14}
              fontWeight={500}
              sx={(theme) => ({
                color: theme.palette.primary.main,
                [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                  fontSize: 12,
                },
              })}
            >
              {formatOffers(reward.offers)}
            </Box>
          )
        }
      />
      <CardContent
        sx={{
          flex: 1,
          paddingTop: "30px",
          paddingLeft: "30px",
          paddingRight: "30px",
          paddingBottom: "22px",
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "25px",
            paddingBottom: "13px",
          },
        }}
      >
        <Typography
          fontSize={16}
          fontWeight={600}
          marginBottom={"10px"}
          sx={{
            [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              fontSize: 14,
            },
          }}
        >
          {title}
        </Typography>
        <Typography
          fontSize={14}
          fontWeight={500}
          sx={{
            [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              fontSize: 12,
            },
          }}
        >
          {description}
        </Typography>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{
          paddingLeft: "30px",
          paddingRight: "30px",
          paddingTop: "0px",
          paddingBottom: "22px",
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "21px",
          },
        }}
      >
        <SolidButton
          sx={(theme) => ({
            backgroundColor: "#f6f8fa",
            borderRadius: 25,
            color: theme.palette.primary.main,
            borderColor: "#f6f8fa",
            whiteSpace: "nowrap",
            [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              fontSize: 11,
            },
          })}
          {...actionButtonProps}
          onClick={onClick}
        >
          {cta}
        </SolidButton>
      </CardActions>
    </BrandstoreRewardsCardContainer>
  );
};

export default BrandstoreRewardCard;
