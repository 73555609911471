import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import BrandclubLoading from "../../assets/lottie/progress_dot.json";
import { SimplePaletteColorOptions } from "@mui/material/styles";
import { CheckCircleIcon } from "@brandclub/common-ui";
import LottieInner from "react-lottie";
import colors from "../styles/colors";
import { styled } from "@mui/material";
import { progressLottieOptions } from "../../utils/lottieUtils";

// @ts-ignore
const Lottie = LottieInner.default
  ? // @ts-ignore
    LottieInner.default
  : LottieInner;

const StepContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  ".step_container": {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const StoreStepper = ({
  steps,
  currentIndex,
  dotSize = 23,
  sectionHeight = 97,
  gap = 14,
  animated = false,
}: {
  steps: { visible: boolean; success: boolean }[];
  currentIndex: number;
  dotSize?: number;
  sectionHeight?: number;
  gap?: number;
  animated?: boolean;
}) => {
  const dotDistance = sectionHeight + gap;
  const theme = useTheme();
  const primaryColor = theme.palette.primary as SimplePaletteColorOptions;

  return (
    <StepContainer style={{}}>
      <div className="step_container" style={{ alignItems: "center" }}>
        <div
          style={{
            width: dotSize / 6 || 4,
            top: sectionHeight / 2,
            height: (steps.length - 1) * dotDistance + 3,
            // backgroundColor: `rgba(220, 221, 220, 0.5)`,
            borderRadius: 10,
            position: "absolute",
          }}
        />
        {steps.map((step, idx) => {
          const last = idx === steps.length - 1;
          // const prevStep = steps?.[idx - 1];
          const nextStep = steps?.[idx + 1];

          if (last) {
            return null;
          }
          return (
            <motion.div
              key={idx}
              style={{
                width: dotSize / 6 || 4,
                top: sectionHeight / 2 + dotDistance * idx,
                // height: (steps.length - 1) * dotDistance + 3,
                height: dotDistance,
                backgroundColor: `rgba(220, 221, 220, 0.5)`,
                borderRadius: 10,
                position: "absolute",
              }}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: step?.visible && nextStep?.visible ? 1 : 0,
              }}
              // transition={{
              //   type: "timing",
              //   duration: 500,
              // }}
            >
              <motion.div
                style={{
                  width: dotSize / 6 || 4,
                  top: 0,
                  height: 0,
                  backgroundColor: theme.dtc
                    ? primaryColor.main
                    : colors.darkBlue,
                  borderRadius: 10,
                  position: "absolute",
                }}
                initial={{
                  height: 0,
                }}
                animate={{
                  height: currentIndex > idx ? dotDistance : 0,
                }}
              ></motion.div>
            </motion.div>
          );
        })}
        {steps.map((step, idx) => {
          return (
            <motion.div
              key={`${idx}`}
              initial={{
                opacity: 0,
                scale: 0,
              }}
              animate={{
                opacity: step.visible ? 1 : 0,
                scale: step.visible ? 1 : 0,
              }}
              // transition={{
              //   type: "timing",
              //   duration: 500,
              // }}
            >
              {animated && idx === currentIndex ? (
                <motion.div
                  key={"active"}
                  className="active1"
                  style={{
                    top: sectionHeight / 2 - dotSize / 2,
                    width: dotSize,
                    height: dotSize,
                    marginBottom: dotDistance - dotSize,
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                  initial={{
                    opacity: 0,
                    scale: 0,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  // transition={{
                  //   type: "timing",

                  //   duration: 0,
                  // }}
                >
                  <Lottie
                    style={{ position: "absolute" }}
                    options={progressLottieOptions(theme, BrandclubLoading)}
                    isClickToPauseDisabled
                    width={dotSize * 1.2 || 30}
                    height={dotSize * 1.2 || 30}
                  />
                </motion.div>
              ) : idx <= currentIndex ? (
                <motion.div
                  key={"active"}
                  className="active"
                  style={{
                    top: sectionHeight / 2 - dotSize / 2,
                    width: dotSize,
                    height: dotSize,
                    marginBottom: dotDistance - dotSize,
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                  initial={{
                    opacity: 0,
                    scale: 0,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  // transition={{
                  //   type: "timing",

                  //   duration: 0,
                  // }}
                >
                  <CheckCircleIcon
                    backgroundColor="primary"
                    sx={{ fontSize: 18, position: "absolute" }}
                    htmlColor="#ffffff"
                  />
                </motion.div>
              ) : (
                <motion.div
                  key={"inactive"}
                  className="inactive"
                  style={{
                    width: dotSize,
                    height: dotSize,
                    top: sectionHeight / 2 - dotSize / 2,
                    marginBottom: dotDistance - dotSize,
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                  initial={{
                    opacity: 0,
                    scale: 0,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  // transition={{
                  //   type: "timing",
                  // }}
                >
                  <div
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: 18,
                      height: 18,
                      backgroundColor: `#f6f8fa`,
                      borderRadius: 99,
                      position: "absolute",
                    }}
                  >
                    <div
                      style={{
                        width: 9,
                        height: 9,
                        backgroundColor: "#dcdddc",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </motion.div>
              )}
            </motion.div>
          );
        })}
      </div>
    </StepContainer>
  );
};

export default StoreStepper;
