import { SxProps, Theme, styled, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";

import moment from "moment";
import { Link } from "react-router-dom";

import {
  getAppConsistentParams,
  getProductUrl,
  getRetailerFromId,
} from "@brandclub/common-ui";
import KeyboardArrowRightRounded from "@mui/icons-material/KeyboardArrowRightRounded";
import { ComponentProps, FC, PropsWithChildren } from "react";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import { StyledLink } from "../../../../AppNavigation/StyledLink";
import { useCheckout } from "../../../../Checkout/CheckoutProvider";
import { useCheckoutDrawer } from "../../../../Checkout/useCheckoutDrawer";
import { isItemCheckoutSuccess } from "../../../../Checkout/utils";
import { useSiteContext } from "../../../../SiteProvider";
import {
  SolidButton,
  OutlineButton,
} from "../../../../StoreComponents/StoreButton";
import { PurchaseRowType } from "./data";
import ProductImageWithQuantity from "./ProductImageWithQuantity";
import { getShipmentStatus } from "./purchaseUtil";
import { useAppSelector } from "../../../../../redux/hooks";

const RowContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",

  alignItems: "flex-start",
  padding: "30px 0",
  ".info": {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "16px",
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gap: "20px",
    },
    ".detail": {
      display: "flex",
      flexDirection: "column",
      gap: "6px",
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        gap: 4,
      },
      ".title": {
        whiteSpace: "nowrap",
        fontSize: "16px",
        fontWeight: 700,
        marginBottom: 3,
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 14,
          marginBottom: 0,
        },
      },
      ".detail_row": {
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        ".label": {
          fontSize: "14px",
          fontWeight: 500,
        },
        ".value": {
          fontSize: "14px",
          fontWeight: 600,
        },
      },
    },
  },
  ".actions": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "flex-end",
    justifyContent: "space-between",
    [`@media screen and (max-width:${MOBILE_SCREEN_SIZE}px)`]: {
      flexDirection: "row",
    },
    a: {
      textDecoration: "none",
    },

    ".link": {
      display: "flex",
      flexDirection: "row",
      gap: "4px",
      alignItems: "center",
      fontSize: "14px",
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
  },
}));

interface ActionButtonProps extends ComponentProps<typeof SolidButton> {
  primary?: boolean;
}
const ActionButton: FC<ActionButtonProps> = ({ primary, sx, ...props }) => {
  if (primary) {
    return (
      <SolidButton
        {...props}
        sx={{
          width: "100%",
          whiteSpace: "nowrap",
          height: 35,
          fontSize: 13,
          [`@media screen and (max-width:${MOBILE_SCREEN_SIZE}px)`]: {
            fontSize: 12,
            height: 27,
            padding: "0 12px",
          },
          ...sx,
        }}
      />
    );
  }
  return (
    <OutlineButton
      {...props}
      sx={{
        width: "100%",
        whiteSpace: "nowrap",
        height: 35,
        fontSize: 13,
        [`@media screen and (max-width:${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 12,
          height: 27,
          padding: "0 12px",
        },
        ...sx,
      }}
    />
  );
};

const ProductDetails = (product: PurchaseRowType) => {
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);

  return (
    <>
      <div className="detail_row">
        <div className="label">Retailer:</div>
        <div className="value">{getRetailerFromId(product.retailerId)}</div>
      </div>
      {!isMobileView && (
        <div className="detail_row">
          <div className="label">Retailer order:</div>
          <div className="value">
            <a
              href={product.retailerOrderPageUrl}
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {product.retailerOrderId ? `${product.retailerOrderId}` : "--"}
            </a>
          </div>
        </div>
      )}
      <div className="detail_row">
        <div className="label">Order date:</div>
        <div className="value">
          {moment(product.updatedTime).format("MMM DD, YYYY")}
        </div>
      </div>
      {!isMobileView && (
        <div className="detail_row">
          <div className="label">Status:</div>
          <div className="value">
            {getShipmentStatus(product.orderTracking?.shipmentStatus)}
          </div>
        </div>
      )}
    </>
  );
};

const PurchaseActionButtons = ({
  item,
  children,
}: PropsWithChildren<{
  item: PurchaseRowType;
}>) => {
  const { addItemsToCart } = useCheckout();
  const { openDrawer } = useCheckoutDrawer();
  const { routePrefix } = useSiteContext();
  const appConfig = useAppSelector((state) => state.appConfig);
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);

  const onDTC = appConfig?.domainConfig.storeBrandingType === "CustomDTCStore";

  const purchaseDetailPageUrl = onDTC
    ? `/purchase/${item.checkoutBatchId}`
    : routePrefix
    ? `${routePrefix}/branddashboard/purchase/${item.checkoutBatchId}`
    : `/dashboard/purchase/${item.checkoutBatchId}`;

  const buyAgain = async () => {
    await addItemsToCart([
      {
        brandId: item.brandId,
        stacklineSku: item.stacklineSku,
        retailerId: item.retailerId,
        quantity: 1,
        retailerSku: item.retailerSku,
        retailPrice: item.retailPrice,
      },
    ]);

    openDrawer();
  };

  const canWriteReview = isItemCheckoutSuccess(item) && item.canWriteReview;
  return (
    <div className="actions">
      <Box
        sx={{
          gap: "7.2px",
          display: "flex",
          flexDirection: canWriteReview ? "column-reverse" : "column",
          width: "100%",
          [`@media screen and (max-width:${MOBILE_SCREEN_SIZE}px)`]: {
            flexDirection: "row",
            gap: "5.4px",
            width: "auto",
          },
        }}
      >
        {canWriteReview ? (
          <>
            <ActionButton
              trackedAdditionalData={{ ...item }}
              primary={isMobileView}
              onClick={buyAgain}
            >
              Buy again
            </ActionButton>
            <StyledLink
              to={`/review/${item.stacklineSku}`}
              state={{ product: item }}
              sx={{ textDecoration: "none" }}
            >
              <ActionButton
                trackedAdditionalData={{ ...item }}
                primary={!isMobileView}
              >
                Write review
              </ActionButton>
            </StyledLink>
          </>
        ) : (
          <>
            <ActionButton
              trackedAdditionalData={{ ...item }}
              primary
              onClick={buyAgain}
            >
              Buy again
            </ActionButton>
            <Link to={purchaseDetailPageUrl}>
              <ActionButton
                trackedAdditionalData={{
                  linkTo: purchaseDetailPageUrl,
                  ...item,
                }}
              >
                View purchase
              </ActionButton>
            </Link>
          </>
        )}
      </Box>
      {children}
    </div>
  );
};

const PurchaseListRow = ({
  item,
  containerStyle = {},
  shouldShowTrackPackage = true,
}: {
  item: PurchaseRowType;
  containerStyle?: SxProps<Theme>;
  shouldShowTrackPackage?: boolean;
}) => {
  const appConfig = useAppSelector((state) => state.appConfig);
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const urlSearchParams = getAppConsistentParams(window.location.search);
  const targetUrl = getProductUrl({
    title: item.title,
    stacklineSku: item.stacklineSku,
    urlSearchParams,
    dtcProductPageUrl: item.dtcProductPageUrl,
    storeType: appConfig?.domainConfig?.storeBrandingType,
  });
  const showTrackPackageLink = shouldShowTrackPackage && !isMobileView;
  return (
    <RowContainer className="row" sx={{ ...containerStyle }}>
      <div className="info">
        <Link
          to={targetUrl}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ProductImageWithQuantity
            url={item?.imageUrls?.[0]}
            quantity={item.quantity}
          />
        </Link>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            flex: 1,
            justifyContent: "space-between",
            [`@media screen and (max-width:${MOBILE_SCREEN_SIZE}px)`]: {
              flex: "unset",
              width: "auto",
              gap: "10px",
              flexDirection: "column",
            },
          }}
        >
          <div className="detail">
            <div className="title">Order #{item.checkoutBatchId}</div>
            <ProductDetails {...item} />
          </div>
          <PurchaseActionButtons item={item}>
            {showTrackPackageLink && (
              <a
                href={item.retailerOrderPageUrl}
                rel="noreferrer"
                target="_blank"
                style={{
                  textDecoration: "none",

                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "inherit",
                }}
              >
                <div className="link">Track package</div>
                <KeyboardArrowRightRounded
                  style={{ color: "inherit", fontSize: 21, marginRight: -3 }}
                />
              </a>
            )}
          </PurchaseActionButtons>
        </Box>
      </div>
    </RowContainer>
  );
};

export default PurchaseListRow;
