import { styled, Theme, useTheme } from "@mui/material/styles";
import Carousel, {
  StoreCarouselProps,
} from "../../../StoreComponents/StoreCarousel";
import { ACTION_LOGO_LINK_PREFIX } from "../Home/HomeActionSection";
import PreviewWidgetWrapper from "../Pages/components/PreviewWidgetWrapper";
import { useAppSelector } from "../../../../redux/hooks";
import BrandstoreRewardCard, {
  BrandstoreRewardCardProps,
  RewardAmount,
  RewardCardType,
} from "./BrandstoreRewardCard";
import { Retailer } from "../../../../redux/types";
import { isDefined } from "../../../../utils/misc";
import { useNavigate } from "react-router-dom";
import InviteFriendsDialog from "../../../StoreComponents/StoreDialogs/InviteFriendsDialog";
import { useLinkPrefix } from "../../../../utils/hooks/useLinkPrefix";
import { ButtonLoadingEllipsis } from "../../../StoreComponents/StoreButton";
import {
  BrandClub,
  CampaignType,
  RewardAmountType,
  SuccessCheckCircleIcon,
} from "@brandclub/common-ui";
import { MOBILE_SCREEN_SIZE } from "../../../AppNavigation/constants";
import { useMediaQuery } from "@mui/material";
import {
  JoinBrandClubState,
  useJoinBrandClubWithRedirect,
} from "../../../../utils/hooks/useJoinBrandClub";

export interface BrandstoreRewardsCarouselProps {
  className?: string;
  carouselProps?: StoreCarouselProps;
}

const PreviewWidgetWrapperStyled = styled(PreviewWidgetWrapper)({
  padding: "10px 5% 40px 5%",
});

const IconImg = styled("img")({
  width: 36,
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    width: 27,
  },
  aspectRatio: "1/1",
});

const getConnectedAccountRewardAmount = (
  retailers: Retailer[]
): RewardAmount => {
  const retailersWithReward = retailers.filter(
    (r) => (r.retailerConnectRewardAmount || 0) > 0
  );
  const rewardAmount = retailersWithReward.reduce(
    (acc, r) => acc + r.retailerConnectRewardAmount!,
    0
  );
  return {
    rewardAmount,
    offers: retailersWithReward.length,
    rewardCardType: "connectedAccount",
    rewardAmountType: RewardAmountType.Fixed,
  };
};

export const getJoinRewardAmount = (brandEntity: {
  brandclub?: BrandClub;
  entity?: any;
}): RewardAmount | undefined => {
  // firt check if the reward amount is set in the entity
  if (brandEntity.entity?.rewardAmounts?.join?.rewardAmount > 0) {
    return {
      rewardAmount: brandEntity.entity.rewardAmounts.join.rewardAmount,
      offers: 1,
      rewardCardType: "join",
      rewardAmountType: RewardAmountType.Fixed,
    };
  }
  // if not, check if the reward amount is set in the brandclub
  const campaignRewards = brandEntity.brandclub?.campaignRewards;
  if (
    campaignRewards &&
    Array.isArray(campaignRewards) &&
    campaignRewards.length > 0
  ) {
    const membershipCampaign = campaignRewards.find(
      (c) => c.campaignType === CampaignType.MembershipRewardCampaign
    );
    if (membershipCampaign) {
      return {
        rewardAmount: membershipCampaign.rewardAmount,
        offers: 1,
        rewardCardType: "join",
        rewardAmountType: RewardAmountType.Fixed,
      };
    }
  }
  return undefined;
};

const getJoinCta = (joinedState: JoinBrandClubState | undefined) => {
  switch (joinedState) {
    case "joined":
      return (
        <>
          <SuccessCheckCircleIcon className="button_img" />
          Member
        </>
      );
    case "joining":
      return (
        <>
          Joining
          <ButtonLoadingEllipsis baseFontSize={17} blue></ButtonLoadingEllipsis>
        </>
      );
    default:
      return "Join";
  }
};

export const brandEntityToRewardAmounts = (
  brandEntity: { entity?: any; brandclub?: BrandClub },
  retailers: Retailer[],
  referralReward: number | null
) => {
  const survey = brandEntity.brandclub?.availableCampaignRewardsSummary?.survey;
  const purchase =
    brandEntity.brandclub?.availableCampaignRewardsSummary?.purchase;
  const content =
    brandEntity.brandclub?.availableCampaignRewardsSummary?.content;
  const affiliate =
    brandEntity.brandclub?.availableCampaignRewardsSummary?.affiliate;
  const rewards: RewardAmount[] = [];
  // join rewards is first in the list
  if (!brandEntity.brandclub?.isMember) {
    const joinReward = getJoinRewardAmount(brandEntity);
    if (joinReward?.rewardAmount) {
      rewards.push(joinReward);
    }
  }
  // purchase rewards is second in the list
  if (purchase?.maxAmount) {
    rewards.push({
      rewardAmount: purchase.maxAmount,
      offers: purchase.count ?? 0,
      rewardCardType: "purchase",
      rewardAmountType: RewardAmountType.Percent,
    });
  }
  // affiliate rewards is third in the list
  if (affiliate?.maxAmount) {
    rewards.push({
      rewardAmount: affiliate.maxAmount,
      offers: affiliate.count ?? 0,
      rewardCardType: "affiliate",
      rewardAmountType: RewardAmountType.Percent,
    });
  }
  // content rewards is fourth in the list
  if (content?.amount) {
    rewards.push({
      rewardAmount: content.amount,
      offers: content.count ?? 0,
      rewardCardType: "content",
      rewardAmountType: RewardAmountType.Fixed,
    });
  }
  // survey rewards is fifth in the list
  if (survey?.amount) {
    rewards.push({
      rewardAmount: survey.amount,
      offers: survey.count ?? 0,
      rewardCardType: "survey",
      rewardAmountType: RewardAmountType.Fixed,
    });
  }
  // customer referral rewards is sixth in the list
  if (referralReward) {
    rewards.push({
      rewardAmount: referralReward,
      offers: Infinity,
      rewardCardType: "customerReferral",
      rewardAmountType: RewardAmountType.Fixed,
    });
  }
  // connected account rewards is seventh in the list
  if (retailers) {
    const connectedAccount = getConnectedAccountRewardAmount(retailers);
    rewards.push(connectedAccount);
  }
  return rewards;
};

/**
 * Retrieves the reward amounts from a brand entity and filters out any rewards with a reward amount of 0.
 *
 * @param brandEntity - The brand entity object.
 * @returns An array of reward entries with non-null reward amounts.
 */

interface RewardsSummaryProps {
  rewards: RewardAmount[];
  brandclubJoinedState?: JoinBrandClubState;
  theme: Theme;
}

const mapRewardsToCardProps = ({
  rewards,
  brandclubJoinedState,
  theme,
}: RewardsSummaryProps): BrandstoreRewardCardProps[] => {
  return rewards
    .map((reward) => {
      const rewardType = reward?.rewardCardType;
      switch (rewardType) {
        case "join":
          return {
            reward,
            title: "Membership rewards",
            description: `Join to access exclusive offers and rewards.`,
            cta: getJoinCta(brandclubJoinedState),
            icon: (
              <IconImg
                alt="join icon"
                src={`${ACTION_LOGO_LINK_PREFIX}join.svg`}
              />
            ),
            actionButtonProps: {
              style:
                brandclubJoinedState === "joined"
                  ? {
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.common.white,
                      border: `1px solid ${theme.palette.primary.main}`,
                    }
                  : {},
            },
          };
        case "survey":
          return {
            reward,
            title: "Survey rewards",
            description: `Complete surveys to earn more rewards.`,
            cta: "Get started",
            icon: (
              <IconImg
                alt="surveys icon"
                src={`${ACTION_LOGO_LINK_PREFIX}surveys.svg`}
              />
            ),
            actionButtonProps: {},
          };
        case "connectedAccount":
          return {
            reward,
            title: "Connect rewards",
            description:
              "Connect retailer accounts to earn more on everyday purchases.",
            cta: "Connect",
            icon: (
              <IconImg
                alt="connect icon"
                src={`${ACTION_LOGO_LINK_PREFIX}connect.svg`}
              />
            ),
          };
        case "content":
          return {
            reward,
            title: "Content rewards",
            description: `Earn rewards by viewing the latest content.`,
            cta: "View",
            actionButtonProps: {},
            icon: (
              <IconImg
                alt="content icon"
                src={`${ACTION_LOGO_LINK_PREFIX}content.svg`}
              />
            ),
          };
        case "affiliate":
          return {
            reward,
            title: "Affiliate rewards",
            description:
              "Get paid to refer purchases by using your link to share products.",
            cta: "Refer",
            actionButtonProps: {},
            icon: (
              <IconImg
                alt="affiliate icon"
                src={`${ACTION_LOGO_LINK_PREFIX}affiliate.svg`}
              />
            ),
          };
        case "purchase":
          return {
            reward,
            title: "Purchase rewards",
            description: `Earn cash back rewards when you buy products.`,
            cta: "Shop",
            icon: (
              <IconImg
                alt="affiliate icon"
                src={`${ACTION_LOGO_LINK_PREFIX}purchase.svg`}
              />
            ),
          };
        case "customerReferral":
          return {
            reward,
            title: "Refer a friend",
            description:
              "Get paid for every friend you invite to join the club.",
            cta: "Invite",
            actionButtonProps: {},
            icon: (
              <IconImg
                alt="invite icon"
                src={`${ACTION_LOGO_LINK_PREFIX}invite-dark.svg`}
              />
            ),
          };
        default:
          return undefined;
      }
    })
    .filter(isDefined);
};

export const BrandstoreRewardsCarousel = ({
  carouselProps,
  ...props
}: BrandstoreRewardsCarouselProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_SCREEN_SIZE}px)`);
  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);
  const retailers = useAppSelector(({ retailers }) => retailers ?? []);
  const { amountForReferringUser } = useAppSelector(
    ({ referralReward }) => referralReward
  );
  const navigate = useNavigate();
  const { linkPrefix } = useLinkPrefix();
  const { handleJoin, joinedState } = useJoinBrandClubWithRedirect(
    brandEntity?.brandclub
  );

  if (!brandEntity) {
    return null;
  }

  const getOnClickHandler = (rewardType: RewardCardType) => () => {
    switch (rewardType) {
      case "join":
        joinedState === "not_join" && handleJoin();
        return;
      case "survey":
        navigate(linkPrefix + "/surveys");
        return;
      case "connectedAccount":
        navigate("/dashboard/accounts");
        return;
      case "content":
        navigate(linkPrefix + "/contentrewards");
        return;
      case "affiliate":
        navigate(linkPrefix + "/affiliate");
        return;
      case "purchase":
        navigate(linkPrefix + "/popular");
        return;
      case "customerReferral":
        InviteFriendsDialog.show();
        return;
      default:
        return undefined;
    }
  };
  const rewardAmounts = brandEntityToRewardAmounts(
    brandEntity,
    retailers,
    amountForReferringUser
  );
  const mappedRewardProps = mapRewardsToCardProps({
    rewards: rewardAmounts,
    brandclubJoinedState: joinedState,
    theme,
  });
  // hide the carousel if there are less than 3 rewards
  if (mappedRewardProps.length < 3) {
    return null;
  }
  const shouldCenter = mappedRewardProps.length < 4;
  return (
    <PreviewWidgetWrapperStyled {...props}>
      <Carousel
        {...carouselProps}
        hideArrows={isMobile}
        styleProps={{
          sx: {
            display: shouldCenter ? "flex" : undefined,
            justifyContent: shouldCenter ? "center" : undefined,
          },
        }}
      >
        {mappedRewardProps.map((rewardProps, index) => (
          <BrandstoreRewardCard
            {...rewardProps}
            key={rewardProps.title}
            data-testid={rewardProps.title}
            data-order={index}
            onClick={getOnClickHandler(rewardProps.reward.rewardCardType)}
          />
        ))}
      </Carousel>
    </PreviewWidgetWrapperStyled>
  );
};
