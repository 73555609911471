import { useContext, useEffect } from "react";
import Loading from "../../Loading";
import { useAppSelector } from "../../../redux/hooks";
import { StoreBrandingType } from "../../../types/misc";
import { UserLoginContext } from "./UserLoginProvider";

export default function SignOutRedirect() {
  const { send } = useContext(UserLoginContext);
  const appConfig = useAppSelector((state) => state.appConfig);
  const linkTo =
    appConfig?.domainConfig?.storeBrandingType ===
      StoreBrandingType.CustomDTCStore &&
    appConfig?.domainConfig?.customShopDomainName
      ? `https://${appConfig?.domainConfig?.customShopDomainName}/`
      : `/`;

  useEffect(() => {
    if (linkTo) {
      if (linkTo && linkTo.startsWith("http")) {
        window.open(linkTo, "_self");
      } else if (linkTo) {
        send({
          type: "SIGN_OUT",
          redirectPath: {
            pathname: linkTo,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkTo]);

  return <Loading fullscreen dot />;
}
