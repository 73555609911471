import { Backdrop, styled, useTheme } from "@mui/material";
import React from "react";
import { useCheckout } from "../../CheckoutProvider";
import SpinAnimation from "../../../../assets/lottie/fill_cycle_spin.json";
import LottieInner from "react-lottie";
import { progressLottieOptions } from "../../../../utils/lottieUtils";

// @ts-ignore
const Lottie = LottieInner.default
  ? // @ts-ignore
    LottieInner.default
  : LottieInner;

const BackdropContent = styled("div")(({ theme }) => ({
  maxWidth: 250,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 7,
  ".spinner": {
    width: 71,
    height: 71,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 4,
  },
  ".title": {
    coler: theme.palette.primary.main,
    textAlign: "center",
    fontSize: 16,
    fontWeight: 700,
  },
  ".subtitle": {
    coler: theme.palette.primary.main,
    textAlign: "center",
    fontSize: 14,
    fontWeight: 500,
  },
}));
const CheckoutLoadingOverlay = () => {
  const theme = useTheme();
  const {
    updateAddressStatus,
    updatingDelivery,
    updatePaymentStatus,
    removingOutOfStockProduct,
    submittingCheckout,
    loading,
  } = useCheckout();

  const updatingAddress = updateAddressStatus === "loading";
  const updatingPayment = updatePaymentStatus === "loading";

  const open = loading
    ? updatingAddress ||
      updatingDelivery ||
      updatingPayment ||
      removingOutOfStockProduct ||
      submittingCheckout
    : false;

  let title = "";
  let subtitle = "";
  if (updatingAddress) {
    title = "Give us just a second";
    subtitle = `We’re updating your shipping address`;
  } else if (updatingDelivery) {
    title = "Give us just a second";
    subtitle = `We’re updating your shipping choices`;
  } else if (updatingPayment) {
    title = "Give us just a second";
    subtitle = `We’re updating your payment method`;
  } else if (submittingCheckout) {
    title = "We’re placing your order";
    subtitle = `Stay tuned for a moment while we wrap up the last details`;
  } else if (removingOutOfStockProduct) {
    title = "Give us just a second";
    subtitle = `We’re updating your order`;
  }
  if (!open) {
    return null;
  }

  return (
    <Backdrop
      sx={{
        color: (theme) => theme.palette.primary.main,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "rgba(255, 255, 255, 0.95)",
      }}
      open={open}
      onClick={() => {}}
    >
      <BackdropContent>
        <div className="spinner">
          <Lottie
            style={{ position: "absolute" }}
            options={progressLottieOptions(theme, SpinAnimation)}
            isClickToPauseDisabled
            width={160}
            height={160}
          />
        </div>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </BackdropContent>
    </Backdrop>
  );
};

export default CheckoutLoadingOverlay;
