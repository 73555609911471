export interface PaymentMethod {
  cardNumber: string;
  cardType: string;
  cardExpiry: string;
  cardHolderName: string;
}
export enum UserApprovalStatus {
  PENDING = "pending",
  APPROVED = "approved",
  DELETED = "deleted",
}

export interface ShoppingCartItem {
  brandId: number;
  retailerSku: string;
  retailerId: number;
  stacklineSku: string;
  quantity: number;
  retailPrice: number;
  status?: string;
  errorMessage?: string;
  requestedQuantity?: number;

  userApprovalStatus?: UserApprovalStatus;
}

export interface UserRetailerShoppingCart {
  checkoutOrderId?: string;
  userId: string;
  shoppingCartId: string;
  retailerId: number;
  shippingAddress?: ShippingAddress;
  billingAddress?: ShippingAddress;
  paymentMethod?: PaymentMethod;
  items: ShoppingCartItem[];
  status:
    | "active"
    | "checkout_in_progress"
    | "checkout_success"
    | "checkout_error";
  statusMessage?: string;
  createdTime: Date;
  createdBy: string;
  updatedTime: Date;
  updatedBy: string;
}

export interface UserRetailerCheckoutBatch {
  userId: string;
  checkoutBatchId: string;

  retailerIds: number[];

  checkoutOrderIdsByRetailerId: {
    [retailerId: number]: string;
  };

  checkoutOrderStatusByRetailerId: {
    [retailerId: number]: UserRetailerCheckoutOrderStatus;
  };

  checkoutOrderStatusMessageByRetailerId: {
    [retailerId: number]: string | undefined;
  };
  createdTime: Date;

  createdBy: string;
  updatedTime: Date;

  updatedBy: string;
}

export interface UserRetailerCheckoutBatchStatus {
  status: string;
  userRetailerCheckoutBatch: UserRetailerCheckoutBatch;
}

export interface ShippingAddress {
  lastName: string;
  zipCode: string;
  country: string;
  address2: string;
  city: string;
  address1: string;
  userId: string;
  phoneNumber: string;
  extendedAttributes: {
    retailerId: number;
    isSelected: boolean;
  };
  firstName: string;
  id: string;
  state: string;
  updatedAt: any;
  createdAt: any;
}

// TODO: get these from @brandclub npm somehow?
export type UserRetailerCheckoutSessionStatus =
  // connect events
  | "queued"
  // checkout events
  | "clear_existing_cart_in_progress"
  | "clear_existing_cart_success"
  | "clear_existing_cart_failed"
  | "add_to_cart_in_progress"
  | "add_to_cart_success"
  | "add_to_cart_failed"
  | "navigate_to_checkout_in_progress"
  | "navigate_to_checkout_success"
  | "navigate_to_checkout_failed"
  | "retrieve_order_summary_in_progress"
  | "retrieve_order_summary_success"
  | "retrieve_order_summary_failed"
  | "add_to_cart_success"
  | "add_to_cart_failed"
  | "update_payment_method_in_progress"
  | "update_payment_method_success"
  | "update_payment_method_failed"
  | "update_shipping_address_in_progress"
  | "update_shipping_address_success"
  | "update_shipping_address_failed"
  | "update_shipping_option_in_progress"
  | "update_shipping_option_success"
  | "update_shipping_option_failed"
  | "checkout_waiting_for_user_input"
  | "user_requested_update_payment_method"
  | "user_requested_update_shipping_address"
  | "user_requested_update_shipping_option"
  | "user_requested_complete_checkout"
  | "user_requested_cancel_checkout"
  | "place_order_in_progress"
  | "place_order_success"
  | "place_order_failed"
  | "checkout_success"
  | "checkout_error"
  | "checkout_cancelled"
  | "checkout_timed_out";

export type UserRetailerCheckoutOrderStatus =
  | "queued"
  | "initializing"
  | "login_in_progress"
  | "login_success"
  | "login_failed"
  | "checkout_in_progress"
  | "checkout_success"
  | "checkout_cancelled"
  | "checkout_error"
  | "checkout_timed_out";

export interface UserRetailerCheckoutSession {
  errorMessage?: string;
  notificationEvents?: Set<string>;
  serverUpdatedAt: number;
  clientUpdatedAt: number;
  createdAt: number;
  updatedAt: number;
  status: UserRetailerCheckoutSessionStatus;
}

export interface ShippingGroup {
  products: {
    retailerSku: string;
    stacklineSku: string;
    name?: string;
    quantity: number;
    retailPrice: number;
  }[];
  deliveryOptions: {
    name: string;
    description: string;
    isSelected: boolean;
    offers: string[];
    cssSelector: string;
    deliveryTimeWindows: {
      name: string;
      description: string;
      isSelected: boolean;
      cssSelector: string;
    }[];
  }[];
}

export interface CanPlaceOrder {
  status: boolean;
  reasons?: {
    section: "shipping_address" | "payment_method";
    status: "enabled" | "disabled";
  }[];
}
export interface UserRetailerCheckoutOrder {
  userId: string;
  brandId: number;
  orderId: string;
  checkoutBatchId: string;
  checkoutId: string;
  createdTime: string;
  retailerId: number;
  status?: UserRetailerCheckoutOrderStatus;
  statusMessage?: string;
  items: ShoppingCartItem[];
  canPlaceOrder?: CanPlaceOrder;
  shippingAddress?: ShippingAddress;
  availableAddresses?: ShippingAddress[];
  billingAddress?: ShippingAddress;
  errorMessage?: string;
  availablePaymentMethods?: {
    cardHolderName: string;
    cardIssuer: string;
    cardNumberEnding: string;
    expirationDate: string;
    isSelected: boolean;
  }[];
  paymentMethod?: {
    cardNumberEnding: string;
    cardIssuer: string;
    cardHolderName: string;
    paymentConfirmationTypes: {
      type: "cvv" | "cardNumber" | "expirationDate" | "cardHolderName";
    }[];
  };
  shippingOptions?: {
    shippingGroups: ShippingGroup[];
  };
  orderSummary?: {
    subtotal: number;
    shippingFees: number;
    shippingDiscount: number;
    tax: number;
    total: number;
    giftCard?: number;
  };
}

// Order

interface CardInfo {
  cardNumber: string;
  cardType: string;
  expirationDate: string;
  cvv: string;
  cardHolderName: string;
}

interface DeliveryOptions {
  name: string;
  cssSelector: string;
}

interface DeliveryTimeWindow {
  name: string;
  cssSelector: string;
}

export interface UserRetailerCheckoutEvent {
  id: string;
  initiator: "client" | "server";
  timestamp: number;
  type:
    | "update-shipping-address"
    | "update-payment-method"
    | "update-delivery-option"
    | "confirm-checkout"
    | "cancel-checkout";
  status: "queued" | "processing" | "completed" | "failed";
  data:
    | {
        shippingAddress: ShippingAddress;
      }
    | {
        cardInfo: CardInfo;
        billingAddress: ShippingAddress;
      }
    | {
        deliveryOptions?: DeliveryOptions;
        deliveryTimeWindow?: DeliveryTimeWindow;
      };
}
