import { UserPurchaseHistory } from "../../types/misc";
import { formatMoney } from "../../utils/StringUtils";
import {
  ShippingAddress,
  ShoppingCartItem,
  UserRetailerCheckoutOrder,
  UserRetailerShoppingCart,
} from "./types";

const getTotal = (products: ShoppingCartItem[]) => {
  return products.reduce((acc, item) => {
    return acc + item.retailPrice * item.quantity;
  }, 0);
};

export const calculateTotalOfAllCarts = (
  carts: Partial<UserRetailerShoppingCart>[]
) => {
  const total = carts.reduce((acc, cart) => {
    return acc + getTotal(cart.items ?? []);
  }, 0);
  return formatMoney(total);
};

export const calculateTotal = (products: ShoppingCartItem[]) => {
  const total = getTotal(products);

  return formatMoney(total);
};

export const isItemCheckoutSuccess = (
  item: ShoppingCartItem | UserPurchaseHistory
) => {
  return !item.status || item.status === "success";
};

export const isPurchaseCheckoutSuccess = (
  p: UserRetailerCheckoutOrder | UserPurchaseHistory | undefined
) => {
  return p?.status === "checkout_success";
};

type ItemWithQuantity = {
  quantity?: number;
};
type ItemWithItems = {
  items?: ItemWithQuantity[];
};
export const sumItemQuantities = (
  items: (ItemWithQuantity | undefined)[] | undefined
) => {
  if (!items) {
    return 0;
  }
  return items.reduce((total, item) => total + (item?.quantity ?? 0), 0);
};

export const sumAllItemQuantities = (item: (ItemWithItems | undefined)[]) => {
  return item.reduce((total, i) => total + sumItemQuantities(i?.items), 0);
};

export const flattenCartItems = (carts: Partial<UserRetailerShoppingCart>[]) =>
  carts
    .flatMap((c) => c.items)
    .filter((i): i is ShoppingCartItem => Boolean(i));

export const createRetailerSkuMap = (
  carts: Partial<UserRetailerShoppingCart>[]
) => new Map(flattenCartItems(carts).map((item) => [item.retailerSku, item]));

export const buildAddressString = (
  shippingAddress?: Partial<ShippingAddress>
): string => {
  const address = [
    shippingAddress?.address1,
    shippingAddress?.address2,
    shippingAddress?.city,
    shippingAddress?.state,
  ]
    .map((str) => str?.trim())
    .filter((str) => !!str)
    .join(", ");
  const zipCode = shippingAddress?.zipCode?.trim();
  if (zipCode) {
    return address + " " + zipCode;
  }
  return address;
};
