import { BrandAppConfig } from "../../../redux/types";
import axios from "../../../utils/axios";
const getErrorMessage = (e: unknown): string => {
  return e instanceof Error ? e.message : String(e);
};

export const getBrandAppConfig = async (req: { appId: string }) => {
  const res = await axios.post<BrandAppConfig>(
    "/brandapp/getBrandAppConfig",
    req
  );
  return res?.data;
};

export interface ThemeResponse {
  primary: string;
  secondary: string;
  tertiary: string;
  font: {
    fontFamily: string;
  };
}

export const getCustomTheme = async (): Promise<ThemeResponse | undefined> => {
  const appId = window.location.origin.split("https://")[1];
  const { data } = await axios.post("/brandapp/getDTCAppTheme", {
    appId: appId,
  });

  return new Promise<any>((resolve, reject) => {
    if (data) {
      resolve(data);
    } else {
      reject("No data found");
    }
  }).catch((e) => {
    console.error(`Error retrieving custom theme: ${getErrorMessage(e)}`);
    return undefined;
  });
};

export const getAppConfigFromServer = async () => {
  const { data } = await axios.get(`/config/webApp/getAppConfig`, {});

  if (data) {
    return {
      appConfig: {
        authConfig: data.authConfig,
        domainConfig: data.domainConfig,
      },
    };
  }

  return {
    appConfig: undefined,
  };
};
