import React, { ComponentProps, FC } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  ButtonProps as CommonUiButtonProps,
} from "@brandclub/common-ui";
import { TABLET_SCREEN_SIZE } from "../../AppNavigation/constants";
import { joinClassNames } from "../../../utils/StringUtils";
import { RedirectLoginPath, useStoreLogin } from "./useStoreLogin";
import { LinkProps } from "react-router-dom";
import { StyledLink } from "../../AppNavigation/StyledLink";

export const LoginButtonsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 15,
  width: "100%",
}));

const StyledButton = styled(Button)({
  height: 50,
  width: "100%",
  fontSize: 16,
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    fontSize: 14,
  },
});

interface ButtonProps extends CommonUiButtonProps {
  trackedAdditionalData?: Record<string, unknown>;
}

export const SignInButton: FC<ButtonProps> = ({
  className,
  trackedAdditionalData,
  onClick,
  ...props
}) => {
  return (
    <StyledButton
      onClick={(event) => {
        onClick?.(event);
      }}
      {...props}
      className={joinClassNames(className, "login-button")}
    />
  );
};

interface CreateAccountLinkProps extends LinkProps {
  redirectPath?: RedirectLoginPath;
  trackedAdditionalData?: Record<string, unknown>;
}

export const CreateAccountButton: FC<ButtonProps> = ({
  className,
  children,
  onClick,
  trackedAdditionalData,
  ...props
}) => {
  const { createAccount } = useStoreLogin();

  return (
    <StyledButton
      {...props}
      onClick={(event) => {
        onClick ? onClick(event) : createAccount();
      }}
      variant="outline"
      className={joinClassNames(className, "login-button")}
    >
      {children ?? "Sign up"}
    </StyledButton>
  );
};

export const CreateAccountLink: FC<CreateAccountLinkProps> = ({
  children,
  onClick,
  redirectPath,
  trackedAdditionalData,
  ...props
}) => {
  const { createAccount } = useStoreLogin({ redirectPath });

  return (
    <StyledLink
      {...props}
      onClick={(e) => {
        e.preventDefault();
        createAccount();
        onClick?.(e);
      }}
    >
      {children ?? "Sign up"}
    </StyledLink>
  );
};

export interface LoginButtonsProps
  extends ComponentProps<typeof LoginButtonsWrapper> {
  onSignInClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCreateAccountClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  createAccountCta?: string;
  trackedAdditionalData?: Record<string, unknown>;
}

const LoginButtons: FC<LoginButtonsProps> = ({
  onSignInClick,
  children,
  createAccountCta,
  onCreateAccountClick,
  trackedAdditionalData,
  ...props
}) => {
  return (
    <LoginButtonsWrapper {...props}>
      <SignInButton
        trackedAdditionalData={trackedAdditionalData}
        onClick={onSignInClick}
      >
        {children ?? "Sign in"}
      </SignInButton>
      <CreateAccountButton
        trackedAdditionalData={trackedAdditionalData}
        onClick={onCreateAccountClick}
      >
        {createAccountCta}
      </CreateAccountButton>
    </LoginButtonsWrapper>
  );
};

export default LoginButtons;
