import { useEffect, useRef } from "react";
import FlowContainer from "../../../pages/AppPages/Pages/components/FlowContainer";
import { useCheckout } from "../../CheckoutProvider";

import ChooseMFAOption from "../../../pages/AppPages/Sync/MFA/ChooseMFAOption";

const CheckoutMFAChoose = () => {
  const { MFAChooseRetailerSyncSession } = useCheckout();
  // used to poll the status of MFAEnterRetailerSyncSession after updating OTP
  const CheckMFAChooseRetailerSyncSessionTimeoutRef =
    useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const timeout = CheckMFAChooseRetailerSyncSessionTimeoutRef.current;
    return () => {
      if (timeout) {
        // cleanup timeout when component unmounts
        clearTimeout(timeout);
      }
    };
  }, []);

  const onCompleteCallback = async () => {
    return new Promise<void>((resolve) => {
      function checkMFAChooseStatus() {
        if (!MFAChooseRetailerSyncSession) {
          resolve();
        } else {
          CheckMFAChooseRetailerSyncSessionTimeoutRef.current = setTimeout(
            checkMFAChooseStatus,
            100
          ); // Poll every 100ms
        }
      }
      checkMFAChooseStatus();
    });
  };

  if (!MFAChooseRetailerSyncSession) {
    return null;
  }

  return (
    <FlowContainer>
      <ChooseMFAOption
        retailerId={MFAChooseRetailerSyncSession.retailerId}
        retailerSyncSession={MFAChooseRetailerSyncSession}
        onCompleteCallback={onCompleteCallback}
      />
    </FlowContainer>
  );
};

export default CheckoutMFAChoose;
