import {
  NotificationActiveIcon,
  PersonIcon,
  SmartphoneIcon,
} from "@brandclub/common-ui";
import { Link } from "react-router-dom";
import { HeaderLink, NAV_ATTACHMENT_SIZE } from ".";
import { useAppSelector } from "../../../redux/hooks";
import NavCartButton from "../Buttons/NavCartButton";
import { useSyncContext } from "../../SyncProvider";

import pluralize from "pluralize";
import { useCheckout } from "../../Checkout/CheckoutProvider";

const formatUserDisplayName = (name: string) => {
  return `Hi, ${name}.`;
};

const AuthAttachment = ({ onClose }: { onClose?: (e: any) => void }) => {
  // const { rewards } = useAppSelector(({ rewards }) => rewards);
  //   const currentBalance = rewards.currentBalance;
  const userName = useAppSelector(({ userProfile }) => userProfile?.given_name);
  const { syncStatus, latestSyncBatchId, sessionRewardSummaryDisplayStatus } =
    useSyncContext();
  const { cartCount } = useCheckout();
  const shoppingCartLabel =
    cartCount > 0 ? pluralize("item", cartCount, true) : "My Bag";
  return (
    <>
      {syncStatus === "normal" &&
      latestSyncBatchId &&
      sessionRewardSummaryDisplayStatus !== "viewed" ? (
        <Link
          to={`/sync/summary/${latestSyncBatchId}`}
          className="link"
          onClick={onClose}
        >
          <HeaderLink>
            <NotificationActiveIcon sx={{ fontSize: NAV_ATTACHMENT_SIZE }} />
            <div className="label">Sync summary</div>
          </HeaderLink>
        </Link>
      ) : null}
      <Link to={"/mybag"} className="link" onClick={onClose}>
        <HeaderLink>
          <NavCartButton id="nav_cart_button" />
          <div className="label">{shoppingCartLabel}</div>
        </HeaderLink>
      </Link>

      <Link to={"/dashboard/me"} className="link" onClick={onClose}>
        <HeaderLink>
          <PersonIcon sx={{ fontSize: NAV_ATTACHMENT_SIZE }} />
          {userName && (
            <div className="label">{formatUserDisplayName(userName)}</div>
          )}
        </HeaderLink>
      </Link>
      <Link to={"/getApp"} className="link" onClick={onClose}>
        <HeaderLink>
          <SmartphoneIcon
            sx={{
              fontSize: NAV_ATTACHMENT_SIZE,
            }}
          />
          <div className="label">Get the app</div>
        </HeaderLink>
      </Link>
    </>
  );
};
export default AuthAttachment;
