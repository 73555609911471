import { LandingHeader } from "../components/Header";
import { useFetchContent } from "./data";
import { LongCardGrid } from "../components/CardGrid";
import ContentCard from "./components/ContentCard";
import LandingPageWrapper from "../components/LandingPageWrapper";
import { useEffect, useRef } from "react";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { useInView } from "framer-motion";
import RewardEmptyPage from "../components/RewardEmptyPage";
import LandingContainer from "../components/LandingContainer";

const ContentLanding = ({ brandclubIds }: { brandclubIds?: string[] }) => {
  const { data, loading, loadingMore, fetchMore, hasMore } = useFetchContent({
    pageSize: 20,
    brandclubIds,
  });

  const ref = useRef<any>(null);

  const widgetInView = useInView(ref);

  useEffect(() => {
    if (widgetInView && !loading && !loadingMore && hasMore) {
      fetchMore();
    }
  }, [widgetInView, loading, loadingMore, hasMore, fetchMore]);

  if (!loading && data.length === 0) {
    return <RewardEmptyPage emptyTitle="No content rewards?" />;
  }

  return (
    <LandingPageWrapper>
      <LandingContainer>
        {loading ? (
          <StoreLoadingSkeleton cardStyle="longCard" header row={2} />
        ) : (
          <>
            <LandingHeader
              title="Content rewards"
              subtitle="Earn rewards for viewing content"
            ></LandingHeader>
            <LongCardGrid style={{ marginTop: 20 }}>
              {data.map((d) => (
                <ContentCard key={d.campaignId} content={d} border />
              ))}
            </LongCardGrid>
          </>
        )}
        {loadingMore ? (
          <div style={{ marginTop: 20 }}>
            <StoreLoadingSkeleton cardStyle="longCard" header={false} />
          </div>
        ) : null}
        <div className="end_detect" ref={ref}></div>
      </LandingContainer>
    </LandingPageWrapper>
  );
};

export default ContentLanding;
