import { styled } from "@mui/material";
import {
  OutlineButton,
  SolidButton,
} from "../../../StoreComponents/StoreButton";
import { hexadecimal } from "@brandclub/common-ui";
import { useAppSelector } from "../../../../redux/hooks";
import { SignUpCard } from "./MemberComponents/SignUpCard";
import { RewardCard } from "./MemberComponents/RewardCard";
import { CashOutCard } from "./MemberComponents/CashOutCard";
import {
  MOBILE_HORIZONTAL_PADDING,
  MOBILE_SCREEN_SIZE,
  TABLET_HORIZONTAL_PADDING,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import { Link } from "react-router-dom";

const SignUpButton = styled(SolidButton)(({ theme }) => ({
  width: 196,
  height: 58,
  fontSize: 20,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    width: 126,
  },
}));

const SignInButton = styled(OutlineButton)(({ theme }) => ({
  width: 196,
  height: 58,
  fontSize: 20,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    width: 126,
  },
}));

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  // paddingTop: 80,
  // paddingBottom: 120,
  padding: `80px 5% 80px 5%`,
  boxSizing: "border-box",

  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    padding: `50px ${TABLET_HORIZONTAL_PADDING}px 80px ${TABLET_HORIZONTAL_PADDING}px`,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: `50px ${MOBILE_HORIZONTAL_PADDING}px 80px ${MOBILE_HORIZONTAL_PADDING}px`,
  },
  backgroundColor: hexadecimal(theme.palette.secondary.main)(20),
  ".header": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 64.5,
    maxWidth: "1400px",
    ".title": {
      fontSize: 32,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: 15,
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 24,
      },
    },
    ".subtitle": {
      fontSize: 20,
      color: theme.palette.primary.main,
      marginBottom: 30,
      textAlign: "center",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 16,
      },
    },
    ".button_group": {
      display: "flex",
      gap: 20,
    },
  },
  ".blocks": {
    maxWidth: "1400px",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    width: "100%",
    justifyItems: "center",
    gap: "15px",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      gridTemplateColumns: "repeat(1, 1fr)",
      gap: 60,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gridTemplateColumns: "repeat(1, 1fr)",
      gap: 60,
    },
  },
}));

const BlockContainer = styled("div")(({ theme }) => ({
  width: 326,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  ".card-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: 40,
    width: "100%",
    height: 275,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      marginBottom: 24,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      marginBottom: 24,
    },
  },
  ".reward-card-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    marginBottom: 40,
    height: 275,
    width: "100%",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      marginBottom: 24,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      marginBottom: 24,
    },
  },
  ".cashout-container": {
    height: 275,
    marginBottom: 40,
    width: "95%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      marginBottom: 24,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      marginBottom: 24,
    },
  },
  ".title": {
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginBottom: 10,
    textAlign: "center",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 20,
    },
  },
  ".subtitle": {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.primary.main,
    textAlign: "center",
    padding: "0px 40px",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 14,
    },
  },
}));

const SignUpBlock = () => {
  return (
    <BlockContainer>
      <div className="card-container">
        <SignUpCard text="Phone number" placeholderLength={200} />
        <SignUpCard text="Email" placeholderLength={115} />
        <SignUpCard text="Name" placeholderLength={156} />
      </div>
      <div className="title">Sign up</div>
      <div className="subtitle">
        Get started by creating a free membership account.
      </div>
    </BlockContainer>
  );
};
const ShopBlock = () => {
  const { appConfig } = useAppSelector((state) => state);

  return (
    <BlockContainer>
      <div className="reward-card-container">
        <RewardCard title="Purchase Reward" rewardAmount={3} />
        <RewardCard
          title="Survey Reward"
          subtitle="Newest Product Release"
          rewardAmount={2}
          image={appConfig?.membershipPageConfig?.surveyPicture}
          imageStyle={{ borderRadius: "100%" }}
        />
      </div>
      <div className="title">Shop and engage</div>
      <div className="subtitle">
        Earn rewards with us online or in your favorite retail stores.
      </div>
    </BlockContainer>
  );
};
const CashBlock = () => {
  const appConfig = useAppSelector((state) => state.appConfig);

  return (
    <BlockContainer>
      <div className="cashout-container">
        <CashOutCard logoImage={appConfig?.membershipPageConfig?.logoPicture} />
      </div>
      <div className="title">Cash out</div>
      <div className="subtitle">Send your cash rewards to PayPal or Venmo.</div>
    </BlockContainer>
  );
};

const ClubSection = () => {
  const linkTo = "/signIn";
  const appConfig = useAppSelector((state) => state.appConfig);
  const brandName = appConfig?.domainConfig.brandName;

  return (
    <Container>
      <div className="header">
        <div className="title">Join the club</div>
        <div className="subtitle">
          Earn cash rewards when you shop and engage with {brandName}.
        </div>
        <div className="button_group">
          <Link to={linkTo} style={{ textDecoration: "none" }}>
            <SignUpButton>Sign up</SignUpButton>
          </Link>
          <Link to={linkTo} style={{ textDecoration: "none" }}>
            <SignInButton>Sign in</SignInButton>
          </Link>
        </div>
      </div>
      <div className="blocks">
        <SignUpBlock />
        <ShopBlock />
        <CashBlock />
      </div>
    </Container>
  );
};

export default ClubSection;
