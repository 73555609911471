import { useEffect } from "react";
import GenericDialogNoClose, {
  DialogButtonAction,
} from "../../../StoreComponents/StoreDialogs/GenericDialogNoClose";
import { useCheckout } from "../../CheckoutProvider";
import { useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { getRetailerFromId } from "@brandclub/common-ui";
import { useIsAllOutOfStock } from "./AllOutOfStockScreen";

const CheckoutErrorDialog = () => {
  const order = useAppSelector((state) => state.checkout.order);
  const batch = useAppSelector((state) => state.checkout.batch);

  const { allOutOfStock, showFullSrceen } = useIsAllOutOfStock();
  const { checkoutNextOrder, setLoading, endCheckout, initiateCartCheckout } =
    useCheckout();
  const navigate = useNavigate();

  const orderStatus = order?.userRetailerCheckoutOrder?.status;
  const shouldShowError =
    orderStatus === "checkout_timed_out" ||
    orderStatus === "checkout_error" ||
    orderStatus === "checkout_cancelled";

  const errorMessage = order?.userRetailerCheckoutOrder?.errorMessage;

  const goBack = () => {
    endCheckout();
    navigate("/mybag");
  };

  const isTimeOutError = errorMessage === "Timeout waiting for user input";

  const shouldShowGenericError =
    shouldShowError && !isTimeOutError && !allOutOfStock;

  const currentRetailerId = order?.userRetailerCheckoutOrder?.retailerId ?? 0;
  const index = batch?.retailerIds.indexOf(currentRetailerId);

  const nextRetailerId = batch?.retailerIds?.[(index ?? 0) + 1];

  const timeOutErrorTitle = "Your session has timed out";
  const timeOutErrorDescription =
    "Its been a bit, so we need to confirm your products are in stock again to process this order.";

  const genericErrorTitle = "Let’s try that again";
  const genericErrorDescription =
    "We’ve encountered an issue trying to process your order. Please make a selection below.";

  const errorTitle = isTimeOutError ? timeOutErrorTitle : genericErrorTitle;
  const errorDescription = isTimeOutError
    ? timeOutErrorDescription
    : genericErrorDescription;

  const handleOpen = () => {
    const actions: DialogButtonAction[] = [];

    actions.push({
      variant: "outline",
      text: "Back to my bag",
      onClick: goBack,
    });

    if (nextRetailerId) {
      actions.push({
        variant: "solid",
        text: `Checkout ${getRetailerFromId(nextRetailerId)} order`,
        onClick: () => {
          setLoading(true);
          checkoutNextOrder();
        },
      });
    } else {
      actions.push({
        variant: "solid",
        text: "Refresh my order",
        onClick: () => {
          initiateCartCheckout(
            order?.userRetailerCheckoutOrder?.retailerId as number,
            batch?.retailerIds as number[],
            batch?.checkoutBatchId
          );
        },
      });
    }
    GenericDialogNoClose.show({
      title: errorTitle,

      trackingParams: { errorMessage, order },
      text: errorDescription,
      actions: actions,
    });
  };

  const handleOpenAllOutOfStock = () => {
    const actions: DialogButtonAction[] = [];
    if (nextRetailerId) {
      actions.push({
        variant: "solid",
        text: `Continue ${getRetailerFromId(nextRetailerId)} order`,
        onClick: () => {
          setLoading(true);
          checkoutNextOrder();
        },
      });
    }
    actions.push({
      variant: "outline",
      text: "Back to my bag",
      onClick: goBack,
    });

    GenericDialogNoClose.show({
      title: "Out of stock items",

      trackingParams: { order },
      text: `Your order from ${getRetailerFromId(
        order?.userRetailerCheckoutOrder?.retailerId as number
      )} couldn’t be processed because all items were out of stock.`,
      actions: actions,
    });
  };
  const handleClose = () => {
    GenericDialogNoClose.hide();
  };

  useEffect(() => {
    if (shouldShowGenericError || isTimeOutError) {
      handleOpen();
    } else {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowGenericError, isTimeOutError]);

  useEffect(() => {
    if (allOutOfStock && !showFullSrceen) {
      handleOpenAllOutOfStock();
    } else if (!showFullSrceen) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOutOfStock, showFullSrceen]);

  return <></>;
};

export default CheckoutErrorDialog;
