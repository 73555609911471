import React, { ReactNode, Suspense, useEffect } from "react";

import {
  AnalyticsTrackingEvent,
  BRANDCLUB_BRAND_ID,
  CustomThemeProvider,
  useFingerprintJSContext,
} from "@brandclub/common-ui";
import { styled } from "@mui/material/styles";
import { AnimatePresence } from "framer-motion";
import CookiesProvider from "react-cookie/cjs/CookiesProvider";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import { LocationTrackingContextProvider } from "./providers/LocationTrackingProvider";

import StoreThemeProvider from "./providers/StoreThemeProvider";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useTrackViewEvent } from "../utils/hooks/useTracking";
import { updateVisitorTracking } from "../redux/reducers/visitorTracking";
import { StoreBrandingType } from "../types/misc";
import { AnalyticsService } from "@brandclub/common-ui";
import { removeFunctionFields } from "../utils/misc";
import ScrollToTop from "../utils/routing/ScrollToTop";
import CheckoutProvider from "./Checkout/CheckoutProvider";
import FaviconManager from "./FaviconManager";
import { SiteProvider } from "./SiteProvider";
import AppDialogs from "./StoreComponents/StoreDialogs";
import StoreFullScreenLoading from "./StoreComponents/StoreFullScreenLoading";
import StoreSinglePageOutlet from "./StoreComponents/StoreSinglePageOutlet";
import { SyncProvider } from "./SyncProvider";
import AppMainPageOutlet from "./pages/AppPages/AppMainPageOutlet";
import AuthOutlet from "./pages/Auth/AuthOutlet";
import AuthSignUpPageOutlet from "./pages/Auth/AuthSignUpPageOutlet";

import UserLoginProvider from "./pages/Auth/UserLoginProvider";
import {
  appPageRoutes,
  brandstoreLandingRoutes,
  dtcCommonAppPageRoutes,
  fallbackRoutes,
} from "./routes/appPageRoutes";
import { authRoutes } from "./routes/authRoutes";
import { dashboardRoutes, dtcDashboardRoutes } from "./routes/dashboardRoutes";
import { appGeneralRoutes, generalRoutes } from "./routes/generalRoutes";
import { buildRoutes } from "./routes/routeBuilder";
import {
  dtcCheckoutRoutes,
  dtcSinglePageRoutes,
  membershipProgramRoutes,
  singlePageCustomCloseRoutes,
  singlePageRoutes,
} from "./routes/singlePageRoutes";
import { connectRoutes, syncRoutes } from "./routes/syncRoutes";
import DtcFooterOutlet from "./pages/AppPages/DtcFooterOutlet";
import { useCustomTheme } from "../utils/hooks/useCustomTheme";

const StyledMain = styled("main")((theme) => ({
  overflow: "hidden",
}));

const RouteProviders = () => {
  const theme = useCustomTheme();
  const customTheme = theme?.theme;
  const storeTheme = theme?.storeTheme;
  return (
    <>
      <ScrollToTop />
      <CustomThemeProvider themeOptions={customTheme}>
        <StoreThemeProvider theme={storeTheme}>
          <LocationTrackingContextProvider>
            <TrackPageViewContextProvider>
              <CookiesProvider>
                <Suspense fallback={<StoreFullScreenLoading />}>
                  <UserLoginProvider>
                    <CheckoutProvider>
                      <AuthOutlet>
                        <SiteProvider>
                          <SyncProvider>
                            <AppDialogs />
                            <FaviconManager />
                            <Outlet />
                          </SyncProvider>
                        </SiteProvider>
                      </AuthOutlet>
                    </CheckoutProvider>
                  </UserLoginProvider>
                </Suspense>
              </CookiesProvider>
            </TrackPageViewContextProvider>
          </LocationTrackingContextProvider>
        </StoreThemeProvider>
      </CustomThemeProvider>
    </>
  );
};

const UniversalStoreRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<RouteProviders />}>
        {buildRoutes(generalRoutes)}
        <Route element={<StoreSinglePageOutlet />}>
          <Route element={<AuthSignUpPageOutlet />}>
            <Route>{buildRoutes(authRoutes)}</Route>
          </Route>
        </Route>

        <Route element={<AppMainPageOutlet />}>
          {/*app pages */}
          {buildRoutes(brandstoreLandingRoutes)}
          {buildRoutes(appGeneralRoutes)}
          {buildRoutes(appPageRoutes)}
          {buildRoutes(syncRoutes)}
          {buildRoutes(dashboardRoutes)}
        </Route>

        <Route element={<StoreSinglePageOutlet />}>
          {buildRoutes(singlePageRoutes)}
        </Route>

        <Route
          element={
            <StoreSinglePageOutlet
            // closeAction="close"
            // handleCloseClick={(navigate) => navigate(-1)}
            />
          }
        >
          {buildRoutes(singlePageCustomCloseRoutes)}
        </Route>

        {buildRoutes(fallbackRoutes)}
      </Route>
    </Route>
  )
);

const DtcRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<RouteProviders />}>
        {buildRoutes(generalRoutes)}
        <Route element={<StoreSinglePageOutlet />}>
          <Route element={<DtcFooterOutlet />}>
            {buildRoutes(dtcDashboardRoutes)}
            {buildRoutes(dtcCommonAppPageRoutes)}
            {buildRoutes(syncRoutes)}
            {buildRoutes(connectRoutes)}
            {buildRoutes(membershipProgramRoutes)}
          </Route>
        </Route>
        <Route element={<StoreSinglePageOutlet />}>
          <Route element={<AuthSignUpPageOutlet />}>
            <Route>{buildRoutes(authRoutes)}</Route>
          </Route>
          {buildRoutes(dtcCheckoutRoutes)}
        </Route>

        <Route element={<StoreSinglePageOutlet />}>
          {buildRoutes(dtcSinglePageRoutes)}
        </Route>
        {buildRoutes(fallbackRoutes)}
      </Route>
    </Route>
  )
);

const TrackPageViewContext = React.createContext<
  (data: { pageType: string; pageId: string }) => void
>(() => {});

export const useAnalyticsTrackPageView = (
  pageType?: string,
  pageId?: string,
  additionalData?: any
) => {
  const trackPageView = React.useContext(TrackPageViewContext);
  return () => {
    trackPageView({ pageType, pageId, ...additionalData });
  };
};
const TrackPageViewContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const trackPageView = useTrackViewEvent<{ pageType: string; pageId: string }>(
    AnalyticsTrackingEvent.PAGE_VIEW
  );
  return (
    <TrackPageViewContext.Provider value={trackPageView}>
      {children}
    </TrackPageViewContext.Provider>
  );
};

const RoutesSwitch = () => {
  const appConfig = useAppSelector(({ appConfig }) => appConfig);
  // const [, send] = useActor(React.useContext(UserLoginContext).service);
  // const { endCheckout } = useCheckout();

  if (
    appConfig?.domainConfig.brandId !== BRANDCLUB_BRAND_ID &&
    appConfig?.domainConfig.storeBrandingType ===
      StoreBrandingType.CustomDTCStore
  ) {
    return (
      <AnimatePresence mode="wait">
        <RouterProvider router={DtcRoutes} />
      </AnimatePresence>
    );
  }

  return (
    <AnimatePresence mode="wait">
      <RouterProvider router={UniversalStoreRoutes} />
    </AnimatePresence>
  );
};

const AppRoutes = () => {
  const visitorTracking = useFingerprintJSContext();
  const userProfile = useAppSelector((state) => state.userProfile);
  // const userProfile = useAppSelector((state) => state.userProfile);
  const dispatch = useAppDispatch();
  useEffect(() => {
    // console.log("visitorTracking", visitorTracking);
    // console.log("userProfile", userProfile);
    // Mixpanel tracking
    try {
      if (!AnalyticsService.isMixpanelInitialized()) {
        AnalyticsService.initializeMixpanel();
      }
      AnalyticsService.setCurrentVisitorTracking(visitorTracking);
      AnalyticsService.setMixpanelUserIdentity(
        visitorTracking,
        userProfile as any
      );
      if (visitorTracking?.uniqueVisitorId) {
        dispatch(updateVisitorTracking(removeFunctionFields(visitorTracking)));
      }
    } catch (e) {
      console.warn(e);
    }
  }, [dispatch, visitorTracking, userProfile]);

  return (
    <StyledMain>
      <RoutesSwitch />
    </StyledMain>
  );
};

export default AppRoutes;
