import OnBoardingConnect from "../pages/Auth/Connect";
import OnBoardingConnectRetailer from "../pages/Auth/OnBoardingConnectRetailer";
import OnBoardingJoinClub from "../pages/Auth/OnBoardingJoinClub";
import ConnectRetailerInProgress from "../pages/Auth/Connect/ConnectRetailerInProgress";
import ConnectRetailerOTP from "../pages/Auth/Connect/ConnectRetailerOTP";
import { OnBoardingSuccess } from "../pages/Auth/OnBoardingSuccess";
import SignIn from "../pages/Auth/SignIn";
import SignInVerify from "../pages/Auth/SignInVerify";
import { StoreNavAction } from "../StoreComponents/StoreSinglePageOutlet";
import { AppRoutesWithChildrenProps } from "./routeBuilder";
import SetupUserProfile from "../pages/Auth/SetupUserProfile";

export const authRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "signIn/",
    element: <SignIn />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "Auth",
    pageId: "SignIn",
  },
  {
    path: "signInVerify/",
    element: <SignInVerify />,
    handle: {
      pageNavConfig: {
        back: StoreNavAction.SentBack,
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "Auth",
    pageId: "SignInVerify",
  },
  {
    path: "setupUserProfile/",
    element: <SetupUserProfile />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.SignOut,
      },
    },
    pageType: "Auth",
    pageId: "SetupUserProfile",
  },
  {
    path: "joinBrandclubs/",
    element: <OnBoardingJoinClub />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.SignOut,
      },
    },
    pageType: "OnBoarding",
    pageId: "JoinBrandclubs",
  },
  {
    path: "connectRetailers/",
    element: <OnBoardingConnectRetailer />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.SignOut,
      },
    },
    pageType: "OnBoarding",
    pageId: "ConnectRetailers",
  },
  {
    path: "authenticateWithRetailer/",
    element: <OnBoardingConnect />,
    pageType: "OnBoarding",
    pageId: "AuthenticateWithRetailer",
  },
  {
    path: "connectRetailerInProgress",
    element: <ConnectRetailerInProgress />,
    pageType: "ConnectRetailer",
    pageId: "ConnectRetailerInProgress",
  },
  {
    path: "connectRetailerOTP",
    element: <ConnectRetailerOTP />,
    pageType: "ConnectRetailer",
    pageId: "ConnectRetailerOTP",
  },
  {
    path: "success",
    element: <OnBoardingSuccess />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "OnBoarding",
    pageId: "OnBoardingSuccess",
  },
];
