import { styled, useTheme } from "@mui/material";
import { SolidButton } from "../../../StoreComponents/StoreButton";
import { CheckCircleIcon } from "@brandclub/common-ui";
import {
  MOBILE_HORIZONTAL_PADDING,
  MOBILE_SCREEN_SIZE,
  TABLET_HORIZONTAL_PADDING,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import { Link } from "react-router-dom";

const blocks = [
  {
    title: "Purchase rewards",
    subtitle: "Get cash back when you make purchases.",
  },
  {
    title: "Survey rewards",
    subtitle: "Get paid to share feedback and take surveys.",
  },
  {
    title: "Affiliate rewards",
    subtitle: "Earn commission for each sale you refer.",
  },
  {
    title: "Content rewards",
    subtitle: "Earn rewards to watch videos and learn about us.",
  },
  {
    title: "Referral rewards",
    subtitle: "Get paid for each club member you refer.",
  },
  {
    title: "Membership rewards",
    subtitle: "Get rewards and member perks when you join.",
  },
  {
    title: "Connect rewards",
    subtitle: "Get rewards for connecting your retailer accounts.",
  },
  {
    title: "Daily deal rewards",
    subtitle: "Get exclusive access to limited-time deals.",
  },
];

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  padding: `0 5%`,
  boxSizing: "border-box",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    padding: `0 ${TABLET_HORIZONTAL_PADDING}px`,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: `0 ${MOBILE_HORIZONTAL_PADDING}px`,
  },
  ".title": {
    fontSize: 32,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginBottom: 15,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 24,
    },
  },
  ".subtitle": {
    fontSize: 20,
    color: theme.palette.primary.main,
    marginBottom: 56,
    maxWidth: 660,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 16,
      marginBottom: 40,
    },
  },
  ".blocks": {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "41px 100px",
    marginBottom: 54,
    maxWidth: 660,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gridTemplateColumns: "repeat(1, 1fr)",
      gap: "45px",
      marginBottom: 40,
    },
  },
  ".bottom_subtitle": {
    fontSize: 20,
    color: theme.palette.primary.main,
    marginBottom: 30,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 16,
    },
  },
}));

const BecomeAMemberButton = styled(SolidButton)({
  width: "fit-content",
  paddingLeft: 50,
  paddingRight: 50,
  height: 58,
  fontSize: 20,
});

const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  maxWidth: 30,
  maxHeight: 30,
  width: 30,
  height: 30,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    maxWidth: 30,
    maxHeight: 30,
    width: 30,
    height: 30,
  },
});

const BlockContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    gap: "14px",
  },
  ".info": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    maxWidth: 240,
    ".block_label": {
      fontSize: 20,
      fontWeight: 700,
      color: theme.palette.primary.main,
      textAlign: "left",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 20,
      },
    },
    ".block_sublabel": {
      fontWeight: 500,
      fontSize: 16,
      color: theme.palette.primary.main,
      textAlign: "left",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 14,
      },
    },
  },
}));

const Block = ({ title, subtitle }: { title: string; subtitle: string }) => {
  const theme = useTheme();
  return (
    <BlockContainer>
      <div className="check">
        <StyledCheckCircleIcon
          backgroundColor={theme.palette.secondary.main}
          htmlColor={theme.palette.primary.main}
        />
      </div>
      <div className="info">
        <div className="block_label">{title}</div>
        <div className="block_sublabel">{subtitle}</div>
      </div>
    </BlockContainer>
  );
};

const ProgramSection = () => {
  const linkTo = "/signIn";
  return (
    <Container>
      <div className="title">The ultimate rewards program</div>
      <div className="subtitle">
        Get rewarded when you shop and engage with us on our website, or on
        Amazon, Walmart, and other major retailers.
      </div>
      <div className="blocks">
        {blocks.map((block) => (
          <Block
            title={block.title}
            subtitle={block.subtitle}
            key={block.title}
          />
        ))}
      </div>
      <Link to={linkTo} style={{ textDecoration: "none" }}>
        <BecomeAMemberButton>Become a member</BecomeAMemberButton>
      </Link>
    </Container>
  );
};

export default ProgramSection;
