import { Outlet } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import axios from "../../../utils/axios";
import { AppMainNavigation } from "../../AppNavigation";
import { useSiteContext } from "../../SiteProvider";
import { withFadeTransition } from "../../StoreComponents/StoreTransition";
import { buildFooterConfig } from "./BrandStore/config";
import { useEffect, useMemo, useState } from "react";
import { BrandclubFooter } from "@brandclub/common-ui";
import { sumCampaignRewardCounts } from "../../../utils/misc";
import { SiteBrandingType } from "../../../types/misc";
import ConfigUtils from "../../../utils/ConfigUtils";
import reduxApolloClient from "../../../redux/reduxApolloClient";
import { getRewardAmountsByStatus } from "../../../api";
import AppLink from "../../AppNavigation/AppLink";

const AppMainPageOutlet = () => {
  const { siteBrandingType, site, persistentQueryParams, routePrefix } =
    useSiteContext();
  const appConfig = useAppSelector(({ appConfig }) => appConfig);
  const userProfile = useAppSelector(({ userProfile }) => userProfile);
  const [rewardSummaryForBrand, setRewardSummaryForBrand] = useState<{
    lifetimeEarnings: number;
    lifetimePayouts: number;
    lifetimeAdjustments: number;
    lockedBalance: number;
    pendingBalance: number;
    unlockedBalance: number;
    cancelledBalance: number;
    hasLoaded: boolean;
  }>({
    lifetimeEarnings: 0,
    lifetimePayouts: 0,
    lifetimeAdjustments: 0,
    lockedBalance: 0,
    pendingBalance: 0,
    unlockedBalance: 0,
    cancelledBalance: 0,
    hasLoaded: false,
  });
  const currentBalance = useAppSelector(
    ({ rewards }) => rewards?.rewards.currentBalance
  );
  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);
  const totalRewardCounts = useMemo(() => {
    return brandEntity?.brandclub
      ? sumCampaignRewardCounts(brandEntity.brandclub)
      : 0;
  }, [brandEntity?.brandclub]);

  useEffect(() => {
    (async () => {
      if (brandEntity?.brandclub && userProfile?.userId) {
        const variables = {
          brandIds: brandEntity?.brandclub?.brandIds,
          forceRefresh: true,
        };
        const { data } = await reduxApolloClient.query({
          query: getRewardAmountsByStatus,
          variables,
          context: {
            uri: `${ConfigUtils.getGraphQlUri()}?id=getRewardAmountsByStatus`,
          },
        });
        if (data && data.RewardSummary) {
          setRewardSummaryForBrand({ ...data.RewardSummary, hasLoaded: true });
        }
      }
    })();
  }, [brandEntity?.brandclub, userProfile]);

  const footerConfigs = useMemo(() => {
    const config = buildFooterConfig(
      siteBrandingType,
      rewardSummaryForBrand.lifetimeEarnings -
        rewardSummaryForBrand.lifetimePayouts -
        rewardSummaryForBrand.lifetimeAdjustments,
      totalRewardCounts,
      site,
      routePrefix,
      persistentQueryParams,
      undefined,
      brandEntity
    );
    const universalStoreFooterConfig = buildFooterConfig(
      SiteBrandingType.UniversalStore,
      currentBalance,
      totalRewardCounts,
      undefined,
      "",
      persistentQueryParams
    );

    if (siteBrandingType === SiteBrandingType.UniversalStore) {
      return [universalStoreFooterConfig];
    }
    if (siteBrandingType === SiteBrandingType.CustomStore) {
      return [config];
    }
    return [config, universalStoreFooterConfig];
  }, [
    routePrefix,
    siteBrandingType,
    currentBalance,
    totalRewardCounts,
    site,
    persistentQueryParams,
    rewardSummaryForBrand,
    brandEntity,
  ]);
  return (
    <div style={{}}>
      <AppMainNavigation></AppMainNavigation>
      <div>
        <div
          className="main-page-content"
          style={{
            minHeight: "66vh",
            paddingBottom: 100,
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Outlet />
        </div>

        <BrandclubFooter
          LinkComponent={AppLink}
          axios={axios}
          brandEntity={brandEntity as any}
          footerConfigs={footerConfigs}
          userProfile={userProfile}
          socialLinks={appConfig?.socialLinks}
        />
      </div>
    </div>
  );
};

export default withFadeTransition(AppMainPageOutlet);
