import React, { useRef, useState } from "react";

import { BotBar, FooterSection, TopBar } from "./HomePage";
import "./contact.scss";
import { joinWaitList, submitContactForm } from "../api";
import { validateEmail } from "../utils/StringUtils";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const SubmitSuccess = ({ title, subtitle, imgLink }: any) => {
  return (
    <div className="content contact_form">
      <div className="contact_form_top">
        <div className="hero_img">
          <img src={`${imgLink}.png`} alt="contact_head" />
        </div>
        <div className="hero_img_sm">
          <img src={`${imgLink}_sm.png`} alt="contact_head" />
        </div>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
    </div>
  );
};

const BusinessForm = () => {
  const emptyError = { firstName: "", lastName: "", company: "", email: "" };
  const [submitting, setSubmitting] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(emptyError);
  const [submitDone, setSubmitDone] = useState(false);

  const verifyAndSubmit = () => {
    const newError: any = {};
    if (!firstName) {
      newError.firstName = "Please enter first name";
    }
    if (!lastName) {
      newError.lastName = "Please enter last name";
    }
    if (!company) {
      newError.company = "Please enter your company";
    }
    if (!email) {
      newError.email = "Please enter your email";
    }
    if (email && !validateEmail(email)) {
      newError.email = "Please enter a valid email";
    }
    if (firstName && lastName && company && email && validateEmail(email)) {
      handleSubmit();
    } else {
      setError(newError);
    }
  };
  const handleSubmit = async () => {
    setSubmitting(true);
    const req = {
      type: "Business & Partnerships",
      form: {
        type: "Business & Partnerships",
        firstName,
        lastName,
        company,
        email,
      },
    };
    await submitContactForm(req);
    setSubmitting(false);
    setSubmitDone(true);
  };
  return (
    <div className="section contact_form" style={{}}>
      <Helmet>
        <title>Contact Business</title>
        <meta
          name="description"
          content="Contact us to learn more about how Brandclub helps consumer brands build direct, meaningful connections with the shoppers who matter the most."
        />
      </Helmet>
      <div className="content_container" style={{}}>
        {submitDone ? (
          <SubmitSuccess
            imgLink="/contact_head_partnership"
            title="Thanks for reaching out"
            subtitle="A member of our team will contact you soon"
          />
        ) : (
          <div className="content contact_form">
            <div className="contact_form_top">
              <div className="hero_img">
                <img
                  src="https://media.brandclub.com/brandclub/image_asset/contact_head_partnership.png"
                  alt="contact_head_partnership"
                />
              </div>
              <div className="hero_img_sm">
                <img
                  src="https://media.brandclub.com/brandclub/image_asset/contact_head_partnership_sm.png"
                  alt="contact_head_partnership"
                />
              </div>
              <div className="title">All the tools to master loyalty</div>
              <div className="subtitle">
                Share a few details to request a demo
              </div>
            </div>
            <div className="contact_form_bot">
              <div className="row">
                <div className="field">
                  <div className="label">
                    First name{" "}
                    {error.firstName && (
                      <div className="error_msg">{error.firstName}</div>
                    )}
                  </div>
                  <input
                    value={firstName}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className="field">
                  <div className="label">
                    Last name{" "}
                    {error.lastName && (
                      <div className="error_msg">{error.lastName}</div>
                    )}
                  </div>
                  <input
                    value={lastName}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setLastName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="field">
                  <div className="label">
                    Work email{" "}
                    {error.email && (
                      <div className="error_msg">{error.email}</div>
                    )}
                  </div>
                  <input
                    value={email}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="field">
                  <div className="label">
                    Company{" "}
                    {error.company && (
                      <div className="error_msg">{error.company}</div>
                    )}
                  </div>
                  <input
                    value={company}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setCompany(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <button disabled={submitting} onClick={verifyAndSubmit}>
                  {submitting ? "Submitting" : "Get started"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DemoForm = () => {
  const emptyError = { firstName: "", lastName: "", company: "", email: "" };
  const [submitting, setSubmitting] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(emptyError);
  const [submitDone, setSubmitDone] = useState(false);

  const verifyAndSubmit = () => {
    const newError: any = {};
    if (!firstName) {
      newError.firstName = "Please enter first name";
    }
    if (!lastName) {
      newError.lastName = "Please enter last name";
    }
    if (!company) {
      newError.company = "Please enter your company";
    }
    if (!email) {
      newError.email = "Please enter your email";
    }
    if (email && !validateEmail(email)) {
      newError.email = "Please enter a valid email";
    }
    if (firstName && lastName && company && email && validateEmail(email)) {
      handleSubmit();
    } else {
      setError(newError);
    }
  };
  const handleSubmit = async () => {
    setSubmitting(true);
    const req = {
      type: "Request Demo",
      form: {
        type: "Request Demo",
        firstName,
        lastName,
        company,
        email,
      },
    };
    await submitContactForm(req);
    setSubmitting(false);
    setSubmitDone(true);
  };
  return (
    <div className="section contact_form" style={{}}>
      <Helmet>
        <title>Request Demo</title>
        <meta
          name="description"
          content="Request a demo with our Brandclub partnerships team."
        />
      </Helmet>
      <div className="content_container" style={{}}>
        {submitDone ? (
          <SubmitSuccess
            imgLink="/contact_head_partnership"
            title="Thanks for reaching out"
            subtitle="A member of our team will contact you soon"
          />
        ) : (
          <div className="content contact_form">
            <div className="contact_form_top">
              <div className="hero_img">
                <img
                  src="https://media.brandclub.com/brandclub/image_asset/contact_head_partnership.png"
                  alt="contact_head_partnership"
                />
              </div>
              <div className="hero_img_sm">
                <img
                  src="https://media.brandclub.com/brandclub/image_asset/contact_head_partnership_sm.png"
                  alt="contact_head_partnership"
                />
              </div>
              <div className="title">All the tools to master loyalty</div>
              <div className="subtitle">
                Share a few details to request a demo
              </div>
            </div>
            <div className="contact_form_bot">
              <div className="row">
                <div className="field">
                  <div className="label">
                    First name{" "}
                    {error.firstName && (
                      <div className="error_msg">{error.firstName}</div>
                    )}
                  </div>
                  <input
                    value={firstName}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className="field">
                  <div className="label">
                    Last name{" "}
                    {error.lastName && (
                      <div className="error_msg">{error.lastName}</div>
                    )}
                  </div>
                  <input
                    value={lastName}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setLastName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="field">
                  <div className="label">
                    Work email{" "}
                    {error.email && (
                      <div className="error_msg">{error.email}</div>
                    )}
                  </div>
                  <input
                    value={email}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="field">
                  <div className="label">
                    Company{" "}
                    {error.company && (
                      <div className="error_msg">{error.company}</div>
                    )}
                  </div>
                  <input
                    value={company}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setCompany(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <button disabled={submitting} onClick={verifyAndSubmit}>
                  {submitting ? "Submitting" : "Get started"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const MediaForm = () => {
  const emptyError = { firstName: "", lastName: "", company: "", email: "" };
  const [submitting, setSubmitting] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(emptyError);
  const [submitDone, setSubmitDone] = useState(false);

  const verifyAndSubmit = () => {
    const newError: any = {};
    if (!firstName) {
      newError.firstName = "Please enter first name";
    }
    if (!lastName) {
      newError.lastName = "Please enter last name";
    }
    if (!company) {
      newError.company = "Please enter your company";
    }
    if (!email) {
      newError.email = "Please enter your email";
    }
    if (email && !validateEmail(email)) {
      newError.email = "Please enter a valid email";
    }
    if (firstName && lastName && company && email && validateEmail(email)) {
      handleSubmit();
    } else {
      setError(newError);
    }
  };
  const handleSubmit = async () => {
    setSubmitting(true);
    const req = {
      type: "Media Relations",
      form: {
        type: "Media relations",
        firstName,
        lastName,
        company,
        email,
      },
    };
    await submitContactForm(req);
    setSubmitting(false);
    setSubmitDone(true);
  };
  return (
    <div className="section contact_form" style={{}}>
      <Helmet>
        <title>Contact Media</title>
        <meta
          name="description"
          content="Contact us to speak with a member of the Brandclub media team."
        />
      </Helmet>
      <div className="content_container" style={{}}>
        {submitDone ? (
          <SubmitSuccess
            imgLink="/contact_head_media"
            title="Thanks for reaching out"
            subtitle="A member of our team will contact you soon"
          />
        ) : (
          <div className="content contact_form">
            <div className="contact_form_top">
              <div className="hero_img">
                <img
                  src="https://media.brandclub.com/brandclub/image_asset/contact_head_media.png"
                  alt="contact_head"
                />
              </div>
              <div className="hero_img_sm">
                <img
                  src="https://media.brandclub.com/brandclub/image_asset/contact_head_media_sm.png"
                  alt="contact_head"
                />
              </div>
              <div className="title">Media relations</div>
              <div className="subtitle">
                Share your information to speak to our team
              </div>
            </div>
            <div className="contact_form_bot">
              <div className="row">
                <div className="field">
                  <div className="label">
                    First name{" "}
                    {error.firstName && (
                      <div className="error_msg">{error.firstName}</div>
                    )}
                  </div>
                  <input
                    value={firstName}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className="field">
                  <div className="label">
                    Last name{" "}
                    {error.lastName && (
                      <div className="error_msg">{error.lastName}</div>
                    )}
                  </div>
                  <input
                    value={lastName}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setLastName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="field">
                  <div className="label">
                    Work email{" "}
                    {error.email && (
                      <div className="error_msg">{error.email}</div>
                    )}
                  </div>
                  <input
                    value={email}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="field">
                  <div className="label">
                    Company{" "}
                    {error.company && (
                      <div className="error_msg">{error.company}</div>
                    )}
                  </div>
                  <input
                    value={company}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setCompany(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <button disabled={submitting} onClick={verifyAndSubmit}>
                  {submitting ? "Submitting" : "Get started"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const WaitlistForm = () => {
  const emptyError = { firstName: "", lastName: "", phone: "", email: "" };
  const [submitting, setSubmitting] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(emptyError);
  const [submitDone, setSubmitDone] = useState(false);

  const verifyAndSubmit = () => {
    const newError: any = {};
    if (!firstName) {
      newError.firstName = "Please enter first name";
    }
    if (!lastName) {
      newError.lastName = "Please enter last name";
    }
    if (!phone) {
      newError.phone = "Please enter your phone";
    }
    if (!email) {
      newError.email = "Please enter your email";
    }
    if (email && !validateEmail(email)) {
      newError.email = "Please enter a valid email";
    }
    if (firstName && lastName && phone && email && validateEmail(email)) {
      handleSubmit();
    } else {
      setError(newError);
    }
  };
  const handleSubmit = async () => {
    setSubmitting(true);
    await joinWaitList({
      name: `${firstName} ${lastName}`,
      phoneNumber: phone,
      email,
    });
    setSubmitting(false);
    setSubmitDone(true);
  };
  return (
    <div className="section contact_form" style={{}}>
      <div className="content_container" style={{}}>
        {submitDone ? (
          <SubmitSuccess
            imgLink="/contact_head_waitlist"
            title="You're on the list!"
            subtitle="Keep an eye out in your email for our launch date announcement"
          />
        ) : (
          <div className="content contact_form">
            <div className="contact_form_top">
              <div className="hero_img">
                <img
                  src="https://media.brandclub.com/brandclub/image_asset/contact_head_waitlist.png"
                  alt="contact_head"
                />
              </div>
              <div className="hero_img_sm">
                <img
                  src="https://media.brandclub.com/brandclub/image_asset/contact_head_waitlist_sm.png"
                  alt="contact_head"
                />
              </div>
              <div className="title">Join the waitlist</div>
              <div className="subtitle">
                Keep an eye out in your email for our launch date
              </div>
            </div>
            <div className="contact_form_bot">
              <div className="row">
                <div className="field">
                  <div className="label">
                    First name{" "}
                    {error.firstName && (
                      <div className="error_msg">{error.firstName}</div>
                    )}
                  </div>
                  <input
                    value={firstName}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className="field">
                  <div className="label">
                    Last name{" "}
                    {error.lastName && (
                      <div className="error_msg">{error.lastName}</div>
                    )}
                  </div>
                  <input
                    value={lastName}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setLastName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="field">
                  <div className="label">
                    Email{" "}
                    {error.email && (
                      <div className="error_msg">{error.email}</div>
                    )}
                  </div>
                  <input
                    value={email}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="field">
                  <div className="label">
                    Phone number{" "}
                    {error.phone && (
                      <div className="error_msg">{error.phone}</div>
                    )}
                  </div>
                  <input
                    value={phone}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setPhone(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <button disabled={submitting} onClick={verifyAndSubmit}>
                  {submitting ? "Submitting" : "Get started"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const SupportForm = () => {
  const emptyError = {
    name: "",
    phone: "",
    email: "",
    subject: "",
    detail: "",
  };
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [detail, setDetail] = useState("");
  const [error, setError] = useState(emptyError);
  const [submitDone, setSubmitDone] = useState(false);

  const verifyAndSubmit = () => {
    const newError: any = {};
    if (!name) {
      newError.name = "Please enter your name";
    }
    if (!phone) {
      newError.phone = "Please enter your phone";
    }
    if (!email) {
      newError.email = "Please enter your email";
    }
    if (email && !validateEmail(email)) {
      newError.email = "Please enter a valid email";
    }
    if (!subject) {
      newError.subject = "Please enter a subject";
    }
    if (!detail) {
      newError.detail = "Please enter something";
    }
    if (name && phone && email && subject && detail && validateEmail(email)) {
      handleSubmit();
    } else {
      setError(newError);
    }
  };
  const handleSubmit = async () => {
    setSubmitting(true);
    const req = {
      type: "Contact Support",
      form: {
        type: "Contact Support",
        name,

        phone,
        email,
        subject,
        detail,
      },
    };
    await submitContactForm(req);
    setSubmitting(false);
    setSubmitDone(true);
  };
  return (
    <div className="section contact_form" style={{}}>
      <Helmet>
        <title>Contact Support</title>
        <meta
          name="description"
          content="Get in touch with our Brandclub support team."
        />
      </Helmet>
      <div className="content_container" style={{}}>
        {submitDone ? (
          <SubmitSuccess
            imgLink="/contact_head_waitlist"
            title="Thanks for reaching out"
            subtitle="A member of our team will contact you soon"
          />
        ) : (
          <div className="content contact_form">
            <div className="contact_form_top">
              <div className="hero_img">
                <img
                  src="https://media.brandclub.com/brandclub/image_asset/contact_head_waitlist.png"
                  alt="contact_head"
                />
              </div>
              <div className="hero_img_sm">
                <img
                  src="https://media.brandclub.com/brandclub/image_asset/contact_head_waitlist_sm.png"
                  alt="contact_head"
                />
              </div>
              <div className="title">Contact us</div>
              <div className="subtitle" style={{}}>
                Get in touch with our Brandclub support team
              </div>
            </div>
            <div className="contact_form_bot">
              <div className="row">
                <div className="field" style={{ maxWidth: 900 }}>
                  <div className="label">
                    Full name{" "}
                    {error.name && (
                      <div className="error_msg">{error.name}</div>
                    )}
                  </div>
                  <input
                    value={name}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="field" style={{ maxWidth: 900 }}>
                  <div className="label">
                    Phone number{" "}
                    {error.phone && (
                      <div className="error_msg">{error.phone}</div>
                    )}
                  </div>
                  <input
                    value={phone}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setPhone(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="field" style={{ maxWidth: 900 }}>
                  <div className="label">
                    Email address{" "}
                    {error.email && (
                      <div className="error_msg">{error.email}</div>
                    )}
                  </div>
                  <input
                    value={email}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="field" style={{ maxWidth: 900 }}>
                  <div className="label">
                    Subject{" "}
                    {error.subject && (
                      <div className="error_msg">{error.subject}</div>
                    )}
                  </div>
                  <input
                    value={subject}
                    disabled={submitting}
                    className="input_box"
                    onChange={(e) => {
                      setError(emptyError);
                      setSubject(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="field" style={{ maxWidth: 900 }}>
                  <div className="label">
                    Tell us what’s up{" "}
                    {error.detail && (
                      <div className="error_msg">{error.detail}</div>
                    )}
                  </div>
                  <textarea
                    value={detail}
                    disabled={submitting}
                    className="input_box"
                    style={{ resize: "none", height: 250 }}
                    onChange={(e) => {
                      setError(emptyError);
                      setDetail(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <button disabled={submitting} onClick={verifyAndSubmit}>
                  {submitting ? "Submitting" : "Submit"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ContactForm = ({
  formType,
}: {
  formType: "business" | "demo" | "media" | "waitlist" | "support";
}) => {
  const ref = useRef() as any;
  const disable = false;
  if (disable) {
    return <></>;
  }

  const renderForm = () => {
    if (formType === "business") {
      return <BusinessForm />;
    } else if (formType === "demo") {
      return <DemoForm />;
    } else if (formType === "media") {
      return <MediaForm />;
    } else if (formType === "waitlist") {
      return <WaitlistForm />;
    } else if (formType === "support") {
      return <SupportForm />;
    }
    return null;
  };

  return (
    <div className="site" ref={ref}>
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="Get in touch with our Brandclub support team."
        />
      </Helmet>
      <TopBar borderButton />
      {renderForm()}
      <FooterSection />
      <BotBar />
    </div>
  );
};

export const SubmitRes = (props: any) => {
  const location: any = useLocation();
  if (location && location.state && location.state.status === "success") {
    return (
      <div className="site">
        <Helmet>
          <title>Success</title>
        </Helmet>
        <TopBar borderButton />
        <div className="section contact_form" style={{}}>
          <div className="content_container" style={{}}>
            <SubmitSuccess
              imgLink="/contact_head_waitlist"
              title="Thanks for reaching out"
              subtitle="A member of our team will contact you soon"
            />
          </div>
        </div>
        <FooterSection />
        <BotBar />
      </div>
    );
  }
  return null;
};

export default ContactForm;
