import {
  brandclub_colors,
  getPurchaseRewardAmountForProductV2,
  hexadecimal,
} from "@brandclub/common-ui";
import { Box, Divider, styled } from "@mui/material";
import { getRetailerFromId } from "@stackline/ui";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../../../redux/hooks";
import { formatMoney, getEntityImage } from "../../../../utils/StringUtils";
import { useTheme } from "@mui/material/styles";
import Loading from "../../../Loading";
import {
  OutlineButton,
  SolidButton,
} from "../../../StoreComponents/StoreButton";
import {
  ShoppingCartItem,
  UserRetailerCheckoutBatch,
  UserRetailerCheckoutOrderStatus,
} from "../../types";

import { isDefined } from "../../../../utils/misc";
import { StoreError } from "../../../StoreComponents/StoreError";
import { TotalLineItem } from "../TotalLineItem";
import { useCheckout } from "../../CheckoutProvider";
import DeliverySection from "./DeliverySection";
import PaymentSection from "./PaymentSection";
import ShippingSection from "./ShippingSection";
import RewardBlock from "../RewardBlock";

import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import { ERROR_MESSAGES } from "../../../../utils/errors/errorUtils";
import SubmitVerification from "../../SubmitVerification";

const getRewardText = (rewardAmount: number, rewardUnlocked: number) => {
  const rewardBlockTitle = `Get cash rewards`;
  let rewardBlockText = ``;
  if (rewardAmount > 0 && rewardUnlocked > 0) {
    rewardBlockText = `Earn ${formatMoney(
      rewardAmount
    )} in new rewards and unlock ${formatMoney(
      rewardUnlocked
    )} in existing rewards on this order.`;
  } else if (rewardAmount > 0) {
    rewardBlockText = `Earn ${formatMoney(
      rewardAmount
    )} in new rewards on this order.`;
  } else if (rewardUnlocked > 0) {
    rewardBlockText = `Unlock ${formatMoney(
      rewardUnlocked
    )} in existing rewards on this order.`;
  }

  return { rewardBlockTitle, rewardBlockText };
};

const TopLevelContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  marginTop: 73,
  marginBottom: 73,

  ".terms": {
    fontSize: 12,
    marginTop: 20,
    opacity: 0.3,
    textAlign: "center",
    ".tosLinks": {
      color: "unset",
      "&:visited": {},
    },
  },
  ".checkout_stepper": {
    marginBottom: 5,
    marginTop: 0,
  },
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    marginTop: 40,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    marginTop: 40,
  },
});
const Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  paddingBottom: 0,

  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    paddingBottom: "100px",
    flexDirection: "column",
    gap: 20,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    paddingBottom: "100px",
    flexDirection: "column",
    gap: 20,
  },

  ".checkout_desktop": {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      display: "none",
    },
  },
  ".checkout_mobile": {
    backgroundColor: "#fff",
    display: "none",
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: "20px 20px 35px 20px",
    borderTop: `1px solid ${brandclub_colors.grayLight}`,
    boxSizing: "border-box",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  ".info": {
    flex: 1,
    ".section + .section": {
      borderTop: `1px solid ${brandclub_colors.grayLight}`,
    },
    ".section": {
      padding: "40px 0",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        padding: "20px 0",
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        padding: "20px 0",
      },
      ".section_head": {
        display: "flex",
        alignItems: "center",
        marginBottom: 16,
        position: "relative",
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          marginBottom: 5,
        },
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          marginBottom: 5,
        },
        minHeight: 35,
        ".title": {
          fontSize: 20,
          fontWeight: 600,
          flex: 1,
          [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
            fontSize: 14,
            fontWeight: 600,
            opacity: 0.5,
          },
          [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            fontSize: 14,
            fontWeight: 600,
            opacity: 0.5,
          },
        },
        ".action": {
          fontSize: 16,
          fontWeight: 500,

          "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
          },
        },
      },

      ".info_box": {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        ".info_row": {
          fontSize: 16,
          fontWeight: 500,
          lineHeight: "25px",
          display: "flex",
          alignItems: "flex-start",
          "&.address": {
            overflow: "hidden",
            WebkitLineClamp: 1,
            lineHeight: 1.5,
            height: `1.5em`, // line height * 2
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          },
          [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
            fontSize: 14,
          },
          [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            fontSize: 14,
          },
          ".icon": {
            width: 25,
            height: 25,
            marginRight: 8,
            objectFit: "contain",
            marginTop: 2,
            marginBottom: 2,
            [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
              width: 20,
              height: 20,
            },
            [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              width: 20,
              height: 20,
            },
          },
        },
      },
      ".delivery": {
        marginTop: 10,
      },
      ".shipping_group_list": {
        display: "flex",
        flexDirection: "column",
        gap: 40,
      },
      ".shipping_group": {},
    },
    ".place_order_actions": {
      display: "flex",
      flexDirection: "row",
      gap: 25,
      ".order_total": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ".title": {
          fontSize: 20,
          fontWeight: 600,
        },
        ".subtitle": {
          fontSize: 16,
          fontWeight: 500,
        },
      },
    },
  },
  ".summary": {
    // paddingTop: 30,
    display: "flex",
    flexDirection: "column",
    gap: 23,
    maxWidth: 476,
    width: "100%",
    minWidth: 350,
    boxSizing: "border-box",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      minWidth: "unset",
      maxWidth: "unset",
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      minWidth: "unset",
      maxWidth: "unset",
    },
    ".breakdown": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      ">*": {
        width: "100%",
      },

      backgroundColor: "#f6f8fa80",
      padding: "30px",
      borderRadius: 8,
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        backgroundColor: "#fff",
        padding: "0px",
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        backgroundColor: "#fff",
        padding: "0px",
      },
    },
    ".summary_head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 27,
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        borderTop: `1px solid ${brandclub_colors.grayLight}`,
        paddingTop: 20,
        marginBottom: 10,
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        borderTop: `1px solid ${brandclub_colors.grayLight}`,
        paddingTop: 20,
        marginBottom: 10,
      },
      ".title": {
        fontSize: 20,
        fontWeight: 700,
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          fontSize: 14,
          fontWeight: 500,
          opacity: 0.5,
        },
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 14,
          fontWeight: 500,
          opacity: 0.5,
        },
      },
    },
    ".summary_retailer": {
      display: "flex",
      // justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 14,
      fontSize: 16,
      fontWeight: 600,
      ".logo": {
        width: 25,
        height: 25,
        marginRight: 8,
        objectFit: "contain",
      },
    },
  },
});

const EditBagButton = styled(OutlineButton)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  height: 28,
  color: theme.palette.primary.main,
  transition: "all 0.3s",
  "&:hover": {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
}));

const EditPaymentShippingButton = styled(EditBagButton)({
  fontSize: 14,
  height: 35,
  paddingLeft: 23,
  paddingRight: 23,

  right: 0,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    display: "none",
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    display: "none",
  },
});

const PlaceOrderButtonMini = styled(SolidButton)(({ theme }) => ({
  height: 50,
  width: "100%",

  fontSize: 16,
  fontWeight: 600,
  maxWidth: "100%",

  ":disabled": {
    opacity: 1,
    borderColor: "#dcdddc",
    backgroundColor: "#fff",
    color: hexadecimal(theme.palette.primary.main)(30),
    cursor: "not-allowed",
  },
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    maxWidth: "100%",
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    maxWidth: "100%",
  },
}));

const SkipButtonMini = styled(OutlineButton)({
  height: 50,
  width: "100%",
  marginTop: 12,
  fontSize: 15,
  fontWeight: 600,
  borderWidth: 1.5,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    maxWidth: "100%",
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    maxWidth: "100%",
  },
});

const getErrorMessageFromItems = (items: ShoppingCartItem[]) => {
  const errors = items.map((item) => item.errorMessage);
  const filteredErrors = errors.filter(isDefined);
  if (filteredErrors.length === 0) return undefined;
  // remove duplicates
  const uniqueErrors = Array.from(new Set(filteredErrors));
  // categorize errors
  const outOfStockErrors: string[] = [];
  const productErrors: string[] = [];
  const paymentErrors: string[] = [];
  const otherErrors: string[] = [];
  uniqueErrors.forEach((error) => {
    const errorString = error.toLowerCase();
    if (errorString.includes("out of stock")) {
      outOfStockErrors.push(error);
    } else if (errorString.includes("payment")) {
      paymentErrors.push(error);
    } else if (
      errorString.match(/add \w* to (cart|bag|basket)/i) ||
      errorString.match(/quantity/i)
    ) {
      productErrors.push(error);
    } else {
      otherErrors.push(error);
    }
  });
  // return the first error category that has errors
  if (outOfStockErrors.length > 0) {
    return "Some items in your bag are out of stock and have been removed from your bag.";
  }
  if (paymentErrors.length > 0) {
    return "There was an issue with your payment. Please retry or select another card.";
  }
  if (productErrors.length > 0) {
    return "We encountered an error with some items and have removed them from your bag.";
  }
  if (otherErrors.length > 0) {
    return ERROR_MESSAGES.default[1];
  }
  // if no errors are found, return undefined
  return undefined;
};
const formatRetailerStep = (
  retailerIds?: number[],
  orderStatusById?: UserRetailerCheckoutBatch["checkoutOrderStatusByRetailerId"]
) => {
  if (!retailerIds || !orderStatusById) {
    return [];
  }

  const steps = retailerIds.map((retailerId) => {
    return {
      retailerId,
      displayName: getRetailerFromId(retailerId),
      status: orderStatusById[retailerId] as
        | UserRetailerCheckoutOrderStatus
        | undefined,
    };
  });

  steps.sort((a, b) => {
    return a.retailerId - b.retailerId;
  });

  return steps;
};

const TitleContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 15,
  ".logo": {
    width: 30,
    height: 30,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      width: 25,
      height: 25,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      width: 25,
      height: 25,
    },
  },
  ".title": {
    fontSize: 24,

    fontWeight: 700,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      fontSize: 22,
      fontWeight: 700,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 22,
      fontWeight: 700,
    },
  },
});

const CheckoutTitle = () => {
  const retailerIds = useAppSelector(
    ({ checkout }) => checkout.batch?.retailerIds
  );
  const checkoutOrderStatusByRetailerId = useAppSelector(
    ({ checkout }) => checkout.batch?.checkoutOrderStatusByRetailerId
  );
  const currentRetailerId = useAppSelector(
    ({ checkout }) => checkout.order?.userRetailerCheckoutOrder?.retailerId
  );

  if (!checkoutOrderStatusByRetailerId || retailerIds?.length === 0) {
    return (
      <TitleContainer>
        <div className="title">Checkout</div>
      </TitleContainer>
    );
  }

  const retailerSteps = formatRetailerStep(
    retailerIds,
    checkoutOrderStatusByRetailerId
  );

  const activeStep = retailerSteps.findIndex(
    (step) => step.retailerId === currentRetailerId
  );

  return (
    <TitleContainer>
      <img
        alt="logo"
        className="logo"
        style={{}}
        src={getEntityImage(currentRetailerId as any, "retailer")}
      />
      <div className="title">
        {retailerSteps.length > 1
          ? `Checkout ${activeStep + 1} of ${retailerSteps.length}`
          : "Checkout"}
      </div>
    </TitleContainer>
  );
};

export const CheckoutMainFlow = () => {
  const [verificationOpen, setVerificationOpen] = useState(false);
  const order = useAppSelector((state) => state.checkout.order);

  const batch = useAppSelector((state) => state.checkout.batch);

  const subtotal =
    order?.userRetailerCheckoutOrder?.orderSummary?.subtotal ?? 0;
  const shippingFees =
    order?.userRetailerCheckoutOrder?.orderSummary?.shippingFees ?? 0;
  const shippingDiscount = Math.abs(
    order?.userRetailerCheckoutOrder?.orderSummary?.shippingDiscount ?? 0
  );
  const shipping = shippingFees - shippingDiscount;
  const tax = order?.userRetailerCheckoutOrder?.orderSummary?.tax ?? 0;
  const total = order?.userRetailerCheckoutOrder?.orderSummary?.total ?? 0;
  const giftCardAmount =
    order?.userRetailerCheckoutOrder?.orderSummary?.giftCard ?? 0;
  const {
    getAllCarts,
    products,
    cancelOrder,
    confirmOrder,
    checkoutNextOrder,
  } = useCheckout();

  const placeOrder = () => {
    const paymentConfirmationTypes =
      order?.userRetailerCheckoutOrder?.paymentMethod?.paymentConfirmationTypes;
    if (paymentConfirmationTypes?.length) {
      setVerificationOpen(true);
    } else {
      confirmOrder();
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    getAllCarts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const syncSession = async () => {
      //const data = await getLatestSyncSession();
    };
    if (!order && !batch) {
      syncSession();
      navigate("/mybag");
    }
  }, [order, batch, navigate]);

  const retailerName = getRetailerFromId(
    order?.userRetailerCheckoutOrder?.retailerId ?? 0
  );
  const items = Object.values(order?.userRetailerCheckoutOrder?.items ?? {});

  // const handleUpdatePaymentConfirmation = async (paymentConfirmation: {
  //   type: "cvv" | "cardNumber" | "expirationDate" | "cardHolderName";
  //   value: string;
  // }) => {
  //   await updatePaymentConfirmation(paymentConfirmation);
  //   CheckoutTransition.toOrder(orderId);
  //   clearEditing();
  // };

  const EditBag = () => {
    if (order?.userRetailerCheckoutOrder?.retailerId) {
      cancelOrder(order?.userRetailerCheckoutOrder?.retailerId);
    }
    navigate("/mybag");
  };

  const currentRetailerId = useAppSelector(
    ({ checkout }) => checkout.order?.userRetailerCheckoutOrder?.retailerId
  );
  const termsAndService = useAppSelector((state) => state.retailers).find(
    (item) => item.retailerId === currentRetailerId
  )?.tosUrl;

  const loadingPage = !order?.userRetailerCheckoutOrder?.retailerId;

  // useEffect(() => {
  //   // init fetch for batch status
  //   if (order?.userRetailerCheckoutOrder?.checkoutBatchId) {
  //     updateBatch();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [order?.userRetailerCheckoutOrder?.checkoutBatchId]);
  const currentProducts = order?.userRetailerCheckoutOrder?.items.map(
    (item) => {
      const product = products?.[item.stacklineSku] ?? {};
      return {
        ...product,
        quantity: item.quantity,
      };
    }
  );

  const canSkip =
    batch?.retailerIds &&
    batch?.retailerIds?.length > 1 &&
    batch?.retailerIds[batch.retailerIds.length - 1] !==
      order?.userRetailerCheckoutOrder?.retailerId;

  const rewardAmount = currentProducts?.reduce((acc, product) => {
    const { amount } = getPurchaseRewardAmountForProductV2(product as any);
    return acc + amount;
  }, 0);

  const rewardUnlocked = 0;
  const { rewardBlockTitle, rewardBlockText } = getRewardText(
    rewardAmount || 0,
    rewardUnlocked
  );
  const theme = useTheme();
  const canPlaceOrder =
    order?.userRetailerCheckoutOrder?.canPlaceOrder?.status === true;

  return (
    <TopLevelContainer>
      <Container gap={12} className="checkout_main_flow_container">
        {loadingPage ? (
          <Box className="info">
            <Loading dot />
          </Box>
        ) : (
          <Box className="info">
            <CheckoutTitle />
            <PaymentSection />
            <ShippingSection />
            <DeliverySection />
          </Box>
        )}
        <Box className="summary">
          <Box className="breakdown">
            <Box className="summary_head">
              <div className="title">Order Summary</div>
              {!theme.dtc && (
                <EditPaymentShippingButton onClick={EditBag}>
                  Edit
                </EditPaymentShippingButton>
              )}
            </Box>

            <Box className="summary_retailer">
              <img
                className="logo"
                alt="r_logo"
                src={getEntityImage(
                  order?.userRetailerCheckoutOrder?.retailerId ?? "",
                  "retailer"
                )}
              />
              {retailerName}
            </Box>
            {/* Total Section */}
            <Box display="flex" flexDirection="column" gap={1}>
              <TotalLineItem label={"Subtotal"} value={subtotal} />
              <TotalLineItem
                label={"Shipping"}
                zeroString="Free"
                value={shipping}
              />
              {giftCardAmount ? (
                <TotalLineItem label={"Gift Card"} value={-giftCardAmount} />
              ) : null}
              <TotalLineItem label={"Estimated Tax"} value={tax} />
              <Divider
                sx={(theme) => ({
                  my: "8px",
                  background: theme.palette.primary.main,
                  height: "0.5px",
                })}
              />
              <TotalLineItem label={"Total"} value={total} />
            </Box>
            {/* Checkout initiation */}
            <>
              <div className="checkout_mobile">
                <PlaceOrderButtonMini
                  disabled={!canPlaceOrder}
                  onClick={() => {
                    placeOrder();
                  }}
                >
                  Place my order
                </PlaceOrderButtonMini>
                {canSkip ? (
                  <SkipButtonMini onClick={checkoutNextOrder}>
                    Skip {retailerName}
                  </SkipButtonMini>
                ) : null}
              </div>
              <div className="checkout_desktop">
                <PlaceOrderButtonMini
                  disabled={!canPlaceOrder}
                  onClick={() => {
                    placeOrder();
                  }}
                >
                  Place my order
                </PlaceOrderButtonMini>
                {canSkip ? (
                  <SkipButtonMini onClick={checkoutNextOrder}>
                    Skip {retailerName}
                  </SkipButtonMini>
                ) : null}
                <div className="terms">
                  By ordering, you agree to the {retailerName}{" "}
                  <a
                    href={termsAndService?.terms}
                    target="_blank"
                    rel="noreferrer"
                    className="tosLinks"
                  >
                    Terms
                  </a>{" "}
                  and{" "}
                  <a
                    href={termsAndService?.policies}
                    target="_blank"
                    rel="noreferrer"
                    className="tosLinks"
                  >
                    Privacy Policy
                  </a>
                  .
                </div>
              </div>
            </>
          </Box>
          <StoreError
            errorMessage={getErrorMessageFromItems(items)}
            sx={{ justifyContent: "center" }}
          />
          {rewardAmount && rewardAmount > 0 ? (
            <RewardBlock title={rewardBlockTitle} text={rewardBlockText} />
          ) : null}
        </Box>
      </Container>
      <SubmitVerification
        open={verificationOpen}
        close={() => {
          setVerificationOpen(false);
        }}
      />
    </TopLevelContainer>
  );
};
