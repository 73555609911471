import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { FC, forwardRef } from "react";

import {
  CalendarIcon,
  brandclub_colors,
  brandclubStyles,
} from "@brandclub/common-ui";
import { IconButton, InputProps } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import StoreInput from "./StoreInput";

type AppDateValue = Date | string | undefined | null;
interface AppDatePickerCustomProps {
  value: AppDateValue;
  onChange: (val: AppDateValue) => void;
  showCalendarIcon?: boolean;
  inputProps?: InputProps;
  label?: string;
}

export type AppDatePickerProps = AppDatePickerCustomProps &
  Omit<DatePickerProps<AppDateValue, Date>, "renderInput">;

const paper = {
  width: 450,
  day: {
    width: 29,
    margin: "3px 16px",
    hover: {
      "&:not(.Mui-selected):hover": {
        backgroundColor: brandclub_colors.lightGreenLogo3,
        transition: "0.3s",
      },
    },
  },
};

const StoreDatePicker: FC<AppDatePickerProps> = (props) => {
  const { value, onChange, inputProps, label } = props;

  const { disabled, sx } = inputProps || {};

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        OpenPickerButtonProps={{
          component: forwardRef((props: any, ref) => (
            <IconButton {...props} disableRipple ref={ref}>
              <CalendarIcon color="primary" sx={{ fontSize: 17.5 }} />
            </IconButton>
          )),
          disableRipple: true,
        }}
        PopperProps={{
          style: { top: -53 },

          popperOptions: {
            placement: "bottom-start",
            modifiers: [
              {
                name: "flip",
                enabled: false,
                options: {
                  altBoundary: false,
                  rootBoundary: "document",
                },
              },
              {
                name: "offset",
                enabled: true,
                options: {
                  offset: [-1.3, -53],
                },
              },
              {
                name: "preventOverflow",
                enabled: false,
                options: {
                  altAxis: false,
                  altBoundary: false,
                  tether: false,
                  rootBoundary: "viewport",
                },
              },
            ],
          },
        }}
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: brandclub_colors.white,
            borderRadius: 7,
            // boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.16)",
            width: "100%",
            // height: 245,
            overflow: "hidden",
            "& div.css-epd502": {
              width: paper.width,
              "& div.MuiCalendarPicker-root": {
                width: paper.width,
                "& div.MuiPickersCalendarHeader-root": {
                  "& div.MuiPickersCalendarHeader-labelContainer": {
                    fontSize: 14,
                    // month label
                    color: theme.palette.primary.main,
                    whiteSpace: "nowrap",
                    "& button": {
                      color: theme.palette.primary.main,
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    },
                  },
                  "& div.MuiPickersArrowSwitcher-root": {
                    // month switcher
                    "& button": {
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      "& svg": {
                        color: theme.palette.primary.main,
                      },
                    },
                    "& div.MuiPickersArrowSwitcher-spacer": {
                      width: 3,
                    },
                  },
                },
                "& div.MuiDayPicker-header": {
                  // weekdays
                  width: paper.width,
                  "& span": {
                    color: brandclub_colors.gray,
                    height: paper.day.width,
                    width: paper.day.width,
                    margin: paper.day.margin,
                    fontSize: "10px",
                    fontWeight: 500,
                  },
                },
                "& div.MuiDayPicker-monthContainer": {
                  // days
                  width: paper.width,
                  "& div[role='row']": {
                    margin: 0,
                    "& div.MuiPickersDay-root": {
                      height: paper.day.width,
                      width: paper.day.width,
                      margin: paper.day.margin,
                    },
                    "& button": {
                      margin: paper.day.margin,
                      height: paper.day.width,
                      width: paper.day.width,
                      borderRadius: 0,
                      color: theme.palette.primary.main,
                      backgroundColor: brandclub_colors.white,
                      fontSize: 12,
                      fontWeight: 500,
                      ...paper.day.hover,
                      "&.Mui-selected": {
                        backgroundColor: brandclub_colors.lightGreenLogo3,
                      },
                      "&.MuiPickersDay-today:not(.Mui-selected)": {
                        border: `1px solid ${brandclub_colors.grayBorder}`,
                      },
                      "&.Mui-disabled": {
                        color: brandclub_colors.gray5,
                      },
                    },
                  },
                },
                "& div.MuiYearPicker-root": {
                  // year picker
                  ...brandclubStyles.customScrollbar,
                  height: 202,
                  margin: 0,
                  "& div button": {
                    borderRadius: 0,
                    color: theme.palette.primary.main,
                    fontSize: 12,
                    fontWeight: 500,
                    margin: 0,
                    ...paper.day.hover,
                    "&.Mui-selected": {
                      backgroundColor: brandclub_colors.lightGreenLogo3,
                    },
                    "&.Mui-disabled": {
                      color: brandclub_colors.gray5,
                      "&:hover": {
                        backgroundColor: "transparent",
                        cursor: "default",
                      },
                    },
                  },
                },
              },
            },
          }),
        }}
        value={value}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => {
          return (
            <StoreInput {...params} label={label} disabled={disabled} sx={sx} />
          );
        }}
        dayOfWeekFormatter={(dayOfWeek) => dayOfWeek}
      />
    </LocalizationProvider>
  );
};

export default StoreDatePicker;
