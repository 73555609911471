import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { isImageSrcExist } from "../utils/StringUtils";
import { useAppSelector } from "../redux/hooks";
import { BRANDCLUB_BRAND_ID } from "@brandclub/common-ui";

const DEFAULT_FAVICON = "https://media.brandclub.com/brandclub/def_favicon.ico";

// This component stay in the top level of any route
// and it is used to change the favicon of the page based on the brandId

const FaviconManager = () => {
  const brandIdForDomain = useAppSelector(
    ({ appConfig }) => appConfig?.domainConfig?.brandId
  );
  const brandIdFromStore = useAppSelector(
    ({ brandEntity }) => brandEntity?.brandclub?.brandId
  );
  const params = useParams();
  const { brandId: brandIdFromParam } = params;

  // trick: brandId can be string or number, always convert to number and put it in the useEffect dependency
  const brandId =
    brandIdForDomain && brandIdForDomain !== BRANDCLUB_BRAND_ID
      ? brandIdForDomain
      : brandIdFromParam ?? brandIdFromStore;
  const numberBrandId = brandId ? +brandId : brandId;

  const [favicon, setFavicon] = useState(DEFAULT_FAVICON);

  useEffect(() => {
    const getFavicon = async () => {
      const customFavicon = `https://media.brandclub.com/brand_custom/${numberBrandId}/favicon.png`;
      const imageValid = await isImageSrcExist(customFavicon);
      if (imageValid) {
        setFavicon(customFavicon);
      } else {
        setFavicon(DEFAULT_FAVICON);
      }
    };

    if (numberBrandId) {
      getFavicon();
    } else {
      setFavicon(DEFAULT_FAVICON);
    }
    // return () => {
    //   setFavicon(DEFAULT_FAVICON);
    // };
  }, [numberBrandId]);

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
    </>
  );
};
export default FaviconManager;
