import { Box, Typography } from "@mui/material";
import useQueryParam from "../../utils/hooks/useQueryParam";
import { CartProduct } from "./components/CartProduct";
import useRetailerIdToNameMap from "../../utils/hooks/useRetailerIdToNameMap";
import { formatDate } from "../../utils/StringUtils";
import { useOrderStatus } from "./useOrderStatus";

const Title = (props: { children: React.ReactNode }) => {
  return <h1 style={{ fontSize: 22 }}>{props.children}</h1>;
};
const Body = (props: { children: React.ReactNode }) => {
  return <p style={{ fontSize: 16 }}>{props.children}</p>;
};

export const OrderDetails = () => {
  // orderId is a query param - use react-router-dom to get it
  const [orderId] = useQueryParam("orderId", "");

  const { order } = useOrderStatus(orderId);
  const retailerMap = useRetailerIdToNameMap();

  if (!order?.userRetailerCheckoutOrder) {
    return null;
  }

  const checkoutOrder = order.userRetailerCheckoutOrder;
  return (
    <Box
      sx={(theme) => ({
        padding: "32px 5%",
        color: theme.palette.primary.main,
      })}
    >
      <Box
        width="100%"
        flex={2}
        display="flex"
        flexDirection="column"
        alignItems={"center"}
      >
        <Title>Order confirmation</Title>
        <Body>
          Thank you for your order. A confirmation email is on the way.
        </Body>

        <Box
          key={`cartGroup:${checkoutOrder.orderId}`}
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            maxWidth: 500,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            // gap={"12px"}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: 18,
              }}
            >
              {retailerMap[checkoutOrder.retailerId] ?? "Retailer"}
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                Order #{checkoutOrder.orderId}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                {formatDate(checkoutOrder.createdTime)}
              </Typography>
            </Box>
          </Box>
          {Object.values(checkoutOrder?.items ?? {}).map((item) => (
            <CartProduct
              product={item}
              key={`order:${checkoutOrder.orderId}:item:${item.stacklineSku}`}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
