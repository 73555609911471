import React from 'react';
import { styled, Tabs, Tab } from '@mui/material';
import { brandclub_colors } from '@brandclub/common-ui';

const CashOutCardContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#FFF',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '30px 30px 10px 10px',
  overflow: 'hidden',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  maxWidth: 290,

  h5: {
    all: 'unset',
    fontSize: 10,
    marginBottom: 10,
    display: 'block',
  },
  ".content-container": {
    overflow: 'hidden',
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'relative',
    padding: '18px 20px 0px 20px',
    boxSizing: 'border-box',
    "&:after": {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: '50%',
      bottom: 0,
      transform: 'translateX(50%)',
      width: '400px',
      height: '100%',
      backgroundColor: theme.palette.secondary.main,
      zIndex: 0,
      borderRadius: '0px 0px 100% 100%',
    },
  },
  ".cash-out-card__bottom": {
    padding: "0px 20px 15px 20px",
  },
  ".top__circles": {
    display: 'flex',
    marginLeft: 'auto',
    zIndex: 1,
  },
  ".cash-out-card__image": {
    width: 58,
    height: 58,
    borderRadius: '100%',
    position: 'absolute',
    right: '50%',
    bottom: '-29px',
    border: 'solid 2px #FFF',
    transform: 'translateX(50%)',
    zIndex: 1,
  },
  ".cash-out-card__top": {
    height: 100,
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
  },
  ".top__text": {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
  },
  ".circle": {
    '&:first-of-type': {
      marginRight: 8,
    },
    opacity: 0.8,
    backgroundColor: '#FFF',
    width: 21,
    height: 21,
    display: 'inline-block',
    borderRadius: '100%',
  },
  ".bottom__image": {
    height: 29,
    width: 29,
    borderRadius: '100%',
  },
  ".bottom__placeholders": {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 6,
  },
  ".bottom__placeholder": {
      backgroundColor: '#FFF',
      borderRadius: 6,
      height: 9,
      width: 76,
      "&:first-of-type": {
        width: 106,
        marginBottom: 4,
      },
    },
  ".bottom-contents__card": {
    backgroundColor: brandclub_colors.blueWhite,
    display: 'flex',
    alignItems: 'center',
    padding: "6px 8px",
  },
  ".card__reward": {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    ".small_span": {
      fontSize: 12,
      fontWeight: 500,
      display: 'inline-block',
      height: 'fit-content',
      marginBottom: 'auto',
      marginTop: 3,
    },
    span: {
      fontWeight: 600,
      fontSize: 20,
    },
  },
}))

const StyledTabs = styled(Tabs)({
  backgroundColor: '#f0f0f0',
  borderRadius: 9999,
  padding: 2,
  boxSizing: 'border-box',
  marginTop: 47,
  maxWidth: 250,
  marginLeft: 'auto',
  marginRight: 'auto',
  height: 'fit-content',
  minHeight: 'unset',
  marginBottom: 17,
  '& .MuiTabs-scroller': {
    height: 'fit-content',
  },
  '& .MuiTabs-indicator': {
    height: '100%',
    borderRadius: 9999,
    backgroundColor: 'white',
  },
})

const StyledTab = styled(Tab)({
  color: '#888888',
  textTransform: 'none',
  flexGrow: 1,
  borderRadius: 9999,
  padding: '5px 25px',
  fontSize: 10,
  fontWeight: 500,
  height: 'fit-content',
  minHeight: 'unset',
  zIndex: 1,
  '&.Mui-selected': {
    color: '#000000',
  },
})

export const CashOutCard = ({ logoImage } : { logoImage?: string }) => {
  return (
    <CashOutCardContainer className="cash-out-card">
      <div className="cash-out-card__top">
        <div className="content-container">
          <div className="top__text">
            <div
              style={{
                fontWeight: 600,
                fontSize: 12,
              }}
            > Jessica Smith </div>
            <div
              style={{
                fontWeight: 500,
                fontSize: 10,
              }}
            >@Jess-Smith</div>
          </div>
            <div className="top__circles">
              <span className="circle"/>
              <span className="circle"/>
            </div>
          </div>
        <img src="https://media.brandclub.com/brandclub/image_asset/membership_avat.jpg" alt="reward" className="cash-out-card__image"
          style={{
            objectFit: 'cover',
            objectPosition: 'center'
          }}
        />
      </div>
      <div className="cash-out-card__bottom">
        <StyledTabs
          value={1}
        >
          <StyledTab label="Wallet" />
          <StyledTab label="Transactions" />
        </StyledTabs>
        <div className="bottom-contents">
          <h5>Completed</h5>
          <div className='bottom-contents__card'>
            <img src={logoImage || "https://via.placeholder.com/150"}alt="reward" className="bottom__image"/>
            <div className="bottom__placeholders">
              <div className="bottom__placeholder"/>
              <div className="bottom__placeholder"/>
            </div>
            <div className="card__reward">
              <span className="small_span">$</span>
              <span>20</span>
            </div>
          </div>
        </div>
      </div>
    </CashOutCardContainer>
  )
}
