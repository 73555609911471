import { useEffect, useState } from "react";
import { useCheckout } from "../../CheckoutProvider";

import { EnterAnimationStaging, RetailerCheckoutOrderStatus } from "../../api";
import { useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Loading";
import { getEntityImage } from "../../../../utils/StringUtils";
import { styled, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import StoreStepper from "../../../StoreComponents/StoreStepper";

import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import { getRetailerFromId } from "@brandclub/common-ui";

const getMaxStep = (length: number, stage?: EnterAnimationStaging) => {
  if (stage === "itemSelection") {
    return length - 3;
  }
  if (stage === "userAuthentication") {
    return length - 2;
  }
  if (stage === "orderSummary") {
    return length - 1;
  }
  if (stage === "success") {
    return length - 1;
  }
  return 0;
};

const desktopCardStyleConfig = {
  height: 142,
  gap: 20,
};
const mobileCardStyleConfig = {
  height: 97,
  gap: 14,
};

const MinAnimationGap = 1300;

const getRandomDelay = () =>
  Math.floor(Math.random() * (500 + 1)) + MinAnimationGap;

const getEnterStage = (order: RetailerCheckoutOrderStatus) => {
  const stage = order.displayStatusInfo?.stage;
  const orderStatus = order.userRetailerCheckoutOrder?.status;
  if (
    stage !== "success" &&
    orderStatus &&
    ["checkout_error", "checkout_timed_out", "login_failed"].includes(
      orderStatus
    )
  ) {
    return undefined;
  }
  return stage;
};
/**
 *
 * Calculates how many steps can fit on the screen based on the window height
 */
const getNumberOfVisibleSteps = (
  currentWindowHeight: number,
  cardHeight: number,
  cardGap: number
) => {
  return Math.floor((currentWindowHeight - 50) / (cardHeight + cardGap));
};

export type ProductCardProps = {
  title: string;
  subtitle: string;
  visible: boolean;
  success: boolean;
  imageUrl?: string;
  imageSource?: any;
};

export const getConnectPlugCard = (retailerId: number): ProductCardProps => ({
  title: `Connecting ${getRetailerFromId(retailerId)}`,
  subtitle: "Communicating with the retailer",
  visible: true,
  success: true,
  imageSource: "https://media.brandclub.com/brandclub/icons/checkout_plug.webp",
});

export const deliveryCard: ProductCardProps = {
  title: "Locating shipping options",
  subtitle: "Confirming delivery choices",
  visible: true,
  success: true,
  imageSource:
    "https://media.brandclub.com/brandclub/icons/checkout_truck.webp",
};

export const boxingCard: ProductCardProps = {
  title: "Wrapping things up",
  subtitle: "Getting order ready to review",
  visible: true,
  success: true,
  imageSource: "https://media.brandclub.com/brandclub/icons/checkout_box.webp",
};

const EnterAnimationContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flex: 1,
  paddingTop: 65,
  paddingBottom: 0,
  justifyContent: "flex-start",
  flexDirection: "column",
  maxWidth: 656,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    paddingTop: 30,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    paddingTop: 30,
  },
  "*": {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
  ".retailer_icon": {
    width: 32,
    height: 32,

    borderRadius: 999,
    marginRight: 12,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      width: 25,
      height: 25,
      marginRight: 10,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      width: 25,
      height: 25,
      marginRight: 10,
    },
  },
  ".titleText": {
    paddingHorizontal: 0,
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 700,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      fontSize: 22,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 22,
    },
  },
  ".subtitleText": {
    display: "flex",
    justifyContent: "center",
    marginTop: 13,
    marginBottom: 35,
    paddingHorizontal: 0,
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      marginTop: 6,
      fontSize: 16,
      marginBottom: 10,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      marginTop: 6,
      fontSize: 16,
      marginBottom: 10,
    },
  },
  ".step_animation_container": {
    display: "flex",
    marginTop: 10,
    flexDirection: "row",
    gap: 20,

    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      gap: 9,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gap: 9,
    },
  },
  ".step_card": {
    paddingLeft: 35,
    paddingRight: 35,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      paddingLeft: 19.5,
      paddingRight: 12.5,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      paddingLeft: 19.5,
      paddingRight: 12.5,
    },
    ".text_container": {
      flex: 1,
      display: "flex",
      gap: 12,
      flexDirection: "column",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        gap: 4,
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        gap: 4,
      },
    },
    ".step_image_container": {
      width: 90,
      height: 90,
      borderRadius: 999,
      display: "flex",
      backgroundColor: "white",
      alignItems: "center",
      justifyContent: "center",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        width: 60,
        height: 60,
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        width: 60,
        height: 60,
      },
      ".step_image": {
        width: 60,
        height: 60,
        objectFit: "contain",
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          width: 40,
          height: 40,
        },
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          width: 40,
          height: 40,
        },
      },
    },
    ".cardTitleText": {
      paddingHorizontal: 0,
      color: theme.palette.primary.main,
      fontSize: 20,
      fontWeight: 600,
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        fontSize: 14,
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 14,
      },
    },
    ".cardSubtitleText": {
      paddingHorizontal: 0,
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 500,
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        fontSize: 12,
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 12,
      },
    },
  },
  ".cancel_order": {
    fontWeight: 600,
    cursor: "pointer",
    fontSize: 16,
    color: theme.palette.primary.main,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      fontSize: 14,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 14,
    },
  },
}));

const StepCard = ({ step }: { step: ProductCardProps }) => {
  const isMobileView = useMediaQuery(`(max-width:${TABLET_SCREEN_SIZE}px)`);
  return (
    <motion.div
      className="step_card"
      style={{
        border: "1px solid #dcdddc",
        height: isMobileView
          ? mobileCardStyleConfig.height
          : desktopCardStyleConfig.height,
        borderRadius: 8,
        backgroundColor: `rgba(246, 248, 250, 0.5)`,

        paddingTop: 10,
        paddingBottom: 10,
        flexDirection: "row",
        alignItems: "center",
      }}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: step.visible ? 1 : 0,
      }}
      transition={{
        type: "timing",
      }}
    >
      <div style={{}} className="text_container">
        <div className="cardTitleText">{step.title}</div>
        <div className="cardSubtitleText">{step.subtitle}</div>
      </div>
      <div className="step_image_container">
        <img
          className="step_image"
          alt=""
          src={step.imageSource ? step.imageSource : step.imageUrl}
        ></img>
      </div>
    </motion.div>
  );
};

const EnterCheckoutAnimation = () => {
  const isMobileView = useMediaQuery(`(max-width:${TABLET_SCREEN_SIZE}px)`);
  const {
    setDoneEnteringAnimation,

    enterAnimationConfig,
    endCheckout,
  } = useCheckout(); // Add this line
  const currentWindowHeight = window.innerHeight;

  const title = enterAnimationConfig?.title;
  const stepCards = enterAnimationConfig?.stepCards;

  const [activeIndex, setActiveIndex] = useState(0);
  const [windowPosition, setWindowPosition] = useState(0);
  const order = useAppSelector((state) => state.checkout.order);
  const enterStage = order && getEnterStage(order);
  const [delay, setDelay] = useState(getRandomDelay());
  const navigate = useNavigate();

  const offset = isMobileView ? 170 : 270;

  const cardStyleConfig = isMobileView
    ? mobileCardStyleConfig
    : desktopCardStyleConfig;

  const numberOfVisibleSteps = getNumberOfVisibleSteps(
    currentWindowHeight - offset,
    cardStyleConfig.height,
    cardStyleConfig.gap
  );

  const middleIndex = Math.floor(numberOfVisibleSteps / 2);

  useEffect(() => {
    const maxStep = getMaxStep(stepCards?.length || 0, enterStage);

    if (stepCards && activeIndex < maxStep && stepCards.length > 2) {
      const timeout = setTimeout(() => {
        setActiveIndex(activeIndex + 1);
        setDelay(getRandomDelay());
      }, delay + 1000); // 1000ms for the animation duration

      return () => clearTimeout(timeout);
    } else if (stepCards && stepCards.length > 2 && enterStage === "success") {
      setDoneEnteringAnimation(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, delay, stepCards?.length, enterStage]);

  useEffect(() => {
    if (
      stepCards &&
      activeIndex >= middleIndex &&
      windowPosition < stepCards?.length - numberOfVisibleSteps
    ) {
      setWindowPosition(activeIndex - middleIndex); // Window starts moving at number 3 and stops moving at number 18
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  // const retailerSteps = formatRetailerStep(retailerIds, checkoutOrderStatusByRetailerId);

  // const activeStep = retailerSteps.findIndex((step) => step.retailerId === currentRetailerId);

  if (!stepCards || stepCards?.length === 0) {
    return (
      <EnterAnimationContainer key="checkout-enter-animation">
        <Loading dot />
      </EnterAnimationContainer>
    );
  }

  const stepsInViewCount = Math.min(numberOfVisibleSteps, stepCards.length);

  const retailerId = enterAnimationConfig?.retailerId;

  const animationMaxHeight =
    stepsInViewCount * cardStyleConfig.height +
    (stepsInViewCount - 1) * cardStyleConfig.gap +
    15;

  return (
    <EnterAnimationContainer key="checkout-enter-animation">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="retailer"
          className="retailer_icon"
          style={{}}
          src={getEntityImage(
            (order?.userRetailerCheckoutOrder?.retailerId as number) ||
              retailerId,
            "retailer"
          )}
        />
        <div className="titleText">{title}</div>
      </div>

      <div className="subtitleText">We are preparing your order details.</div>
      <div
        style={{
          overflow: "hidden",
          maxHeight: animationMaxHeight,
        }}
      >
        <motion.div
          initial={{
            translateY: 0,
          }}
          animate={{
            translateY:
              (cardStyleConfig.height + cardStyleConfig.gap) * -windowPosition,
          }}
          style={{}}
          className="step_animation_container"
        >
          <div>
            <StoreStepper
              dotSize={30}
              steps={stepCards.map((card, index) => ({
                visible:
                  index >= windowPosition &&
                  index < windowPosition + numberOfVisibleSteps,
                success: card.success,
              }))}
              animated={true}
              currentIndex={activeIndex}
              sectionHeight={cardStyleConfig.height}
              gap={cardStyleConfig.gap}
            />
          </div>
          <div style={{ gap: cardStyleConfig.gap, flex: 1 }}>
            {stepCards.map((step, id) => (
              <StepCard
                step={{
                  title: step.title,
                  subtitle: step.subtitle,
                  visible:
                    id >= windowPosition &&
                    id < windowPosition + numberOfVisibleSteps,
                  success: step.success,
                  imageUrl: step.imageUrl,
                  imageSource: step.imageSource,
                }}
                key={id}
              />
            ))}
          </div>
        </motion.div>
      </div>
      <div style={{ width: "100%", alignItems: "center", marginTop: 20 }}>
        <div
          onClick={() => {
            endCheckout();
            navigate("/mybag", { replace: true }); // redirect to mybag
          }}
        >
          <div className="cancel_order">Cancel my order</div>
        </div>
      </div>
    </EnterAnimationContainer>
  );
};

export default EnterCheckoutAnimation;
