import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "../../initialState";
import {
  ShoppingCartItem,
  UserRetailerShoppingCart,
} from "../../../components/Checkout/types";
import { createRetailerSkuMap } from "../../../components/Checkout/utils";

type OptimisticCart = Partial<UserRetailerShoppingCart>;
interface UpdateCartParams {
  carts: OptimisticCart[];
  retailerId: number;
  handleUpdateItems: (cartItems: ShoppingCartItem[]) => ShoppingCartItem[];
}
interface UpdateCartItemParams {
  carts: OptimisticCart[];
  retailerId: number;
  retailerSku: string;
  handleUpdateItem: (item: ShoppingCartItem) => ShoppingCartItem;
}

interface AddOrUpdateCartItemsParams {
  carts: OptimisticCart[];
  retailerId: number;
  items: ShoppingCartItem[];
}

const updateCart = ({
  carts,
  retailerId,
  handleUpdateItems,
}: UpdateCartParams) => {
  return carts.map((cart) => {
    if (cart.retailerId === retailerId) {
      // only update the cart that matches the retailerId
      return { ...cart, items: handleUpdateItems(cart.items ?? []) };
    }
    // leave every other cart the same
    return cart;
  });
};

const updateCartItem = ({
  carts,
  retailerId,
  retailerSku,
  handleUpdateItem,
}: UpdateCartItemParams) => {
  return carts.map((cart) => {
    // check if the cart has items to update and that it matches the retailerId
    if (cart.items && cart.retailerId === retailerId) {
      const updatedItems = cart.items.map((i) => {
        if (i.retailerSku === retailerSku) {
          // only update the item that matches the retailerSku
          return handleUpdateItem(i);
        }
        // leave every other item the same
        return i;
      });
      return { ...cart, items: updatedItems };
    }
    return cart;
  });
};

const incrementCartItemQuantity = (item: ShoppingCartItem) => ({
  ...item,
  quantity: item.quantity + 1,
});
const decrementCartItemQuantity = (item: ShoppingCartItem) => ({
  ...item,
  quantity: item.quantity - 1,
});

const addOrUpdateCartItems = ({
  carts,
  retailerId,
  items,
}: AddOrUpdateCartItemsParams) => {
  if (!carts.some((cart) => cart.retailerId === retailerId)) {
    // if the retailerId doesn't exist in the carts, add a new cart
    return carts.concat({ retailerId, items });
  }
  const newItems: ShoppingCartItem[] = [];
  // keep track of the items that we're updating
  const updatedItemSkuSet = new Set<string>();
  const allCartItemsMap = createRetailerSkuMap(carts);
  // separate the new items from the items that we're updating
  items.forEach((item) => {
    if (allCartItemsMap.has(item.retailerSku)) {
      updatedItemSkuSet.add(item.retailerSku);
    } else {
      newItems.push(item);
    }
  });
  return updateCart({
    carts,
    retailerId,
    handleUpdateItems: (cartItems) => {
      const updatedItems = cartItems.map((item) => {
        if (updatedItemSkuSet.has(item.retailerSku)) {
          return incrementCartItemQuantity(item);
        }
        // don't do anything to items that are not being updated
        return item;
      });
      // add the new items to the updated items
      return updatedItems.concat(newItems);
    },
  });
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState: initialState.checkout,
  reducers: {
    setCart(state, action) {
      return {
        ...state,
        cart: action.payload,
      };
    },
    setProducts(state, action) {
      return {
        ...state,
        products: { ...(state.products ?? {}), ...action.payload },
      };
    },
    setAllCarts(state, action) {
      return {
        ...state,
        allCarts: action.payload,
      };
    },
    setOrder(state, action) {
      return {
        ...state,
        order: action.payload,
      };
    },
    setBatch(state, action) {
      return {
        ...state,
        batch: action.payload,
      };
    },
    toggleCartDrawer(state) {
      return { ...state, open: !state.open };
    },
    closeCartDrawer(state) {
      return { ...state, open: false };
    },
    openCartDrawer(state) {
      return { ...state, open: true };
    },
    incrementOptimisticShoppingCartItemQuantity(
      state,
      action: OptimisticCartItemQuantityUpdateAction
    ) {
      const { retailerId, retailerSku } = action.payload;
      const optimisticAllCarts = updateCartItem({
        carts: state.optimisticAllCarts ?? state.allCarts,
        retailerId,
        retailerSku,
        handleUpdateItem: incrementCartItemQuantity,
      });
      return { ...state, optimisticAllCarts };
    },
    decrementOptimisticShoppingCartItemQuantity(
      state,
      action: OptimisticCartItemQuantityUpdateAction
    ) {
      const { retailerId, retailerSku } = action.payload;
      const optimisticAllCarts = updateCartItem({
        carts: state.optimisticAllCarts ?? state.allCarts,
        retailerId,
        retailerSku,
        handleUpdateItem: decrementCartItemQuantity,
      });
      return { ...state, optimisticAllCarts };
    },
    addOptimisticShoppingCartItem(state, action: OptimisticCartUpdateAction) {
      const optimisticAllCarts = addOrUpdateCartItems({
        carts: state.optimisticAllCarts ?? state.allCarts,
        retailerId: action.payload.retailerId,
        items: action.payload.items,
      });
      return { ...state, optimisticAllCarts };
    },
    removeOptimisticShoppingCartItem(
      state,
      action: OptimisticCartUpdateAction
    ) {
      const optimisticAllCarts = updateCart({
        carts: state.optimisticAllCarts ?? state.allCarts,
        retailerId: action.payload.retailerId,
        handleUpdateItems: (cartItems) =>
          cartItems.filter(
            (cartItem) =>
              !action.payload.items.some(
                (i) => i.retailerSku === cartItem.retailerSku
              )
          ),
      });
      return { ...state, optimisticAllCarts };
    },
    clearOptimisticAllCarts(state) {
      return { ...state, optimisticAllCarts: undefined };
    },
  },
  extraReducers: (builders) => {
    builders.addDefaultCase((state) => state);
  },
});

export const {
  setAllCarts,
  setCart,
  setProducts,
  openCartDrawer,
  closeCartDrawer,
  toggleCartDrawer,
  setBatch,
  setOrder,
  incrementOptimisticShoppingCartItemQuantity,
  decrementOptimisticShoppingCartItemQuantity,
  addOptimisticShoppingCartItem,
  removeOptimisticShoppingCartItem,
  clearOptimisticAllCarts,
} = checkoutSlice.actions;

export type CheckoutState = ReturnType<typeof checkoutSlice.reducer>;
export type OptimisticCartUpdateAction = PayloadAction<{
  retailerId: number;
  items: ShoppingCartItem[];
}>;
export type OptimisticCartItemQuantityUpdateAction = PayloadAction<{
  retailerId: number;
  retailerSku: string;
}>;

export default checkoutSlice.reducer;
